import {Pipe, PipeTransform} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {ELang} from "@models/dist";

@Pipe({
  name: 'NumberPipe',
  pure: false
})
export class NumberPipe implements PipeTransform {
  private lang: ELang = ELang.FR;

  constructor(private translateService: TranslateService) {
    this.lang = this.translateService.currentLang as ELang;
    this.translateService.onLangChange.subscribe(lang => {
      this.lang = lang.lang as ELang
    });
  }

  transform(number: any): any {
    return new Intl.NumberFormat(this.lang, {notation: "compact", compactDisplay: "short"}).format(number);
  }
}
