<div class="summitModal">
  <button (click)="close()" class="close" icon="pi pi-times" pButton></button>
  <div class="modal-header">
    <div [innerHtml]="'Nom' | translate" class="title text-left"></div>
    <div
      [innerHtml]="'Changez votre nom et prénom' | translate"
      class="subtitle text-left mb-4"
    ></div>
  </div>
  <ng-container>
    <div class="modal-body">
      <div class="flex w-full">
        <ul *ngIf="user$ | async" class="list-none p-0 m-0 w-full">
          <li
            class="flex justify-content-between py-3 px-2 border-top-1 surface-border flex-wrap"
          >
            <div class="text-500 w-6 md:w-2 font-medium">Nom</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
              <input [(ngModel)]="lastName" pInputText type="text">
            </div>
          </li>
          <li
            class="flex justify-content-between py-3 px-2 border-top-1 surface-border flex-wrap"
          >
            <div class="text-500 w-6 md:w-2 font-medium">Prénom</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
              <input [(ngModel)]="firstName" pInputText type="text">
            </div>
          </li>
        </ul>
      </div>
    </div>
  </ng-container>
  <div class="modal-body">
    <div class="col 12 flex justify-content-between actions-buttons">
      <button
        (click)="close()"
        [label]="'Common.cancel' | translate"
        class="p-button-outlined"
        pButton
      ></button>
      <button
        [label]="'Common.submit' | translate"
        bgColor="p-button-primary"
        pButton
        rButton
      ></button>
    </div>
  </div>
</div>
