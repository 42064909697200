<button
  [disabled]="disabled"
  [label]="label"
  [class.w-auto]="wAuto"
  class="research-btn"
  [class.outline]="outline"
  [class.disabled]="disabled"
  *ngIf="!children"
  [icon]="icon"
  pRipple
  pButton
  rButton
  [rId]="rId"
>
</button>

<ng-container *ngIf="children">
  <button class="research-btn"
          >
    <ng-content></ng-content>
  </button>
</ng-container>
