import {EResearchRoutes} from "@models/dist";

interface IResearchDirectory {
  label: string
  items: any[]
}


const directory: IResearchDirectory[] = [
  {
    label: 'menu.research.home',
    items: [
      {
        label: 'menu.research.dashboard',
        icon: 'home-outline',
        routerLink: ['/research/' + EResearchRoutes.DASHBOARD],
      },
      {
        label: 'menu.research.portfolio',
        icon: 'wallet-outline',
        routerLink: ['/research/portfolio'],
      },
      {
        label: 'menu.research.projects',
        icon: 'wallet-outline',
        routerLink: ['/research/' + EResearchRoutes.PROJECTS],
      },
      // {
      //   label: 'menu.research.nextAnalyzes',
      //   icon: 'time-outline',
      //   routerLink: ['/research/' + EResearchRoutes.NEXT_PROJECTS],
      // },
      // {
      //   label: 'menu.research.reports',
      //   icon: 'document-text-outline',
      //   routerLink: ['/research/' + EResearchRoutes.REPORTS],
      // },
      {
        label: 'menu.research.analyzes',
        icon: 'bar-chart-outline',
        routerLink: ['/research/' + EResearchRoutes.ANALYSIS],
      },
      {
        label: 'menu.research.guides',
        icon: 'people-outline',
        routerLink: ['/research/' + EResearchRoutes.GUIDES],
      },
      {
        label: 'menu.research.offers',
        icon: 'pricetags-outline',
        routerLink: ['/research/' + EResearchRoutes.OFFERS],
      },
      // {
      //   label: 'menu.research.transaction',
      //   icon: 'receipt-outline',
      //   routerLink: ['/research/' + EResearchRoutes.TRANSACTIONS],
      // },
      {
        label: 'menu.research.account',
        icon: 'person-circle-outline',
        routerLink: ['/research/'+EResearchRoutes.TRANSACTIONS],
      },

    ],
  }
];





export default directory;
