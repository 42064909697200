export default [
  {
    label: 'menu.gravity.home',
    items: [
      {
        label: 'menu.gravity.dashboard',
        icon: 'pi pi-fw pi-home',
        routerLink: ['/gravity/dashboard'],
      },
    ],
  }
];
