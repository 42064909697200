<div class="kyc">
  <app-ico-top [nohelp]="true" [title]="'KYC.page.title' | translate : {title : 'KYB'}"></app-ico-top>
  <p-card class="mt-3 p-3">
    <div class="flex stepper">
      <div
        [ngClass]="{ active: tutorialStep === ETutorialStep.START, passed: tutorialStep > ETutorialStep.START }"
        class="col p-0 m-2 step"
      ></div>
      <div
        [ngClass]="{ active: tutorialStep === ETutorialStep.ONE, passed: tutorialStep > ETutorialStep.ONE }"
        class="col p-0 m-2 step"
      ></div>
      <div
        [ngClass]="{ active: tutorialStep === ETutorialStep.TWO, passed: tutorialStep > ETutorialStep.TWO }"
        class="col p-0 m-2 step"
      ></div>
      <div
        [ngClass]="{ active: tutorialStep === ETutorialStep.THREE, passed: tutorialStep > ETutorialStep.THREE }"
        class="col p-0 m-2 step"
      ></div>
      <div
        [ngClass]="{ active: tutorialStep === ETutorialStep.FOUR, passed: tutorialStep > ETutorialStep.FOUR }"
        class="col p-0 m-2 step"
      ></div>
      <div
        [ngClass]="{ active: tutorialStep === ETutorialStep.FIVE, passed: tutorialStep > ETutorialStep.FIVE }"
        class="col p-0 m-2 step"
      ></div>
      <div
        [ngClass]="{ active: tutorialStep === ETutorialStep.SIX, passed: tutorialStep > ETutorialStep.SIX }"
        class="col p-0 m-2 step"
      ></div>
      <div
        [ngClass]="{ active: tutorialStep === ETutorialStep.SEVEN, passed: tutorialStep > ETutorialStep.SEVEN }"
        class="col p-0 m-2 step"
      ></div>
      <div
        [ngClass]="{ active: tutorialStep === ETutorialStep.EIGHT, passed: tutorialStep > ETutorialStep.EIGHT }"
        class="col p-0 m-2 step"
      ></div>
    </div>
    <ng-container *ngIf="tutorialStep === ETutorialStep.START">
      <div class="center-screen content-step">
        <div class="flex flex-1 flex-column align-items-start">


          <h1 class="title">
            {{ "KybTutorial.title0" | translate : {title: 'KYB'} }}
          </h1>
          <h6 class="subtitle">
            {{ "KybTutorial.subtitle0" | translate :{title: 'KYB'} }}
          </h6>
          <div [innerHTML]="'KybTutorial.text0' | translate :{title : 'KYB'}" class="content"></div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="tutorialStep === ETutorialStep.ONE">
      <div class="center-screen content-step">
        <div class="flex flex-1 flex-column align-items-start">
          <h6 class="title">
            {{ "KybTutorial.title1" | translate :{title: 'KYB'} }}
          </h6>
          <h6 class="subtitle">
            {{ "KybTutorial.subtitle1" | translate :{title: 'KYB'} }}
          </h6>
          <div [innerHTML]="'KybTutorial.text1' | translate :{title : 'KYB'}" class="content"></div>
        </div>
        <div class="flex img-ctn">
          <img alt="" height="248px" src="assets/forms/kyc/hello.png"/>

        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="tutorialStep === ETutorialStep.TWO">
      <div class="center-screen content-step">
        <div class="flex flex-1 flex-column align-items-start justify-content-center">
          <h6 class="title">
            {{ "KybTutorial.title2" | translate :{title: 'KYB'} }}
          </h6>
          <h6 class=" subtitle ">
            {{ "KybTutorial.subtitle2" | translate :{title: 'KYB'} }}
          </h6>
          <div [innerHTML]="'KybTutorial.text2' | translate :{title : 'KYB'}" class="content"></div>
          <a (click)="openAcceptedCountryModal()" class="cursor-pointer">{{
            "KybTutorial.euAccepted" | translate :{title: 'KYB'}
            }}</a>
          <div class="flex selector-ctn">
            <div
              (click)="euSelect = true"
              [class.is-selected]="euSelect"
              class="selector"
            >
              <img class="euImg" src="assets/forms/kyc/eu.svg"/>
              <div class="label">
                {{ "KybTutorial.euYes" | translate :{title: 'KYB'} }}
              </div>
            </div>
            <div
              (click)="euSelect = false"
              [class.is-selected]="!euSelect"
              class="selector"
            >
              <img class="euImg" src="assets/forms/kyc/eu-disabled.svg"/>
              <div class="label">
                {{ "KybTutorial.euNo" | translate :{title: 'KYB'} }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex img-ctn">
          <img alt="" height="248px" src="assets/forms/kyc/EU_map.png"/>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="tutorialStep === ETutorialStep.THREE">
      <div class="center-screen content-step">
        <div class="flex flex-column align-items-start justify-content-center">
          <h6 class=" title">
            {{ "KybTutorial.title3" | translate :{title: 'KYB'} }}
          </h6>
          <h6 class=" subtitle ">
            {{ "KybTutorial.subtitle3" | translate :{title: 'KYB'} }}
          </h6>
          <div [innerHTML]="'KybTutorial.text3' | translate :{title : 'KYB'}" class="content"></div>
        </div>
        <div class="flex">
          <img alt="" height="248px" src="assets/forms/kyc/credit_card.png"/>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="tutorialStep === ETutorialStep.FOUR">
      <div class="center-screen content-step">

        <div class="flex flex-column align-items-start justify-content-start">

          <h6 class=" title">
            {{ "KybTutorial.title4" | translate :{title: 'KYB'} }}
          </h6>
          <h6 class=" subtitle">
            {{ "KybTutorial.subtitle4" | translate :{title: 'KYB'} }}
          </h6>
          <div [innerHTML]="'KybTutorial.text4' | translate :{title : 'KYB'}" class="content"></div>
        </div>
        <div class="flex img-ctn">
          <img alt="" height="248px" src="assets/forms/kyc/selfie.png"/>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="tutorialStep === ETutorialStep.FIVE">
      <div class="center-screen content-step">

        <div class="flex flex-column align-items-start justify-content-start">
          <h6 class=" title">
            {{ "KybTutorial.title5" | translate :{title: 'KYB'} }}
          </h6>
          <h6 class=" subtitle ">
            {{ "KybTutorial.subtitle5" | translate :{title: 'KYB'} }}
          </h6>
          <div [innerHTML]="'KybTutorial.text5' | translate :{title : 'KYB'}" class="content"></div>
          <a
            (click)="openAcceptedSepaCountryModal()"
            class="cursor-pointer mt-5"
          >{{ "KybTutorial.euSepaAccepted" | translate :{title: 'KYB'} }}</a
          >
        </div>
        <div class="flex img-ctn">
          <img alt="" height="200px" src="assets/forms/kyc/vault.png"/>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="tutorialStep === ETutorialStep.SIX">
      <div class="center-screen content-step">

        <div class="flex flex-column align-items-start justify-content-start">
          <h6 class=" title">
            {{ "KybTutorial.title6" | translate :{title: 'KYB'} }}
          </h6>
          <h6 class=" subtitle ">
            {{ "KybTutorial.subtitle6" | translate :{title: 'KYB'} }}
          </h6>
          <div [innerHTML]="'KybTutorial.text6' | translate :{title : 'KYB'}" class="content"></div>
        </div>
        <div class="flex img-ctn">
          <img alt="" src="assets/forms/kyc/home.png"/>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="tutorialStep === ETutorialStep.SEVEN">
      <div class="center-screen content-step">

        <div class="flex flex-column align-items-start justify-content-start">
          <h6 class=" title">
            {{ "KybTutorial.title10" | translate :{title: 'KYB'} }}
          </h6>
          <h6 class=" subtitle ">
            {{ "KybTutorial.subtitle10" | translate :{title: 'KYB'} }}
          </h6>
          <div [innerHTML]="'KybTutorial.text10' | translate :{title : 'KYB'}" class="content"></div>
        </div>
        <div class="flex img-ctn">
          <img alt="" src="assets/forms/kyc/building.png"/>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="tutorialStep === ETutorialStep.EIGHT">
      <div class="center-screen content-step">

        <div class="flex flex-column align-items-start justify-content-start">
          <h6 class=" title">
            {{ "KybTutorial.title7" | translate :{title: 'KYB'} }}
          </h6>
          <h6 class=" subtitle">
            {{ "KybTutorial.subtitle7" | translate :{title: 'KYB'} }}
          </h6>
          <div [innerHTML]="'KybTutorial.text7' | translate :{title : 'KYB'}" class="content"></div>
        </div>
        <div class="flex img-ctn">
          <img alt="" src="assets/forms/kyc/confirmed.png"/>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="tutorialStep === ETutorialStep.NINE">
      <div class="center-screen content-step">
        <div class="flex flex-column align-items-start justify-content-center">
          <h6 class="gradient-text title">
            {{ "KybTutorial.title8" | translate :{title: 'KYB'} }}
          </h6>
          <h6 class="text-black-600 subtitle mt-3">
            {{ "KybTutorial.subtitle8" | translate :{title: 'KYB'} }}
          </h6>
          <div [innerHTML]="'KybTutorial.text8' | translate :{title : 'KYB'}"></div>
        </div>
        <div class="flex img-ctn">
          <img alt="" height="249px" src="assets/forms/kyc/error.png"/>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="tutorialStep === ETutorialStep.TEN">
      <div class="center-screen content-step">
        <div class="flex flex-column align-items-start justify-content-center">
          <h6 class="gradient-text title">
            {{ "KybTutorial.title9" | translate :{title: 'KYB'} }}
          </h6>
          <h6 class="text-black-600 subtitle mt-3">
            {{ "KybTutorial.subtitle9" | translate :{title: 'KYB'} }}
          </h6>
          <div [innerHTML]="'KybTutorial.text9' | translate :{title : 'KYB'}"></div>
        </div>
        <div class="flex img-ctn">
          <img alt="" height="249px" src="assets/forms/kyc/missing_document.png"/>
        </div>
      </div>
    </ng-container>

    <ng-template pTemplate="footer">
      <div class="actions" [class.justify-content-between]="tutorialStep < ETutorialStep.EIGHT"
           [class.justify-content-end]="tutorialStep >= ETutorialStep.EIGHT">
        <button (click)="tutorialStep = ETutorialStep.EIGHT"
                [label]="'KybTutorial.skipTutorial' | translate :{title : 'KYB'}"
                *ngIf="tutorialStep < ETutorialStep.EIGHT"
                class="outline-button md:w-10rem w-full"
                pButton
                pRipple
                type="button"></button>
        <div class="next-ctn">
          <ng-container *ngIf="tutorialStep !== ETutorialStep.THREE">

            <button (click)="prevStep()"
                    *ngIf="tutorialStep > ETutorialStep.START && tutorialStep < ETutorialStep.NINE"
                    [label]="'Common.back' | translate :{title : 'KYB'}"
                    class="outline-button md:w-10rem  w-full mr-1"
                    pButton
                    type="button"></button>

            <app-ico-btn
              (click)="nextStep()"
              *ngIf="tutorialStep < ETutorialStep.EIGHT"
              [text]="'Common.next' | translate:{title : 'KYB'}"
              class="md:w-10rem w-full"></app-ico-btn>


            <app-ico-btn
              (click)="openKyc()"
              *ngIf="tutorialStep === ETutorialStep.EIGHT"
              [disabled]="user?.accountType === EAccountType.INDIVIDUAL && !user?.passfortFormRequest?.data"
              [text]="'KYC.page.startMyKyc' | translate:{title : 'KYB'}"
              class="md:w-10rem w-full"></app-ico-btn>

          </ng-container>


          <ng-container *ngIf="tutorialStep === ETutorialStep.THREE">
            <div class="flex btnChildBox">

              <app-ico-btn
                (click)="tutorialStep = ETutorialStep.TEN"
                [outline]="true"
                [text]="'Common.no' | translate:{title : 'KYB'}"
                class="md:w-10rem  w-full mr-1"></app-ico-btn>

              <app-ico-btn
                (click)="nextStep()"
                [text]="'Common.yes' | translate:{title : 'KYB'}"
                class="md:w-10rem w-full"></app-ico-btn>

            </div>
          </ng-container>

          <app-ico-btn
            (click)="navigateTo('profile/kyc')"
            *ngIf="tutorialStep >= ETutorialStep.NINE"
            [text]="'Common.quit' | translate:{title : 'KYB'}"
            class="md:w-10rem w-full"></app-ico-btn>
        </div>
      </div>
    </ng-template>
  </p-card>
</div>
