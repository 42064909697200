import {Component, OnInit} from '@angular/core';
import {Store} from "@ngxs/store";
import {Logout} from "@shared/stores/auth/auth.actions";
import {DynamicDialogRef} from "primeng/dynamicdialog";
import {Router} from "@angular/router";

@Component({
  selector: 'app-error-migrated',
  templateUrl: './error-migrated.component.html',
  styleUrls: ['./error-migrated.component.scss']
})
export class ErrorMigratedComponent implements OnInit {

  constructor(
    public ref: DynamicDialogRef, private store: Store, private router: Router) {
  }

  ngOnInit(): void {
  }

  logout() {
    this.store.dispatch(new Logout())
    this.ref.close();
    this.router.navigateByUrl('/')
  }
}
