import directoryIcoConst from "./directory.ico.const";
import directoryResearchConst from "./directory.research.const";
import directoryMediasConst from "./directory.medias.const";

import {IMenuDirectory} from "@shared/components/menuItem/directory.interface";
import {ERoutes} from "@models/dist";
import directoryMiningConst from "@shared/components/menuItem/directory.mining.const";
import directoryGravityConst from "@shared/components/menuItem/directory.gravity.const";
import directorySummitConst from "@shared/components/menuItem/directory.summit.const";

export const MenuDirectory: Array<IMenuDirectory> = [
  // {
  //   title: 'menu.main.summitgroup',
  //   color: 'white',
  //   background: '#1A202C',
  //   childrens: directoryGroupConst,
  //   baseLink: 'group',
  //   rotated: false,
  //   active: false,
  // },

  {
    title: 'menu.main.summit',
    color: 'black',
    background: '#1A202C',
    children: directorySummitConst,
    baseLink: 'summit',
    rotated: false,
    route: ERoutes.SUMMIT,
    active: false,
    display: true,
    disabled: false,
    medias: false,
  },

  {
    title: 'menu.main.summittoken',
    color: 'white',
    background: 'linear-gradient(45deg, #D60580, #00FFFF)',
    children: directoryIcoConst,
    baseLink: 'ico',
    rotated: true,
    route: ERoutes.ICO,
    active: false,
    display: true,
    disabled: false,
    medias: false,
  },
  {
    title: 'menu.main.summitmining',
    color: 'white',
    background: 'linear-gradient(45deg, #0000F5 11%, #2454F8 38%, #51BDFD 73%, #64E8FF 88%)',
    children: directoryMiningConst,
    route: '/mining/home/dashboard',
    baseLink: 'mining',
    rotated: false,
    active: false,
    display: true,
    disabled: false,
    medias: false,
  },
  {
    title: 'menu.main.summitgravity',
    color: 'white',
    background: 'linear-gradient(45deg, #FF00CC 11%, #FF2AA9 24%, #FF9351 54%, #FFD71A 73%)',
    children: directoryGravityConst,
    route: '/gravity/dashboard',
    baseLink: 'gravity',
    rotated: false,
    active: false,
    display: true,
    disabled: false,
    medias: false,
  },
  // {
  //   title: 'menu.main.summitquantum',
  //   color: 'white',
  //   background: 'linear-gradient(45deg, #00FFFF, #00B15E)',
  //   children: [],
  //   route: ERoutes.NONE,
  //   baseLink: '',
  //   rotated: false,
  //   active: false,
  //   disabled: true,
  //   display: true,
  // },
  {
    title: 'menu.main.summitresearch',
    color: 'white',
    background: 'linear-gradient(45deg, #6D34DB, #C79CCE)',
    children: directoryResearchConst,
    route: ERoutes.RESEARCH,
    baseLink: 'research',
    rotated: false,
    active: false,
    disabled: false,
    display: true,
    medias: false,
  },
  {
    title: 'menu.main.summitmedias',
    color: 'white',
    background: '#DA0000',
    children: directoryMediasConst,
    route: 'medias',
    baseLink: 'medias',
    rotated: true,
    active: false,
    disabled: false,
    display: true,
    medias: true,
  },
];
