import { Injectable } from '@angular/core';
import {environment} from "@environments/environment";
import {HttpClient} from "@angular/common/http";
import {firstValueFrom} from "rxjs";
const BASE = `${environment.nestApiUrl}/api/sgp/currency`;

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  constructor(private http: HttpClient) {
  }


  getEuroDollarRate() {
    return firstValueFrom(this.http.get(`${BASE}/usd-eur`))
  }

  getCurrencies(service = 'ICO') {
    return firstValueFrom(this.http.get( `${BASE}?service=${service}`));
  }
}
