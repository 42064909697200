<div class="errorDisplay">
  <!--close modal button -->
  <button
    class="close"
    icon="pi pi-times"
    (click)="close()"
    pButton
  ></button>
  <div class="modal-header">
    <div class="display-block">
      <div [innerHTML]="'welcome-popup.title' | translate" class="title text-center">
      </div>
    </div>


  </div>
  <div class="modal-body text-center">
    <span [innerHTML]="'welcome-popup.description' | translate" class="description text-center"></span>
    <img class="w-full object-container mt-4" [src]="currentImg" alt="">
  </div>
  <div class="modal-footer">
    <app-ico-btn (click)="done()" routerLink="./invest" [text]="'welcome-popup.invest'|translate"></app-ico-btn>
  </div>
</div>
