<div *ngIf="infosForm" class="form-container">
  <form [formGroup]="infosForm">
    <ng-container *ngIf="infosForm?.controls?.password">
      <div class="w-full  mx-auto relative">
        <!--        <label class="block text-900 font-medium mb-2" for="password">-->
        <!--          {{ "password" | translate }}-->
        <!--        </label>-->
        <!--        <span class="required" *ngIf="infosForm.controls.password.invalid"></span>-->
        <div (click)="displayPassword = !displayPassword" class="showPassword">
          <ion-icon name="{{ !displayPassword ? 'eye-off-outline' : 'eye-outline' }}"></ion-icon>
        </div>

        <input
          (keyup)="keyupEnter()"
          (change)="keyupEnter()"
          (input)="keyupEnter()"
          [type]="displayPassword? 'text' : 'password'"
          autocomplete="password"
          class="w-full "
          #password
          formControlName="password"
          id="password2"
          pInputText
          placeholder="{{ 'password' | translate }}"
          style="padding: 1rem"
        />


        <!--        <p-password-->
        <!--          required-->
        <!--          (keyup)="keyupEnter()"-->
        <!--          placeholder="{{ 'password' | translate }}"-->
        <!--          autocomplete="password"-->
        <!--          class="w-full "-->
        <!--          formControlName="password"-->
        <!--          [toggleMask]="true"-->
        <!--          id="password"-->
        <!--        >-->

        <!--          &lt;!&ndash;ng-template pTemplate="header">-->
        <!--            <h6>Pick a password</h6>-->
        <!--          </ng-template&ndash;&gt;-->
        <!--          <ng-template pTemplate="footer">-->
        <!--            <p-divider></p-divider>-->
        <!--            <p class="mt-2">Suggestions</p>-->
        <!--            <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">-->
        <!--              <li-->
        <!--                [style.color]="-->
        <!--                      errorControl?.password?.errors?.minlength ? 'red' : 'green'-->
        <!--                    "-->
        <!--                class="form-error full"-->
        <!--              >-->
        <!--                {{ "errorLength" | translate: {length: 8} }}-->
        <!--              </li>-->
        <!--              <li-->
        <!--                [style.color]="-->
        <!--                      errorControl?.password?.errors?.noLowerCase-->
        <!--                        ? 'red'-->
        <!--                        : 'green'-->
        <!--                    "-->
        <!--                class="form-error full"-->
        <!--              >-->
        <!--                {{ "mustGetLowercase" | translate }}-->
        <!--              </li>-->
        <!--              <li-->
        <!--                [style.color]="-->
        <!--                      errorControl?.password?.errors?.noUpperCase-->
        <!--                        ? 'red'-->
        <!--                        : 'green'-->
        <!--                    "-->
        <!--                class="form-error full"-->
        <!--              >-->
        <!--                {{ "mustGetUppercase" | translate }}-->
        <!--              </li>-->
        <!--              <li-->
        <!--                [style.color]="-->
        <!--                      errorControl?.password?.errors?.noNumber ? 'red' : 'green'-->
        <!--                    "-->
        <!--                class="form-error full"-->
        <!--              >-->
        <!--                {{ "mustGetNumber" | translate }}-->
        <!--              </li>-->
        <!--              <li-->
        <!--                [style.color]="-->
        <!--                      errorControl?.password?.errors?.noSpecialChar-->
        <!--                        ? 'red'-->
        <!--                        : 'green'-->
        <!--                    "-->
        <!--                class="form-error full"-->
        <!--              >-->
        <!--                {{ "mustGetSpecialChar" | translate }}-->
        <!--              </li>-->
        <!--            </ul>-->
        <!--          </ng-template>-->
        <!--        </p-password>-->
        <div class="mb-3"></div>
      </div>
    </ng-container>

    <ng-container *ngIf="infosForm?.controls?.confirmPassword">
      <div class="w-full  mx-auto relative">
        <!--        <label-->
        <!--          class="block text-900 font-medium mb-2"-->
        <!--          for="confirmPassword"-->
        <!--        >-->
        <!--          {{ "confirmPassword" | translate }}-->
        <!--        </label>-->
        <!--        <span class="required" *ngIf="infosForm.controls.password.invalid"></span>-->

        <input
          (keyup)="keyupEnter()"
          (change)="keyupEnter()"
          (input)="keyupEnter()"
          [type]="displayPassword? 'text' : 'password'"
          autocomplete="password"
          class="w-full "
          formControlName="confirmPassword"
          id="confirmPassword"
          pInputText
          placeholder="{{ 'confirmPassword' | translate }}"
          style="padding: 1rem"
        />

        <div (click)="displayPassword = !displayPassword" class="showPassword">
          <ion-icon name="{{ !displayPassword ? 'eye-off-outline' : 'eye-outline' }}"></ion-icon>
        </div>
      </div>
      <!-- Error messages -->

      <ul class="pl-2 mt-4" style="line-height: 1.5; list-style-type: none;">
        <li
          [style.color]=" errorControl?.confirmPassword?.value.length ? (errorControl?.confirmPassword?.dirty &&
              infosForm.hasError('notSame') ? 'red': 'green') : 'red'
                            " class="form-error full flex-end">

          <div>
            <ion-icon class="padding-right" *ngIf="errorControl?.confirmPassword?.value.length && errorControl?.confirmPassword?.dirty &&
            infosForm.hasError('notSame')" color="red" name="close-outline"></ion-icon>
            <ion-icon class="padding-right" *ngIf="(!errorControl?.confirmPassword?.dirty || !infosForm.hasError('notSame')) &&
            errorControl?.confirmPassword?.value.length" color="green" name="checkmark-outline"></ion-icon>
            <ion-icon class="padding-right" *ngIf="!errorControl?.confirmPassword?.value.length" color="red" name="close-outline"></ion-icon>
          </div>
          {{ "passwordsDoNotMatch" | translate }}
        </li>
        <li
          [style.color]="
                              errorControl?.password?.errors?.minlength ? 'red' : 'green'
                            "
          class="form-error full flex-end"
        >
          <div>
            <ion-icon class="padding-right" *ngIf="!errorControl?.password?.errors?.minlength" color="green" name="checkmark-outline"></ion-icon>
            <ion-icon class="padding-right" *ngIf="errorControl?.password?.errors?.minlength" color="red" name="close-outline"></ion-icon>
          </div>
          {{ "errorLength" | translate: {length: 8} }}
        </li>
        <li
          [style.color]="
                              errorControl?.password?.errors?.noLowerCase
                                ? 'red'
                                : 'green'
                            "
          class="form-error full flex-end"
        >
          <div>
            <ion-icon class="padding-right" *ngIf="!errorControl?.password?.errors?.noLowerCase" color="green" name="checkmark-outline"></ion-icon>
            <ion-icon class="padding-right" *ngIf="errorControl?.password?.errors?.noLowerCase" color="red" name="close-outline"></ion-icon>
          </div>
          {{ "mustGetLowercase" | translate }}
        </li>
        <li
          [style.color]="
                              errorControl?.password?.errors?.noUpperCase
                                ? 'red'
                                : 'green'
                            "
          class="form-error full flex-end"
        >
          <div>
            <ion-icon class="padding-right" *ngIf="!errorControl?.password?.errors?.noUpperCase" color="green" name="checkmark-outline"></ion-icon>
            <ion-icon class="padding-right" *ngIf="errorControl?.password?.errors?.noUpperCase" color="red" name="close-outline"></ion-icon>
          </div>
          {{ "mustGetUppercase" | translate }}
        </li>
        <li
          [style.color]="
                              errorControl?.password?.errors?.noNumber ? 'red' : 'green'
                            "
          class="form-error full flex-end"
        >
          <div>
            <ion-icon class="padding-right" *ngIf="!errorControl?.password?.errors?.noNumber" color="green" name="checkmark-outline"></ion-icon>
            <ion-icon class="padding-right" *ngIf="errorControl?.password?.errors?.noNumber" color="red" name="close-outline"></ion-icon>
          </div>
          {{ "mustGetNumber" | translate }}
        </li>
        <li
          [style.color]="errorControl?.password?.errors?.noSpecialChar ? 'red' : 'green'"class="form-error full flex-end">
          <div>
            <ion-icon class="padding-right" *ngIf="!errorControl?.password?.errors?.noSpecialChar" color="green" name="checkmark-outline"></ion-icon>
            <ion-icon class="padding-right" *ngIf="errorControl?.password?.errors?.noSpecialChar" color="red" name="close-outline"></ion-icon>
          </div>
          {{ "mustGetSpecialChar" | translate }}
        </li>
      </ul>
    </ng-container>
  </form>
</div>
