<div class="errorDisplay">
  <!--close modal button -->
  <button
    class="close"
    icon="pi pi-times"
    pButton
    (click)="close()"
  ></button>
  <div class="modal-header">
    <div [innerHTML]="title | translate" class="title text-center">

    </div>

  </div>
  <div class="modal-body text-center flex flex-column aligns-items-center justify-content-center">
    <span [innerHTML]="'contact.modal.description' | translate" class="description text-center"></span>

    <div class=" flex flex-column align-items-center mt-4">
<!--      <div class="tel-ctn flex justify-content-between align-items-center">-->
<!--        <div class="flex align-items-center gap-3">-->
<!--          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">-->
<!--            <path d="M19.2308 23.9998C18.1849 23.9998 16.7157 23.6216 14.5156 22.3926C11.8402 20.8926 9.77084 19.5078 7.10994 16.8543C4.54442 14.2909 3.29595 12.6313 1.54863 9.4523C-0.425348 5.86297 -0.0888504 3.98152 0.287298 3.1774C0.735247 2.21632 1.39645 1.64149 2.25109 1.07094C2.73652 0.75296 3.25023 0.480375 3.78569 0.256649C3.83927 0.233613 3.88911 0.211648 3.93358 0.191827C4.19881 0.072361 4.60068 -0.108177 5.10971 0.0846825C5.44942 0.212184 5.7527 0.47308 6.22744 0.941836C7.20103 1.90185 8.53148 4.03991 9.0223 5.08992C9.35183 5.79761 9.56991 6.26476 9.57045 6.78869C9.57045 7.40209 9.26181 7.87513 8.88727 8.38568C8.81708 8.48157 8.74742 8.57318 8.67991 8.66211C8.27214 9.19783 8.18266 9.35265 8.2416 9.62908C8.36109 10.1846 9.25217 11.8384 10.7166 13.2993C12.181 14.7602 13.7874 15.5949 14.3452 15.7138C14.6334 15.7754 14.7915 15.6822 15.3445 15.26C15.4238 15.1995 15.5052 15.1368 15.5904 15.0741C16.1616 14.6493 16.6128 14.3488 17.2118 14.3488H17.215C17.7364 14.3488 18.1827 14.5749 18.9222 14.9477C19.8867 15.4342 22.0894 16.7472 23.0555 17.7217C23.5254 18.1953 23.7875 18.4974 23.9155 18.8365C24.1084 19.3471 23.9268 19.7472 23.8084 20.0151C23.7885 20.0596 23.7666 20.1083 23.7435 20.1624C23.518 20.6968 23.2437 21.2094 22.9242 21.6935C22.3547 22.5453 21.7776 23.2048 20.8142 23.6532C20.3195 23.8871 19.778 24.0057 19.2308 23.9998Z" fill="#D60580"/>-->
<!--          </svg>-->
<!--          <a-->
<!--            [innerHTML]="'+33 1 76 40 39 49'"-->
<!--            class="cursor-pointer" href="tel:+33176403949"></a>-->
<!--        </div>-->
<!--        <app-copy [text]="'+33176403949'"></app-copy>-->
<!--      </div>-->
      <div class="tel-ctn mb-0 flex justify-content-between align-items-center">
        <div class="flex align-items-center gap-3">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20" fill="none">
            <path d="M21 0.5H3C2.20461 0.500857 1.44204 0.819598 0.879619 1.38628C0.317195 1.95297 0.000850835 2.72131 0 3.52273V16.4773C0.000850835 17.2787 0.317195 18.047 0.879619 18.6137C1.44204 19.1804 2.20461 19.4991 3 19.5H21C21.7954 19.4991 22.558 19.1804 23.1204 18.6137C23.6828 18.047 23.9991 17.2787 24 16.4773V3.52273C23.9991 2.72131 23.6828 1.95297 23.1204 1.38628C22.558 0.819598 21.7954 0.500857 21 0.5ZM20.2404 5.49992L12.5261 11.5454C12.3756 11.6632 12.1905 11.7272 12 11.7272C11.8095 11.7272 11.6244 11.6632 11.4739 11.5454L3.75964 5.49992C3.66901 5.43095 3.59288 5.34452 3.53568 5.24566C3.47848 5.14679 3.44135 5.03746 3.42646 4.92401C3.41156 4.81056 3.41918 4.69526 3.44889 4.58481C3.4786 4.47436 3.5298 4.37095 3.59952 4.28061C3.66923 4.19027 3.75607 4.11479 3.85499 4.05855C3.95391 4.00232 4.06293 3.96645 4.17572 3.95304C4.28852 3.93962 4.40283 3.94893 4.51203 3.98042C4.62122 4.0119 4.72311 4.06494 4.81179 4.13645L12 9.76952L19.1882 4.13645C19.368 3.99968 19.5939 3.9397 19.8172 3.96947C20.0405 3.99924 20.2432 4.11637 20.3814 4.29553C20.5197 4.4747 20.5824 4.70149 20.556 4.92687C20.5296 5.15225 20.4162 5.35811 20.2404 5.49992Z" fill="#D60580"/>
          </svg>
          <a
            [innerHTML]="'hello@summit.io'"
            class="cursor-pointer" href="mailto:hello@summit.io"></a>
        </div>
        <app-copy [text]="'hello@summit.io'"></app-copy>
      </div>
<!--      <span class="or">{{ 'contact.modal.or' | translate}}</span>-->
<!--      <app-ico-btn (click)="goToCalendly()" class="w-full" [text]="'contact.modal.rdv' | translate"></app-ico-btn>-->
<!--      <div (click)="goToCalendly()" class="rdv-btn cursor">-->
<!--        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26" fill="none">-->
<!--          <path d="M24.75 5.99992C24.75 5.07166 24.3813 4.18142 23.7249 3.52505C23.0685 2.86867 22.1783 2.49992 21.25 2.49992H20.375V1.64953C20.375 1.17867 20.013 0.77453 19.5421 0.751014C19.4238 0.745314 19.3056 0.763693 19.1946 0.805037C19.0836 0.846382 18.9821 0.909832 18.8964 0.991541C18.8106 1.07325 18.7424 1.17152 18.6957 1.28039C18.6491 1.38926 18.625 1.50647 18.625 1.62492V2.49992H6.375V1.64953C6.375 1.17867 6.01297 0.77453 5.54211 0.751014C5.4238 0.745314 5.30557 0.763693 5.19457 0.805037C5.08358 0.846382 4.98213 0.909832 4.89638 0.991541C4.81063 1.07325 4.74237 1.17152 4.69572 1.28039C4.64907 1.38926 4.62501 1.50647 4.625 1.62492V2.49992H3.75C2.82174 2.49992 1.9315 2.86867 1.27513 3.52505C0.618749 4.18142 0.25 5.07166 0.25 5.99992V6.65617C0.25 6.71419 0.273047 6.76983 0.31407 6.81085C0.355094 6.85187 0.410734 6.87492 0.46875 6.87492H24.5312C24.5893 6.87492 24.6449 6.85187 24.6859 6.81085C24.727 6.76983 24.75 6.71419 24.75 6.65617V5.99992ZM0.25 21.7499C0.25 22.6782 0.618749 23.5684 1.27513 24.2248C1.9315 24.8812 2.82174 25.2499 3.75 25.2499H21.25C22.1783 25.2499 23.0685 24.8812 23.7249 24.2248C24.3813 23.5684 24.75 22.6782 24.75 21.7499V8.84367C24.75 8.78565 24.727 8.73001 24.6859 8.68899C24.6449 8.64797 24.5893 8.62492 24.5312 8.62492H0.46875C0.410734 8.62492 0.355094 8.64797 0.31407 8.68899C0.273047 8.73001 0.25 8.78565 0.25 8.84367V21.7499Z" fill="white"/>-->
<!--        </svg>-->
<!--        <span>-->
<!--          {{ 'contact.modal.rdv' | translate }}-->
<!--        </span>-->
<!--      </div>-->
      <app-ico-btn [outline]="true" (click)="close()" class="w-full mt-5" [text]="'contact.modal.close' | translate"></app-ico-btn>

      <!--      <span class="or">{{ 'contact.modal.or' | translate}}</span>-->

    </div>
  </div>
</div>
