import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DEFAULT_QUEUE_TIME, ETransactionCurrency, ETransactionStatus, ITransaction, IUser} from "@models/dist";
import {
  getAmount,
  getCurrencyImg,
  getDate,
  getInfos,
  getStatus,
  getTransactionDirection,
  getTransactionProduct,
  getUid
} from '@shared/helpers/transactions.utils';
import {
  TransactionDetailsModal
} from "@shared/modules/transactions/components/transaction-details-modal/transaction-details.modal.component";
import {map, Observable, Subject, takeUntil} from "rxjs";
import {DialogService} from "primeng/dynamicdialog";
import {CancelModalTransactionComponent, ResumeModalComponent} from "@shared/components";
import {TransactionService, UtilsService} from "@shared/services";
import {AuthState} from "@shared/stores/auth/auth.state";
import {Select, Store} from "@ngxs/store";
import {WebSocketService} from "@shared/services/webSocket.service";
import {setLastRefresh} from '@app/shared/stores/utils/utils.actions';
import {User} from "firebase/auth";

@Component({
  selector: 'app-invest-last-transactions',
  templateUrl: './last-transactions.component.html',
  styleUrls: ['./last-transactions.component.scss']
})
export class LastTransactionsComponent implements OnInit, OnDestroy {
  @Input() data: any[] = [];
  @Output() reload: EventEmitter<any> = new EventEmitter();
  getInfos = getInfos;
  getDate = getDate;
  getUid = getUid;
  getAmount = getAmount;
  getStatus = getStatus;
  getTransactionProduct = getTransactionProduct;
  getCurrencyImg = getCurrencyImg;
  getTransactionDirection = getTransactionDirection;
  ETransactionStatusPending = ETransactionStatus.PENDING_PAYMENT;
  ETransactionStatusPendingUnlock = ETransactionStatus.PENDING_UNLOCK;
  ETransactionCurrencyEURO = ETransactionCurrency.EURO;
  ref: any;
  @Select(AuthState.getUser) user$: Observable<IUser> | undefined;
  public display: boolean[] = [];
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private userId: string | undefined;
  public user: IUser | undefined;

  constructor(
    private utilsService: UtilsService,
    private dialogService: DialogService,
    private transactionService: TransactionService,
    private websocketService: WebSocketService,
    private store: Store,
  ) {
  }

  ngOnInit(): void {

    this.user$?.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      if (user) {
        this.user = user;
        this.userId = user.id;
      }
    });

    this.initWebSocket()
  }

  initWebSocket() {

    this.websocketService.getUserUpdateTransaction(this.userId!).pipe(map((data: any) => data)).subscribe((transaction: any) => {
      this.reload.next(true);
      this.store.dispatch(new setLastRefresh());
    })
  }

  async seeDetails(transaction: ITransaction) {
    if (transaction.status === this.ETransactionStatusPending || transaction.status === this.ETransactionStatusPendingUnlock) {
      const convert = await this.getConvertValue(transaction.custody.currency, Math.round(transaction.amountEUR));

      this.ref = this.dialogService.open(ResumeModalComponent, {
        showHeader: false,
        data: {
          userId: this.userId,
          id: transaction.id,
          address: transaction.custody.address,
          selectedCrypto: transaction.custody.currency,
          time: transaction.created_at,
          queueTime: transaction.round ? transaction.round?.queueTime : DEFAULT_QUEUE_TIME,
          status: transaction.status,
          price: transaction.amount,
          rate: transaction.round?.price,
          credit: 0,
          rest: transaction.amount,
          amountUSD: Math.round(transaction.amountEUR),
          convert,
          activeRound: transaction.round
        },
        contentStyle: {'width': '100%', 'max-width': '650px', 'border-radius': '20px'},
        baseZIndex: 10000,
      });
      this.ref.onClose.subscribe((data: { status: string, id: string }) => {
        if (data.status === "openCancelModal") {
          this.cancel(transaction);
          return;
        }
      })
    } else {
      this.ref = this.dialogService.open(TransactionDetailsModal, {
        showHeader: false,
        data: {
          userId: this.userId,
          transaction: {...transaction, created: transaction.created_at, updated: transaction.updated_at}
        },
        contentStyle: {'width': '100%', 'max-width': '650px', 'border-radius': '20px'},
        baseZIndex: 10000,
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  async cancel(transaction: ITransaction) {
    try {
      this.ref = this.dialogService.open(CancelModalTransactionComponent, {
        showHeader: false,
        data: {
          id: transaction.id
        },
        contentStyle: {'width': '100%', 'max-width': '650px', 'border-radius': '20px'},
        baseZIndex: 10000,
      });
      this.ref.onClose.subscribe(() => {
        this.reload.next(true);
      });
    } catch (e) {
      console.log(e);
    }
  }

  private async getConvertValue(selectedCrypto, rest: number) {
    const data: any = await this.utilsService.getConvert(selectedCrypto?.slug, rest)
    if (!!data) {
      // const convert = Number(data.quote[Object.keys(data.quote)[0]].price);
      const convert = Number(data.price);
      return convert.toFixed(5);
    }
    return 0;
  }
}
