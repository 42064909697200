import {LocalizedDatePipe} from "./localized-date.pipe";
import {FixedPipe} from "@shared/pipes/fixed.pipe";
import {CustomNumberPipe} from "@shared/pipes/customNumber.pipe";
import {CountryPipe} from "@shared/pipes/country.pipe";
import {CapitalizePipe} from "@shared/pipes/capitalize.pipe";
import {AddressPipe} from "@shared/pipes/address.pipe";
import {TxPipe} from "@shared/pipes/tx.pipe";
import {ToStringPipe} from "@shared/pipes/to-string.pipe";
import {PhaseNamePipe} from "@shared/pipes/phaseName.pipe";
import {SafePipe} from "@shared/pipes/safe.pipe";
import {HighlightPipe} from "./highlight.pipe";
import {NumberPipe} from "@shared/pipes/number.pipe";
import {TranslateStaticPipe} from "@shared/pipes/translateStatic.pipe";
import {TranslateGainsPipe} from "@shared/pipes/translateGains.pipe";
import {TranslateFilePipe} from "@shared/pipes/translateFile.pipe";
import {TranslateCoverPipe} from "@shared/pipes/translateCover.pipe";
import {TranslateObjectPipe} from "@shared/pipes/translateObject.pipe";
import {SafeHtmlPipe} from "@shared/pipes/safe-html.pipe";

export {
  LocalizedDatePipe,
  FixedPipe,
  CustomNumberPipe,
  CountryPipe,
  CapitalizePipe,
  AddressPipe,
  TxPipe,
  ToStringPipe,
  PhaseNamePipe,
  SafePipe,
  NumberPipe,
  HighlightPipe,
  TranslateStaticPipe,
  TranslateGainsPipe,
  TranslateFilePipe,
  TranslateCoverPipe,
  TranslateObjectPipe,
  SafeHtmlPipe
};

export const PIPES = [
  LocalizedDatePipe,
  FixedPipe,
  CustomNumberPipe,
  CountryPipe,
  CapitalizePipe,
  AddressPipe,
  TxPipe,
  ToStringPipe,
  PhaseNamePipe,
  HighlightPipe,
  NumberPipe,
  SafePipe,
  TranslateStaticPipe,
  TranslateGainsPipe,
  TranslateFilePipe,
  TranslateCoverPipe,
  TranslateObjectPipe,
  SafeHtmlPipe
];
