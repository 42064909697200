<div class="errorDisplay">
  <!--close modal button -->
  <button
    class="close"
    icon="pi pi-times"
    pButton
    (click)="close()"
  ></button>
  <div class="modal-header">
    <div [innerHTML]="'cgu.modal.title' | translate" class="title text-center">

    </div>

  </div>
  <div class="modal-body text-center flex flex-column aligns-items-center justify-content-center">
<!--    <span [innerHTML]="'cgu.modal.content' | translate" class="description"></span>-->
    <p class="description">
      {{ 'cgu.modal.content' | translate}}
      <a class="link" (click)="read()">{{ 'readCGU'| translate}}</a>
    </p>
    <!--    <div class="flex align-items-center gap-1">-->
    <!--    </div>-->
    <div class="actions">
      <app-ico-btn class="mt-4" (click)="accept()" [text]="'cguAccept' | translate"></app-ico-btn>
      <app-ico-btn class="mt-4" [outline]="true" (click)="cancel()" [text]="'cguReject' | translate"></app-ico-btn>
    </div>
    <span class="simple-link" (click)="close()">{{ 'seeLater'| translate}}</span>
  </div>
</div>
