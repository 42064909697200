<div class="form-container md:w-10 mx-auto">

<!--  <div *ngIf="displayTitle" class="top-container">-->
<!--    <div class="sub-text mt-2">-->
<!--      {{ "phone.verificationTelDescription" | translate }}-->
<!--    </div>-->
<!--  </div>-->
  <div class=" w-full flex justify-content-center">
    <ngx-intl-tel-input
      #tel
      (input)="telChange($event)"
      [(ngModel)]="currentPhone"
      [cssClass]="'custom w-full'"
      [enableAutoCountrySelect]="true"
      [enablePlaceholder]="true"
      [maxLength]="15"
      [numberFormat]="PhoneNumberFormat.National"
      [phoneValidation]="true"
      [preferredCountries]="preferredCountries"
      [searchCountryPlaceholder]="'searchCountry' | translate"
      [searchCountryField]="[
          SearchCountryField.Iso2,
          SearchCountryField.Name
        ]"
      [searchCountryFlag]="true"
      [selectFirstCountry]="false"
      [selectedCountryISO]="CountryISO.France"
      [separateDialCode]="true">
    </ngx-intl-tel-input>
  </div>
  <div class="my-4 flex justify-center align-center flex-column w-full">
    <div
      *ngIf="timeLeft"
      [innerHTML]="'resendCodeTimer' | translate:{timeLeft}"
      class="sub-text">
    </div>
    <!--    <button-->
    <!--      (click)="resendCode()"-->
    <!--      *ngIf="!codeSent && !timeLeft"-->
    <!--      [label]="getLabel() | translate"-->
    <!--      class="p-button-text  text-center w-full"-->
    <!--      icon="pi pi-send"-->
    <!--      rId="code-btn"-->
    <!--      pRipple-->
    <!--      rButton-->
    <!--      pButton>-->
    <!--    </button>-->
    <app-ico-btn
      (click)="resendCode()"
      *ngIf="!timeLeft"
      [disabled]="!isValidNumber()"
      [rId]="'code-btn'"
      [text]="getLabel() | translate"
      class="w-full"
      icon="pi pi-send"
    ></app-ico-btn>
  </div>

  <div *ngIf="!!codeSent" class="code-input-container">
    <code-input
      (codeChanged)="onOtpChanged($event)"
      (codeCompleted)="onCodeComplete()"
      [codeLength]="codeLength"
      [initialFocusField]="0"
      [code]="otpCode">
    </code-input>
  </div>

</div>
