<div class="device">


<p-table #dt2 [value]="data">
  <ng-template pTemplate="header">
    <tr class="header-table">
      <th pSortableColumn="date">{{'ico.invest.table.montant' | translate}}
        <p-sortIcon field="date"></p-sortIcon>
      </th>
      <th pSortableColumn="status">{{'ico.invest.table.status' | translate}}
        <p-sortIcon field="status"></p-sortIcon>
      </th>
      <th style="min-width: 20rem">
        <div class="flex justify-content-end">
          {{'ico.invest.table.actions' | translate}}
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template let-columns="columns" let-rowData pTemplate="body">

    <tr class="transactionRow">
      <td class="infos" style="min-width: 15rem">
        <div class="flex">
          <div class="flex flex-column justify-content-center" style="width: 2.6rem;">
            <img [src]="getTransactionProduct(rowData).img" alt="" class="w-full"/>
          </div>
          <div class="flex-grow-1 ml-3">
            <p class="  title">{{ rowData.amount | FixedPipe | customNumber:'0':'0':'SMT' }}</p>
            <!--                      <p class="sub">{{ getTransactionProduct(rowData).name | translate }}</p>-->
            <div class="date">
                        <span class="time">{{ rowData.created_at | localizedDate:'d MMMM YYYY' }}
                          - {{ rowData.created_at | localizedDate:'shortTime' }}</span>
            </div>
            <p *ngIf="rowData.updated_at > rowData.created_at"
               class="mb-0 text-green-700 text-xs">{{ rowData.updated_at | localizedDate:'d MMMM YYYY' }} {{ rowData.updated_at | date:'shortTime' }}</p>
          </div>
        </div>
      </td>
      <td style="max-width: 15rem;">
        <div
          [style]="{ background: getStatus(rowData)!.color }"
          class="badge-status w-full"
        >
          <span [style]="{ color: getStatus(rowData)!.textcolor}">
            {{ getStatus(rowData)!.text | translate }}
          </span>
        </div>
      </td>
      <td class="">
        <div class="flex gap-3 justify-content-end">
          <div class="btn-details cursor " (click)="seeDetails(rowData)">
            {{ 'details' | translate }}
          </div>
          <div class="btn-details cursor cancel " (click)="cancel(rowData)"
               *ngIf="rowData.status === ETransactionStatusPending || rowData.status === ETransactionStatusPendingUnlock">
            {{ 'cancel' | translate}}
          </div>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>

</div>
<div class="mobile">
  <ng-container *ngIf="data">
    <div class="flex flex-column w-full">
      <div class="transaction-card" *ngFor="let transaction of data; let index=index;">
        <span class="status"></span>
        <div class="content">
          <div class="head">
            <div class="flex flex-column">
              <span class="type">{{ getTransactionDirection(transaction)  | translate : {month: (transaction.created_at | date : 'MMMM': '': user?.language)} }}</span>
              <span class="round">{{ getTransactionProduct(transaction).name | translate }}</span>
            </div>
            <img src="./assets/icon/arrow-down.svg" (click)="display[index] = !display[index]" class="cursor" alt=""
                 height="8px" width="16px">
          </div>
          <div class="info">
          <span [class]="'label ' + getStatus(transaction).value.toLowerCase()"
                [style]="{ color: getStatus(transaction)!.textcolor }">
            <svg width="16" height="16" viewBox="0 0 16 16" [style.fill]="getStatus(transaction)!.textcolor" xmlns="http://www.w3.org/2000/svg">
<path
  d="M8 0C3.58192 0 0 3.58192 0 8C0 12.4181 3.58192 16 8 16C12.4181 16 16 12.4181 16 8C16 3.58192 12.4181 0 8 0ZM11.6923 9.23077H8C7.83679 9.23077 7.68026 9.16594 7.56486 9.05053C7.44945 8.93512 7.38462 8.77859 7.38462 8.61539V3.07692C7.38462 2.91371 7.44945 2.75719 7.56486 2.64178C7.68026 2.52637 7.83679 2.46154 8 2.46154C8.16321 2.46154 8.31974 2.52637 8.43514 2.64178C8.55055 2.75719 8.61539 2.91371 8.61539 3.07692V8H11.6923C11.8555 8 12.012 8.06483 12.1275 8.18024C12.2429 8.29565 12.3077 8.45218 12.3077 8.61539C12.3077 8.77859 12.2429 8.93512 12.1275 9.05053C12.012 9.16594 11.8555 9.23077 11.6923 9.23077Z"
/>
</svg>
            {{ getStatus(transaction)!.text  | translate }}
          </span>
            <span class="label">
            <img src="./assets/icon/date.svg" alt="">
              {{ transaction.created_at | localizedDate:'d MMMM YYYY' }}
          </span>
            <span class="label">
            <img src="./assets/icon/credit-card.svg" alt="">
              {{ transaction.fromAmount | customNumber:'2':'0':transaction?.fromCurrency}}</span>
          </div>
          <div class="open" *ngIf="display[index]">


            <div class="line">
              <div class="for">{{ 'Transactions.table.date' | translate}}</div>
              <div
                class="of font-bold cursor flex flex-column align-items-center">
                <div class="date" *ngIf="transaction.updated_at <= transaction.created_at">
                  <span class="date-txt">{{ transaction.created_at | localizedDate:'d MMMM YYYY' }}</span>
                  <span
                    class="time">{{ 'Transactions.table.at' | translate }} {{ transaction.created_at | localizedDate:'shortTime' }}</span>
                </div>
                <p *ngIf="transaction.updated_at > transaction.created_at"
                   class="mb-0 text-green-700 text-xs">{{ transaction.updated_at | date:'d MMMM YYYY' }} {{ transaction.updated_at | date:'shortTime' }}</p>
              </div>
            </div>
            <div class="line">
              <div class="for">{{ 'Transactions.table.type' | translate }}</div>
              <div
                class="of font-bold cursor flex align-items-center">
                <span class="  title">{{ getTransactionDirection(transaction)  | translate: {month: (transaction.created_at | date : 'MMMM': '': user?.language)} }}</span> -
                <span class="sub">{{ getTransactionProduct(transaction).name | translate }}</span>
              </div>
            </div>
            <div class="line">
              <div class="for">{{ 'Transactions.table.depositamount' | translate }}</div>
              <div
                class="of font-bold cursor flex align-items-center">
                <div class="flex">
                  <div class="flex flex-column justify-content-center align-items-center" style="width: 1.5rem;">
                    <img [src]="getCurrencyImg(ETransactionCurrencyEURO)"
                         alt=""
                         class="" width="20px" height="20px"
                    />
                  </div>
                  <div class="flex-grow-1 ml-2">
                    <p class="mb-0 basic-txt">{{ transaction.fromAmount | customNumber:'2':'0' }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="line">
              <div class="for">{{ 'Transactions.table.rate' | translate }}</div>
              <div
                class="of font-bold cursor flex align-items-center">
                <div class="flex">
                  <div class="flex-grow-1 ml-2">
                    <p
                      class="mb-0 basic-txt">{{ (transaction.fromAmount / transaction.amount) | customNumber : '3': '3': '€' }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="line">
              <div class="for">{{ 'Transactions.table.amount' | translate }}</div>
              <div
                class="of font-bold cursor flex align-items-center">
                <div class="flex">
                  <div class="flex flex-column justify-content-center align-items-center" style="width: 1.5rem;">
                    <img [src]="getCurrencyImg(transaction.currency)"
                         alt=""
                         class="" width="20px" height="20px"
                    />
                  </div>
                  <div class="flex-grow-1 ml-2">
                    <p class="mb-0 basic-txt">{{ transaction.amount | customNumber:'2':'0' }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="line">
              <div class="for">{{ 'Transactions.table.status' | translate }}</div>
              <div
                class="of font-bold cursor flex align-items-center">
                <span [class]="'label ' + getStatus(transaction).value.toLowerCase()"
                      [style]="{ color: getStatus(transaction)!.textcolor }">
            <svg width="16" height="16" viewBox="0 0 16 16" [style.fill]="getStatus(transaction)!.textcolor" xmlns="http://www.w3.org/2000/svg">
<path
  d="M8 0C3.58192 0 0 3.58192 0 8C0 12.4181 3.58192 16 8 16C12.4181 16 16 12.4181 16 8C16 3.58192 12.4181 0 8 0ZM11.6923 9.23077H8C7.83679 9.23077 7.68026 9.16594 7.56486 9.05053C7.44945 8.93512 7.38462 8.77859 7.38462 8.61539V3.07692C7.38462 2.91371 7.44945 2.75719 7.56486 2.64178C7.68026 2.52637 7.83679 2.46154 8 2.46154C8.16321 2.46154 8.31974 2.52637 8.43514 2.64178C8.55055 2.75719 8.61539 2.91371 8.61539 3.07692V8H11.6923C11.8555 8 12.012 8.06483 12.1275 8.18024C12.2429 8.29565 12.3077 8.45218 12.3077 8.61539C12.3077 8.77859 12.2429 8.93512 12.1275 9.05053C12.012 9.16594 11.8555 9.23077 11.6923 9.23077Z"
/>
</svg>
                  {{ getStatus(transaction)!.text  | translate }}
          </span>
              </div>
            </div>
            <div class="line w-full justify-content-center flex">
              <button (click)="seeDetails(transaction)" [label]="'Common.details' | translate"
                      class="p-2 btn-details" color="grey-500"
                      pButton pRipple type="button"></button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </ng-container>

</div>
