import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {EToastSeverity, ETransactionStatus, ICryptoNetwork, IRound} from "@models/dist";
import {ToastService} from "@shared/services";
import {getDirectStatus,} from '@shared/helpers/transactions.utils';

@Component({
  selector: 'app-invest-modal-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss']
})
export class ResumeComponent implements OnInit, OnDestroy {
  @Input() data: {
    time: Date, queueTime: number,
    selectedCrypto: ICryptoNetwork | null, amountUSD: number, rate: number | string, address: string, status: string, message: string, price: number, credit: number, rest: number, convert: number, target: number, activeRound: IRound | undefined
  } | undefined;

  @Input() canCancel: boolean = false;

  @Output() closeEvent: EventEmitter<any> = new EventEmitter();
  @Output() backEvent: EventEmitter<any> = new EventEmitter();
  @Output() cancelEvent: EventEmitter<any> = new EventEmitter();
  public summitTokenAmount = 0;
  public timeLeft = '';
  public countdownInterval: any | null = null;
  getStatus = getDirectStatus;
  ETransactionStatusPending = ETransactionStatus.PENDING_PAYMENT;
  ETransactionStatusPendingUnlock = ETransactionStatus.PENDING_UNLOCK;
  @Input() hasBeenUpdated: any = false;


  constructor(private toastService: ToastService) {
  }

  ngOnInit(): void {
    if (this.data?.message) this.toastService.addToast(EToastSeverity.info, 'ico.invest.modalRecap.info', this.data?.message);

    this.summitTokenAmount = Math.floor(this.data?.amountUSD! / Number(this.data?.rate!));

    const end = new Date(new Date(this.data?.time!).setMilliseconds(this.data?.queueTime!)).getTime();
    const _second = 1000;
    const _minute = _second * 60;
    const _hour = _minute * 60;
    const _day = _hour * 24;

    this.countdownInterval = setInterval(() => {
      const now = new Date().getTime();
      const distance = end - now;
      if (distance < 0) {

        clearInterval(this.countdownInterval);
        return;
      }
      const hours = Math.floor((distance % _day) / _hour);
      const minutes = Math.floor((distance % _hour) / _minute).toString().padStart(2, '0');
      const seconds = Math.floor((distance % _minute) / _second).toString().padStart(2, '0');

      this.timeLeft = `${minutes}:${seconds}`;
    }, 1000);
  }

  backFn() {
    this.backEvent.next(true)
  }

  closeFn() {
    this.closeEvent.next(true);
  }

  cancel() {
    this.cancelEvent.next(true)
  }

  ngOnDestroy(): void {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
  }
}

