import {IMiningParcResponse, MiningGateway} from "@mining/ports/mining.gateway";
import {inject} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "@environments/environment";
import {Observable} from "rxjs";
import {
  EYieldType,
  IBtcHistory, IInvestedData, IMiningChartGainsHistory,
  IMiningFile,
  IMiningGainsHistory,
  IMiningParc,
  IMiningUser, IMiningWallet, IMonthlyPaiement,
  IOverview, IYield
} from "@mining/store/mining.state";
import {EService, ETypeService, EVaultService, ICryptoNetwork, ITransaction} from "@models/dist";
import {EMiningChartFilter, IMiningGainTotal, IMiningResponseGainTotal} from "@mining/store/gains/gains.state";

export class HttpMiningGateway implements MiningGateway {
  http = inject(HttpClient)
  url = environment.nestApiUrl + '/api/sgp/mining';
  base_url = environment.nestApiUrl + '/api/sgp';

  getMiningUser(): Observable<IMiningUser> {
    return this.http.get<IMiningUser>(`${this.url}/user`);
  }

  getOverview(): Observable<IOverview> {
    return this.http.get<IOverview>(`${this.url}/overview`);
  }

  getMiningParc(): Observable<IMiningParcResponse> {
    return this.http.get<IMiningParcResponse>(`${this.url}/parc`);
  }

  getBtcHistory(): Observable<IBtcHistory> {
    return this.http.get<IBtcHistory>(`${this.url}/btc`);
  }

  getMiningTransactions(year?: number): Observable<ITransaction[]> {
    return this.http.get<ITransaction[]>(`${this.url}/transactions?year=${year}`);
  }

  getMiningFile(): Observable<IMiningFile[]> {
    return this.http.get<IMiningFile[]>(`${this.url}/files`)
  }

  getMiningCurrency(): Observable<ICryptoNetwork[]> {
    return this.http.get<ICryptoNetwork[]>(`${this.base_url}/currency?service=${ETypeService.MINING}`)
  }

  getMiningWallet(): Observable<IMiningWallet[]> {
    return this.http.get<IMiningWallet[]>(`${this.url}/wallet`)
  }

  getMiningInvested(): Observable<number> {
    return this.http.get<number>(`${this.url}/invested`)
  }

  getMiningInvestedData(year?: number): Observable<IInvestedData> {
    return this.http.get<IInvestedData>(`${this.url}/invested-data?year=${year}`)
  }

  getMonthlyPayment(): Observable<IMonthlyPaiement> {
    return this.http.get<IMonthlyPaiement>(`${this.url}/paiements`);
  }

  getMiningCryptocurrency(): Observable<ICryptoNetwork[]> {
    return this.http.get<ICryptoNetwork[]>(`${this.url}/currencies`)
  }

  getMiningChartGainHistory(filter: EMiningChartFilter): Observable<IMiningChartGainsHistory[]> {
    return this.http.get<IMiningChartGainsHistory[]>(`${this.url}/gains/charts?filter=${filter}`);
  }

  getMiningTotalGainHistory(
    month: number,
    year: number
  ): Observable<IMiningResponseGainTotal> {
    return this.http.get<IMiningResponseGainTotal>(`${this.url}/gains/total?month=${month}&year=${year}`);
  }

  getMiningGainHistory(month: number, year: number): Observable<IMiningGainsHistory[]> {
    return this.http.get<IMiningGainsHistory[]>(`${this.url}/gains?month=${month}&year=${year}`);
  }

  getPerformance(type?: EYieldType): Observable<IYield> {
    return this.http.get<IYield>(`${this.url}/performances?type=${type}`);
  }


}
