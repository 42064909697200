import {Logout} from '@shared/stores/auth/auth.actions';
import {EventData} from "@shared/services/langBus.service";

export const userMenu = [
  {
    label: 'menu.user.home',
    items: [
      {
        label: 'menu.user.profil',
        icon: 'person-circle-outline',
        routerLink: ['/profile'],
      },
      {
        label: 'menu.user.messaging',
        icon: 'mail-outline',
        routerLink: ['/profile/messaging'],
      },
      {
        label: 'menu.user.kyc',
        icon: 'finger-print-outline',
        routerLink: ['/profile/kyc'],
      },
      // {
      //   label: 'menu.user.wallets',
      //   icon: 'wallet-outline',
      //   routerLink: ['/profile/wallets'],
      // },
      // {
      //   label: 'menu.user.referral',
      //   icon: 'people-outline',
      //   routerLink: ['/profile/referral'],
      // },
      // {
      //   label: 'menu.user.vip',
      //   icon: 'trophy-outline',
      //   disabled: true,
      //   routerLink: ['/profile/vip'],
      // },
      {
        label: 'menu.user.changepassword',
        icon: 'lock-closed-outline',
        routerLink: ['/profile/change-password'],
      },
      // {
      //   label: 'menu.user.changeEmail',
      //   icon: 'pi pi-fw pi-envelope',
      //   disabled: true,
      //   routerLink: ['/profile/change-email'],
      // },
      {
        label: 'menu.user.2fa',
        icon: 'barcode-outline',
        routerLink: ['/profile/2fa'],
      },
      {
        label: 'menu.user.history',
        icon: 'laptop-outline',
        routerLink: ['/profile/history'],
      },
      {
        label: 'menu.user.logout',
        icon: 'log-out-outline',
        class: 'text-red-600 text-bold',
        command: (vue: any, ctx: any) => {
          vue.zone.run(() => {
            vue.logoutBusService.emit(new EventData('open', true));
          });
        },
      },
    ],
  },
];
