export class GetAirdrop {
  static readonly type = '[Airdrop] Get Airdrop';
}

export class SetAirdrop{
  static readonly type = '[Airdrop] Set airdrop';

  constructor(public airdrop: any) {
  }
}

export class SetAirdropSuccess{
  static readonly type = '[Airdrop] Set airdrop';

  constructor(public airdrop: any) {
  }
}

export class ClaimAirdrop{
  static readonly type = '[Airdrop] Claim Airdrop';

  constructor(public id:string) {
  }
}

export class SuccessClaimAirdrop{
  static readonly type = '[Airdrop] Success Claim Airdrop';

}
