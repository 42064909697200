import {inject, Injectable} from "@angular/core";
import {MiningGateway} from "@mining/ports/mining.gateway";
import {Action, State, StateContext} from "@ngxs/store";
import {IMinedAsset, IMiningGainsHistory} from "@mining/store/mining.state";
import {
  GetChartGains,
  GetDataGains,
  GetTotalGains, SetChartFilter, SetDataFilter,
  SetTotalFilter,
} from "@mining/store/gains/gains.actions";
import {tap} from "rxjs";

export interface IMiningGainTotal {
  data: IMiningResponseGainTotal,
  loading: boolean
  filter: {
    month: number,
    year: number
  },
}

export enum EMiningChartFilter {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year'

}

export interface IMiningChartGainsHistory {
  data: any[];
  filter: EMiningChartFilter
}


export interface IMiningGainState {
  chart: IMiningChartGainsHistory,
  total: IMiningGainTotal,
  data: {
    data: IMiningGainsHistory[],
    filter: {
      month: number,
      year: number
    },
  }
}

export interface IMiningResponseGainTotal {
  data: {
    total_mined: number,
    mined_euro_last_day: number,
    mined_euro_last_day_value_today: number,
    variation: number,
    last_day: Date,
    asset: IMinedAsset,
  }[],
  assets: IMinedAsset[]
}

@State<IMiningGainState>({
  name: 'miningGains',
  defaults: {
    chart: {
      data: [],
      filter: EMiningChartFilter.DAY
    },
    total: {
      loading: true,
      data: {
        data: [],
        assets: []
      },
      filter: {
        month: new Date().getMonth(),
        year: new Date().getFullYear()
      },
    },
    data: {
      data: [],
      filter: {
        month: new Date().getMonth(),
        year: new Date().getFullYear()
      },
    }
  }
})

@Injectable()
export class MiningGainsState {
  miningGateway = inject(MiningGateway)

  @Action(GetTotalGains)
  getTotalGains(ctx: StateContext<IMiningGainState>, action: GetTotalGains) {

    const state = ctx.getState();
    ctx.patchState({
      total: {
        ...state.total,
        loading: true
      }
    })
    const filter = action.filter ?? state.total.filter;
    return this.miningGateway.getMiningTotalGainHistory(filter.month, filter.year).pipe(
      tap((data) => {
        ctx.patchState({
          total: {
            ...state.total,
            data: {
              data: data.data,
              assets: data.assets
            },
            loading: false,
            filter
          }
        })
      })
    )
  }

  @Action(GetDataGains)
  getDataGains(ctx: StateContext<IMiningGainState>, action: GetDataGains) {
    const state = ctx.getState();
    const filter = action.filter ?? state.data.filter;
    return this.miningGateway.getMiningGainHistory(filter.month, filter.year).pipe(
      tap((data) => {
        ctx.patchState({
          data: {
            ...state.data,
            data: data,
            filter
          }
        })
      })
    )
  }

  @Action(GetChartGains)
  getChartGains(ctx: StateContext<IMiningGainState>, action: GetChartGains) {
    const state = ctx.getState();
    return this.miningGateway.getMiningChartGainHistory(state.chart.filter).pipe(
      tap((data) => {
        ctx.patchState({
          chart: {
            ...state.chart,
            data: data
          }
        })
      })
    )
  }

  @Action(SetTotalFilter)
  setTotalFilter(ctx: StateContext<IMiningGainState>, action: SetTotalFilter) {
    return ctx.dispatch(new GetTotalGains(action.payload));
  }

  @Action(SetDataFilter)
  setDataFilter(ctx: StateContext<IMiningGainState>, action: SetDataFilter) {
    const state = ctx.getState();
    return ctx.dispatch(new GetDataGains(action.payload));
  }

  @Action(SetChartFilter)
  setChartFilter(ctx: StateContext<IMiningGainState>, action: SetChartFilter) {
    const state = ctx.getState();
    ctx.patchState({
      chart: {
        ...state.chart,
        filter: action.payload
      }
    });
    return ctx.dispatch(new GetChartGains());
  }
}
