import {IcoTopComponent} from "@shared/components/ico-top/ico-top.component";
import {UserPanelComponent} from "@shared/components/user-panel/user-panel.component";
import {PhaseIcoCardComponent} from "@shared/components/phase-ico-card/phase-ico-card.component";
import {IcoBtnComponent} from "@shared/components/ico-btn/ico-btn.component";
import {InfoComponent} from "@shared/components/info/info.component";
import {InvestModalComponent} from "@shared/components/invest-modal/invest-modal.component";
import {UserDetailsComponent} from "@shared/components/user-details/user-details.component";
import {ErrorMigratedComponent} from "@shared/components/error-migrated/error-migrated.component";
import {LangComponent} from "@shared/components/lang/lang.component";
import {SetPasswordComponent} from "@shared/components/set-password/set-password.component";
import {LayoutComponent} from "@shared/components/layout/layout.component";
import {FooterComponent} from "./footer/footer.component";
import {HeaderComponent} from "./header/header.component";
import {AppMenuComponent} from "./sidebar/app.menu.component";
import {AppMenuitemComponent} from "./sidebar/app.menuitem.component";
import {CopyComponent} from "@shared/components/copy/copy.component";
import {LogoutComponent} from "@shared/components/logout/logout.component";
import {TwofaTriggerComponent} from "@shared/components/twofa-trigger/twofa-trigger.component";
import {LastTransactionsComponent} from "@shared/components/last-transactions/last-transactions.component";
import {ResumeModalComponent} from "@shared/components/resume-modal/resume-modal.component";
import {
  CancelModalTransactionComponent
} from "@shared/components/cancel-modal-transaction/cancel-modal-transaction.component";
import {DefaultTableComponent} from "@shared/components/default-table/default-table.component";
import {ContactModalComponent} from "@shared/components/contact-modal/contact-modal.component";
import {WheelComponent} from "@shared/components/wheel/wheel.component";
import {InfoReferralModalComponent} from "@shared/components/info-referral-modal/info-referral-modal.component";
import {NgxWheelComponent} from "@shared/components/wheel-inside/wheel-inside.components";
import {SummitLogoComponent} from "@shared/components/summit-logo/summit-logo.component";
import {MiningModalComponent} from "@shared/components/mining-modal/mining-modal.component";
import {GravityModalComponent} from "@shared/components/gravity-modal/gravity-modal.component";
import {OnboardingLayoutComponent} from "@shared/components/onboarding-layout/onboarding-layout.component";
import {EntryComponent} from "@shared/components/entry/entry.component";
import {KycModalComponent} from "@shared/components/kyc-modal/kyc-modal.component";
import {PhoneNumberComponent} from "@shared/components/phone-number/phone-number.component";
import {PhoneNumberModalComponent} from "@shared/components/phone-number-modal/phone-number-modal.component";
import {CountryDropdownComponent} from "@shared/components/country-dropdown/country-dropdown.component";
import {CheckComponent} from "@shared/components/check/check.component";
import {AirdropModalComponent} from "@shared/components/airdrop-modal/airdrop-modal.component";

export {
  IcoTopComponent,
  UserPanelComponent,
  PhaseIcoCardComponent,
  IcoBtnComponent,
  InfoComponent,
  InvestModalComponent,
  UserDetailsComponent,
  ErrorMigratedComponent,
  LangComponent,
  SetPasswordComponent,
  LayoutComponent,
  HeaderComponent,
  AppMenuComponent,
  AppMenuitemComponent,
  FooterComponent,
  CopyComponent,
  LogoutComponent,
  TwofaTriggerComponent,
  LastTransactionsComponent,
  ResumeModalComponent,
  CancelModalTransactionComponent,
  DefaultTableComponent,
  ContactModalComponent,
  WheelComponent,
  InfoReferralModalComponent,
  NgxWheelComponent,
  SummitLogoComponent,
  MiningModalComponent,
  GravityModalComponent,
  OnboardingLayoutComponent,
  EntryComponent,
  KycModalComponent,
  PhoneNumberComponent,
  PhoneNumberModalComponent,
  CountryDropdownComponent,
  CheckComponent,
  AirdropModalComponent,
};

export const COMPONENTS = [
  IcoTopComponent,
  PhaseIcoCardComponent,
  UserPanelComponent,
  IcoBtnComponent,
  InfoComponent,
  InvestModalComponent,
  UserDetailsComponent,
  ErrorMigratedComponent,
  LangComponent,
  SetPasswordComponent,
  LayoutComponent,
  HeaderComponent,
  AppMenuComponent,
  AppMenuitemComponent,
  FooterComponent,
  CopyComponent,
  LogoutComponent,
  TwofaTriggerComponent,
  LastTransactionsComponent,
  ResumeModalComponent,
  CancelModalTransactionComponent,
  DefaultTableComponent,
  ContactModalComponent,
  WheelComponent,
  InfoReferralModalComponent,
  NgxWheelComponent,
  SummitLogoComponent,
  MiningModalComponent,
  GravityModalComponent,
  OnboardingLayoutComponent,
  EntryComponent,
  KycModalComponent,
  PhoneNumberComponent,
  PhoneNumberModalComponent,
  CountryDropdownComponent,
  CheckComponent,
  AirdropModalComponent,
];

