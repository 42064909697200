import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {firstValueFrom} from "rxjs";
import {environment} from "../../../environments/environment";
import {ELang, IUserPayload, IWithdrawWallet} from "@models/dist";
import {IPhoneNumber} from "@models/dist/models/auth";
import {IMiningWallet} from "@mining/store/mining.state";


const BASE_NEST = `${environment.nestApiUrl}/api/sgp`;
const BASE_NEST_USERS = `${BASE_NEST}/users`;

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) {
  }

  /**************************************
   * USER
   ***************************************/

  async getUser() {
    return firstValueFrom(this.http.get(`${BASE_NEST_USERS}`));
  }

  // Send a new link to verify user email
  async sendVerificationEmail(email: string) {
    return firstValueFrom(this.http.post(`${BASE_NEST_USERS}/verify-email`, {email}));
  }


  // Perform actions after email verification
  async emailVerifiedByUid(email: string) {
    return firstValueFrom(this.http.post(`${BASE_NEST_USERS}/email-verified`, {email}));
  }

  async signin() {
    return firstValueFrom(this.http.get(`${BASE_NEST_USERS}/signin`));
  }

  async createUser(data: IUserPayload) {
    return firstValueFrom(this.http.post(`${BASE_NEST_USERS}`, data))
  }

  async validateReferralCode(code: string) {
    return firstValueFrom(this.http.get(`${BASE_NEST}/referrals/` + code));
  }

  async setReferral(referral:string){
    return firstValueFrom(this.http.post(`${BASE_NEST_USERS}/referrals/`,{referral}));
  }
  async uploadAvatar(file: any) {
    const formData = new FormData();
    formData.append('avatar', file);
    return firstValueFrom(this.http.post(`${BASE_NEST_USERS}/avatar`, formData));
  }

  checkUserImported(email: string, lang = ELang.FR) {
    return firstValueFrom(this.http.post(`${BASE_NEST_USERS}/migrations/users`, {email, lang}));
  }

  async verifyOtp(otp: string, email: string) {
    return firstValueFrom(this.http.post(`${BASE_NEST_USERS}/migrations/users/check`, {email, otp}));
  }

  async migrations(email: string, password: any, otp: string, country: string) {
    return firstValueFrom(this.http.post(`${BASE_NEST_USERS}/migrations`, {email, password, otp, country}));
  }

  async triggerLogin(email: any) {

    return firstValueFrom(this.http.post(`${BASE_NEST_USERS}/logging`, {email}));

  }

  async getHistory() {
    return firstValueFrom(this.http.get<{logins :any[]}>(`${BASE_NEST_USERS}/history`));
  }

  async updateLang(lang: ELang) {
    return firstValueFrom(this.http.put(`${BASE_NEST_USERS}`, {lang}));
  }

  async getUserWallets() {
    return firstValueFrom(this.http.get(`${BASE_NEST}/wallet/all`))
  }

  async getUserHiddenWallets() {
    return firstValueFrom(this.http.get(`${BASE_NEST}/wallet/user-hidden`))
  }

  async addWallet(wallet) {
    return firstValueFrom(this.http.post(`${BASE_NEST}/wallet`, {wallet}))
  }

  async updateWallet(wallet, id) {
    return firstValueFrom(this.http.put(`${BASE_NEST}/wallet/${id}`, {wallet}))
  }

  async getWalletById(id) {
    return this.http.get<IWithdrawWallet>(`${BASE_NEST}/wallet/${id}`);
  }

  async hideWallet(id) {
    return firstValueFrom(this.http.get(`${BASE_NEST}/wallet/toggle-hidden/${id}`))

  }

  async getPhoneOtp(phone: string, lang:string) {
    return firstValueFrom(this.http.post(`${BASE_NEST_USERS}/get-phone-otp`, {phone, lang}));
  }

  async verifyPhoneOtp(values: IPhoneNumber) {
    return firstValueFrom(this.http.post(`${BASE_NEST_USERS}/verify-phone-otp`, values));
  }

  verifyAddress(address:string, currency:string) {
    return this.http.get<boolean>(
      `${BASE_NEST}/wallet/handle-verify?address=${address}&currency=${currency}`
    )
  }
}
