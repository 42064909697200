import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {firstValueFrom, of} from "rxjs";
import {environment} from "@environments/environment";
import {EKycForm} from "@profile/components/kyc-form/kyc-form.component";

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private BASE = `${environment.nestApiUrl}/api/sgp`;


  constructor(
    private httpService: HttpClient) {
  }

  async getProfile() {
    return firstValueFrom(this.httpService.get(`${this.BASE}/ico/profile`));
  }

  async getReferral() {
    return firstValueFrom(this.httpService.get(`${this.BASE}/users/referrals`));
  }

  async getBadges() {
    return firstValueFrom(this.httpService.get(`${this.BASE}/users/badges`));
  }

  async getReferralLeaderboard() {
    return firstValueFrom(this.httpService.get(`${this.BASE}/referrals/leaderboard`));
  }

  async update(data: { pseudo }) {
    return firstValueFrom(this.httpService.post(`${this.BASE}/users/update`, data))
  }

  async updateICOWallet(wallet: string){
    return firstValueFrom(this.httpService.post(`${this.BASE}/ico/wallet`, {wallet}))
  }

  chooseGains(data: any) {
    return firstValueFrom(this.httpService.post(`${this.BASE}/referrals/choose`, data))

  }

  validateOnboardReferral() {
    return firstValueFrom(this.httpService.post(`${this.BASE}/referrals/onboarding`, {}))

  }

  getTicket() {
    return firstValueFrom(this.httpService.get(`${this.BASE}/referrals/ticket`))
  }

  getSpin() {
    return firstValueFrom(this.httpService.get(`${this.BASE}/referrals/spin`))
  }

  updatePhoneNumber() {
    return firstValueFrom(this.httpService.post(`${this.BASE}/users/update/phoneNumber`, {}))
  }

  getAllConversationsForUser() {
    return firstValueFrom(this.httpService.get(`${this.BASE}/conversations/`));
  }

  getMessagesFromConversation(convId: string, {page}: { page: number }) {
    return firstValueFrom(this.httpService.get(`${this.BASE}/conversations/${convId}/messages?page=${page}`));
  }

  addNewMessageToConversation(content: any) {
    return firstValueFrom(this.httpService.post(`${this.BASE}/conversations/messages`, content));
  }

  createNewConversation(content: any) {
    return firstValueFrom(this.httpService.post(`${this.BASE}/conversations/ticket`, content));
  }

  endConversation(convId: string) {
    return firstValueFrom(this.httpService.get(`${this.BASE}/conversations/${convId}/close/`));
  }

  async initKyc(type: EKycForm) {
    return firstValueFrom(this.httpService.post(`${this.BASE}/compliance/kyc/init`, {type}));

  }

  saveStepKycLight(form: any, step: number, type: EKycForm,
                   files?: { data: any[], type: string }[]
  ) {

    console.log(`Save form => ${form} for step ${step} and type ${type} with files ${files}`);


    // if (currentStep === 0) {
    //   return firstValueFrom(this.httpService.post(`${this.BASE}/compliance/kyc/init`, {data: form}));
    // }
    // if (step !== 5) {
    const birthday = !!form.birthday ? this.parseDate(form.birthday) : null;
    const data = {
      ...form,
      postalAddress: !!form.postalAddress && form.postalAddress.includes(',') ? form.postalAddress.split(',')[0] : form.postalAddress,
      step,
      type,
      files,
      birthday
    };
    console.log(data);
    return firstValueFrom(this.httpService.post(`${this.BASE}/compliance/kyc/step`, {data}));
    // }

    // if (step === 5) {
    //   const data = {
    //     ...form, files,
    //     step,
    //     type
    //   }
    //   console.log(data);
    //   return firstValueFrom(this.httpService.post(`${this.BASE}/compliance/kyc/step`,
    //       {
    //         data,
    //       }
    //     )
    //   );
    // }
  }

  parseDate(birthday: string) {
    if(!(Boolean(typeof birthday === "string"))) return;

    const parts = birthday.split('/');

    // Rearrange to "YYYY-MM-DD" format
    const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

    // Create a Date object
    return new Date(formattedDate);
  }

  contestCountry() {
    return firstValueFrom(this.httpService.post(`${this.BASE}/compliance/contest`, {}));

  }

  async updateCountry(country: any) {
    return firstValueFrom(this.httpService.post(`${this.BASE}/compliance/country`, {country}));

  }

  async getOnfidoStatus(kycTypeForm: EKycForm) {
    return firstValueFrom(this.httpService.post(`${this.BASE}/compliance/onfido/status`, {type: kycTypeForm}));
  }

  async getSumSubStatus(kycTypeForm: EKycForm) {
    return firstValueFrom(this.httpService.post(`${this.BASE}/compliance/sumsub/status`, {type: kycTypeForm}));
  }

  async getKyc(kycTypeForm: EKycForm) {
    return firstValueFrom(this.httpService.post(`${this.BASE}/compliance/kyc`, {type: kycTypeForm}));
  }

  async getKycFiles(id: string) {
    return firstValueFrom(this.httpService.post(`${this.BASE}/compliance/kyc/files`, {id}));
  }

  async checkEnhancedKyc() {
    return firstValueFrom(this.httpService.post(`${this.BASE}/compliance/kyc/enhanced`, {}));
  }

  // async checkEnhancedKycObservable() {
  //   return of(this.httpService.post(`${this.BASE}/compliance/kyc/enhanced`, {}));
  // }

  async endKycFull(step: number, action = true) {
    return firstValueFrom(this.httpService.post(`${this.BASE}/compliance/kyc/full/end`, {
      step,
      action
    }));

  }


  async enKycLight(step: number, action = true) {
    return firstValueFrom(this.httpService.post(`${this.BASE}/compliance/kyc/light/end`, {
      step, action
    }));
  }
}

