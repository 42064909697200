import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthState} from '@shared/stores/auth/auth.state';
import {Select} from '@ngxs/store';
import {DialogService} from 'primeng/dynamicdialog';
import {Observable, Subject, takeUntil} from 'rxjs';
import {ETwoFaType, IUser} from "@models/dist";
import {TwoFaGenericModalComponent} from "@profile/components/twofa/twofa-modal-generic/twofa-generic.modal.component";

@Component({
  selector: 'app-twofa',
  templateUrl: './twofa.component.html',
  styleUrls: ['./../scss/profile.scss'],
  providers: [DialogService],
})
export class TwoFaComponent implements OnInit, OnDestroy {

  @Select(AuthState.getUser)
  user$: Observable<IUser> | undefined;
  selectedCountry: any;
  countries: any[];
  ref: any;

  user: IUser | null = null;
  private subscription = new Subject<void>();

  constructor(public dialogService: DialogService) {

    this.selectedCountry = {name: 'France', code: 'FR'}
    this.countries = [
      {name: 'Australia', code: 'AU'},
      {name: 'Brazil', code: 'BR'},
      {name: 'China', code: 'CN'},
      {name: 'Egypt', code: 'EG'},
      {name: 'France', code: 'FR'},
      {name: 'Germany', code: 'DE'},
      {name: 'India', code: 'IN'},
      {name: 'Japan', code: 'JP'},
      {name: 'Spain', code: 'ES'},
      {name: 'United States', code: 'US'},
    ];
  }

  ngOnInit(): void {
    this.getUser()
  }

  ngOnDestroy() {
    this.subscription.next();
    this.subscription.complete();
  }

  openGoogle2FaModal() {
    this.ref = this.dialogService.open(TwoFaGenericModalComponent, {
      showHeader: false,
      contentStyle: {'max-width': '550px', 'border-radius': '20px'},
      baseZIndex: 10000,
      data: {type: ETwoFaType.ACTIVATE, user: this.user}
    });
  }

  openGoogle2FaRevokeModal() {
    this.ref = this.dialogService.open(TwoFaGenericModalComponent, {
      showHeader: false,
      contentStyle: {'max-width': '550px', 'border-radius': '20px'},
      baseZIndex: 10000,
      data: {type: ETwoFaType.REVOKE}
    });
  }

  private getUser() {
    this.user$?.pipe(takeUntil(this.subscription)).subscribe(user => {
      this.user = user;
    })
  }
}
