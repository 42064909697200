<div class="layout-topbar" *ngIf="user$ | async as user">
  <app-summit-logo class="layout-topbar-logo" [user]="user" [service]="service"></app-summit-logo>

  <a class="p-link layout-menu-button layout-topbar-button" (click)="appMain.toggleMenu()">
    <i class="pi pi-bars"></i>
  </a>

  <div class="flex align-items-center layout-topbar-menu-button" *ngIf="service === 'ico'" (click)="handleUserPanel()">
    <!--a class="p-link layout-topbar-button" (click)="userMobile.toggle($event)">
      <i class="pi pi-ellipsis-v"></i>
    </a-->
    <a
      class="p-link laMyout-topbar-button flex align-items-center"

    >
      <svg width="22" height="22" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 1C3.23886 1 1 3.23886 1 6C1 8.76114 3.23886 11 6 11C8.76114 11 11 8.76114 11 6C11 3.23886 8.76114 1 6 1Z" stroke="black" stroke-miterlimit="10"/>
        <path d="M5.23926 5.23926H6.10882V8.39143" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.91309 8.5H7.30439" stroke="black" stroke-miterlimit="10" stroke-linecap="round"/>
        <path d="M5.99998 2.79346C5.86024 2.79346 5.72364 2.83489 5.60746 2.91253C5.49127 2.99016 5.40071 3.1005 5.34724 3.2296C5.29376 3.3587 5.27977 3.50076 5.30703 3.63781C5.33429 3.77487 5.40158 3.90076 5.50039 3.99957C5.5992 4.09837 5.72509 4.16566 5.86214 4.19292C5.9992 4.22019 6.14125 4.20619 6.27035 4.15272C6.39945 4.09924 6.5098 4.00869 6.58743 3.8925C6.66506 3.77631 6.7065 3.63972 6.7065 3.49998C6.7065 3.3126 6.63206 3.13289 6.49957 3.00039C6.36707 2.86789 6.18736 2.79346 5.99998 2.79346Z" fill="black"/>
      </svg>

    </a>
  </div>
  <!--  [ngClass]="{'layout-topbar-menu-mobile-active':appMain.topMenuActive}" -->
</div>


<!--p-overlayPanel #userMobile>
  <ng-template pTemplate>

  </ng-template>
</p-overlayPanel-->
