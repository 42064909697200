import { inject } from '@angular/core';
import { Component } from '@angular/core';
import {Store} from "@ngxs/store";
import {GetProfile} from "@profile/stores/profile/profile.actions";

@Component({
  selector: 'app-profile-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class ProfileLayoutComponent {

  constructor(
    private store: Store
  ) {
    this.store.dispatch(new GetProfile())
  }

}
