import {Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";
import {PasswordValidator} from "@shared/validators/password";
import {IPassword} from "@models/dist";

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit, OnDestroy {

  @Output()
  formChangeEvent = new EventEmitter<IPassword>();
  public displayPassword: boolean = false;

  infosForm: UntypedFormGroup | null = null;

  @ViewChild('password') password?:ElementRef;

  timeOut?:any;

  constructor(
    private formBuilder: UntypedFormBuilder) {
  }

  get errorControl() {
    return this.infosForm?.controls;
  }

  ngOnInit(): void {
    this.infosForm = this.formBuilder.group(
      {
        password: ['', [PasswordValidator.isValid]],
        confirmPassword: [''],
      },
      {validators: this.checkPasswords}
    );

    this.timeOut = setTimeout(() =>{
      this.password?.nativeElement.focus();
    }, 100)
  }

  keyupEnter() {
    this.formChangeEvent.emit(this.infosForm?.value);
  }

  private checkPasswords: ValidatorFn = (
    group: AbstractControl
  ): ValidationErrors | null => {
    const pass = group.get('password')?.value;
    const confirmPass = group.get('confirmPassword')?.value;
    return pass === confirmPass ? null : {notSame: true};
  };

  ngOnDestroy(): void {
    clearTimeout(this.timeOut)
  }


}
