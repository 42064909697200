import {Action, NgxsOnInit, Selector, State, StateContext} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {IcoService} from "@ico/services/ico.service";
import {IcoStateModel, IIco, IRound} from "@models/dist";
import {GetIco, TooglePastPhase} from "@ico/stores/ico/ico.actions";

@State<IcoStateModel>({
  name: 'IcoState',
  defaults: {
    ico: null,
    pastPhase: false,
    rounds: [],
  },
})

@Injectable()
export class IcoState implements NgxsOnInit {

  constructor(private icoService: IcoService) {
  }

  @Selector()
  static getIco(state: IcoStateModel): IIco | null {
    return state.ico;
  }

  @Selector()
  static getRounds(state: IcoStateModel): IRound[] {
    return state.rounds;
  }

  @Selector()
  static getCurrentRound(state: IcoStateModel): IRound | undefined {
    return state.ico?.rounds.find(round => round.current && !round.isPrivate);
  }

  @Selector()
  static getPrivateSale(state: IcoStateModel): IRound | undefined {
    return state.ico?.rounds.find(round => round.current && round.isPrivate);
  }

  @Selector()
  static hasActiveRound(state: IcoStateModel): boolean {
    return !!state.ico?.rounds.filter(round => round.current).length
  }

  ngxsOnInit(ctx: StateContext<IcoStateModel>) {
    this.initState(ctx);
  }

  @Action(GetIco)
  async getIco(ctx: StateContext<IcoStateModel>) {
    try {
      const pastPhase = ctx.getState().pastPhase;
      const ico = await this.icoService.getIco() as IIco
      const rounds = ico.rounds.filter((r) => pastPhase ? r.isFilled : !r.isFilled);
      ctx.patchState({
        ico: {...ico, rounds},
        rounds: ico.rounds
      })
    } catch (e) {
      console.log(e);
      ctx.patchState({
        ico: null
      })
    }
  }

  @Action(TooglePastPhase)
  async tooglePastPhase(ctx: StateContext<IcoStateModel>) {
    const {ico, rounds, pastPhase} = ctx.getState();
    const newValuePastPhase = !pastPhase;
    const newIcostate = {...ico!, rounds: newValuePastPhase ? rounds : rounds.filter((r: IRound) => !r.isFilled)};
    ctx.patchState({
      ico: newIcostate,
      pastPhase: newValuePastPhase
    })
  }

  private async initState(ctx: StateContext<IcoStateModel>) {
  }
}
