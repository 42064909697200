<div *ngIf="isVirtualDashboard && user"  class="virtual-dashboard-banner">
  {{ 'virtualDashboard' | translate : {firstName: user.firstName, lastName: user.lastName} }}
</div>

<div
  [ngClass]="{
    'layout-overlay': isOverlay(),
    'layout-static': isStatic(),
    'layout-theme-light': config.dark,
    'layout-theme-dark': !config.dark,
    'layout-overlay-sidebar-active': overlayMenuActive,
    'layout-static-sidebar-inactive': staticMenuInactive,
    'layout-mobile-sidebar-active': menuActiveMobile,
    'p-ripple-disabled': !config.ripple,
    'p-input-filled': config.inputStyle === 'filled',
    'with-banner': isVirtualDashboard && user
  }"
  class="layout-wrapper">
  <div *ngIf="menuActiveMobile" class="overlay-menu"></div>

  <app-header></app-header>
  <div class="layout-sidebar" [class.noMenu]="noMenu">
    <app-menu [menuActiveMobile]="menuActiveMobile"></app-menu>
  </div>
  <app-lang (close)="closeLangModal()" *ngIf="langModal"></app-lang>
  <app-logout (close)="closeLogoutModal()" *ngIf="openLogoutModal"></app-logout>

  <div class="layout-main-container" [class.noMenu]="noMenu">
    <div class="layout-main">
      <!--      <app-lang (close)="openLanguageModal()" *ngIf="langModal" [dashboard]="true"></app-lang>-->
      <router-outlet></router-outlet>
    </div>
  </div>

<!--  <div class="help-btn" (click)="openContactModal()">-->
<!--    <ion-icon name="call-outline" class="mr-3"></ion-icon>-->
<!--    {{ 'help' | translate}}</div>-->

  <div *ngIf="menuActiveMobile" class="layout-mask p-component-overlay"></div>
</div>
