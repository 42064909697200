import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {firstValueFrom} from "rxjs";
import {environment} from "../../../environments/environment";


const BASE = `${environment.apiUrl}/api/sgp/help`;
const BASENEST = `${environment.nestApiUrl}/api/sgp/help`;

@Injectable({
  providedIn: 'root'
})
export class HelpService {

  constructor(private http: HttpClient) {
  }

  /**************************************
   * HELP
   ***************************************/

  async getHelp() {
    return firstValueFrom(this.http.get(`${BASENEST}`));
  }

  async getHelpContent(id: string, lang: string) {
    return firstValueFrom(this.http.get(`${BASENEST}/getContent/${id}/${lang}`));
  }


}

