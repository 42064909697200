<div class="content">
  <div class="cover mask">
    <div class="cover overlay"></div>
    <img
      src="https://assets-global.website-files.com/623a3ef1c2fd57a9d9b56c5f/64141a1214fcdd8fc3829634_parallax_summit.jpg"
      loading="lazy" sizes="100vw"
      srcset="https://assets-global.website-files.com/623a3ef1c2fd57a9d9b56c5f/64141a1214fcdd8fc3829634_parallax_summit-p-500.jpg 500w, https://assets-global.website-files.com/623a3ef1c2fd57a9d9b56c5f/64141a1214fcdd8fc3829634_parallax_summit-p-800.jpg 800w, https://assets-global.website-files.com/623a3ef1c2fd57a9d9b56c5f/64141a1214fcdd8fc3829634_parallax_summit-p-1080.jpg 1080w, https://assets-global.website-files.com/623a3ef1c2fd57a9d9b56c5f/64141a1214fcdd8fc3829634_parallax_summit-p-1600.jpg 1600w, https://assets-global.website-files.com/623a3ef1c2fd57a9d9b56c5f/64141a1214fcdd8fc3829634_parallax_summit-p-2000.jpg 2000w, https://assets-global.website-files.com/623a3ef1c2fd57a9d9b56c5f/64141a1214fcdd8fc3829634_parallax_summit.jpg 2560w"
      alt="" class="cover img">
  </div>
  <div class="profile">
    <div class="c-4">
      <div class="user info"><h1 class="heading-15">APP.SUMMITMINING.IO</h1></div>
    </div>
    <div class="c-2">
      <div class="user photo"><img loading="lazy" sizes="100vw"
                                   srcset="https://assets-global.website-files.com/623a3ef1c2fd57a9d9b56c5f/648c3afd6e5742c80d760be3_Summit_Black_Sq_P-p-500.png 500w, https://assets-global.website-files.com/623a3ef1c2fd57a9d9b56c5f/648c3afd6e5742c80d760be3_Summit_Black_Sq_P-p-800.png 800w, https://assets-global.website-files.com/623a3ef1c2fd57a9d9b56c5f/648c3afd6e5742c80d760be3_Summit_Black_Sq_P-p-1080.png 1080w, https://assets-global.website-files.com/623a3ef1c2fd57a9d9b56c5f/648c3afd6e5742c80d760be3_Summit_Black_Sq_P.png 1190w"
                                   src="https://cdn.discordapp.com/attachments/1062791971879526481/1174407093223166033/Mining_Black_Ci.png?ex=65677ad6&is=655505d6&hm=8c4fee3a80132340bccf823684f4ca281a866f8329a8a388ed1ec2b4debeb0c9&"
                                   alt="" class="profile-pic"></div>
    </div>
    <div class="c-4">
      <div class="user contact">
        <div class="icon-menu">

          <img (click)="toggleLanguageModal()"
               [src]="langSelected === ELang.FR ? './assets/flag/fr.svg' : './assets/flag/en.svg'" alt="">
          <app-lang (close)="toggleLanguageModal()" *ngIf="modalLang"></app-lang>
        </div>
      </div>
    </div>
  </div>
  <div class="list-link">
    <div class="">
      <h1 class="heading-15 text-center">Dashboard migration</h1>
      <p>
        <b>Since the beginning of the year and with the aim of clarity, we have been working on the reunification of
        the Summit dashboards.</b>
        <br><br>
        So today is the time to say goodbye to <b>APP.SUMMITMINING.IO</b> to make way for the new <b>APP.SUMMIT.IO.</b>
        On this new site, you will discover all our other services and data updates concerning them.
      </p>
    </div>

    <a data-w-id="9aa58724-5f65-89a9-4df9-c54f0a355d64" href="/fr/ico"
       class="link-9 wrap linktree highlight w-inline-block"><h3 class="donate-heading highlight">I already have a Mining account</h3>
      <div class="donate-button">
        <div class="text-block-57">Migrate</div>
        <div class="donate-icon">
          <svg id="Layer_2" height="17" viewBox="0 0 24 24" width="17" xmlns="http://www.w3.org/2000/svg" data-name="Layer 2"><path fill="#FFFFFF" d="m22.707 11.293-7-7a1 1 0 0 0 -1.414 1.414l5.293 5.293h-17.586a1 1 0 0 0 0 2h17.586l-5.293 5.293a1 1 0 1 0 1.414 1.414l7-7a1 1 0 0 0 0-1.414z"/></svg>
        </div>
      </div>
    </a>
    <a data-w-id="9aa58724-5f65-89a9-4df9-c54f0a355d64" href="/fr/ico"
       class="link-9 wrap linktree highlight w-inline-block"><h3 class="donate-heading highlight">I don't have a Mining account</h3>
      <div class="donate-button">
        <div class="text-block-57">Sign Up</div>
        <div class="donate-icon">
          <svg id="Layer_2" height="17" viewBox="0 0 24 24" width="17" xmlns="http://www.w3.org/2000/svg" data-name="Layer 2"><path fill="#FFFFFF" d="m22.707 11.293-7-7a1 1 0 0 0 -1.414 1.414l5.293 5.293h-17.586a1 1 0 0 0 0 2h17.586l-5.293 5.293a1 1 0 1 0 1.414 1.414l7-7a1 1 0 0 0 0-1.414z"/></svg>
        </div>
      </div>
    </a>


  </div>
</div>
