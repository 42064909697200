<div class="">
  <app-ico-top [nohelp]="true" [title]="'2FA.page2fa.title' | translate"></app-ico-top>

  <p-card class="mt-3 p-0">
    <div class="userSettings">
      <app-user-details></app-user-details>
      <div class="title mt-6">{{ "2FA.page2fa.title" | translate }}</div>
      <div class="subtitle">
        {{ "2FA.page2fa.text" | translate }}
        <a class="cursor-pointer pink-hypertext" routerLink="/help">
          {{ "2FA.page2fa.help" | translate }}
        </a>
      </div>
      <ng-container *ngIf="!(user$ | async)">
        <p-skeleton
          class="mt-3"
          height="4rem"
          styleClass="mt-2"
          width="100%"
        ></p-skeleton>
        <p-skeleton
          class="mt-3"
          height="2rem"
          styleClass="mt-2"
          width="100%"
        ></p-skeleton>
      </ng-container>
      <ng-container *ngIf="user$ | async">
        <div *ngIf="!user?.isTotpActivated" class="bannerMsg error mt-4">
          <div class="img">
            <img src="/assets/icon/twofa.svg"/>
          </div>
          <div class="msg">
            <div class="title">
              {{ "2FA.page2fa.notProtected" | translate }}
            </div>
            <div
              [innerHtml]="'2FA.page2fa.notProtectedText' | translate"
              class="msg"
            ></div>
            <div
              (click)="openGoogle2FaModal()"
              *ngIf="!user?.isTotpActivated"
              class="link">
              {{ "2FA.page2fa.notProtectedLink" | translate }}
            </div>
          </div>
        </div>
        <div *ngIf="user?.isTotpActivated" class="bannerMsg success mt-4">
          <div class="img">
            <img src="/assets/forms/settings/protected.png"/>
          </div>
          <div class="msg">
            <div class="title">{{ "2FA.page2fa.protected" | translate }}</div>
            <div
              [innerHtml]="'2FA.page2fa.protectedText' | translate"
              class="msg"
            ></div>
          </div>
        </div>
        <div class="separator"></div>
        <div class="flex justify-content-between mt-4">
          <h1 class="title">
            {{ "2FA.page2fa.GoogleAuthenticator" | translate }}
          </h1>

          <button
            (click)="openGoogle2FaModal()"
            *ngIf="!user?.isTotpActivated"
            [label]="'Common.enable' | translate"
            class="gradient-button"
            pButton
            type="button"></button>

          <button (click)="openGoogle2FaRevokeModal()"
                  *ngIf="(user$ | async)?.isTotpActivated"
                  [label]="'Common.disable' | translate"
                  class="outline-button"
                  pButton
                  type="button"></button>


        </div>
      </ng-container>
    </div>
  </p-card>
</div>
