import {inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "@environments/environment";
import {map, Observable} from "rxjs";

@Injectable()
export class AirdropGateway {
  constructor(
    private http: HttpClient
  ) {
  }

  base = `${environment.nestApiUrl}/api/sgp/users`
  url = `${this.base}/users`

  getAirdrop(): Observable<any> {
    return this.http.get<{ airdrop: any }>(`${this.base}/airdrop`).pipe(
      map(response => response)
    )
  }

  claimAirdrop(id: string): Observable<any> {
    return this.http.post<any>(`${this.base}/airdrop/${id}`, {}).pipe(
      map(response => response)
    )
  }

}
