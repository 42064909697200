import {Component, OnInit} from '@angular/core';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-contact-modal',
  templateUrl: './contact-modal.component.html',
  styleUrls: ['./contact-modal.component.scss']
})
export class ContactModalComponent implements OnInit {
  title = 'contact.modal.title';


  constructor(
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
  ) {
  }

  async ngOnInit() {
   this.title = this.config.data.title ??  'contact.modal.title'
  }

  cancel() {
    this.ref.close(false);
  }

  close() {
    this.ref.close();
  }

  goToCalendly() {
    window.open('https://calendly.com/hello-summit')
  }
}
