import {Pipe, PipeTransform} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {ELang} from "@models/dist";

@Pipe({
  name: 'TranslateGains',
  pure: false
})
export class TranslateGainsPipe implements PipeTransform {
  private lang: ELang = ELang.FR;

  constructor(private translateService: TranslateService) {
    this.lang = this.translateService.currentLang as ELang;
    this.translateService.onLangChange.subscribe(lang => {
      this.lang = lang.lang as ELang
    });
  }

  transform(item: { name: string, gainsEn: string, gains: string, percent?: string, condition: number, completed: boolean, img?: string }): string {
    if (!item) {
      return '';
    }

    if (this.lang === ELang.EN) {
      return item.gainsEn
    }
    return item.gains;
  }
}
