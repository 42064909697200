import {Component, OnInit} from '@angular/core';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {
  ETemporaryTransactionStatus,
  EToastSeverity,
  ETransactionStatus,
  EVaultService,
  ICryptoNetwork,
  IRound
} from "@models/dist";
import {
  CurrencyService,
  CustodyService,
  ReactiveLoaderService,
  ToastService,
  TransactionService,
  UtilsService
} from "@shared/services";
import {Store} from "@ngxs/store";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {reactiveButtonState} from "@shared/services/reactive-loader.service";


@Component({
  selector: 'app-invest-modal',
  templateUrl: './invest-modal.component.html',
  styleUrls: ['./invest-modal.component.scss']
})
export class InvestModalComponent implements OnInit {

  allCryptos: ICryptoNetwork[] = [];
  loading = false;
  public time: Date = new Date();
  selectedCrypto: ICryptoNetwork | null = null;
  address = '';
  public target: number = 0;
  public activeRound: IRound | undefined;
  public isWaitingList: boolean = false;

  decimal = 0;
  public resume = false;
  price: number = 0;
  credit: number = 0;
  public convert: any = 0;
  public error: string = "";
  public rest: number = 0;
  public status: string = '';
  public message: string = '';
  public transactionId: any = '';
  public priceChanged: boolean = false;
  public priceInterval: any = null;

  constructor(
    private store: Store,
    private transactionService: TransactionService,
    private utilsService: UtilsService,
    private custodyService: CustodyService,
    private currencyService: CurrencyService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private router: Router,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private reactiveLoader: ReactiveLoaderService,
  ) {
  }

  async ngOnInit() {
    try {
      this.priceInterval = setInterval(async () => {
        await this.getConvertValue(this.rest)
      }, 60000);
      this.price = this.config.data.price;
      this.allCryptos = (await this.currencyService.getCurrencies() as ICryptoNetwork[]).sort((a, b) => a.label < b.label ? -1 : (a.label > b.label ? 1 : 0))
      this.credit = this.config.data.credits;
      this.target = this.config.data.target;
      this.rest = this.config.data.rest;
      this.activeRound = this.config.data.activeRound;
      this.isWaitingList = this.config.data.isWaitingList;
    } catch (e) {
      console.log(e);
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.priceInterval)
  }

  async pay() {
    const success = await this.handleTransaction();
    // this.close();
    this.ref.close({
      status: "goResumeModal",
      transaction: {
        id: this.transactionId,
      },
    });
  }

  back() {
    this.ref.close({
      status: "back",
    });
  }

  toFixed(number: number) {
    return number.toFixed(3)
  }

  async selectedValue() {
    this.address = '';
    if (!this.selectedCrypto) {
      return;
    }
    this.loading = true;
    try {
      const dataAddress = await this.custodyService.getDepositAddress(this.selectedCrypto?.providerId, EVaultService.ICO);
      this.address = dataAddress?.address ? dataAddress.address : ''
      if (this.rest) {
        await this.getConvertValue(this.rest);
      }

    } catch (e) {
      this.error = this.translateService.instant('ico.errors.transaction');
      this.toastService.addToast(EToastSeverity.error, 'error', e);
    }
    this.loading = false;
  }

  public async handleTransaction() {
    const data = await this.transactionService.createTransaction(this.target, this.selectedCrypto?.providerId!) as {
      status: string,
      id: string
    }
    this.transactionId = data.id;
    this.status = data.status;
    // switch (data.status) {
    //   case ETransactionStatus.PENDING_PAYMENT:
    //     this.toastService.addToast(EToastSeverity.success, 'Transaction', this.translateService.instant('ico.invest.toast.locked.success'));
    //     break;
    //
    //   case ETransactionStatus.PENDING_UNLOCK:
    //     this.toastService.addToast(EToastSeverity.info, 'Transaction', this.translateService.instant('ico.invest.toast.locked.waitingList'));
    //
    //     return;
    //
    //   case ETemporaryTransactionStatus.ALREADY_EXISTING:
    //     this.toastService.addToast(EToastSeverity.error, 'Transaction', this.translateService.instant('ico.invest.toast.locked.alreadyExists'));
    //     break;
    //
    //   case ETransactionStatus.COMPLETED:
    //     this.toastService.addToast(EToastSeverity.success, 'Transaction', this.translateService.instant('ico.invest.toast.locked.confirm', {token: this.target}));
    //     return;
    //
    //   case ETemporaryTransactionStatus.NOT_ENOUGH_TOKEN:
    //     this.toastService.addToast(EToastSeverity.error, 'Transaction', this.translateService.instant('ico.invest.toast.locked.notEnought'));
    //
    //     return;
    //
    //   case ETemporaryTransactionStatus.ERROR:
    //     this.toastService.addToast(EToastSeverity.error, 'Transaction', this.translateService.instant('ico.invest.toast.locked.error'));
    //     this.error = this.translateService.instant('ico.errors.transaction');
    //     return;
    //
    //   default:
    //     break;
    // }
    return true;

  }

  async goToResume() {
    this.reactiveLoader.setReactiveButtonState(
      'create-tr',
      reactiveButtonState.loading
    );

    await this.getConvertValue(this.rest);

    // HANDLE TRANSACTION
    await this.handleTransaction();

    this.reactiveLoader.setReactiveButtonState(
      'create-tr',
      reactiveButtonState.success
    );

    this.ref.close({
      status: "goResumeModal",
      transaction: {
        id: this.transactionId,
      },
    });

  }

  cancel() {
    this.ref.close({
      status: "goCancelModal",
      transaction: {
        id: this.transactionId,
      },
    });
  }

  async buy() {
    this.reactiveLoader.setReactiveButtonState(
      'direct-buy',
      reactiveButtonState.loading
    );
    await this.handleTransaction();
    this.reactiveLoader.setReactiveButtonState(
      'direct-buy',
      reactiveButtonState.success
    );
    this.ref.close({
      status: "goResumeModal",
      transaction: {
        id: this.transactionId,
      },
    });
  }

  private async getConvertValue(rest: number) {
    if (this.selectedCrypto) {
      const data: any = await this.utilsService.getConvert(this.selectedCrypto?.slug, rest)
      if (!!data) {


      // const convert = Math.round(Number(data.quote[Object.keys(data.quote)[0]].price));
      // const convert = Number(data.quote[Object.keys(data.quote)[0]].price);
      const convert = Number(data.price);

      this.convert = convert.toFixed(7);
      this.decimal = convert.toString().split('.')[1].length;
      this.priceChanged = true;
      setTimeout(() => this.priceChanged = false, 1000);
      }
    }

  }
}
