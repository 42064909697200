<div class="">
  <app-ico-top [title]="'KYC.page.title' | translate : {title : isKyb ? 'KYB' : 'KYC'}"></app-ico-top>

  <p-card class="mt-3">
    <div class="userSettings">
      <app-user-details></app-user-details>

      <ng-container *ngIf="!(user$ | async)">
        <p-skeleton class="mt-3" height="4rem" styleClass="mt-2" width="100%"></p-skeleton>
        <p-skeleton class="mt-3" height="2rem" styleClass="mt-2" width="100%"></p-skeleton>
      </ng-container>

      <ng-container *ngIf="isKyb">

        <ng-container *ngIf="user$ | async as user">
          <div class="title mt-6">{{ "KYC.page.alternateTitle" | translate: {title: isKyb ? 'KYB' : 'KYC'} }}</div>
          <div class="subtitle">
            {{ "KYC.page.subtitle" | translate }}
          </div>


          <ng-container *ngIf="user.complianceStatus === EPassfortProductState.APPROVED">
            <div class="bannerMsg success mt-4">
              <div class="img">
                <img src="/assets/forms/kyc/status/KYC_COMPLETE.svg" />
              </div>
              <div class="msg">
                <div class="title">
                  {{ "KYC.page.approved" | translate }}
                </div>
                <div [innerHtml]="'KYC.page.approvedText' | translate" class="msg"></div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="user.complianceStatus === EPassfortProductState.REJECTED">
            <div class="bannerMsg error mt-4">
              <div class="img">
                <img src="/assets/forms/kyc/status/KYC_NOT_OK.svg" />
              </div>
              <div class="msg">
                <div class="title">
                  {{ "KYC.page.rejected" | translate : {title: 'KYB'} }}
                </div>
                <div [innerHtml]="'KYC.page.rejectedText' | translate : {title : 'KYB'}" class="msg"></div>
              </div>
            </div>
          </ng-container>


          <ng-container *ngIf="user.complianceStatus === EPassfortProductState.IN_REVIEW">
            <div class="bannerMsg pending mt-4">
              <div class="img">
                <img src="/assets/forms/kyc/status/KYC_PENDING.svg" />
              </div>
              <div class="msg">
                <div class="title">
                  {{ "KYC.page.pending" | translate : {title: 'KYB'} }}
                </div>
                <div [innerHtml]="'KYC.page.pendingText' | translate: {title : 'KYB'}" class="msg"></div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="user.complianceStatus === EPassfortProductState.NONE">
            <div class="bannerMsg error mt-4">
              <div class="img">
                <img src="/assets/forms/kyc/status/KYC_NOT_OK.svg" />
              </div>
              <div class="msg">
                <div class="title">
                  {{ "KYC.page.none" | translate : {title: 'KYB'} }}
                </div>
                <div [innerHtml]="'KYC.page.noneText' | translate : {title :'KYB (Know Your Business)'}" class="msg">
                </div>
                <a (click)="goToKyb()" class="link">{{ 'KYC.page.noneLink' | translate : {title: 'KYB'} }}</a>
              </div>
            </div>

            <div class="actions flex align-items-center gap-5 justify-content-end mt-5">
              <app-ico-btn (click)="tutorialRoute()" class="w-10rem" [text]="'KYB.actions.tuto' | translate"
                [outline]="true"></app-ico-btn>
              <app-ico-btn (click)="goToKyb()" class="w-10rem" [text]="'KYB.actions.pass'| translate"></app-ico-btn>

            </div>
          </ng-container>


        </ng-container>


      </ng-container>

      <ng-container *ngIf="!isKyb">

        <ng-container *ngIf="user$ | async as user">

          <div class="title mt-6">{{ "KYC.page.alternateTitle" | translate: {title: isKyb ? 'KYB' : 'KYC'} }}</div>
          <div class="subtitle">
            {{ "KYC.page.subtitle" | translate }}
          </div>

          <div class="bandeaux"
            [class.full]="!(isNiceCountry$ | async) || ((user.complianceStatus === EPassfortProductState.IN_REVIEW  || user.complianceStatus === EPassfortProductState.MAJ) && user.kycLightStatus === EPassfortProductState.NONE)">
            <!--            // KYC LIGHT-->
            <ng-container *ngIf="(isNiceCountry$ | async)">
              <!-- <ng-container
                *ngIf="user.kycLightStatus === EPassfortProductState.IN_REVIEW">
                <div class="bannerMsg pending mt-4">
                  <div class="img">
                    <img src="/assets/forms/kyc/status/KYC_PENDING.svg"/>
                  </div>
                  <div class="msg">
                    <div class="head-title">
                      {{ "KYC.page.light" | translate : {title: isKyb ? 'KYB' : 'KYC'} }}
                    </div>
                    <div class="title">
                      {{ "KYC.page.pending" | translate : {title: isKyb ? 'KYB' : 'KYC'} }}
                    </div>
                    <div
                      [innerHtml]="'KYC.page.pendingText' | translate: {title : isKyb ? 'KYB' : 'KYC'}"
                      class="msg"
                    ></div>
                  </div>
                </div>
              </ng-container> -->

              <!-- <ng-container
                *ngIf="user.kycLightStatus === EPassfortProductState.APPROVED">
                <div class="bannerMsg success mt-4">
                  <div class="img">
                    <img src="/assets/forms/kyc/status/KYC_COMPLETE.svg"/>
                  </div>
                  <div class="msg">
                    <div class="head-title">
                      {{ "KYC.page.light" | translate : {title: isKyb ? 'KYB' : 'KYC'} }}
                    </div>
                    <div class="title">
                      {{ "KYC.page.approved" | translate }}
                    </div>
                    <div
                      [innerHtml]="'KYC.page.approvedText' | translate"
                      class="msg"
                    ></div>
                  </div>
                </div>
              </ng-container> -->

              <!-- <ng-container
                *ngIf="user.kycLightStatus === EPassfortProductState.MAJ">
                <div class="bannerMsg pending mt-4">
                  <div class="img">
                    <img src="/assets/forms/kyc/status/KYC_PENDING.svg"/>
                  </div>
                  <div class="msg">
                    <div class="head-title">
                      {{ "KYC.page.light" | translate : {title: isKyb ? 'KYB' : 'KYC'} }}
                    </div>
                    <div class="title">
                      {{ "KYC.page.waitingMAJ" | translate : {title: isKyb ? 'KYB' : 'KYC'} }}
                    </div>
                    <div
                      [innerHtml]="'KYC.page.waitingMAJTxt' | translate : {title : isKyb ? 'KYB' : 'KYC'}"
                      class="msg"
                    ></div>

                    <a (click)="goToKycLight(true)"
                       class="link">{{ 'KYC.page.waitingMAJLink' | translate : {title: isKyb ? 'KYB' : 'KYC'} }}</a>

                  </div>
                </div>
              </ng-container> -->

              <!-- <ng-container
                *ngIf="user.kycLightStatus === EPassfortProductState.CANCELLED">
                <div class="bannerMsg error mt-4">
                  <div class="img">
                    <img src="/assets/forms/kyc/status/KYC_NOT_OK.svg"/>
                  </div>
                  <div class="msg">
                    <div class="head-title">
                      {{ "KYC.page.light" | translate : {title: isKyb ? 'KYB' : 'KYC'} }}
                    </div>
                    <div class="title">
                      {{ "KYC.page.error" | translate }}
                    </div>
                    <div
                      [innerHtml]="'KYC.page.errorText' | translate"
                      class="msg"
                    ></div>
                  </div>
                </div>
              </ng-container> -->

              <!-- <ng-container
                *ngIf="user.kycLightStatus === EPassfortProductState.REJECTED">
                <div class="bannerMsg error mt-4">
                  <div class="img">
                    <img src="/assets/forms/kyc/status/KYC_NOT_OK.svg"/>
                  </div>
                  <div class="msg">
                    <div class="head-title">
                      {{ "KYC.page.light" | translate : {title: isKyb ? 'KYB' : 'KYC'} }}
                    </div>
                    <div class="title">
                      {{ "KYC.page.rejected" | translate : {title: isKyb ? 'KYB' : 'KYC'} }}
                    </div>
                    <div
                      [innerHtml]="'KYC.page.rejectedText' | translate : {title : isKyb ? 'KYB' : 'KYC'}"
                      class="msg"
                    ></div>
                  </div>
                </div>
              </ng-container> -->

              <!-- <ng-container *ngIf="user.kycLightStatus === EPassfortProductState.NONE && ((isNiceCountry$ | async)) && user.complianceStatus === EPassfortProductState.NONE">
                <div class="bannerMsg error mt-4">
                  <div class="img">
                    <img src="/assets/forms/kyc/status/KYC_NOT_OK.svg"/>
                  </div>
                  <div class="msg">
                    <div class="head-title">
                      {{ "KYC.page.light" | translate : {title: isKyb ? 'KYB' : 'KYC'} }}
                    </div>
                    <div class="title">
                      {{ "KYC.page.none" | translate  : {title: isKyb ? 'KYB' : 'KYC'} }}
                    </div>
                    <div
                      [innerHtml]="'KYC.page.noneText' | translate : {title : isKyb ? 'KYB (Know Your Business)' : 'KYC (Know Your Customer)'}"
                      class="msg"
                    ></div>
                    <ng-container *ngIf="kycAvailable$ | async as kycAvailable">

                      <ng-container>
                        <a (click)="goToKycLight(kycAvailable.kycLight)"
                           *ngIf="kycAvailable.kycLight"
                           class="link">{{ 'KYC.page.noneLink' | translate : {title: isKyb ? 'KYB' : 'KYC'} }}</a>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </ng-container> -->
              <!--          <div-->
              <!--            *ngIf="displayStartButton()"-->
              <!--            class="flex justify-content-end mt-5">-->
              <!--            <app-ico-btn (click)="tutorialRoute()" [outline]="true"-->
              <!--                         [text]="'KYC.page.tuto' | translate: {title : isKyb ? 'KYB' : 'KYC'}"-->
              <!--                         class="mr-3">-->

              <!--            </app-ico-btn>-->
              <!--            <app-ico-btn (click)="clickStartBtn()"-->
              <!--                         [text]="'KYC.page.startMyKyc' | translate: {title : isKyb ? 'KYB' : 'KYC'}">-->

              <!--            </app-ico-btn>-->
              <!--          </div>-->

            </ng-container>

            <!--            // KYC STANDARD-->
            <ng-container>
              <ng-container
                *ngIf="kyc && kyc.version === 2 && user.complianceStatus === EPassfortProductState.APPROVED">
                <div class="bannerMsg success mt-4">
                  <div class="img">
                    <img src="/assets/forms/kyc/status/KYC_COMPLETE.svg" />
                  </div>
                  <div class="msg">
                    <div class="head-title">
                      {{ "KYC.page.full" | translate : {title: isKyb ? 'KYB' : 'KYC'} }}
                    </div>
                    <div class="title">
                      {{ "KYC.page.approved" | translate }}
                    </div>
                    <div [innerHtml]="'KYC.page.approvedTextWithdrawal' | translate" class="msg"></div>
                    <div class="mt-4 kyc-withdrawal-buttons">
                      <app-ico-btn class="mining-btn" [text]="'KYC.page.miningWithdrawal' | translate" (btnClick)="onMiningFundsWithdrawal()" [disabled]="!(canWithdrawFromMining$ | async)"></app-ico-btn>
                      <app-ico-btn class="gravity-btn" [text]="'KYC.page.gravityWithdrawal' | translate" (btnClick)="onGravityFundsWithdrawal()" [disabled]="!(canWithdrawFromGravity$ | async)"></app-ico-btn>
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-container
                *ngIf="kyc && kyc.version === 2 && user.complianceStatus === EPassfortProductState.REJECTED || (isBannedCountry$|async) || (isSpecialCountry$ | async)">
                <div class="bannerMsg error mt-4">
                  <div class="img">
                    <img src="/assets/forms/kyc/status/KYC_NOT_OK.svg" />
                  </div>
                  <div class="msg">
                    <div class="head-title">
                      {{ "KYC.page.full" | translate : {title: isKyb ? 'KYB' : 'KYC'} }}
                    </div>
                    <div class="title">
                      {{ "KYC.page.rejected" | translate : {title: isKyb ? 'KYB' : 'KYC'} }}
                    </div>
                    <div [innerHtml]="'KYC.page.errorTextWithdrawal' | translate : {title : isKyb ? 'KYB' : 'KYC'}"
                      class="msg"></div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="kyc && kyc.version === 2 && user.complianceStatus === EPassfortProductState.MAJ">
                <div class="bannerMsg pending mt-4">
                  <div class="img">
                    <img src="/assets/forms/kyc/status/KYC_PENDING.svg" />
                  </div>
                  <div class="msg">
                    <div class="head-title">
                      {{ "KYC.page.full" | translate : {title: isKyb ? 'KYB' : 'KYC'} }}
                    </div>
                    <div class="title">
                      {{ "KYC.page.waitingMAJ" | translate : {title: isKyb ? 'KYB' : 'KYC'} }}
                    </div>
                    <div [innerHtml]="'KYC.page.waitingMAJTxt' | translate : {title : isKyb ? 'KYB' : 'KYC'}"
                      class="msg"></div>

                    <a (click)="goToKycFull(true)" class="link">{{ 'KYC.page.waitingMAJLink' | translate : {title: isKyb
                      ? 'KYB' : 'KYC'} }}</a>

                  </div>
                </div>
              </ng-container>

              <ng-container
                *ngIf="kyc && kyc.version === 2 && user.complianceStatus === EPassfortProductState.IN_REVIEW">
                <div class="bannerMsg pending mt-4">
                  <div class="img">
                    <img src="/assets/forms/kyc/status/KYC_PENDING.svg" />
                  </div>
                  <div class="msg">
                    <div class="head-title">
                      {{ "KYC.page.full" | translate : {title: isKyb ? 'KYB' : 'KYC'} }}
                    </div>
                    <div class="title">
                      {{ "KYC.page.pending" | translate : {title: isKyb ? 'KYB' : 'KYC'} }}
                    </div>
                    <div [innerHtml]="'KYC.page.pendingTextComplete' | translate: {title : isKyb ? 'KYB' : 'KYC'}"
                      class="msg"></div>
                  </div>
                </div>
              </ng-container>
              <ng-container
                *ngIf="kyc && kyc.version === 2 && user.complianceStatus === EPassfortProductState.NONE && ((isNiceCountry$ | async) || (isRiskCountry$ | async))">
                <div class="bannerMsg error mt-4">
                  <div class="img">
                    <img src="/assets/forms/kyc/status/KYC_NOT_OK.svg" />
                  </div>
                  <div class="msg">
                    <div class="head-title">
                      {{ "KYC.page.full" | translate : {title: isKyb ? 'KYB' : 'KYC'} }}
                    </div>
                    <div class="title">
                      {{ "KYC.page.none" | translate : {title: isKyb ? 'KYB' : 'KYC'} }}
                    </div>
                    <div
                      [innerHtml]="'KYC.page.noneTextWithdrawal' | translate : {title : isKyb ? 'KYB (Know Your Business)' : 'KYC (Know Your Customer)'}"
                      class="msg"></div>
                    <ng-container *ngIf="kycAvailable$ | async as kycAvailable">

                      <ng-container>
                        <a (click)="goToKycFull(kycAvailable.kycComplete)"
                          *ngIf="kycAvailable.kycComplete && (user.kycLightStatus !== EPassfortProductState.IN_REVIEW && user.kycLightStatus !== EPassfortProductState.MAJ)"
                          class="link">{{ 'KYC.page.noneLink' | translate : {title: isKyb ? 'KYB' : 'KYC'} }}</a>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="!kyc || kyc.version === 1">
                <div class="bannerMsg error mt-4">
                  <div class="img">
                    <img src="/assets/forms/kyc/status/KYC_NOT_OK.svg" />
                  </div>
                  <div class="msg">
                    <div class="head-title">
                      {{ "KYC.page.full" | translate : {title: isKyb ? 'KYB' : 'KYC'} }}
                    </div>
                    <div class="title">
                      {{ "KYC.page.none" | translate : {title: isKyb ? 'KYB' : 'KYC'} }}
                    </div>
                    <div
                      [innerHtml]="'KYC.page.noneTextWithdrawal' | translate : {title : isKyb ? 'KYB (Know Your Business)' : 'KYC (Know Your Customer)'}"
                      class="msg"></div>
                    <ng-container *ngIf="kycAvailable$ | async as kycAvailable">

                      <ng-container>
                        <a (click)="goToKycFull(true)" class="link">{{ 'KYC.page.noneLink' | translate : {title: isKyb ?
                          'KYB' : 'KYC'} }}</a>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>

          <app-list-invest-card></app-list-invest-card>


          <!-- <ng-container *ngIf="!!form && !!user">
            <div class="title mt-6">{{ "KYC.tableau.title" | translate }}</div>
            <div class="subtitle">
              {{ "KYC.tableau.subtitle" | translate }}
            </div>
            <ng-container *ngIf="(kycAvailable$ | async) as kycAvailable">

              <div class="tableau" [class.no-kyc-light]="(isRiskCountry$ | async)"
                   [class.special-country]="(isSpecialCountry$ | async)"
                   #tableau>
                <div class="overlay" [style.width.px]="width$| async"
                     *ngIf="!kycAvailable.kycLight && !kycAvailable.kycComplete && !kycAvailable.noKyc && (selectedCountry$| async)">
                  <span class="simple-text">{{ 'KYC.light.bannedCountries' | translate}}</span>
                  <div class="flex align-items-center gap-1 mt-2"
                       *ngIf="!user['fk_clickup_contest_id'] else hasAlreadyContest">
                    <a class="simple-link no-underline">{{ 'KYC.light.appel' | translate }}</a>
                    <a (click)="contest($event)"
                       class=" cursor-pointer simple-link">{{ 'KYC.light.appelLink' | translate }}</a>
                  </div>
                  <ng-template #hasAlreadyContest>
                    <a class="simple-link no-underline">
                      {{ 'KYC.light.alreadyAppel'  | translate}}
                    </a>
                  </ng-template>
                </div>
                <div class="label" *ngIf="!!form">
                  <div [formGroup]="form" class="country head">
                    <span class="title">{{ 'KYC.tableau.data.title' | translate }}</span>
                    <div class="select-ctn">
                      <div class="p-dropdown cursor-normal" *ngIf="!!user.country">
                        <div class="country-item country-item-value"
                             *ngIf="form.value?.country">
                          {{form.value?.country.flag}}<span
                          class="pl-2">{{form.value?.country.name }}</span>
                        </div>
                      </div>
                      <ng-container *ngIf="!user.country">
                        <ng-container *ngIf="countries$ | async as countries">
                          <p-dropdown formControlName="country" [options]="countries"
                                      optionLabel="name"
                                      [filter]="true"
                                      filterBy="name"
                                      [resetFilterOnHide]="true"
                                      [emptyFilterMessage]="'Common.notFound' | translate"
                                      [showClear]="true"
                                      [placeholder]="'selectCountry' | translate">
                            <ng-template pTemplate="filter" let-options="options">
                              <div class="search-dropdown">
                                <div class="input-ctn"
                                     (click)="$event.stopPropagation()">
                                  <span class=""><i class="pi pi-search"></i></span>
                                  <input class="w-full" type="text" pInputText
                                         [placeholder]="'searchCountry' | translate"
                                         formControlName="searchCountry"
                                         (keyup)="options.filter($event)">
                                  <span (click)="clearSearch(options)"><img
                                    src="/assets/icon/close.svg" alt=""></span>
                                </div>
                              </div>
                            </ng-template>
                            <ng-template pTemplate="selectedItem">
                              <div class="country-item country-item-value"
                                   *ngIf="form.value?.country">
                                {{form.value?.country.flag}} <span
                                class="pl-2">{{form.value?.country.name }}</span>
                              </div>
                            </ng-template>
                            <ng-template let-country pTemplate="item">
                              <div class="country-item">
                                {{country.flag}}<span
                                class="pl-2">{{country.name  }}</span>
                              </div>
                            </ng-template>
                          </p-dropdown>
                        </ng-container>
                      </ng-container>
                    </div>
                    <div class="flex align-items-center gap-1 mt-2 ico-link notCountryResidence"
                         *ngIf="!!user.country">
                      <span class="xmall-txt">{{ 'notCountryResidence' | translate}}</span>
                      <span (click)="contactModal(true)"
                            class="xmall-txt font-bold cursor">{{ 'contactTeam' | translate}}</span>
                    </div>
                  </div>

                  <div class="head">
                    <span class="product-title">{{'KYC.page.research' | translate}}</span>
                  </div>
                  <div class="head">
                    <span class="product-title expo">{{'KYC.page.ico' | translate}}</span>
                  </div>

                  <div class="head">
                    <span class="subproduct-title">{{'KYC.page.1K' | translate}}</span>
                  </div>

                  <div class="head">
                    <span class="subproduct-title">{{'KYC.page.10K' | translate}}</span>
                  </div>

                  <div class="head">
                    <span class="subproduct-title"> {{'KYC.page.more10K' | translate}}</span>
                  </div>

                  <div class="head">
                    <span class="product-title"> {{'KYC.page.otherService' | translate}} </span>
                  </div>

                  <div class="head">
                    <span class="subproduct-title"> {{'KYC.page.1K' | translate}}</span>
                  </div>

                  <div class="head">
                    <span class="subproduct-title">  {{'KYC.page.10K' | translate}}</span>
                  </div>

                  <div class="head">
                    <span class="subproduct-title"> {{'KYC.page.more10K' | translate}}</span>
                  </div>

                </div>
                <div class="no-kyc" *ngIf="!(isSpecialCountry$ | async)">
                  <div class="row"
                       [class.active]="user.complianceStatus !== EPassfortProductState.APPROVED && user.kycLightStatus !== EPassfortProductState.APPROVED && !(isBannedCountry$| async) && (selectedCountry$| async)"
                  >
                    <span class="kyc-title">{{ 'KYC.page.noKyc' | translate}}</span>
                    <div class="flex align-items-center gap-2 pb-7rem"
                         *ngIf="!(isBannedCountry$ | async) && (selectedCountry$| async)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17"
                           viewBox="0 0 17 17" fill="none">
                        <path
                          d="M8.875 0.5C4.46385 0.5 0.875 4.08885 0.875 8.5C0.875 12.9112 4.46385 16.5 8.875 16.5C13.2862 16.5 16.875 12.9112 16.875 8.5C16.875 4.08885 13.2862 0.5 8.875 0.5ZM13.0385 5.81885L7.86923 11.9727C7.81253 12.0402 7.74197 12.0948 7.66234 12.1327C7.58271 12.1706 7.49587 12.1909 7.40769 12.1923H7.39731C7.31105 12.1923 7.22577 12.1741 7.14699 12.139C7.0682 12.1039 6.99768 12.0526 6.94 11.9885L4.72462 9.52692C4.66835 9.46725 4.62459 9.39693 4.59589 9.3201C4.56719 9.24327 4.55414 9.16148 4.5575 9.07953C4.56087 8.99758 4.58058 8.91714 4.61547 8.84292C4.65037 8.76869 4.69976 8.7022 4.76072 8.64734C4.82168 8.59247 4.893 8.55035 4.97048 8.52344C5.04795 8.49654 5.13002 8.48539 5.21187 8.49065C5.29372 8.49592 5.37369 8.51749 5.44708 8.5541C5.52047 8.59071 5.5858 8.64163 5.63923 8.70385L7.38115 10.6392L12.0962 5.02731C12.2019 4.90503 12.3516 4.82928 12.5127 4.81644C12.6739 4.8036 12.8336 4.85469 12.9574 4.95868C13.0812 5.06266 13.1591 5.21119 13.1743 5.37215C13.1894 5.5331 13.1406 5.69357 13.0385 5.81885Z"
                          fill="#34A853"/>
                      </svg>
                      <span class="valid">{{ 'Common.valid' | translate}}</span>
                    </div>

                    <div class="flex align-items-center gap-2 pb-7rem invalid-ctn"
                         *ngIf="(isBannedCountry$| async)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17"
                           viewBox="0 0 17 17" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M8.875 0.5C4.46385 0.5 0.875 4.08885 0.875 8.5C0.875 12.9112 4.46385 16.5 8.875 16.5C13.2862 16.5 16.875 12.9112 16.875 8.5C16.875 4.08885 13.2862 0.5 8.875 0.5ZM11.9654 5.46971C12.2583 5.7626 12.2583 6.23748 11.9654 6.53037L10.0209 8.47489L11.9654 10.4194C12.2583 10.7123 12.2583 11.1872 11.9654 11.4801C11.6725 11.773 11.1977 11.773 10.9048 11.4801L8.96025 9.53555L7.01568 11.4801C6.72279 11.773 6.24791 11.773 5.95502 11.4801C5.66213 11.1872 5.66213 10.7124 5.95502 10.4195L7.89959 8.47489L5.95502 6.53033C5.66213 6.23744 5.66213 5.76256 5.95502 5.46967C6.24791 5.17678 6.72279 5.17678 7.01568 5.46967L8.96025 7.41423L10.9048 5.46971C11.1977 5.17682 11.6725 5.17682 11.9654 5.46971Z"
                              fill="#FD3A84"/>
                      </svg>
                      <span class="invalid">{{ 'Common.invalid' | translate}}</span>
                    </div>
                  </div>

                  <div class="row "
                       [class.active]="user.complianceStatus !== EPassfortProductState.APPROVED && user.kycLightStatus !== EPassfortProductState.APPROVED && !(isBannedCountry$| async) && (selectedCountry$| async)">
                    <app-check></app-check>
                  </div>
                  <div class="row  no-border"
                       [class.active]="user.complianceStatus !== EPassfortProductState.APPROVED && user.kycLightStatus !== EPassfortProductState.APPROVED && !(isBannedCountry$| async) && (selectedCountry$| async)">
                  </div>
                  <div class="row "
                       [class.active]="user.complianceStatus !== EPassfortProductState.APPROVED && user.kycLightStatus !== EPassfortProductState.APPROVED && !(isBannedCountry$| async) && (selectedCountry$| async)">
                    <app-check></app-check>
                  </div>
                  <div class="row "
                       [class.active]="user.complianceStatus !== EPassfortProductState.APPROVED && user.kycLightStatus !== EPassfortProductState.APPROVED && !(isBannedCountry$| async) && (selectedCountry$| async)">
                  </div>
                  <div class="row "
                       [class.active]="user.complianceStatus !== EPassfortProductState.APPROVED && user.kycLightStatus !== EPassfortProductState.APPROVED && !(isBannedCountry$| async) && (selectedCountry$| async)">
                  </div>
                  <div class="row  no-border"
                       [class.active]="user.complianceStatus !== EPassfortProductState.APPROVED && user.kycLightStatus !== EPassfortProductState.APPROVED && !(isBannedCountry$| async) && (selectedCountry$| async)">
                  </div>
                  <div class="row "
                       [class.active]="user.complianceStatus !== EPassfortProductState.APPROVED && user.kycLightStatus !== EPassfortProductState.APPROVED && !(isBannedCountry$| async) && (selectedCountry$| async)">
                  </div>
                  <div class="row "
                       [class.active]="user.complianceStatus !== EPassfortProductState.APPROVED && user.kycLightStatus !== EPassfortProductState.APPROVED && !(isBannedCountry$| async) && (selectedCountry$| async)">
                  </div>
                  <div class="row "
                       [class.active]="user.complianceStatus !== EPassfortProductState.APPROVED && user.kycLightStatus !== EPassfortProductState.APPROVED && !(isBannedCountry$| async) && (selectedCountry$| async)">
                  </div>
                </div>
                <div class="kyc-simple" *ngIf="!(isRiskCountry$ | async) && !(isSpecialCountry$|async)">
                  <div class="row"
                       [class.active]="user.kycLightStatus === EPassfortProductState.APPROVED && user.complianceStatus !== EPassfortProductState.APPROVED"
                       [class.review]="user.kycLightStatus === EPassfortProductState.IN_REVIEW || user.kycLightStatus === EPassfortProductState.MAJ && user.complianceStatus !== EPassfortProductState.APPROVED">
                    <div class="text-center">
                      <span class="kyc-title">{{ 'KYC.page.kycSimple' | translate}}</span>
                      <div class="sub-title-ctn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12"
                             viewBox="0 0 13 12" fill="none">
                          <path
                            d="M7 2.33333C7 2.05719 6.77614 1.83333 6.5 1.83333C6.22386 1.83333 6 2.05719 6 2.33333H7ZM6.5 6.45833H6C6 6.73448 6.22386 6.95833 6.5 6.95833V6.45833ZM9.25 6.95833C9.52614 6.95833 9.75 6.73448 9.75 6.45833C9.75 6.18219 9.52614 5.95833 9.25 5.95833V6.95833ZM6.5 0C3.1874 0 0.5 2.6874 0.5 6H1.5C1.5 3.23968 3.73968 1 6.5 1V0ZM0.5 6C0.5 9.3126 3.1874 12 6.5 12V11C3.73968 11 1.5 8.76032 1.5 6H0.5ZM6.5 12C9.8126 12 12.5 9.3126 12.5 6H11.5C11.5 8.76032 9.26032 11 6.5 11V12ZM12.5 6C12.5 2.6874 9.8126 0 6.5 0V1C9.26032 1 11.5 3.23968 11.5 6H12.5ZM6 2.33333V6.45833H7V2.33333H6ZM6.5 6.95833H9.25V5.95833H6.5V6.95833Z"
                            fill="black"/>
                        </svg>
                        <span class="sub-title">{{ 'KYC.light.3min' | translate}}</span>
                      </div>
                    </div>
                    <div class="flex align-items-center gap-2 pb-7rem"
                         *ngIf="user.kycLightStatus === EPassfortProductState.APPROVED || user.complianceStatus === EPassfortProductState.APPROVED">
                      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17"
                           viewBox="0 0 17 17" fill="none">
                        <path
                          d="M8.875 0.5C4.46385 0.5 0.875 4.08885 0.875 8.5C0.875 12.9112 4.46385 16.5 8.875 16.5C13.2862 16.5 16.875 12.9112 16.875 8.5C16.875 4.08885 13.2862 0.5 8.875 0.5ZM13.0385 5.81885L7.86923 11.9727C7.81253 12.0402 7.74197 12.0948 7.66234 12.1327C7.58271 12.1706 7.49587 12.1909 7.40769 12.1923H7.39731C7.31105 12.1923 7.22577 12.1741 7.14699 12.139C7.0682 12.1039 6.99768 12.0526 6.94 11.9885L4.72462 9.52692C4.66835 9.46725 4.62459 9.39693 4.59589 9.3201C4.56719 9.24327 4.55414 9.16148 4.5575 9.07953C4.56087 8.99758 4.58058 8.91714 4.61547 8.84292C4.65037 8.76869 4.69976 8.7022 4.76072 8.64734C4.82168 8.59247 4.893 8.55035 4.97048 8.52344C5.04795 8.49654 5.13002 8.48539 5.21187 8.49065C5.29372 8.49592 5.37369 8.51749 5.44708 8.5541C5.52047 8.59071 5.5858 8.64163 5.63923 8.70385L7.38115 10.6392L12.0962 5.02731C12.2019 4.90503 12.3516 4.82928 12.5127 4.81644C12.6739 4.8036 12.8336 4.85469 12.9574 4.95868C13.0812 5.06266 13.1591 5.21119 13.1743 5.37215C13.1894 5.5331 13.1406 5.69357 13.0385 5.81885Z"
                          fill="#34A853"/>
                      </svg>
                      <span class="valid">{{ 'Common.valid' | translate}}</span>
                    </div>
                    <div class="flex align-items-center gap-2 pb-7rem"
                         *ngIf="user.kycLightStatus === EPassfortProductState.MAJ">
                      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17"
                           viewBox="0 0 17 17" fill="none">
                        <path
                          d="M8.125 0.5C3.71385 0.5 0.125 4.08885 0.125 8.5C0.125 12.9112 3.71385 16.5 8.125 16.5C12.5362 16.5 16.125 12.9112 16.125 8.5C16.125 4.08885 12.5362 0.5 8.125 0.5Z"
                          fill="#E09B3D"/>
                        <path
                          d="M9.58666 11.7783C9.9433 12.0862 10.4352 12.0613 10.7638 11.7783C11.0787 11.4609 11.0787 10.9688 10.7638 10.6513L8.65804 8.6352V5.79372C8.65804 5.34918 8.29333 5 7.82902 5C7.36471 5 7 5.34918 7 5.79372V8.96861C7 9.17498 7.08296 9.38135 7.24876 9.52422L9.58666 11.7783Z"
                          fill="white"/>
                      </svg>
                      <span class="review">{{ 'Common.waitingEdd' | translate}}</span>
                    </div>
                    <div class="flex align-items-center gap-2 pb-7rem"
                         *ngIf="user.kycLightStatus === EPassfortProductState.IN_REVIEW">
                      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17"
                           viewBox="0 0 17 17" fill="none">
                        <path
                          d="M8.125 0.5C3.71385 0.5 0.125 4.08885 0.125 8.5C0.125 12.9112 3.71385 16.5 8.125 16.5C12.5362 16.5 16.125 12.9112 16.125 8.5C16.125 4.08885 12.5362 0.5 8.125 0.5Z"
                          fill="#E09B3D"/>
                        <path
                          d="M9.58666 11.7783C9.9433 12.0862 10.4352 12.0613 10.7638 11.7783C11.0787 11.4609 11.0787 10.9688 10.7638 10.6513L8.65804 8.6352V5.79372C8.65804 5.34918 8.29333 5 7.82902 5C7.36471 5 7 5.34918 7 5.79372V8.96861C7 9.17498 7.08296 9.38135 7.24876 9.52422L9.58666 11.7783Z"
                          fill="white"/>
                      </svg>
                      <span class="review">{{ 'Common.runningValidate' | translate}}</span>
                    </div>
                    <div class="flex align-items-center gap-2 pb-7rem invalid-ctn"
                         *ngIf="user.kycLightStatus === EPassfortProductState.REJECTED">
                      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17"
                           viewBox="0 0 17 17" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M8.875 0.5C4.46385 0.5 0.875 4.08885 0.875 8.5C0.875 12.9112 4.46385 16.5 8.875 16.5C13.2862 16.5 16.875 12.9112 16.875 8.5C16.875 4.08885 13.2862 0.5 8.875 0.5ZM11.9654 5.46971C12.2583 5.7626 12.2583 6.23748 11.9654 6.53037L10.0209 8.47489L11.9654 10.4194C12.2583 10.7123 12.2583 11.1872 11.9654 11.4801C11.6725 11.773 11.1977 11.773 10.9048 11.4801L8.96025 9.53555L7.01568 11.4801C6.72279 11.773 6.24791 11.773 5.95502 11.4801C5.66213 11.1872 5.66213 10.7124 5.95502 10.4195L7.89959 8.47489L5.95502 6.53033C5.66213 6.23744 5.66213 5.76256 5.95502 5.46967C6.24791 5.17678 6.72279 5.17678 7.01568 5.46967L8.96025 7.41423L10.9048 5.46971C11.1977 5.17682 11.6725 5.17682 11.9654 5.46971Z"
                              fill="#FD3A84"/>
                      </svg>
                      <span class="invalid">{{ 'Common.invalid' | translate}}</span>
                    </div>
                    <ng-container
                      *ngIf="kycAvailable?.kycLight && (user.kycLightStatus === EPassfortProductState.NONE || user.kycLightStatus === EPassfortProductState.NEEDED) && (user.complianceStatus !== EPassfortProductState.APPROVED) && (user.complianceStatus !== EPassfortProductState.IN_REVIEW) && (user.complianceStatus !== EPassfortProductState.MAJ)">
                      <app-ico-btn (click)="goToKycLight(kycAvailable?.kycLight)"
                                   [disabled]="!kycAvailable?.kycLight "
                                   [text]="'KYC.tableau.actions.btn' | translate"></app-ico-btn>
                    </ng-container>
                  </div>
                  <div class="row"
                       [class.active]="user.kycLightStatus === EPassfortProductState.APPROVED && user.complianceStatus !== EPassfortProductState.APPROVED"
                       [class.review]="user.kycLightStatus === EPassfortProductState.IN_REVIEW || user.kycLightStatus === EPassfortProductState.MAJ"
                  >
                    <app-check></app-check>
                  </div>
                  <div class="row no-border"

                       [class.active]="user.kycLightStatus === EPassfortProductState.APPROVED && user.complianceStatus !== EPassfortProductState.APPROVED"
                       [class.review]="user.kycLightStatus === EPassfortProductState.IN_REVIEW || user.kycLightStatus === EPassfortProductState.MAJ"
                  >
                  </div>
                  <div class="row"
                       [class.active]="user.kycLightStatus === EPassfortProductState.APPROVED && user.complianceStatus !== EPassfortProductState.APPROVED"
                       [class.review]="user.kycLightStatus === EPassfortProductState.IN_REVIEW || user.kycLightStatus === EPassfortProductState.MAJ"
                  >
                    <app-check></app-check>
                  </div>
                  <div class="row"
                       [class.active]="user.kycLightStatus === EPassfortProductState.APPROVED && user.complianceStatus !== EPassfortProductState.APPROVED"
                       [class.review]="user.kycLightStatus === EPassfortProductState.IN_REVIEW || user.kycLightStatus === EPassfortProductState.MAJ"
                  >
                    <app-check></app-check>
                  </div>
                  <div class="row"
                       [class.active]="user.kycLightStatus === EPassfortProductState.APPROVED && user.complianceStatus !== EPassfortProductState.APPROVED"
                       [class.review]="user.kycLightStatus === EPassfortProductState.IN_REVIEW || user.kycLightStatus === EPassfortProductState.MAJ"
                  >
                  </div>
                  <div class="row no-border"
                       [class.active]="user.kycLightStatus === EPassfortProductState.APPROVED && user.complianceStatus !== EPassfortProductState.APPROVED"
                       [class.review]="user.kycLightStatus === EPassfortProductState.IN_REVIEW || user.kycLightStatus === EPassfortProductState.MAJ"
                  >
                  </div>
                  <div class="row"
                       [class.active]="user.kycLightStatus === EPassfortProductState.APPROVED && user.complianceStatus !== EPassfortProductState.APPROVED"
                       [class.review]="user.kycLightStatus === EPassfortProductState.IN_REVIEW || user.kycLightStatus === EPassfortProductState.MAJ"
                  >
                  </div>
                  <div class="row"
                       [class.active]="user.kycLightStatus === EPassfortProductState.APPROVED && user.complianceStatus !== EPassfortProductState.APPROVED"
                       [class.review]="user.kycLightStatus === EPassfortProductState.IN_REVIEW || user.kycLightStatus === EPassfortProductState.MAJ"
                  >
                  </div>
                  <div class="row"
                       [class.active]="user.kycLightStatus === EPassfortProductState.APPROVED && user.complianceStatus !== EPassfortProductState.APPROVED"
                       [class.review]="user.kycLightStatus === EPassfortProductState.IN_REVIEW || user.kycLightStatus === EPassfortProductState.MAJ"
                  >
                  </div>
                </div>
                <div class="kyc-complet">
                  <div class="row"
                       [class.active]="user.complianceStatus === EPassfortProductState.APPROVED"
                       [class.review]="user.complianceStatus === EPassfortProductState.IN_REVIEW || user.complianceStatus === EPassfortProductState.MAJ"
                  >
                    <div class="text-center">
                      <span class="kyc-title">{{ 'KYC.page.kycComplet' | translate}}</span>
                      <div class="sub-title-ctn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12"
                             viewBox="0 0 13 12" fill="none">
                          <path
                            d="M7 2.33333C7 2.05719 6.77614 1.83333 6.5 1.83333C6.22386 1.83333 6 2.05719 6 2.33333H7ZM6.5 6.45833H6C6 6.73448 6.22386 6.95833 6.5 6.95833V6.45833ZM9.25 6.95833C9.52614 6.95833 9.75 6.73448 9.75 6.45833C9.75 6.18219 9.52614 5.95833 9.25 5.95833V6.95833ZM6.5 0C3.1874 0 0.5 2.6874 0.5 6H1.5C1.5 3.23968 3.73968 1 6.5 1V0ZM0.5 6C0.5 9.3126 3.1874 12 6.5 12V11C3.73968 11 1.5 8.76032 1.5 6H0.5ZM6.5 12C9.8126 12 12.5 9.3126 12.5 6H11.5C11.5 8.76032 9.26032 11 6.5 11V12ZM12.5 6C12.5 2.6874 9.8126 0 6.5 0V1C9.26032 1 11.5 3.23968 11.5 6H12.5ZM6 2.33333V6.45833H7V2.33333H6ZM6.5 6.95833H9.25V5.95833H6.5V6.95833Z"
                            fill="black"/>
                        </svg>
                        <span class="sub-title">{{ 'KYC.light.15min' | translate}}</span>
                      </div>
                    </div>
                    <div class="flex align-items-center gap-2 pb-7rem"
                         *ngIf="user.complianceStatus === EPassfortProductState.APPROVED">
                      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17"
                           viewBox="0 0 17 17" fill="none">
                        <path
                          d="M8.875 0.5C4.46385 0.5 0.875 4.08885 0.875 8.5C0.875 12.9112 4.46385 16.5 8.875 16.5C13.2862 16.5 16.875 12.9112 16.875 8.5C16.875 4.08885 13.2862 0.5 8.875 0.5ZM13.0385 5.81885L7.86923 11.9727C7.81253 12.0402 7.74197 12.0948 7.66234 12.1327C7.58271 12.1706 7.49587 12.1909 7.40769 12.1923H7.39731C7.31105 12.1923 7.22577 12.1741 7.14699 12.139C7.0682 12.1039 6.99768 12.0526 6.94 11.9885L4.72462 9.52692C4.66835 9.46725 4.62459 9.39693 4.59589 9.3201C4.56719 9.24327 4.55414 9.16148 4.5575 9.07953C4.56087 8.99758 4.58058 8.91714 4.61547 8.84292C4.65037 8.76869 4.69976 8.7022 4.76072 8.64734C4.82168 8.59247 4.893 8.55035 4.97048 8.52344C5.04795 8.49654 5.13002 8.48539 5.21187 8.49065C5.29372 8.49592 5.37369 8.51749 5.44708 8.5541C5.52047 8.59071 5.5858 8.64163 5.63923 8.70385L7.38115 10.6392L12.0962 5.02731C12.2019 4.90503 12.3516 4.82928 12.5127 4.81644C12.6739 4.8036 12.8336 4.85469 12.9574 4.95868C13.0812 5.06266 13.1591 5.21119 13.1743 5.37215C13.1894 5.5331 13.1406 5.69357 13.0385 5.81885Z"
                          fill="#34A853"/>
                      </svg>
                      <span class="valid">{{ 'Common.valid' | translate}}</span>
                    </div>
                    <div class="flex align-items-center gap-2 pb-7rem"
                         *ngIf="user.complianceStatus === EPassfortProductState.IN_REVIEW">
                      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17"
                           viewBox="0 0 17 17" fill="none">
                        <path
                          d="M8.125 0.5C3.71385 0.5 0.125 4.08885 0.125 8.5C0.125 12.9112 3.71385 16.5 8.125 16.5C12.5362 16.5 16.125 12.9112 16.125 8.5C16.125 4.08885 12.5362 0.5 8.125 0.5Z"
                          fill="#E09B3D"/>
                        <path
                          d="M9.58666 11.7783C9.9433 12.0862 10.4352 12.0613 10.7638 11.7783C11.0787 11.4609 11.0787 10.9688 10.7638 10.6513L8.65804 8.6352V5.79372C8.65804 5.34918 8.29333 5 7.82902 5C7.36471 5 7 5.34918 7 5.79372V8.96861C7 9.17498 7.08296 9.38135 7.24876 9.52422L9.58666 11.7783Z"
                          fill="white"/>
                      </svg>
                      <span class="review">{{ 'Common.runningValidate' | translate}}</span>
                    </div>
                    <div class="flex align-items-center gap-2 pb-7rem"
                         *ngIf="user.complianceStatus === EPassfortProductState.MAJ">
                      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17"
                           viewBox="0 0 17 17" fill="none">
                        <path
                          d="M8.125 0.5C3.71385 0.5 0.125 4.08885 0.125 8.5C0.125 12.9112 3.71385 16.5 8.125 16.5C12.5362 16.5 16.125 12.9112 16.125 8.5C16.125 4.08885 12.5362 0.5 8.125 0.5Z"
                          fill="#E09B3D"/>
                        <path
                          d="M9.58666 11.7783C9.9433 12.0862 10.4352 12.0613 10.7638 11.7783C11.0787 11.4609 11.0787 10.9688 10.7638 10.6513L8.65804 8.6352V5.79372C8.65804 5.34918 8.29333 5 7.82902 5C7.36471 5 7 5.34918 7 5.79372V8.96861C7 9.17498 7.08296 9.38135 7.24876 9.52422L9.58666 11.7783Z"
                          fill="white"/>
                      </svg>
                      <span class="review">{{ 'Common.waitingEdd' | translate}}</span>
                    </div>

                    <div class="flex align-items-center gap-2 pb-7rem invalid-ctn"
                         *ngIf="user.complianceStatus === EPassfortProductState.REJECTED || (isBannedCountry$ | async) || (isSpecialCountry$ | async)">

                      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17"
                           viewBox="0 0 17 17" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M8.875 0.5C4.46385 0.5 0.875 4.08885 0.875 8.5C0.875 12.9112 4.46385 16.5 8.875 16.5C13.2862 16.5 16.875 12.9112 16.875 8.5C16.875 4.08885 13.2862 0.5 8.875 0.5ZM11.9654 5.46971C12.2583 5.7626 12.2583 6.23748 11.9654 6.53037L10.0209 8.47489L11.9654 10.4194C12.2583 10.7123 12.2583 11.1872 11.9654 11.4801C11.6725 11.773 11.1977 11.773 10.9048 11.4801L8.96025 9.53555L7.01568 11.4801C6.72279 11.773 6.24791 11.773 5.95502 11.4801C5.66213 11.1872 5.66213 10.7124 5.95502 10.4195L7.89959 8.47489L5.95502 6.53033C5.66213 6.23744 5.66213 5.76256 5.95502 5.46967C6.24791 5.17678 6.72279 5.17678 7.01568 5.46967L8.96025 7.41423L10.9048 5.46971C11.1977 5.17682 11.6725 5.17682 11.9654 5.46971Z"
                              fill="#FD3A84"/>
                      </svg>
                      <span class="invalid">{{ 'Common.invalid' | translate}}</span>
                    </div>
                    <div class="flex align-items-center gap-2 mt-2 pb-7rem invalid-ctn"
                         *ngIf="(user.kycLightStatus === EPassfortProductState.IN_REVIEW || user.kycLightStatus === EPassfortProductState.MAJ) && user.complianceStatus === EPassfortProductState.NONE">

                      <span class="invalid text-center small-txt">{{ 'KYC.tableau.finishLight' | translate}}</span>
                    </div>
                    <ng-container
                      *ngIf="(user.complianceStatus !== EPassfortProductState.APPROVED && user.complianceStatus !== EPassfortProductState.IN_REVIEW && user.complianceStatus !== EPassfortProductState.MAJ && user.complianceStatus !== EPassfortProductState.REJECTED && (user.kycLightStatus !== EPassfortProductState.IN_REVIEW && user.kycLightStatus !== EPassfortProductState.MAJ)) && kycAvailable?.kycComplete">
                      <app-ico-btn (click)="goToKycFull(kycAvailable?.kycComplete)"
                                   [disabled]="!kycAvailable?.kycComplete" class=""
                                   [text]="'KYC.tableau.actions.btnFull' | translate"></app-ico-btn>
                    </ng-container>
                  </div>
                  <div class="row"
                       [class.active]="user.complianceStatus === EPassfortProductState.APPROVED"
                       [class.review]="user.complianceStatus === EPassfortProductState.IN_REVIEW || user.complianceStatus === EPassfortProductState.MAJ">
                    <app-check></app-check>
                  </div>
                  <div class="row no-border"
                       [class.active]="user.complianceStatus === EPassfortProductState.APPROVED"
                       [class.review]="user.complianceStatus === EPassfortProductState.IN_REVIEW || user.complianceStatus === EPassfortProductState.MAJ">
                  </div>
                  <div class="row"
                       [class.active]="user.complianceStatus === EPassfortProductState.APPROVED"
                       [class.review]="user.complianceStatus === EPassfortProductState.IN_REVIEW || user.complianceStatus === EPassfortProductState.MAJ">
                    <app-check *ngIf="!(isSpecialCountry$ | async)"></app-check>
                  </div>
                  <div class="row"
                       [class.active]="user.complianceStatus === EPassfortProductState.APPROVED"
                       [class.review]="user.complianceStatus === EPassfortProductState.IN_REVIEW || user.complianceStatus === EPassfortProductState.MAJ">
                    <app-check *ngIf="!(isSpecialCountry$ | async)"></app-check>
                  </div>
                  <div class="row"
                       [class.active]="user.complianceStatus === EPassfortProductState.APPROVED"
                       [class.review]="user.complianceStatus === EPassfortProductState.IN_REVIEW || user.complianceStatus === EPassfortProductState.MAJ">
                    <app-check></app-check>
                  </div>
                  <div class="row no-border"
                       [class.active]="user.complianceStatus === EPassfortProductState.APPROVED"
                       [class.review]="user.complianceStatus === EPassfortProductState.IN_REVIEW || user.complianceStatus === EPassfortProductState.MAJ">
                  </div>
                  <div class="row"
                       [class.active]="user.complianceStatus === EPassfortProductState.APPROVED"
                       [class.review]="user.complianceStatus === EPassfortProductState.IN_REVIEW || user.complianceStatus === EPassfortProductState.MAJ">
                    <app-check *ngIf="!(isSpecialCountry$ | async)"></app-check>
                  </div>
                  <div class="row"
                       [class.active]="user.complianceStatus === EPassfortProductState.APPROVED"
                       [class.review]="user.complianceStatus === EPassfortProductState.IN_REVIEW || user.complianceStatus === EPassfortProductState.MAJ">
                    <app-check *ngIf="!(isSpecialCountry$ | async)"></app-check>
                  </div>
                  <div class="row"
                       [class.active]="user.complianceStatus === EPassfortProductState.APPROVED"
                       [class.review]="user.complianceStatus === EPassfortProductState.IN_REVIEW || user.complianceStatus === EPassfortProductState.MAJ">
                    <app-check></app-check>
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="mt-2">
            <span class="subline">
            {{ 'KYC.light.subline1' | translate}}
          </span>
              <span class="subline">
            {{ 'KYC.light.subline2' | translate}}
          </span>
            </div>
          </ng-container> -->
        </ng-container>
      </ng-container>
    </div>
  </p-card>
</div>