import {Component, OnDestroy, OnInit} from '@angular/core';
import {DialogService, DynamicDialogConfig, DynamicDialogRef,} from 'primeng/dynamicdialog';
import {EToastSeverity, ICryptoNetwork, ITwoFaConfig,} from '@models/dist';
import {CurrencyService, ReactiveLoaderService, ToastService, TwoFaService, UserService,} from '@shared/services';
import {TranslateService} from '@ngx-translate/core';
import {MiscHelpers} from '@app/shared/helpers/misc.helpers';
import {Store} from '@ngxs/store';
import {debounceTime, delay, distinctUntilChanged, filter, of, Subject, takeUntil, tap} from "rxjs";
import {reactiveButtonState} from "@shared/services/reactive-loader.service";

@Component({
  selector: 'app-edit-wallet-modal',
  templateUrl: './edit-wallet.component.html',
  styleUrls: ['./edit-wallet.component.scss'],
})
export class EditWalletComponent implements OnInit, OnDestroy {
  allCryptos: ICryptoNetwork[] = [];
  loading = false;
  selectedCrypto: ICryptoNetwork | null = null;
  id?: string = '';
  name: string = '';
  address: string = '';
  step: number = 0;

  originalName: string = '';
  originalAddress: string = '';

  twoFaConfig: ITwoFaConfig | null = null;
  hotp = '';
  totp = '';
  codeLength = 6;

  timeLeft = '';
  remainTime = 60000;
  countdownInterval: any;

  statusAddress: 'checking' | 'validated' | 'disabled' | null = null;


  destroy$ = new Subject<boolean>()
  isSending = false;

  private validationTimer: any;

  addressValidationRules = {
    'BTC': new RegExp(
      '^((1|3)[0-9a-z]{32}|bc1[0-9a-z]{30})([0-9a-z]|[0-9a-z]{9}([0-9a-z]{20})?)?$',
      'i'
    ),
    'USDT': new RegExp('^0x[0-9a-f]{40}$', 'i'),
    'USDC': new RegExp('^0x[0-9a-f]{40}$', 'i'),
    'BUSD': new RegExp('^0x[0-9a-f]{40}$', 'i'),
    'MATIC': new RegExp('^0x[0-9a-f]{40}$', 'i'),
    'DAI': new RegExp('^0x[0-9a-f]{40}$', 'i'),
    'LTC': new RegExp('^0x[0-9a-f]{40}$', 'i'),
    'ETH': new RegExp('^0x[0-9a-f]{40}$', 'i'),
  };

  public status: string = '';
  public priceInterval: any = null;

  nameError: string | null = null;
  addressError: string | null = null;

  constructor(
    private currencyService: CurrencyService,
    private userService: UserService,
    private translate: TranslateService,
    public dialogService: DialogService,
    public reactiveLoader: ReactiveLoaderService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private twoFaService: TwoFaService,
    private toastService: ToastService,
    private store: Store
  ) {
  }

  async ngOnInit() {
    try {

      if (this.config?.data?.id) {
        this.id = this.config.data.id;
        this.name = this.config.data.name;
        this.address = this.config.data.address;
        this.originalName = this.config.data.name;
        this.originalAddress = this.config.data.address;
      }
      this.selectedCrypto = this.config.data.currency;

      this.allCryptos = (
        (await this.currencyService.getCurrencies(this.config.data.service)) as ICryptoNetwork[]
      ).sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0));
    } catch (e) {
      console.log(e);
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.priceInterval);
    this.destroy$.next(true);
    this.destroy$.complete()
  }

  validateInputs() {
    this.debounce(() => {
      console.log("this.address =>", this.address)
      if (!this.name) {
        this.nameError = this.translate.instant(
          'wallets.edit-modal.errors.noName'
        );
      } else if (this.name.length > 20) {
        this.nameError = this.translate.instant(
          'wallets.edit-modal.errors.longName'
        );
      } else {
        this.nameError = null;
      }

      console.log(!this.id, this.selectedCrypto,
        !this.addressValidationRules[
          this.selectedCrypto?.label as keyof typeof this.addressValidationRules
          ].test(this.address));

      if (
        !this.id && this.selectedCrypto &&
        !this.addressValidationRules[
          this.selectedCrypto.network as keyof typeof this.addressValidationRules
          ].test(this.address)
      ) {
        this.addressError = this.translate.instant(
          `wallets.edit-modal.errors.${this.selectedCrypto.network}`
        );
      } else {
        this.addressError = null;
      }
    }, 100)();
  }

  resendCode() {
    this.isSending = true;
    this.setCountDown();

    this.sendWalletEmail();
  }

  getCurrencyName(currency: any) {
    if (currency.network === 'Bitcoin (Segwit)') {
      return `${currency.slug} - Bitcoin`
    } else {
      return `${currency.slug} - ${currency.network}`
    }
  }

  async sendWalletEmail() {
    try {
      if (this.id) {
        await this.twoFaService.sendChangeWalletEmail();
      } else {
        await this.twoFaService.sendAddWalletEmail();
      }
      this.toastService.addToast(
        EToastSeverity.success,
        '2FA.toast.successToastMailTitle',
        '2FA.toast.successToastMailMsg'
      );
    } catch (error) {
      this.toastService.addToast(
        EToastSeverity.error,
        '2FA.toast.errorToastMailTitle',
        '2FA.toast.errorToastMailMsg'
      );
    }
  }

  private async verify(totpToken: string, hotpToken: string) {
    try {
      await this.twoFaService.doubleAuthentication(totpToken, hotpToken);
      this.toastService.addToast(
        EToastSeverity.success,
        this.id ? '2FA.toast.updateWalletTitle' : '2FA.toast.addWalletTitle',
        this.id ? '2FA.toast.successUpdateWalletMsg' : '2FA.toast.successAddWalletMsg'
      );
      await this.save();
    } catch (error) {
      this.cleanInput();
      this.toastService.addToast(
        EToastSeverity.error,
        this.id ? '2FA.toast.updateWalletTitle' : '2FA.toast.addWalletTitle',
        this.id ? '2FA.toast.errorUpdateWalletMsg' : '2FA.toast.errorAddWalletMsg'
      );
    }
  }

  async save() {
    const wallet = {
      name: this.name,
      address: this.address,
      currency: this.selectedCrypto,
    };

    if (this.id) {
      const response = await this.userService.updateWallet(wallet, this.id);
      console.log(response);
      this.ref.close({
        status: 'done',
        wallet: response
      });
    } else {
      const response = await this.userService.addWallet(wallet) as { id: string };
      this.ref.close({
        status: 'done',
        wallet: response
      });
    }
  }

  next() {
    this.verifyAddress();
  }

  submit() {
    this.verify(this.totp, this.hotp);
  }

  verifyAddress() {
    console.log(this.address, this.selectedCrypto?.slug);
    this.statusAddress = 'checking';
    this.reactiveLoader.setReactiveButtonState('next', reactiveButtonState.loading);
    this.userService.verifyAddress(this.address, this.selectedCrypto!.slug!)
      .pipe(
        tap((data) => {
          if (data) {
            this.statusAddress = 'validated';
            return true;
          } else {
            this.statusAddress = 'disabled';
            this.reactiveLoader.setReactiveButtonState('next', reactiveButtonState.error);
            return false;
          }
        }),
        delay(1500),
        tap((data) => {
          if(!data){
            this.statusAddress = null;
            this.reactiveLoader.setReactiveButtonState('next', reactiveButtonState.normal);
          }
        }),
        filter((data) => data),
        tap(() => {
          this.reactiveLoader.setReactiveButtonState('next', reactiveButtonState.success);
          this.step = 1;
          this.sendWalletEmail();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  isDisabled(){
    if(this.step === 0){
      return Boolean(!this.name || !this.address || this.nameError || this.addressError || (this.statusAddress !== null));
    }
    return true;
  }

  back() {
    if (this.step === 1) {
      this.step = 0;
    } else {
      this.ref.close({
        status: 'back',
      });
    }
  }

  close() {
    this.ref.close({
      status: 'back',
    });
  }

  /////////////////////////////twofa utils////////////////////////////////////////////////////

  onHotpChanged(event: any) {
    this.hotp = event;
  }

  onTotpChanged(event: any) {
    this.totp = event;
  }

  onCodeComplete() {
    if (
      this.totp?.length === this.codeLength &&
      this.hotp?.length === this.codeLength
    ) {
      this.submit();
    }
  }

  private setCountDown(): boolean {
    if (this.timeLeft) {
      return true;
    }
    let timestampDiff = MiscHelpers.clone(this.remainTime);
    const oneHourMs = 60 * 60 * 1000;
    this.countdownInterval = setInterval(() => {
      let minutes = Math.floor(
        (timestampDiff % oneHourMs) / (1000 * 60)
      ).toString();
      let seconds = Math.floor((timestampDiff % (1000 * 60)) / 1000).toString();

      minutes = minutes.length === 1 ? '0' + minutes : minutes;
      seconds = seconds.length === 1 ? '0' + seconds : seconds;

      this.timeLeft = `${minutes}:${seconds}`;
      // this.cdr.detectChanges();
      timestampDiff = timestampDiff - 1000;
      // If the count down is over, recheck the period
      if (timestampDiff <= 0) {
        this.timeLeft = '';
        this.isSending = false;
        clearInterval(this.countdownInterval);
      }
    }, 1000);

    return false;
  }

  private cleanInput() {
    this.hotp = '';
    this.totp = '';
  }

  private debounce(func: Function, wait: number) {
    return (...args: any[]) => {
      const context = this;
      clearTimeout(this.validationTimer);
      this.validationTimer = setTimeout(() => func.apply(context, args), wait);
    };
  }

  validateAddress(event: any) {
    const value = event.target.value;
    of(value).pipe(
      debounceTime(400),
      distinctUntilChanged(),
      takeUntil(this.destroy$),
      tap((data) => {
        console.log(data);
        const test = this.addressValidationRules[this.selectedCrypto?.slug!].test(data)
        console.log(test);
        if (!test) {
          this.addressError = this.translate.instant(
            `wallets.edit-modal.errors.${this.selectedCrypto?.slug}`
          );
        } else {
          this.addressError = null;
        }
      })
    ).subscribe()
  }
}
