import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {Select, Store} from "@ngxs/store";
import {filter, Observable, Subject, takeUntil} from "rxjs";
import {AuthState} from '@shared/stores/auth/auth.state';
import {CurrencyService, ToastService, TokenService, UserService} from "@shared/services";
import {IcoState} from "@ico/stores/ico/ico.state";
import {
  EAccountType,
  EPassfortProductState,
  EPortfolio,
  EProducts,
  ERoutes,
  ETransactionCurrency,
  ETransactionDirection,
  ETransactionStatus,
  IProfile,
  IRound,
  IStep,
  ITransaction,
  IUser,
  RiskCountry,
  SpecialCountry
} from "@models/dist";
import {environment} from "@environments/environment";
import {TranslateService} from '@ngx-translate/core';
import {ProfileState} from "@profile/stores/profile/profile-state.service";
import {WebSocketService} from "@shared/services/webSocket.service";
import {GetProfile} from "@profile/stores/profile/profile.actions";
import {setLastRefresh} from '@app/shared/stores/utils/utils.actions';
import {GetUserTransaction, TransactionState} from '@app/shared/stores/transaction/transaction.state';
import { isICOEnabled } from '@app/shared/helpers/ico.utils';

@Component({
  selector: 'app-ico-user-panel',
  templateUrl: './user-panel.component.html',
  styleUrls: ['./user-panel.component.scss']
})
export class UserPanelComponent implements OnInit, OnDestroy {
  @Select(TransactionState.getTransactions) transactions$: Observable<ITransaction[]> | undefined;
  @Select(ProfileState.getProfile) profile$: Observable<IProfile> | undefined;
  @Select(ProfileState.getReferral) referral$: Observable<{
    step: IStep,
    targetStep: IStep,
    referrals: { pending: number, validate: number }[]
  }> | undefined;
  @Select(AuthState.getUser) user$: Observable<any> | undefined;
  @Select(IcoState.getCurrentRound) activeRound$: Observable<IRound> | undefined;
  copy: string = 'Common.copy';
  EPassfortProductState = EPassfortProductState;

  isInvestPage = false;
  amountInvestedEuro: number = 0;
  averagePrice: number = 0;
  credits: number = 0;
  summitToken: number = 0;
  summitTokenThisRound: number = 0;

  user: IUser | undefined;
  public kycInfo: { text: string, textcolor: string, icon: string } = {text: '', textcolor: '', icon: ''};
  private subscription = new Subject<void>();

  constructor(private toastService: ToastService,
              private router: Router,
              private store: Store,
              private websocketService: WebSocketService,
              private userService: UserService,
              private tokenService: TokenService,
              private translate: TranslateService,
              private currencyService: CurrencyService
  ) {

  }

  ngOnInit(): void {
    this.getCurrentUser();
    this.isInvestPage = this.router.url.includes('ico/invest');

    this.translate.onLangChange.subscribe((lang) => {
      const isKyb = this.user?.accountType === EAccountType.COMPANY;
      this.setStatus(this.user!, isKyb);
    })

    this.profile$?.pipe(filter((profil) => !!profil), takeUntil(this.subscription)).subscribe((profile) => {

      this.credits = profile.user.portfolios.find(portoflio => portoflio.asset === EPortfolio.CREDITS)!.amount
      this.summitToken = profile.amount
      this.summitTokenThisRound = profile.round
      this.getTransactionsInfos();
      const isKyb = profile.user.accountType === EAccountType.COMPANY;

      this.setStatus(profile.user, isKyb);

    })
  }

  setStatus(user: IUser, isKyb: boolean) {
    const riskCountry = RiskCountry.find(({code}) => code === user.country);
    const isSpecial = SpecialCountry.find(({code}) => code === user.country);
    let status: EPassfortProductState;
    if (user?.accountType === EAccountType.COMPANY) {
      status = user?.complianceStatus!
    } else if (riskCountry || isSpecial) {
      status = user?.complianceStatus!
    } else {
      status = user?.complianceStatus === EPassfortProductState.APPROVED ? user?.complianceStatus! : user?.kycLightStatus!;
    }

    // const isKyb = user.accountType === EAccountType.COMPANY;

    this.getKYCStatus(status, isKyb);
  }

  ngOnDestroy() {
    this.subscription.next();
    this.subscription.complete();
  }

  getKYCStatus(status: EPassfortProductState, isKyb: boolean): any {
    // console.log(status === EPassfortProductState.NONE && NoneAccepted);
    const prefix = this.translate.instant("KYC.page.title", {title: this.user?.accountType === EAccountType.COMPANY ? 'KYB' : 'KYC'});
    // if (status === EPassfortProductState.NONE && !NoneAccepted) {
    //   this.kycInfo = {
    //     text: '',
    //     icon: '',
    //     textcolor: ''
    //   };
    // }

    if (status === EPassfortProductState.NONE) {
      this.kycInfo = {
        text: `${this.translate.instant(`KYC.status${isKyb ? '.kyb' : ''}.none`)}`,
        textcolor: '#ff4444',
        icon: './assets/icon/unvalid.svg'
      }
    }

    if (status === EPassfortProductState.APPLIED) {
      this.kycInfo = {
        text: `${this.translate.instant(`KYC.status${isKyb ? '.kyb' : ''}.none`)}`,
        textcolor: '#ff4444',
        icon: './assets/icon/unvalid.svg'
      }
    }

    if (status === EPassfortProductState.MAJ) {
      this.kycInfo = {
        text: `${this.translate.instant(`KYC.status${isKyb ? '.kyb' : ''}.maj`)}`,
        textcolor: '#ff4444',
        icon: './assets/icon/unvalid.svg'
      }
    }

    if (status === EPassfortProductState.IN_REVIEW) {
      this.kycInfo = {
        text: `${this.translate.instant(`KYC.status${isKyb ? '.kyb' : ''}.review`)}`,
        textcolor: '#ff7c4c',
        icon: './assets/icon/status.svg'
      }
    }

    if (status === EPassfortProductState.NEEDED) {
      this.kycInfo = {
        text: `${this.translate.instant(`KYC.status${isKyb ? '.kyb' : ''}.needed`)}`,
        textcolor: '#ff4444',
        icon: './assets/icon/unvalid.svg'
      }
    }

    if (status === EPassfortProductState.REJECTED) {
      this.kycInfo = {
        text: `${this.translate.instant(`KYC.status${isKyb ? '.kyb' : ''}.rejected`)}`,
        textcolor: '#ff4444',
        icon: './assets/icon/unvalid.svg'
      }
    }

    if (status === EPassfortProductState.APPROVED) {
      this.kycInfo = {
        text: `${this.translate.instant(`KYC.status${isKyb ? '.kyb' : ''}.approved`)}`,
        textcolor: '#34a853',
        icon: './assets/icon/valid.svg'
      }
    }
    return;
    //     {title: this.user?.accountType === EAccountType.COMPANY ? 'KYB' : 'KYC'});
    //
    //   // when a user complete the form, the status is completed, so
    //   // it's mean he have to wait for the admin to validate his kyc
    //   if (this.user?.passfortFormRequest?.data?.status === EPassfortFormState.COMPLETED
    //     && this.user?.kycLightStatus !== EPassfortProductState.APPROVED
    //     && this.user?.kycLightStatus !== EPassfortProductState.CANCELLED
    //     && this.user?.kycLightStatus !== EPassfortProductState.REJECTED) {
    //
    //     this.kycInfo = {
    //       text: `${this.translate.instant("KYC.status.pending")}`,
    //       textcolor: '#ff7c4c',
    //       icon: './assets/icon/status.svg'
    //     }
    //   }
    //
    //   if (this.user?.passfortFormRequest?.data?.status === EPassfortFormState.EXPIRED
    //     && !this.user?.passfortFormRequest?.data?.url
    //     && this.user?.kycLightStatus !== EPassfortProductState.APPROVED
    //     && this.user?.kycLightStatus !== EPassfortProductState.CANCELLED
    //     && this.user?.kycLightStatus !== EPassfortProductState.REJECTED) {
    //
    //     this.kycInfo = {
    //       text: `${prefix} - ${this.translate.instant("KYC.status.expired")}`,
    //       textcolor: '#ff4444',
    //       icon: './assets/icon/unvalid.svg'
    //     }
    //   }
    //
    //   switch (status) {
    //     case EPassfortProductState.NONE:
    //       this.kycInfo = {
    //         text: `${prefix} - ${this.translate.instant("KYC.status.none")}`,
    //         textcolor: '#ff4444',
    //         icon: './assets/icon/unvalid.svg'
    //       }
    //       break;
    //     case EPassfortProductState.APPLIED:
    //       this.kycInfo = {
    //         text: `${prefix} - ${this.translate.instant("KYC.status.toBeCompleted")}`,
    //         textcolor: '#ff7c4c',
    //         icon: './assets/icon/status.svg'
    //       }
    //       break;
    //     case EPassfortProductState.APPROVED:
    //       this.kycInfo = {
    //         text: `${prefix} - ${this.translate.instant("KYC.status.accepted")}`,
    //         textcolor: '#34a853',
    //         icon: './assets/icon/valid.svg'
    //       }
    //       break;
    //     case EPassfortProductState.IN_REVIEW:
    //       this.kycInfo = {
    //         text: `${prefix} - ${this.translate.instant("KYC.status.pending")}`,
    //         textcolor: '#ff7c4c',
    //         icon: './assets/icon/status.svg'
    //       }
    //       break;
    //     case EPassfortProductState.REJECTED:
    //       this.kycInfo = {
    //         text: `${prefix} - ${this.translate.instant("KYC.status.rejected")}`,
    //         textcolor: '#ff4444',
    //         icon: './assets/icon/unvalid.svg'
    //       }
    //       break;
    //     case EPassfortProductState.CANCELLED:
    //       this.kycInfo = {
    //         text: `${prefix} - ${this.translate.instant("KYC.status.cancelled")}`,
    //         textcolor: '#ff4444',
    //         icon: './assets/icon/unvalid.svg'
    //       }
    //       break;
    //
    //   }
    //
    //   return 'Undefined';
  }

  toNumber(data: any) {
    return Number(data);
  }

  getLink(code: string) {
    return environment.appUrl + '/' + ERoutes.AUTH + '/' + ERoutes.REGISTER + '?' + `ref=${code}`;
  }

  floor(number: number) {
    return Math.floor(number);
  }

  private getTransactionsInfos() {
    this.store.dispatch(new GetUserTransaction());
    this.transactions$?.pipe(takeUntil(this.subscription)).subscribe((transactions) => {
      if (transactions) {
        this.calculateData(transactions);
      }
    })
  }

  private async calculateData(tr) {
    let exchangeRate = 0.90;

    const transactions = tr.filter(transaction => {
      return (transaction.status === ETransactionStatus.COMPLETED &&
        transaction.direction === ETransactionDirection.INCOMING &&
        transaction.category === EProducts.SUMMIT_TOKEN)
    })

    const data = await this.currencyService.getEuroDollarRate() as any;
    if (data.exchangeRate) {
      exchangeRate = data.exchangeRate;
    }

    let totalUSD = 0;

    transactions.forEach(transaction => {
      if (transaction.fromCurrency === ETransactionCurrency.US_DOLLAR) {
        totalUSD += Number(transaction.fromAmount)
      } else if (transaction.fromCurrency === ETransactionCurrency.EURO) {
        totalUSD += Number(transaction.fromAmount) * exchangeRate
      } else {
        totalUSD += Number(transaction.amountUSD)
      }
    })

    if (totalUSD !== 0 && this.summitToken !== 0) {
      this.amountInvestedEuro = totalUSD * exchangeRate;
      this.averagePrice = totalUSD / this.summitToken;
    }
  }

  private initWebSocket(userId: string) {
    if (!userId) {
      return;
    }

    this.websocketService.getUserSummitToken(userId).pipe(takeUntil(this.subscription)).subscribe((summitTokenAmount) => {
      this.summitToken = summitTokenAmount as number;
      this.store.dispatch(new setLastRefresh());
    })

    this.websocketService.getUserSummitTokenPerRound(userId).pipe(takeUntil(this.subscription)).subscribe((summitTokenAmount) => {
      this.summitTokenThisRound = this.summitTokenThisRound + (summitTokenAmount as number);
      this.store.dispatch(new setLastRefresh());
    })
    this.websocketService.getUserCredits(this.user?.id || '').pipe(takeUntil(this.subscription)).subscribe((creditsAmount) => {
      this.store.dispatch(new GetProfile());
      this.store.dispatch(new setLastRefresh());
    })

  }

  private getCurrentUser() {
    this.user$?.pipe(takeUntil(this.subscription)).subscribe(user => {
      if (!this.user && user?.id) {
        this.initWebSocket(user.id);
      }
      this.user = user;
    })
  }

  icoEnabled(){
    return isICOEnabled();
  }
}
