import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext,} from '@ngxs/store';
import {TransactionStateModel} from './transaction.actions';
import {TransactionService} from '@shared/services/transaction.service';
import {ITransaction} from "@models/dist";

export class GetUserTransaction {
  static readonly type = '[Transaction] Get Transactions';
}

export class CreateUserTransaction {
  static readonly type = '[Transaction] Create Transaction';

  constructor(public payload: { transaction: ITransaction }) {
  }
}

@State<TransactionStateModel>({
  name: 'TransactionState',
  defaults: {
    transactions: null,
  },
})
@Injectable()
export class TransactionState {

  constructor(
    private transactionService: TransactionService,
  ) {
  }

  @Selector()
  static getTransactions(state: TransactionStateModel) {
    return state.transactions;
  }

  @Action(GetUserTransaction)
  async getUserTransaction(ctx: StateContext<TransactionStateModel>) {
    try {
      const res = await this.transactionService.getTransactions() as { transactions: ITransaction[] };

      ctx.patchState({
        transactions: (res.transactions as ITransaction[])!.map(transaction => {
          return {
            ...transaction,
            created: new Date(transaction.created_at),
            open: false,
            updated: new Date(transaction.updated_at),
          };
        }),
      });
    } catch (error) {
      console.log(error);
    }
  }

}
