import {Action, NgxsOnInit, State, StateContext} from "@ngxs/store";
import {
  AllCountries, COUNTRY_ISO,
  EAssetsIdsToFireblocks,
  IcoStateModel,
  ICryptoNetwork,
  ITransaction,
  IUser,
  IWithdrawWallet,
} from "@models/dist";
import {inject, Injectable} from "@angular/core";
import {IcoService} from "@ico/services/ico.service";
import {
  DefaultMiningError, GetAmountInvested,
  GetBtcHistory, GetInvestedData, GetMiningCryptocurrency,
  GetMiningCurrency,
  GetMiningFile,
  GetMiningGainsHistory,
  GetMiningParc,
  GetMiningTransactions,
  GetMiningUser, GetMiningWallet, GetMonthlyPayment,
  GetOverview, GetPerformance, SetBtcChartFilter,
  SuccessRetrieveBtcHistory,
  SuccessRetrieveMiningGainsHistory,
  SuccessRetrieveMiningParc,
  SuccessRetrieveMiningUser,
  SuccessRetrieveOverview
} from "@mining/store/mining.actions";
import {MiningGateway} from "@mining/ports/mining.gateway";
import {catchError, EMPTY, forkJoin, map, tap} from "rxjs";
import {CustomNumberPipe, NumberPipe} from "@shared/pipes";
import {EMiningChartFilter} from "@mining/store/gains/gains.state";

export interface IMiningUser {
  id: string;
  user: IUser;
  smk_active: number;
  smk_pending: number;
  smk_suspended: number;
}


export interface IOverview {
  invest_amount: number;
  smk_active: number;
  smk_pending: number;
  smk_average: number;
  fees: number;
  smk: number;
  smk_variation: number;
  smk_yield: number;
}

export interface IMiningParc {
  miners: {
    on: number,
    off: number
  },
  power: {
    on: number,
    off: number
  },
  compositions: IMiningParcComposition[],
  machines: IMiningParcMachine[],
  country: { name: { fr: string, en: string }, code: string }[]
}

export interface IMiningParcComposition {
  name: string;
  percentage: number;
  color: string;
}

export interface IMiningParcMachine {
  name: string;
  count: number;
  color: string;
  slug: string;
}

export interface IMiningError {
  message: string;
  title: string;
}

export interface IMinedAsset {
  name: string;
  slug: string;
  color: string;
  symbol: string;
  logo: string;
  decimal: number;
}

export enum MiningFileType {
  CERTIFICATE = 'MPT_CERTIFICATE',
  REPORT = 'MINING_REPORT',
  CONFIRMATION = 'CONFIRMATION',
}

export interface IMiningFile {
  id: string;
  name: string;
  created_at: string;
  type: MiningFileType;
  file: {
    file: string;
  }
}

export interface IMiningChartGainsHistory {
  total_net_mined_euro: number;
  date: Date;
}

export interface IMiningGainsHistory {
  date: Date;
  asset: IMinedAsset;
  mined_crypto: number;
  crypto_price_euro_now: number
  crypto_price_euro: number;
  mined_euro: number;
  percentage: number;
  value_today: number;
}

export interface IMiningWallet {
  amount: number;
  pending_amount: number;
  price: number;
  variation: number;
  currency: ICryptoNetwork;
}

export interface IBtcHistory {
  prices: number[];
  dates: string[];
}

export interface IInvestedData {
  totalAmountInvested: string;
  totalAmountMinded: string;
  totalAmountWithdrawn: string;
}

export interface IMonthlyPaiement {
  miningReinvestPercentage: number;
  percentage: number;
  type: "ALL" | "CRYPTO";
  wallet?: IWithdrawWallet;
}

export type EYieldType = "NET" | "GROSS";
export type IYield = {
  amount: {
    current: 0,
    planned: 0,
  },
  yield: {
    current: 0,
    planned: 0,
  },
  type: EYieldType,
}

export interface IMiningStateModel {
  user: IMiningUser | null;
  overview: IOverview | null;
  btcHistory: IBtcHistory | null;
  filter: EMiningChartFilter;
  parc: IMiningParc | null;
  gainsHistory: IMiningGainsHistory[];
  gainsHistoryChart: IMiningChartGainsHistory[] | []
  state: 'loading' | 'complete' | 'error' | null;
  error: IMiningError | null,
  transactions: ITransaction[];
  investedData: IInvestedData | null;
  files: IMiningFile[];
  currencies: ICryptoNetwork[];
  wallets: IMiningWallet[] | [];
  amountInvested: number | null;
  monthlyPaiment: IMonthlyPaiement | null;
  allCurrencies: ICryptoNetwork[];
  yield: IYield | null;
}

@State<IMiningStateModel>({
  name: 'MiningState',
  defaults: {
    user: null,
    state: null,
    overview: null,
    parc: null,
    gainsHistory: [],
    gainsHistoryChart: [],
    error: null,
    btcHistory: null,
    transactions: [],
    files: [],
    currencies: [],
    wallets: [],
    amountInvested: null,
    investedData: null,
    monthlyPaiment: null,
    filter: EMiningChartFilter.YEAR,
    allCurrencies: [],
    yield: null
  },
})

@Injectable()
export class MiningState {

  miningGateway = inject(MiningGateway)

  ///-------------- GET AND MAP DATA -----------------////

  @Action(GetMiningUser)
  async getMinignUser(
    ctx: StateContext<IMiningStateModel>,
  ) {
    console.log(`Get mining user from mining gateway`);
    return this.miningGateway.getMiningUser().pipe(
      tap((miningUser) => ctx.dispatch(new SuccessRetrieveMiningUser(miningUser))),
      catchError(
        (error) => {
          ctx.dispatch(new DefaultMiningError(error))
          return EMPTY;
        }
      )
    )
  }

  @Action(GetOverview)
  async getOverview(
    ctx: StateContext<IMiningStateModel>,
  ) {
    console.log(`Get overview from mining gateway`);
    return this.miningGateway.getOverview().pipe(
      tap((overview) => ctx.dispatch(new SuccessRetrieveOverview(overview))),
      catchError(
        (error) => {
          ctx.dispatch(new DefaultMiningError(error))
          return EMPTY;
        }
      )
    )
  }

  @Action(GetMiningParc)
  async getMiningParc(
    ctx: StateContext<IMiningStateModel>,
  ) {
    console.log(`Get mining parc from mining gateway`);
    return this.miningGateway.getMiningParc().pipe(
      tap((parc) => ctx.patchState({
        parc: {
          ...parc,
          country: parc.country.map((c) => AllCountries.find(({code}) => code === c)!)
        }
      })),
      catchError(
        (error) => {
          ctx.dispatch(new DefaultMiningError(error))
          return EMPTY;
        }
      )
    )
  }

  @Action(GetBtcHistory)
  async getBtcHistory(
    ctx: StateContext<IMiningStateModel>,
  ) {
    console.log(`Get btc history from mining gateway`);
    return this.miningGateway.getBtcHistory().pipe(
      tap((btcHistory) => {
        ctx.dispatch(new SuccessRetrieveBtcHistory(btcHistory));
      }),
      catchError(
        (error) => {
          ctx.dispatch(new DefaultMiningError(error))
          return EMPTY;
        }
      )
    )
  }

  @Action(GetMiningTransactions)
  async getMiningTransactions(
    ctx: StateContext<IMiningStateModel>,
    action: GetMiningTransactions
  ) {
    console.log(`Get mining transactions from mining gateway`);
    const year = action.year ?? new Date().getFullYear();
    return this.miningGateway.getMiningTransactions(year).pipe(
      tap((transactions) => ctx.patchState({
        transactions
      })),
      catchError(
        (error) => {
          ctx.dispatch(new DefaultMiningError(error))
          return EMPTY;
        }
      )
    )
  }

  @Action(GetMiningFile)
  async getMiningFile(
    ctx: StateContext<IMiningStateModel>,
  ) {
    console.log(`Get mining file from mining gateway`);
    return this.miningGateway.getMiningFile().pipe(
      tap((files) => ctx.patchState({
        files
      })),
      catchError(
        (error) => {
          ctx.dispatch(new DefaultMiningError(error))
          return EMPTY;
        }
      )
    )
  }

  @Action(GetMiningCurrency)
  async getMiningCurrency(
    ctx: StateContext<IMiningStateModel>,
  ) {
    console.log(`Get mining currency from mining gateway`);
    return this.miningGateway.getMiningCurrency().pipe(
      tap((currencies) => ctx.patchState({
        currencies
      })),
      catchError(
        (error) => {
          ctx.dispatch(new DefaultMiningError(error))
          return EMPTY;
        }
      )
    )
  }

  @Action(GetAmountInvested)
  async getAmountInvested(
    ctx: StateContext<IMiningStateModel>,
  ) {
    console.log(`Get amount invested from mining gateway`);
    return this.miningGateway.getMiningInvested().pipe(
      tap((amountInvested) => ctx.patchState({
        amountInvested
      })),
      catchError(
        (error) => {
          ctx.dispatch(new DefaultMiningError(error))
          return EMPTY;
        }
      )
    )
  }

  @Action(GetMiningWallet)
  async getMiningWallet(
    ctx: StateContext<IMiningStateModel>,
  ) {
    console.log(`Get mining wallet from mining gateway`);
    return this.miningGateway.getMiningWallet().pipe(
      tap(() => ctx.dispatch(new GetAmountInvested())),
      tap((wallets) => ctx.patchState({
        wallets
      })),
      catchError(
        (error) => {
          ctx.dispatch(new DefaultMiningError(error))
          return EMPTY;
        }
      )
    )
  }

  @Action(GetInvestedData)
  async GetInvestedData(
    ctx: StateContext<IMiningStateModel>,
    action: GetInvestedData
  ) {
    console.log(`Get invested data from mining gateway`);
    const year = action.year ?? new Date().getFullYear();
    return this.miningGateway.getMiningInvestedData(year).pipe(
      tap((investedData) => ctx.patchState({
        investedData
      })),
      catchError(
        (error) => {
          ctx.dispatch(new DefaultMiningError(error))
          return EMPTY;
        }
      )
    )
  }

  @Action(GetMonthlyPayment)
  async getMonthlyPayment(
    ctx: StateContext<IMiningStateModel>,
  ) {
    console.log(`Get monthly payment from mining gateway`);
    return this.miningGateway.getMonthlyPayment().pipe(
      tap((monthlyPaiment) => ctx.patchState({
        monthlyPaiment
      })),
      catchError(
        (error) => {
          ctx.dispatch(new DefaultMiningError(error))
          return EMPTY;
        }
      )
    )
  }

  @Action(GetMiningCryptocurrency)
  async getMiningCryptocurrency(
    ctx: StateContext<IMiningStateModel>,
  ) {
    console.log(`Get all mining cryptocurrency from mining gateway`);
    return this.miningGateway.getMiningCryptocurrency().pipe(
      tap((allCurrencies) => ctx.patchState({
        allCurrencies
      })),
      catchError(
        (error) => {
          ctx.dispatch(new DefaultMiningError(error))
          return EMPTY;
        }
      )
    )
  }

  @Action(SetBtcChartFilter)
  setBtcChartFilter(
    ctx: StateContext<IMiningStateModel>,
    {payload}: SetBtcChartFilter
  ) {
    ctx.patchState({
      filter: payload
    })
    ctx.dispatch(new GetBtcHistory());
  }


  @Action(GetPerformance)
  getPerformance(
    ctx: StateContext<IMiningStateModel>,
    {type}: GetPerformance
  ) {
    return this.miningGateway.getPerformance(type).pipe(
      tap(
        (data) => {
          ctx.patchState({
            yield: data
          })
        }
      )
    );
  }

  ///-------------- AFFECT DATA TO STORE -----------------////

  @Action(SuccessRetrieveBtcHistory)
  successRetrieveBtcHistory(
    ctx: StateContext<IMiningStateModel>,
    {payload}: SuccessRetrieveBtcHistory
  ) {
    ctx.patchState({
      btcHistory: payload,
    })
  }

  @Action(SuccessRetrieveMiningParc)
  successRetrieveMiningParc(
    ctx: StateContext<IMiningStateModel>,
    {payload}: SuccessRetrieveMiningParc
  ) {
    ctx.patchState({
      parc: payload,
    })
  }

  @Action(SuccessRetrieveMiningGainsHistory)
  successRetrieveMiningGainHistory(
    ctx: StateContext<IMiningStateModel>,
    {payload}: SuccessRetrieveMiningGainsHistory
  ) {
    ctx.patchState({
      gainsHistory: payload,
    })
  }

  @Action(SuccessRetrieveOverview)
  successRetrieveOverview(
    ctx: StateContext<IMiningStateModel>,
    {payload}: SuccessRetrieveOverview
  ) {
    ctx.patchState({
      overview: payload,
    })
  }

  @Action(SuccessRetrieveMiningUser)
  successRetrieveMiningUser(
    ctx: StateContext<IMiningStateModel>,
    {payload}: SuccessRetrieveMiningUser
  ) {
    ctx.patchState({
      user: payload,
    })
  }

  @Action(DefaultMiningError)
  defaultMiningError(
    ctx: StateContext<IMiningStateModel>,
    {payload}: DefaultMiningError
  ) {
    console.log(payload);
    ctx.patchState({
      error: {
        message: payload.error.message,
        title: payload.error.error,
      }
    })
  }
}
