<div class="country-modal">
  <button
    class="close"
    icon="pi pi-times"
    pButton
    (click)="close()"
  ></button>
  <span class="title">
{{ title}}
</span>
  <ul class="lists grid">
    <li class="col-3">Allemagne</li>
    <li class="col-3">Autriche</li>
    <li class="col-3">Belgique</li>
    <li class="col-3">Bulgarie</li>
    <li class="col-3">Chypre</li>
    <li class="col-3">Croatie</li>
    <li class="col-3">Danemark</li>
    <li class="col-3">Espagne</li>
    <li class="col-3">Estonie</li>
    <li class="col-3">Finlande</li>
    <li class="col-3">France</li>
    <li class="col-3">Grèce</li>
    <li class="col-3">Hongrie</li>
    <li class="col-3">Irlande</li>
    <li class="col-3">Italie</li>
    <li class="col-3">Lettonie</li>
    <li class="col-3">Lituanie</li>
    <li class="col-3">Luxembourg</li>
    <li class="col-3">Malte</li>
    <li class="col-3">Pays-Bas</li>
    <li class="col-3">Pologne</li>
    <li class="col-3">Portugal</li>
    <li class="col-3">République tchèque</li>
    <li class="col-3">Roumanie</li>
    <li class="col-3">Slovaquie</li>
    <li class="col-3">Slovénie</li>
    <li class="col-3">Suède</li>

  </ul>
</div>
