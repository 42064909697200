import {EMiningChartFilter} from "@mining/store/gains/gains.state";

export class GetTotalGains{
  static readonly type = '[Mining][Gains] Get total gains';
  constructor(public filter?: {month: number, year: number}) {}
}
export class GetDataGains{
  static readonly type = '[Mining][Gains] Get data gains';
  constructor(public filter?: {month: number, year: number}) {}
}
export class GetChartGains{
  static readonly type = '[Mining][Gains] Get chart gains';
}
export class SetTotalFilter{
  static readonly type = '[Mining][Gains] Set total filter';
  constructor(public payload: {month: number, year: number}) {}
}
export class SetDataFilter{
  static readonly type = '[Mining][Gains] Set Data filter';
  constructor(public payload: {month: number, year: number}) {}
}
export class SetChartFilter{
  static readonly type = '[Mining][Gains] Set chart filter';
  constructor(public payload: EMiningChartFilter) {}
}
