import {Component, OnDestroy, OnInit} from '@angular/core';

import {AuthState} from '@shared/stores/auth/auth.state';
import {Select, Store} from '@ngxs/store';
import {Observable, Subject, takeUntil} from 'rxjs';
import {ETwoFaType, IUser} from "@models/dist";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ReactiveLoaderService, ToastService} from '@shared/services';
import {PasswordValidator} from '@shared/validators/password';
import {Router} from '@angular/router';
import {DialogService} from "primeng/dynamicdialog";
import {Logout} from "@shared/stores/auth/auth.actions";
import {AuthService} from "@shared/services/auth.service";
import {MiscHelpers} from "@shared/helpers/misc.helpers";
import {TwoFaGenericModalComponent} from "@profile/components/twofa/twofa-modal-generic/twofa-generic.modal.component";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['../scss/profile.scss'],
  styles: []
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

  @Select(AuthState.getUser) user$: Observable<IUser> | undefined;

  passwordForm: UntypedFormGroup = this.formBuilder.group({
      oldPassword: ['', [Validators.required]],
      password: ['', [PasswordValidator.isValid]],
      confirmPassword: ['', []]
    },
    {validators: MiscHelpers.checkPasswords});

  public displayPassword = false;

  user: IUser | null = null;
  private subscription = new Subject<void>();

  constructor(private formBuilder: UntypedFormBuilder,
              private reactiveLoader: ReactiveLoaderService,
              private dialogService: DialogService,
              private store: Store,
              private toastService: ToastService,
              private authService: AuthService,
              private router: Router) {
  }

  get errorControl() {
    return this.passwordForm?.controls;
  }

  ngOnInit(): void {
    this.getUser();
  }

  ngOnDestroy() {
    this.subscription.next();
    this.subscription.complete();
  }

  async changePassword() {
    if (!this.user || this.passwordForm.invalid) {
      return;
    }

    const ref = this.dialogService.open(TwoFaGenericModalComponent, {
      showHeader: false,
      contentStyle: {'max-width': '550px', 'border-radius': '20px'},
      baseZIndex: 10000,
      data: {
        type: ETwoFaType.CHANGE_PASSWORD,
        displayTotp: this.user?.isTotpActivated,
        oldPassword: this.passwordForm?.value?.oldPassword,
        newPassword: this.passwordForm?.value?.password
      }
    });

    ref.onClose.subscribe((res: any) => {
        if (res) {
          this.store.dispatch(new Logout());
        }
      }
    );

  }

  navigateTo(route: string) {
    this.router.navigate([route], {replaceUrl: true});
  }

  formChange(event: any) {
    this.passwordForm.patchValue({
      password: event.password,
      confirmPassword: event.confirmPassword
    })
  }

  private getUser() {
    this.user$?.pipe(takeUntil(this.subscription)).subscribe(user => {
      this.user = user;
    })
  }
}
