import {Component, OnInit} from '@angular/core';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {ELang} from "@models/dist";
import {ResearchService} from "@research/shared/services/research.service";
import {ReactiveLoaderService} from "@shared/services";
import {reactiveButtonState} from "@shared/services/reactive-loader.service";

@Component({
  selector: 'app-research-welcome-modal',
  templateUrl: './welcome-modal.component.html',
  styleUrls: ['./welcome-modal.component.scss']
})
export class WelcomeModalComponent implements OnInit {


  public currentImg = './assets/images/modal-research-fr.png';
  public imgEN = './assets/images/modal-research-en.png';
  public imgFR = './assets/images/modal-research-fr.png';

  constructor(
    public ref: DynamicDialogRef,
    public reactiveLoader: ReactiveLoaderService,
    public researchService: ResearchService,
    private router: Router, private langService: TranslateService) {
    const lang = this.langService.currentLang as ELang;
    if (lang == ELang.FR) {
      this.currentImg = this.imgFR
    } else if (lang === ELang.EN) {
      this.currentImg = this.imgEN
    }
  }

  ngOnInit(): void {
  }

  async close() {
    this.reactiveLoader.setReactiveButtonState('close-btn', reactiveButtonState.loading);
    await this.researchService.setIfFirstAccessFalse()
    this.reactiveLoader.setReactiveButtonState('close-btn', reactiveButtonState.success);
    this.ref.close();
  }
}
