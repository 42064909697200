import {Component} from '@angular/core';
import {DynamicDialogRef} from "primeng/dynamicdialog";
import {ComplianceService, ReactiveLoaderService, ToastService} from "@shared/services";
import {EPassfortProductAlias} from "@models/dist/models/passfort";
import {EToastSeverity} from "@models/dist";
import {reactiveButtonState} from "@shared/services/reactive-loader.service";

@Component({
  selector: 'app-kyc-modal',
  templateUrl: './kyc-modal.component.html',
  styleUrls: ['./kyc-modal.component.scss']
})
export class KycModalComponent {

  constructor(
    public ref: DynamicDialogRef,
    private complianceService: ComplianceService,
    private reactiveLoader: ReactiveLoaderService,
    private toastService: ToastService,
  ) {
  }

  laterAction() {
    this.ref.close({
      status: "back",
    });
  }

  async passKyc() {
    this.reactiveLoader.setReactiveButtonState(
      'create-kyc',
      reactiveButtonState.loading
    );
    try {
      // const res = await this.complianceService.initCompliance(EPassfortProductAlias.KYC_LIGHT);
      this.ref.close({
        status: "goToKyc",
      });
      this.reactiveLoader.setReactiveButtonState(
        'create-kyc',
        reactiveButtonState.success
      );
    } catch (error) {
      console.error(error);
      this.toastService.addToast(
        EToastSeverity.error, 'error', error
      )
      this.reactiveLoader.setReactiveButtonState(
        'create-kyc',
        reactiveButtonState.error
      );
    }
  }

}
