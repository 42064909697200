import {Component, Input, NgZone, OnDestroy, OnInit} from '@angular/core';
import {MenuDirectory} from '../menuItem/directory.const';
import {userMenu} from '../menuItem/directory.user.settings';
import {DialogService} from "primeng/dynamicdialog";
import {TranslateService} from "@ngx-translate/core";
import {LayoutComponent} from "@shared/components";
import {Router} from '@angular/router';
import {AuthState} from '@shared/stores/auth/auth.state';
import {Select} from '@ngxs/store';
import {filter, Observable, Subject, takeUntil} from 'rxjs';
import {ELang, ERoutes, IUser} from "@models/dist";
import {LangBusService, UtilsService} from "@app/shared/services";
import {STORAGE_DARK_MODE} from "@app/app.declaration";
import {IMenuDirectory, IMenuItem} from "@shared/components/menuItem/directory.interface";
import {EventData} from "@shared/services/langBus.service";
import {LogoutBusService} from "@shared/services/logoutBus.service";
import {Location} from "@angular/common";
import directoryIcoConst from "@shared/components/menuItem/directory.ico.const";
import directoryResearchConst from "@shared/components/menuItem/directory.research.const";
import {ProfileState} from "@profile/stores/profile/profile-state.service";
import {environment} from "@environments/environment";

@Component({
  selector: 'app-menu',
  styleUrls: ['./app.menu.component.scss'],
  templateUrl: './app.menu.component.html',
  providers: [DialogService]
})
export class AppMenuComponent implements OnInit, OnDestroy {

  @Input() menuActiveMobile = false;
  @Input() item: any;
  @Input() index: any;
  @Input() root: any;
  @Select(AuthState.getUser) user$: Observable<IUser> | undefined;
  @Select(ProfileState.hasUnread) hasUnread$?: Observable<boolean>;

  darkMode = false;
  openedDirectory: IMenuDirectory;
  directory: IMenuDirectory[] = [];
  userMenus: IMenuItem[] = [];
  icoMenus: IMenuItem[] = [];
  user: IUser | null = null;

  ERoutes = ERoutes;

  langModal = false;
  selectedLang: ELang = ELang.EN;
  ELang = ELang;
  isProfil: boolean = false;
  private subscription = new Subject<void>();
  public noMenu: boolean = false;

  constructor(public appMain: LayoutComponent,
              private utilsService: UtilsService,
              private dialogService: DialogService,
              private location: Location,
              private langBusService: LangBusService,
              private logoutBusService: LogoutBusService,
              private translateService: TranslateService,
              private router: Router, private zone: NgZone) {

    this.selectedLang = this.translateService.currentLang as ELang;
    this.userMenus = [
      // {
      //   label: 'menu.buttons.help',
      //   icon: 'pi pi-info-circle',
      //   action: () => {
      //     this.zone.run(() => router.navigate(['/help']));
      //   },
      //   active: false,
      // }
    ];
    this.directory = MenuDirectory;
    this.openedDirectory = this.directory[0];
    this.directory[0].active = true;
    this.openDirectoryByRoute();

    const dark = localStorage.getItem(STORAGE_DARK_MODE);
    this.darkMode = !!dark
  }

  ngOnInit() {
    this.getUser();
  }

  ngOnDestroy() {
    this.subscription.next();
    this.subscription.complete();
  }


  public toggleDark(isDarkMode: boolean) {
    this.darkMode = isDarkMode;
    this.utilsService.toggleDark(isDarkMode);
  }

  getIsDarkMode(): boolean {
    return this.darkMode;
  }

  openDirectoryByRoute() {
    const url = window.location.pathname;
    const urlArray = url.split('/');
    const urlDirectory = urlArray[1];
    if (urlDirectory) {
      this.directory.forEach((element) => {
        if (element.baseLink === urlDirectory) {
          this.openDirectory(element);
        }
      });
    }
    if (urlDirectory === ERoutes.PROFILE) {
      this.isProfil = true;
      this.openDirectory({children: userMenu});
    }
  }

  isActive(item: any) {
    return true;
  }

  openDirectory(item: any) {
    this.directory.forEach((element) => {
      element.active = false;
    });
    item.active = true;
    this.openedDirectory = item;
    if (!!item.route) {
      if (item.route.includes("gravity") || item.route.includes("mining")) {
        this.noMenu = true;
      }
    }
  }

  openSettings() {
    this.isProfil = true;
    this.openDirectory({children: userMenu});
    this.router.navigateByUrl('/profile')
  }

  navigateTo(item: IMenuDirectory) {
    if (item.disabled) {
      return;
    }
    this.router.navigate([item.route], {replaceUrl: true});
  }

  onKeydown(event: KeyboardEvent) {
    const nodeElement = <HTMLDivElement>event.target;
    if (event.code === 'Enter' || event.code === 'Space') {
      nodeElement.click();
    }
  }

  backToIco() {
    this.openDirectory({children: directoryIcoConst})

    this.router.navigateByUrl('/ico/dashboard')
  }

  backToResearch() {
    this.openDirectory({children: directoryResearchConst})

    this.router.navigateByUrl('/research/dashboard')
  }

  public openLanguageModal() {
    this.langModal = !this.langModal;
    this.langBusService.emit(new EventData('open', this.langModal));
  }

  openLogoutModal() {
    this.logoutBusService.emit(new EventData('open', true));
  }

  private getUser() {
    this.user$?.pipe(takeUntil(this.subscription), filter((data) => !!data)).subscribe((user : IUser) => {
      this.user = user;
      if (user?.language !== this.selectedLang) this.selectedLang = user?.language;
    })
  }

  goToMessenger() {
    this.router.navigateByUrl('/profile/messaging')
  }
}
