import {IProject, IRenderPortfolio} from "@models/dist";

export class GetCoinsList {
  static readonly type = '[research] [projects] get coins list data';
}

export class ToggleCarousel {
  static readonly type = '[research] [reports] toggle carousel';

  constructor(public payload: { lenght: number}) {
  }
}

export class HideCarousel {
  static readonly type = '[research] [reports] hide carousel';
}

export class InitResearch {
  static readonly type =  '[research] [init] load researh user'
}

export class GetAccessibleProjects {
  static readonly type = '[research] [projects] get accessible projects';
}
export class GetFavoritesProjects {
  static readonly type = '[research] [projects] get favorites projects';
}

export class SetAccessibleProjects{
  static readonly type = '[research] [projects] set accessible projects';

  constructor(public payload: string[]) {
  }
}

export class SetUpResearch{
  static readonly type = '[research] [projects] set up research';

}

export class GetProjects {
  static readonly type = '[research] [projects] get projects';
}
export class SetProjects {
  static readonly type = '[research] [projects] set projects';

  constructor(public payload: IProject[]) {
  }
}

export class SetSpotlightsProjects {
  static readonly type = '[research] [projects] set spotlights projects';

  constructor(public payload: IProject[]) {
  }
}
export class GetSpotlightsProjects {
  static readonly type = '[research] [projects] get spotlights projects';
}

export class SetFilter{
  static readonly type = '[research] [projects] set filter';

  constructor(public payload: 'recent' | 'note' | 'buyed' | 'search' |'narrative' | null, public search?: string, public narrative?: string[]) {
  }
}

export class ToggleFavorite {
  static readonly type = '[research] [projects] toggle favorite';

  constructor(public id: string) {
  }
}

export class SetActivePortfolioIndex{
  static readonly type = '[research] [projects] set active portfolio index';

  constructor(public id: string) {
  }
}

export class SetPortfolioProject{
  static readonly type = '[research] [projects] set portfolio project';

  constructor(public payload: IRenderPortfolio[]) {
  }
}
