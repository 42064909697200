import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastService, UserService } from '@app/shared/services';
import { EToastSeverity, ETwoFaType, EWalletStatus, IHistory, IUser } from '@models/dist';
import { EditWalletComponent } from './edit/edit-wallet.component';
import { DialogService } from 'primeng/dynamicdialog';
import { getWalletStatus } from '@app/shared/helpers/wallets.utils';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { TwoFaGenericModalComponent } from '../twofa/twofa-modal-generic/twofa-generic.modal.component';
import { AuthState } from '@app/shared/stores/auth/auth.state';

@Component({
  selector: 'app-wallets',
  templateUrl: './wallets.component.html',
  styleUrls: ['./../scss/profile.scss', './wallets.component.scss'],
})
export class WalletsComponent implements OnInit, OnDestroy {
  @Select(AuthState.getUser)
  user$: Observable<IUser> | undefined;

  ref: any;
  public wallets: any[] = [];
  getWalletStatus = getWalletStatus;
  hidden = false;
  user: IUser | null = null;
  private subscription = new Subject<void>();

  constructor(
    private userService: UserService,
    private dialogService: DialogService,
    private toastService: ToastService
  ) {}

  async ngOnInit() {
    try {
      this.getUser()
      await this.getWallets();
    } catch (err) {
      console.log(err);
    }
  }

  ngOnDestroy() {
    this.subscription.next();
    this.subscription.complete();
  }

  async getWallets() {
    const data = (await this.userService.getUserWallets()) as any[];
    this.wallets = data;
    this.hidden = false;
  }

  async getHiddenWallets() {
    const data = (await this.userService.getUserHiddenWallets()) as any[];
    this.wallets = data;
    this.hidden = true;
  }

  async hideWallet(id) {
    await this.userService.hideWallet(id);
    await this.getWallets();
  }

  private getUser() {
    this.user$?.pipe(takeUntil(this.subscription)).subscribe(user => {
      this.user = user;
    })
  }


  openGoogle2FaModal() {
    this.ref = this.dialogService.open(TwoFaGenericModalComponent, {
      showHeader: false,
      contentStyle: {'max-width': '550px', 'border-radius': '20px'},
      baseZIndex: 10000,
      data: {type: ETwoFaType.ACTIVATE}
    });
  }

  getCurrencyName(wallet: any){
    if (wallet.currency.network === 'Bitcoin (Segwit)'){
      let substring = wallet.address.substring(0, 3)

      if (substring === 'bc1' || wallet?.address[0] === '3'){
        return `${wallet.currency.slug} - ${wallet.currency.network}`
      } else {
        return `${wallet.currency.slug} - Bitcoin`
      }
    }
    else {
      return `${wallet.currency.slug} - ${wallet.currency.network}`
    }
  }


  async createOrEditWallet(wallet?: any) {
    if (!!wallet && wallet.status === EWalletStatus.DISABLED){
      this.toastService.addToast(
        EToastSeverity.error,
        'wallets.toast.walletsNotEditable',
        'wallets.toast.walletsNotEditableDesc'
      );
      return;
    }

    this.ref = this.dialogService.open(EditWalletComponent, {
      showHeader: false,
      data: {
        id: wallet?.id || null,
        name: wallet?.name || null,
        address: wallet?.address || null,
        currency: wallet?.currency || null,
      },
      dismissableMask: true,
      contentStyle: {
        width: '100%',
        'max-width': '550px',
        'border-radius': '20px',
        display: 'flex',
        overflow: 'hidden',
        'align-items': 'center',
        'padding-bottom': 'unset',
      },
      baseZIndex: 10000,
    });

    this.ref.onClose.subscribe(async (data: { status: string }) => {
      if (data?.status === 'done') {
        this.wallets = (await this.userService.getUserWallets()) as any[];
      }
    });
  }
}
