import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {NgxWheelComponent} from "@shared/components";
import {TextAlignment, TextOrientation} from "@shared/components/wheel-inside/wheel-inside.components";
import {DialogService} from "primeng/dynamicdialog";
import {ProfileService} from "@profile/services/profile.service";
import {reactiveButtonState, ReactiveLoaderService} from "@shared/services/reactive-loader.service";
import {ConfirmComponent} from "@shared/modules/referral/confirm-modal/confirm.component";
import {TranslateService} from '@ngx-translate/core';

/*

X is the number of choices
125 has 35%
1 / X x 100 = 35
100 = 35x
x = 100 / 35
x = 2,85

2 / X * 100 = 35
x = 5,7

5,7 * 10 = 57

20 / 57 = 0,350877193 * 100 = 35%


29 is the number of choices
x is the number of part of 1000
(x / 29) * 100 = 5%
x = (5 * 29) / 100








 */


export const WHEEL_SECTORS = [
  {id: 1, label: '125 XP', probability: 0.35},
  {id: 2, label: '500 XP', probability: 0.15},
  {id: 3, label: '1000 XP', probability: 0.05},
  {id: 4, label: 'Boost x1.25', probability: 0.2},
  {id: 5, label: 'Boost x1.5', probability: 0.14},
  {id: 6, label: 'Boost x2', probability: 0.1},
  {id: 7, label: 'Ledger Summit', probability: 0.01},
];


@Component({
  selector: 'app-wheel',
  templateUrl: './wheel.component.html',
  styleUrls: ['./wheel.component.scss'],
})
export class WheelComponent {
  @ViewChild(NgxWheelComponent, {static: false}) wheel;

  idToLandOn: any;
  items: any[] = [
    {
      fillStyle: "#D60580",
      text: '125 XP',
      id: 1,
      textFillStyle: 'white',
      textFontSize: '14'
    },
    {
      fillStyle: "#AA389A",
      text: '500 XP',
      id: 2,
      textFillStyle: 'white',
      textFontSize: '14'
    },
    {
      fillStyle: "#8B5EAD",
      text: '1000 XP',
      id: 3,
      textFillStyle: 'white',
      textFontSize: '14'
    },
    {
      fillStyle: "#6C82C0",
      text: this.translateService.instant("ico.referrals.wheel.boost1.25"),
      id: 4,
      textFillStyle: 'white',
      textFontSize: '14'
    },
    {
      fillStyle: "#4DA6D2",
      text: this.translateService.instant("ico.referrals.wheel.boost1.5"),
      id: 5,
      textFillStyle: 'white',
      textFontSize: '14'
    },
    {
      fillStyle: "#2ECAE4",
      text: this.translateService.instant("ico.referrals.wheel.boost2"),
      id: 6,
      textFillStyle: 'white',
      textFontSize: '14'
    },
    {
      fillStyle: "#b48811",
      text: 'Ledger Summit',
      id: 7,
      textFillStyle: 'white',
      textFontSize: '14'
    },
  ];
  textOrientation: TextOrientation = TextOrientation.HORIZONTAL
  textAlignment: TextAlignment = TextAlignment.OUTER
  @Input() available: any = 0;
  @Input() currentBoost?: any;
  public ref?: any;
  public ticketId: number = 0;
  @Output() spinEnd: EventEmitter<any> = new EventEmitter<any>();
  // public available: any = 0;
  private isSpin: boolean = false;

  constructor(
    // public dialogService: DialogService,
    //           public ref: DynamicDialogRef,
    //           public config: DynamicDialogConfig,
    private reactiveLoader: ReactiveLoaderService,
    public profileService: ProfileService,
    private dialogService: DialogService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    // console.log(this.config.data);
    // this.available = this.config.data.available
  }

  reset() {
    this.wheel.reset()
  }

  before() {
    // alert('Your wheel is about to spin')
  }

  async spin() {
    if (!this.available) {
      return;
    }
    if (!!this.currentBoost) {
      this.ref = this.dialogService.open(ConfirmComponent, {
        showHeader: false,
        data: {
          currentBoost: this.currentBoost
        },
        contentStyle: {'width': '100%', 'max-width': '650px', 'border-radius': '20px'},
        baseZIndex: 10000,
      });

      this.ref.onClose.subscribe(({confirm}: { confirm: boolean }) => {

        this.ref = null;
        if (confirm)
          this.spinWheel()
      })
    } else {
      await this.spinWheel()
    }

  }

  async spinWheel() {
    this.reactiveLoader.setReactiveButtonState(
      'spin',
      reactiveButtonState.loading
    );
    if (!this.isSpin) {
      this.isSpin = true;
      const {ticket} = await this.profileService.getTicket() as { ticket: number }
      // const ticket=2;
      this.ticketId = ticket;
      this.idToLandOn = ticket
      await new Promise(resolve => setTimeout(resolve, 0));
      this.wheel.spin()
      setTimeout(() => {
        this.reactiveLoader.setReactiveButtonState(
          'spin',
          reactiveButtonState.success
        );
        this.available--;
        this.isSpin = false;
      }, 8000)
    }
  }

  after() {
    this.spinEnd.next(WHEEL_SECTORS.find((e) => e.id === this.ticketId))
  }

  close() {
    // this.ref.close();
  }
}
