import {Injectable} from '@angular/core';
import {Socket} from 'ngx-socket-io';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {

  constructor(private socket: Socket) {
  }

  // sendMessage(message: string) {
  //   this.socket.emit('ico_progress', message);
  // }

  getIcoProgress() {
    return this.socket.fromEvent('ico_progress');
  }


  onboardingFinish(id:string){
    return this.socket.fromEvent(`user_onboarding_${id}`);

  }
  getUserKycStatus(id: string) {
    return this.socket.fromEvent(`user_update_kyc_status${id}`);
  }

  getUserUpdateTransaction(id: string) {
    return this.socket.fromEvent(`user_update_transactions_${id}`);
  }

  switchToTransaction(id: string) {
    return this.socket.fromEvent(`user_switch_transactions_${id}`);
  }

  getUserToast(id: string) {
    return this.socket.fromEvent(`user_toast_${id}`);
  }

  getUserReferralUsed(id: string) {
    return this.socket.fromEvent(`user_referral_used${id}`);
  }

  getUserUpdateUnread(id: string) {
    return this.socket.fromEvent(`user_update_user_read_${id}`);
  }

  getUserSummitToken(id: string) {
    return this.socket.fromEvent(`user_update_summit_token${id}`);
  }

  getUserSummitTokenPerRound(id: string) {
    return this.socket.fromEvent(`user_update_summit_token_per_round${id}`);
  }

  getUserCredits(id: string) {
    return this.socket.fromEvent(`user_update_credits${id}`);
  }

  isWaitingList() {
    return this.socket.fromEvent(`waiting_list_enabled`);

  }

  getOtcOrderBooks() {
    return this.socket.fromEvent(`otc_order_books`);
  }

  getIncomingMessage(userId) {
    return this.socket.fromEvent(`message_${userId}`);
  }

  getIncomingConversation(userId) {
    return this.socket.fromEvent(`new_conversation_${userId}`);
  }

  listenToTransactionUpdate(userId:string) {
    return this.socket.fromEvent<{transactionId: string}>(`mining_approved_pending_transaction_${userId}`)
  }

  listenToTransactionCancel(userId:string) {
    return this.socket.fromEvent<{transactionId: string}>(`mining_cancel_pending_transaction_${userId}`)
  }
}
