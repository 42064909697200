import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { ERoutes } from "@models/dist";
import { AuthState } from "@shared/stores/auth/auth.state";
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root'
})
export class TwofaGuard implements CanActivate {

  constructor(
    private authState: AuthState,
    private afAuth: AngularFireAuth,
    private router: Router,
    private store: Store
  ) {
  }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const customToken = this.store.snapshot()?.AuthState?.isCustomToken;
    const auth = getAuth()
    return new Promise(resolve => {
      const unsubscribe = onAuthStateChanged(auth, (user: any) => {
        user.getIdTokenResult(true).then((idTokenResult: any) => {
          if (
            !customToken &&
            !idTokenResult.claims.adminId &&
            idTokenResult.claims.mfaD &&
            (idTokenResult.claims.mfaD as any).totp &&
            !this.authState.isValidMfa((idTokenResult.claims.mfaD as any).totp)
          ) {
            this.router.navigate([ERoutes.AUTH]);
            resolve(false);
          } else {
            resolve(true);
          }
        });
        unsubscribe();
      });
    });
  }

}
