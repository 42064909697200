<div class="flex flex-wrap m-0 p-0 profilDetails">
  <div (click)="handleChangeAvatar()" *ngIf="!noAvatar" class="flex align-items-center avatar" [class.cursor]="edit">
    <ion-icon *ngIf="edit" class="edit" name="create-outline"></ion-icon>

    <img [src]="(user$ | async)?.avatar ?? 'assets/icon/exempleAvatar.jpg'" alt="" class="">
    <div *ngIf="edit" class="overlay"></div>
  </div>
  <div class="flex-grow-1 m-0 mt-2 mr-4 p-0">
    <p-skeleton *ngIf="!(user$ | async)" height="2rem" width="70%"></p-skeleton>
    <p-skeleton
      *ngIf="!(user$ | async)"
      height="1rem"
      styleClass="mt-2"
      width="50%"
    ></p-skeleton>
    <p-skeleton
      *ngIf="!(user$ | async)"
      height="2.5rem"
      styleClass="mt-2"
      width="110px"
    ></p-skeleton>
    <ng-container *ngIf="user$ | async as user">
      <div class="title">
        {{ user.firstName }} {{ user.lastName }}
      </div>
      <div class="subtitle">
        {{ user.email }}
      </div>
      <a routerLink="/profile" class="subtitle link" *ngIf="!user.pseudo">Ajouter un pseudo </a>
      <div class="subtitle" *ngIf="user.pseudo">
       {{'yourPseudo' | translate}} {{ user.pseudo }}
      </div>

    </ng-container>
    <!--    <a -->
    <!--       class="kyc-button"-->
    <!--       pButton-->
    <!--       routerLink="/profile/kyc">-->
    <!--      {{ "Profile.kycLv0" | translate }}-->
    <!--    </a>-->
  </div>
  <div class="flex-none mt-2">
    <p-skeleton
      *ngIf="!(user$ | async)"
      height="2.5rem"
      width="150px"
    ></p-skeleton>
    <!--    <p-dropdown-->
    <!--      *ngIf="user$ | async"-->
    <!--      [options]="countries"-->
    <!--      [(ngModel)]="selectedCountry"-->
    <!--      optionLabel="name"-->
    <!--      [readonly]="true"-->
    <!--    >-->
    <!--      <ng-template pTemplate="selectedItem">-->
    <!--        <div class="country-item country-item-value" *ngIf="selectedCountry">-->
    <!--          <img-->
    <!--            src="assets/flag/flag_placeholder.png"-->
    <!--            [class]="'flag flag-' + selectedCountry.code.toLowerCase()"-->
    <!--          />-->
    <!--          <div>{{ selectedCountry.name }}</div>-->
    <!--        </div>-->
    <!--      </ng-template>-->
    <!--      <ng-template let-country pTemplate="item">-->
    <!--        <div class="country-item">-->
    <!--          <img-->
    <!--            src="assets/flag/flag_placeholder.png"-->
    <!--            [class]="'flag flag-' + country.code.toLowerCase()"-->
    <!--          />-->
    <!--          <div>{{ country.name }}</div>-->
    <!--        </div>-->
    <!--      </ng-template>-->
    <!--    </p-dropdown>-->
  </div>
</div>
