import { Injectable, NgZone } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext, Store, } from '@ngxs/store';
import { browserSessionPersistence, getAuth, indexedDBLocalPersistence, setPersistence, } from 'firebase/auth';

import { v4 as uuidv4 } from 'uuid';
import { NavService, ReactiveLoaderService, ToastService, UserService, } from '@shared/services';
import { ERoutes, EToastSeverity, IAuthStateModel, IUser } from "@models/dist";
import {
  loadMyUser,
  LoadUserAfterTwoFa,
  Login,
  Logout,
  ResetPassword,
  SendVerificationEmail,
  updateMfa,
  VerifyPasswordResetCode,
  VirtualDashboard,
} from '@shared/stores/auth/auth.actions';
import { verifyPasswordResetCode } from '@firebase/auth';
import { Router } from '@angular/router';
import { reactiveButtonState } from '@shared/services/reactive-loader.service';
import { AuthService } from "@shared/services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { STORAGE_DEVICE_UID } from "@app/app.declaration";
import LogRocket from "logrocket";
import { environment } from "@environments/environment";
import { firebaseApp } from "@app/app.module";
import { initializeApp } from "firebase/app";

@State<IAuthStateModel>({
  name: 'AuthState',
  defaults: {
    refreshToken: null,
    user: null,
    unread: 0,
    firebaseUser: null,
    loginError: null,
    registerError: null,
    isCustomToken: false,
  },
})
@Injectable()
export class AuthState implements NgxsOnInit {
  auth = getAuth(firebaseApp);
  redirectUrl: string = 'redirectUrl';

  constructor(
    private navService: NavService,
    private authService: AuthService,
    private translate: TranslateService,
    private router: Router,
    private userService: UserService,
    private toastService: ToastService,
    private reactiveLoader: ReactiveLoaderService,
    private store: Store,
    private ngZone: NgZone
  ) {
  }

  @Selector()
  static getToken(state: IAuthStateModel): string | null {
    return state.refreshToken;
  }

  @Selector()
  static getUser(state: IAuthStateModel): any | null {
    return state.user;
  }

  @Selector()
  static getFirebaseUser(state: IAuthStateModel): any {
    return state.firebaseUser;
  }

  @Selector()
  static getRegisterError(state: IAuthStateModel): any | null {
    return state.registerError;
  }

  @Selector()
  static getLoginError(state: IAuthStateModel): any | null {
    return state.loginError;
  }

  @Selector()
  static isAuthenticated(state: IAuthStateModel): boolean {
    return !!state.refreshToken;
  }

  @Selector()
  static isCustomToken(state: IAuthStateModel): boolean | undefined {
    return state.isCustomToken;
  }

  getUniqueId(): string {
    return uuidv4();
  }

  isValidMfa(mfaD: any) {
    let summitId = localStorage.getItem(STORAGE_DEVICE_UID);
    if (!summitId) {
      summitId = this.getUniqueId();
      localStorage.setItem(STORAGE_DEVICE_UID, summitId);
    }
    if (mfaD.find((x: any) => x.d === summitId)) {
      return true;
    }
    return false;
  }

  ngxsOnInit(ctx: StateContext<IAuthStateModel>) {
    this.initState(ctx);
  }

  @Action(Login)
  async Login(ctx: StateContext<IAuthStateModel>, { payload }: Login) {
    this.reactiveLoader.setReactiveButtonState(
      'summit-login',
      reactiveButtonState.loading
    );
    try {
      let firebaseLoginRes: any;
      const email = payload.email.toLowerCase();
      let customToken = false;

      if (payload.customToken) {
        await setPersistence(this.auth, browserSessionPersistence)
        firebaseLoginRes = await this.authService.loginWithCustomToken(payload.customToken, payload.extraHeaders);
        customToken = true
      } else {
        await setPersistence(this.auth, indexedDBLocalPersistence);
        firebaseLoginRes = await this.authService.login(email, payload.password);
        await this.userService.triggerLogin(email);
      }

      ctx.patchState({ firebaseUser: firebaseLoginRes?.user, isCustomToken: customToken, extraHeaders: payload.extraHeaders });

      if (!firebaseLoginRes.user?.emailVerified) {
        await this.emailNotVerified(firebaseLoginRes, ctx);
        return;
      } else {
        await this.initUser(firebaseLoginRes, ctx, payload, customToken);
        return;
      }
    } catch (error) {
      if (!payload.customToken) {
        await this.catchLoginError(error, ctx, payload.showError);
      }
      return;
    }

  }

  @Action(VirtualDashboard)
  async VirtualDashboard(ctx: StateContext<IAuthStateModel>, { customToken, extraHeaders }: VirtualDashboard) {
    ctx.patchState({isCustomToken: !!customToken, extraHeaders});
  }

  catchLoginError(error: any, ctx: StateContext<IAuthStateModel>, showError: boolean = false) {
    console.error(`Error trigger in login action ${JSON.stringify(error)}`);
    this.store.dispatch(new Logout());
    if (showError) {
      this.toastService.addToast(EToastSeverity.error, 'toast.login.titleError', 'toast.login.error');
      this.reactiveLoader.setReactiveButtonState(
        'summit-login',
        reactiveButtonState.error
      );
      if (error.message.includes('auth/user-not-found')) {
        ctx.patchState({
          loginError: { error: true, message: 'USER_NOT_FOUND' },
        });
      } else {
        ctx.patchState({
          loginError: { error: true, message: 'USER_NOT_FOUND' },
        });
      }
    }
  }

  private async initUser(firebaseLoginRes: any, ctx: StateContext<IAuthStateModel>, payload, customToken) {

    const userToken = await firebaseLoginRes.user.getIdTokenResult(true);
    const totp = (userToken?.claims?.mfaD as any)?.totp;

    if (totp && !this.isValidMfa(totp) && !customToken) {
      // REDIRECT TO TWOFA
      this.router.navigate([ERoutes.AUTH, ERoutes.TWOFA]);
      this.reactiveLoader.setReactiveButtonState(
        'summit-login',
        reactiveButtonState.success
      );
      return;
    }

    const user = await this.userService.getUser() as IUser;
    if (!user) {
      throw new Error('User not found');
    }

    if (user?.passfortFormRequest) {
      user.passfortFormRequest = JSON.parse(user.passfortFormRequest as any);
    }
    const lang = user?.language?.toLowerCase();
    this.translate.use(lang);

    ctx.patchState({ user });

    // CHECK FOR ONBOARDING
    if (!!user && !user.onboarding) {
      this.ngZone.run(() => {
        this.router.navigate([ERoutes.ONBOARDING]);
      });
    } else {
      const redirectUrl = localStorage.getItem(this.redirectUrl);
      if (!!redirectUrl) {
        this.ngZone.run(() => {
          this.router.navigateByUrl(redirectUrl);
        });
        localStorage.removeItem(this.redirectUrl)
      } else {
        this.ngZone.run(() => {
          this.router.navigate(['/' + user.service]);
        });
      }
    }
    this.reactiveLoader.setReactiveButtonState(
      'summit-login',
      reactiveButtonState.success
    );
    return;

  }

  async emailNotVerified(firebaseLoginRes: any, ctx: StateContext<IAuthStateModel>) {
    ctx.patchState({
      loginError: { error: true, message: 'EMAIL_NOT_VERIFIED' },
    });

    this.reactiveLoader.setReactiveButtonState(
      'summit-login',
      reactiveButtonState.error
    );
    // if email is not verified we logout the user and redirect to verify email page
    this.store.dispatch(new Logout()).subscribe(() => {
      const userEmail = firebaseLoginRes?.user?.email as string;
      const userUid = firebaseLoginRes?.user?.uid as string;
      this.goToVerifyEmail(userEmail, userUid, true);
    });

  }

  @Action(loadMyUser)
  async loadMyUser(ctx: StateContext<IAuthStateModel>) {
    ctx.patchState({
      user: null,
    });
    try {
      const user = await this.userService.getUser() as IUser;
      if (!user) {
        throw new Error('User not found');
      }

      if (user?.passfortFormRequest) {
        user.passfortFormRequest = JSON.parse(user.passfortFormRequest as any);
      }
      const lang = user?.language?.toLowerCase();
      this.translate.use(lang);
      ctx.patchState({ user });
    } catch (error) {
      this.store.dispatch(new Logout());
    }
    return;
  }

  @Action(LoadUserAfterTwoFa)
  async LoadUserAfterTwoFa(ctx: StateContext<IAuthStateModel>) {

    ctx.patchState({
      user: null,
    });
    try {
      const user = await this.userService.getUser() as IUser;
      if (!user) {
        throw new Error('User not found');
      }

      if (user?.passfortFormRequest) {
        user.passfortFormRequest = JSON.parse(user.passfortFormRequest as any);
      }
      const lang = user?.language?.toLowerCase();
      this.translate.use(lang);
      ctx.patchState({ user });


      if (!!user && !user.onboarding) {
        this.router.navigate([ERoutes.ONBOARDING]);
      } else {
        const redirectUrl = localStorage.getItem(this.redirectUrl);
        if (!!redirectUrl) {
          this.router.navigateByUrl(redirectUrl);
          localStorage.removeItem(this.redirectUrl)
        } else {
          this.router.navigate(['/' + user.service]);
        }
      }

    } catch (error) {
      this.store.dispatch(new Logout());
    }

    return;
  }

  @Action(Logout)
  async Logout(ctx: StateContext<IAuthStateModel>, { noRedirection, customToken, extraHeaders }: Logout) {
    localStorage.removeItem(STORAGE_DEVICE_UID);
    try {
      await this.auth.signOut();

      try {
        const miningFirebase = initializeApp(environment.mining, 'mining')
        const miningAuth = getAuth(miningFirebase);
        await miningAuth.signOut();
      } catch (e) {
        console.error(e);
      }


      try {
        const gravityFirebase = initializeApp(environment.gravity, 'gravity')
        const gravityAuth = getAuth(gravityFirebase);
        await gravityAuth.signOut();
      } catch (e) {
        console.error(e);
      }


      ctx.patchState({
        refreshToken: null,
        user: null,
        firebaseUser: null,
        loginError: null,
        registerError: null,
      });

      if (customToken) {
        this.store.dispatch(new Login({ email: '', password: '', welcome: true, customToken, showError: false, extraHeaders }));
      }

      if (!noRedirection) {
        this.navService.addnav([ERoutes.AUTH]);
      }
    } catch (err) {
      console.error('Error => ', err);
    }
    return;
  }

  @Action(VerifyPasswordResetCode)
  async VerifyPasswordResetCode(
    ctx: StateContext<IAuthStateModel>,
    { payload }: VerifyPasswordResetCode
  ) {
    try {
      this.reactiveLoader.setReactiveButtonState(
        'summit-verify-password-reset-code',
        reactiveButtonState.loading
      );
      const res = await verifyPasswordResetCode(this.auth, payload.oobCode);
      this.reactiveLoader.setReactiveButtonState(
        'summit-verify-password-reset-code',
        reactiveButtonState.success
      );
    } catch (err) {
      this.reactiveLoader.setReactiveButtonState(
        'summit-verify-password-reset-code',
        reactiveButtonState.error
      );
      console.error(err);
      this.toastService.addToast(EToastSeverity.error, 'toast.error', err);
      this.navService.addnav([ERoutes.AUTH]);
    }
  }

  @Action(ResetPassword)
  async ResetPassword(
    ctx: StateContext<IAuthStateModel>,
    { payload }: ResetPassword
  ) {
    try {
      this.toastService.addToast(
        EToastSeverity.info,
        'toast.password.title',
        'toast.password.running'
      );
      this.reactiveLoader.setReactiveButtonState(
        'summit-reset-password',
        reactiveButtonState.loading
      );
      await this.authService.resetPassword(payload.email);
      this.reactiveLoader.setReactiveButtonState(
        'summit-reset-password',
        reactiveButtonState.success
      );
      this.toastService.addToast(
        EToastSeverity.success,
        'toast.password.title',
        'toast.password.successEmail'
      );
      // this.navService.addnav([ERoutes.AUTH]);
    } catch (err) {
      this.reactiveLoader.setReactiveButtonState(
        'summit-reset-password',
        reactiveButtonState.error
      );
      this.toastService.addToast(
        EToastSeverity.error,
        'toast.password.title',
        'toast.password.error'
      );
      console.error(err);
    }
  }

  @Action(SendVerificationEmail)
  async SendVerificationEmail(
    ctx: StateContext<IAuthStateModel>,
    { payload }: SendVerificationEmail
  ) {
    if (payload.toast) {
      this.toastService.addToast(
        EToastSeverity.info,
        'toast.confirmation',
        'toast.password.running'
      );
    }
    try {
      this.reactiveLoader.setReactiveButtonState(
        'summit-send-verification-email',
        reactiveButtonState.loading
      );
      await this.userService.sendVerificationEmail(payload.email);
      this.reactiveLoader.setReactiveButtonState(
        'summit-send-verification-email',
        reactiveButtonState.success
      );
      if (payload.toast) {
        this.toastService.addToast(
          EToastSeverity.success,
          'toast.confirmation',
          'toast.emailVerified.resend'
        );
      }
      // this.navService.addnav([ERoutes.AUTH]);
    } catch (error) {
      this.reactiveLoader.setReactiveButtonState(
        'summit-send-verification-email',
        reactiveButtonState.error
      );
      this.toastService.addToast(
        EToastSeverity.error,
        'toast.error',
        'toast.password.error'
      );
      console.error(error);
    }
    return;
  }

  @Action(updateMfa)
  async updateMfa(ctx: StateContext<IAuthStateModel>, { payload }: updateMfa) {
    ctx.patchState({
      user: {
        ...ctx.getState().user,
        totpActivated: payload.mfa,
      },
    });
    return;
  }

  private initState(ctx: StateContext<IAuthStateModel>) {
    const that = this;
    this.auth.onAuthStateChanged((user) => {
      if (environment.production) {
        LogRocket.identify(user?.uid!, {
          name: user?.displayName!,
          email: user?.email!,
        });
      }
      localStorage.removeItem('mining_auth')
      localStorage.removeItem('gravity_auth')
      
      ctx.patchState({ firebaseUser: user });
      if (user?.emailVerified) {
        const customToken = this.store.snapshot()?.AuthState?.isCustomToken;
        user.getIdTokenResult(true).then((idTokenResult) => {
          if (
            !customToken &&
            !idTokenResult.claims.adminId &&
            idTokenResult.claims.mfaD &&
            (idTokenResult.claims.mfaD as any).totp &&
            !that.isValidMfa((idTokenResult.claims.mfaD as any).totp)
          ) {
            this.router.navigate([ERoutes.AUTH, ERoutes.TWOFA]);
          } else {
            that.store.dispatch(new loadMyUser());
          }
        });
      }
    });
  }

  private goToVerifyEmail(userEmail: string, userUid?: string, resend = false) {
    this.router.navigate([`${ERoutes.AUTH}/${ERoutes.VERIFY_EMAIL}`], {
      replaceUrl: true,
      queryParams: { userEmail, userUid, resend },
    });
  }


}
