<app-ico-top [nohelp]="true" [title]="'ico.top.messaging' | translate"></app-ico-top>

<div class="ico-card mt-4" *ngIf="user$ | async as user"
     [class.auto-h]="!(conversations$|async)?.length && !newConversation">

  <div class="messaging-container" *ngIf="conversations$ | async as conversationsPipe">
    <div class="conversations-container">


<!--      <div class="new-msg-btn" [class.mobile-force]="conversationsPipe.length && !(activeConversationId$ | async)"-->
<!--      >-->
<!--        <app-ico-btn [text]="(newConversation ? 'messaging.seeMessages' : 'messaging.newMessage') | translate"-->
<!--                     (click)="addNewConversation()"></app-ico-btn>-->
<!--      </div>-->

      <div class="back flex align-items-center gap-2" (click)="activeConversationId$.next('')"
           *ngIf="(activeConversationId$| async) && !newConversation">
        <img src="/assets/icon/arrow_right.svg" alt="" width="15px" class="rotate-180">
        <span class="small-txt">{{ 'messaging.backToConversation' | translate}}</span>
      </div>
      <!--      // NEST PAS ENTRAIN DE CR2E UNE NOUVELLE CONVERSATION -->
      <div *ngIf="!(conversations$ | async)?.length && loading" class="flex gap-3 flex-column"
           [style.margin-right.px]="10">
        <p-skeleton *ngFor="let i of [{},{},{},{}]" styleClass="title-header" height="3rem" width="100%"></p-skeleton>
      </div>

      <div *ngIf="!newConversation && (conversations$|async)?.length" class="conversations-container-contact"
           [class.mobile-hidden]="activeConversationId$ | async">


        <div *ngFor="let grouppedConversation of (groupedConversation$ | async)"
             class="conversations-container-contact-list">

          <div class="title-header" (click)="grouppedConversation.display = !grouppedConversation.display">
            <div class="title-container">
              <ion-icon style="width: 16px; margin-right: 10px" name="document-outline"></ion-icon>
              <span class="title">{{ 'messaging.title.' + grouppedConversation.title | translate }}</span>
            </div>

            <ion-icon style="width: 16px" class="expand" *ngIf="!grouppedConversation.display"
                      name="chevron-down-outline"></ion-icon>
            <ion-icon style="width: 16px" class="expand" *ngIf="grouppedConversation.display"
                      name="chevron-up-outline">

            </ion-icon>

          </div>

          <div *ngFor="let conversation of grouppedConversation.conversations"
               [class.selected-conversation]="conversation.id === (activeConversationId$ | async)"
               [class.hidden]="grouppedConversation.display" class="conversations-list cursor"
               (click)="selecteCurrentConversation(conversation)">

            <img class="mr-2 object-cover" width="35px" height="35px"
                 [src]="(conversation.lastMessage?.sender?.id === user?.id ? (!!user.avatarUrl ?user.avatarUrl : 'assets/icon/exempleAvatar.jpg') : conversation.lastMessage?.sender?.avatarUrl)"
                 alt="">
            <div class="conversation-header">
              <div class="name-and-hour">
                <span class="name" *ngIf="conversation.lastMessage?.sender?.id === user?.id">
                  {{ conversation.lastMessage?.sender?.firstName }}
                </span>
                <span class="name" *ngIf="conversation.lastMessage?.sender?.id !== user?.id">
                  {{ conversation.lastMessage?.sender?.firstName }}

                  ({{lang === 'fr' ? ('messaging.team'| translate) + ' ' : '' }}{{'messaging.title.' + conversation.teamTag | translate}}{{lang === 'en' ? ' ' + ('messaging.team'| translate) : ''}}
                  )
                </span>
                <span class="last-hr">
                  {{ !!conversation.lastMessage ? (conversation.lastMessage.created_at | localizedDate:'d MMMM YYYY HH:mm') : '-'}}
                </span>
              </div>
              <div class="message-and-unread">
                <div class="last-message"
                     [innerHTML]="!!conversation.lastMessage ? ((!!conversation.lastMessage.content? conversation.lastMessage.content : '')| safeHtml) : '-'">
                </div>
                <span *ngIf="getUnreadMessage(conversation, user.id) > 0 && !conversation.isEnded"
                      class="unread-dot">{{ getUnreadMessage(conversation, user.id) }}</span>
              </div>
            </div>

          </div>
        </div>
      </div>

      <!--      // EST PAS ENTRAIN DE CR2E UNE NOUVELLE CONVERSATION-->
      <div *ngIf="newConversation" class="conversations-list selected-conversation">
        <img class="mr-2 object-cover" width="35px" height="35px" [src]="'assets/icon/avatar.svg'" alt="">
        <div class="last-msg">
          <span class="name">
            {{'messaging.newMessage' | translate}}
          </span>
        </div>
      </div>
    </div>


    <div class="messages-list"
         [class.mobile-hidden]="(conversations$ | async)?.length ? (!(activeConversationId$| async) && !newConversation) : false">
      <ng-container *ngIf="activeConversation$| async as activeConversation">
        <div *ngIf="!newConversation" class="top">
          <div class="left">
            <ng-container *ngIf="activeConversation.initiator.id === user.id">
              <img class="mr-2 object-cover" width="35px" height="35px"
                   [src]="!!activeConversation.recipient.avatarUrl ? activeConversation.recipient.avatarUrl :  'assets/icon/exempleAvatar.jpg'"
                   alt="">
              <p class="name">
<!--                {{ activeConversation.recipient.firstName }}-->
                {{lang === 'fr' ? ('messaging.team'| translate) + ' ' : '' }}{{'messaging.title.' + activeConversation.teamTag | translate}}{{lang === 'en' ? ' ' + ('messaging.team'| translate) : ''}}
                - {{'messaging.title.' + activeConversation.title | translate}}
              </p>
            </ng-container>

            <ng-container *ngIf="activeConversation.recipient.id === user.id">
              <img class="mr-2 object-cover" width="35px" height="35px"
                   [src]="!!activeConversation.initiator.avatarUrl ? activeConversation.initiator.avatarUrl : 'assets/icon/exempleAvatar.jpg'"
                   alt="">
              <p class="name">
<!--                {{ activeConversation.initiator.firstName }}-->
                {{lang === 'fr' ? ('messaging.team'| translate) + ' ' : '' }}{{'messaging.title.' + activeConversation.teamTag | translate}}{{lang === 'en' ? ' ' + ('messaging.team'| translate) : ''}}
                - {{'messaging.title.' + activeConversation.title | translate}}
              </p>
            </ng-container>
          </div>
          <div class="right">
          <span class="secure-txt"
                style="text-align: center;">{{ 'messaging.exchangeIsSecure' | translate }}</span>
            <img class="ml-2 object-cover secure-img" width="30px" [src]="'assets/icon/secure.svg'" alt="">
<!--            <div class="pointer" (click)="toggleDropdown()" *ngIf="!activeConversation.isEnded">-->
<!--              <img class="pointer ml-3 object-cover"-->
<!--                   width="25px" [src]="'assets/icon/dot.svg'" alt="">-->
<!--            </div>-->
<!--            <div class="flex justify-content-center dropdown-end" *ngIf="isDropdownConversationVisible">-->
<!--              <ion-icon style="margin-right: 10px;" name="archive-outline"></ion-icon>-->
<!--              <p class="dropdown-text"-->
<!--                 (click)="endConversation(activeConversation.id)">{{ 'messaging.endConversation' | translate }}</p>-->
<!--            </div>-->
          </div>
        </div>
      </ng-container>

      <div *ngIf="newConversation" class="top newConversation">
        <div class="left">
          <p class="name">
            {{ 'messaging.subject' | translate }}
          </p>
        </div>

        <p-dropdown class="dropdown" optionLabel="name"
                    (onChange)="selectType($event)" [options]="discussionOptions"></p-dropdown>

      </div>

      <!--      <div class="spacer"></div>-->

      <ng-container *ngIf="(messages$ | async) && !newConversation && (conversations$ | async)?.length">

        <div class="messages" [class.full]="(activeConversation$ | async)?.isEnded"
             *ngIf="activeConversation$ | async as activeConversation">
          <div *ngIf="!(messages$ | async)?.length">
            <div class="message skeleton w-10rem" *ngFor="let i of messageLoader; let index = index"
                 [class.mine]="index % 2">
              <p-skeleton height="100%" width="100%"></p-skeleton>
            </div>
          </div>
          <div *ngIf="(messages$|async)?.length" #messagesContainer>
            <span class="w-full flex justify-content-center align-items-center" *ngIf="canLoadMore">
              <img src="/assets/icon/arrow-down.svg" alt="" class="rotate-180 cursor" (click)="loadMore()">
            </span>
            <ng-container *ngIf="loadingMore">
              <div class="message skeleton w-10rem" *ngFor="let i of messageLoader; let index = index"
                   [class.mine]="index % 2">
                <p-skeleton height="100%" width="100%"></p-skeleton>
              </div>
            </ng-container>

            <div *ngFor="let msg of (messages$ | async)" class="msg-ctn">
              <div class="message"
                   [class.mine]="msg.sender.id === user.id"
                   [innerHTML]="!!msg.content ? (msg.content | safeHtml) : (msg.files.length > 0 ? ('joinnedFile'| translate) : '')"></div>
              <div *ngFor="let file of msg.files" class="files" [ngClass]="{'mine': msg.sender.id === user.id}">
                <div class="file pointer">
                  <ion-icon name="document-text-outline"></ion-icon>
                  <span class="flex align-items-center pl-1" (click)="downloadFile(file)">{{ file.name }}</span>
                </div>
              </div>
              <div class="info" [ngClass]="{'mine': msg.sender.id === user.id}">
                <div class="last-hr">
                  {{ msg.created_at | localizedDate:'d MMMM YYYY HH:mm' }}
                </div>
                <div class="senderName">
                  {{msg.sender.firstName}}
                  <!--                  <ng-container *ngIf="msg.sender.id !== user.id">-->
                  <!--                    ({{ 'messaging.team' | translate}} {{'messaging.title.' + activeConversation.teamTag | translate}})-->
                  <!--                  </ng-container>-->
                </div>
              </div>
            </div>
          </div>
        </div>

      </ng-container>


      <div class="no-messages"
           *ngIf="!newConversation && !(conversations$ | async)?.length && !loading">
        <img class="no-msg-img" alt="" width="150px" src="/assets/img/no_message.svg">
        <span class="title">{{ 'messaging.noConversationStarted' | translate }}</span>
<!--        <app-ico-btn [text]="'messaging.startAConversation' | translate" (click)="addNewConversation()"></app-ico-btn>-->
      </div>

      <div class="message-input-container"
           *ngIf="((activeConversation$ | async) && !(activeConversation$ | async)?.isEnded) || newConversation">
        <div *ngIf="files.length > 0" class="uploaded-files">
          <span class="title">{{ 'messaging.attachments' | translate }}</span>
          <div class="file" *ngFor="let file of files; let i = index">
            {{ file.name }}
            <ion-icon class="ml-2 pointer" width="20px" name="trash-outline" (click)="deleteFile(i)"></ion-icon>
          </div>
        </div>

        <p-editor class="input-search w-full mb-3"
                  (onTextChange)="contentChange($event)"
                  (onInit)="initEditor($event)"
                  (keyup)="enterKeyUp($event)"
                  placeholder="{{ 'messaging.writeMessageOrAttach' | translate }}">
          <ng-template pTemplate="header"></ng-template>
        </p-editor>

        <!--        <quill-editor class="input-search w-full mb-3"></quill-editor>-->


        <div class="bottom">
          <div class="left-icons">
            <div class="pointer" (click)="triggerFileInput()">
              <div class="flex align-items-center">
                <img class="icon cursor" alt="" width="30px" src="/assets/icon/document-attach-outline.svg">
                <span class="small-txt">{{ 'maxSize' | translate : {size: '10MB'} }}</span>
              </div>
              <!--              <ion-icon color="#718096" size="30"  name="document-attach-outline"></ion-icon>-->
              <p-fileUpload #fileupload (onImageError)="handleError($event)" class="hide-default-files"
                            [maxFileSize]="10000000" [fileLimit]="3"
                            #fileInput [class.hide-picture]="true" (onSelect)="addFile($event)" [customUpload]="true"
                            [multiple]="true" [showCancelButton]="false" [showUploadButton]="false"
                            accept="{{acceptedFiles}}">
              </p-fileUpload>
            </div>
            <!--            <div class="pointer" (click)="triggerFileInput()">-->
            <!--              <img class="ml-3" alt="" width="30px" src="../../../../../../assets/icon/file-link.svg">-->
            <!--              <p-fileUpload class="hide-default-files" #fileInput [class.hide-picture]="true"-->
            <!--                            (onSelect)="addFile($event)" [customUpload]="true" [multiple]="true"-->
            <!--                            [showCancelButton]="false" [showUploadButton]="false" accept="{{acceptedFiles}}">-->
            <!--              </p-fileUpload>-->
            <!--            </div>-->
          </div>
          <!--          Disabled : {{ (newMessageContent.length > 0)}}-->
          <!--          <br>-->

          <app-ico-btn [ngxTippy]="'ShiftEnterSave' | translate" [rId]="'sendMsg'" (click)="sendMessage()"
                       [disabled]="!(newMessageContent.length > 0) && !files.length" class="w-10rem"
                       [text]="'messaging.send' | translate"></app-ico-btn>
        </div>
      </div>
    </div>
  </div>
</div>
