import {EResearchRoutes} from "@models/dist";

interface IResearchDirectory {
  label: string
  items: any[]
}


const directory: IResearchDirectory[] = [
  {
    label: 'menu.medias.home',
    items: [
      {
        label: 'menu.medias.all',
        icon: 'home',
        routerLink: ['/medias/' + 'dashboard'],
      },
      {
        label: 'menu.medias.articles',
        icon: 'document-outline',
        routerLink: ['/medias/' + 'articles'],
      },
      {
        label: 'menu.medias.lives',
        icon: 'document-outline',
        routerLink: ['/medias/' + 'lives'],
      },
      {
        label: 'menu.medias.videos',
        icon: 'document-outline',
        routerLink: ['/medias/' + 'videos'],
      },
      {
        label: 'menu.medias.podcast',
        icon: 'document-outline',
        routerLink: ['/medias/' + 'podcast'],
      },
    ],
  }
];


export default directory;
