import {TwoFaService} from '@shared/services/2fa.service';
import {NavService} from '@shared/services/navigation.service';
import {ReactiveLoaderService} from '@shared/services/reactive-loader.service';
import {ToastService} from '@shared/services/toast.service';
import {TokenService} from '@shared/services/token-service';
import {UserService} from '@shared/services/user-service';
import {UtilsService} from '@shared/services/utils.service';
import {ConfirmationService} from 'primeng/api';
import {CustodyService} from "@shared/services/custody.service";
import {TransactionService} from "@shared/services/transaction.service";
import {LangBusService} from "@shared/services/langBus.service";
import {CurrencyService} from "@shared/services/currency.service";
import {ComplianceService} from "@shared/services/compliance.service";
import {AirdropGateway} from "@shared/stores/airdrop/airdrop.gateway";
import {FireworksService} from "@shared/services/fireworks.service";

export {
  TwoFaService,
  NavService,
  ReactiveLoaderService,
  TokenService,
  UserService,
  UtilsService,
  ConfirmationService,
  CustodyService,
  TransactionService,
  LangBusService,
  CurrencyService,
  ToastService,
  ComplianceService,
  AirdropGateway,
  FireworksService
};

export const SERVICES = [
  TwoFaService,
  NavService,
  ReactiveLoaderService,
  TokenService,
  UserService,
  UtilsService,
  ConfirmationService,
  CustodyService,
  TransactionService,
  LangBusService,
  CurrencyService,
  ToastService,
  ComplianceService,
  AirdropGateway,
  FireworksService
];
