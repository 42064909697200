<div class="price-ico-card w-full" [class.end]="!item?.current && item?.isFilled" id="ico-card-{{index}}">
  <div class="flex justify-content-between align-items-center w-full">
    <span class="small-txt">{{ item?.name! | PhaseName }}</span>
    <app-ico-info [text]="'ico.tooltip.text' | translate"></app-ico-info>
  </div>
  <div class="flex align-items-center mb-4">
    <span class="ico-h6-price">{{item?.price | customNumber:'7':'5':'€'}}</span> <span class="xmall-txt ml-1">/ {{
    'Common.summittoken' | translate }}</span>
  </div>
  <div class="info">
    <span class="cliff">
      {{ 'ico.invest.minInvest' | translate }} {{item?.minInvestPerUser | customNumber: '': '': '€'}}
    </span>
    <span class="cliff">
      {{ 'ico.invest.cliff' | translate }} {{item?.cliff}} {{ 'ico.invest.month' | translate }}
    </span>
    <span class="flex w-full justify-content-between align-items-center">
      <span class="cliff w-full flex justify-content-between align-items-center">
        {{ 'ico.invest.vesting' | translate }} {{item?.vesting}} {{ 'ico.invest.month' | translate }}
      </span>
    </span>
    <div class="progress">
      <div [style.width.%]="getPercent()" class="overlay max-w-full"></div>
      <!--      <div [style.width.%]="getPercentLocked()" class="overlay-locked p-skeleton max-w-full"></div>-->

    </div>
    <div class="count">
      {{ currentAmountSMT | NumberPipe }} {{currentAmountSMTNumber > 0 ? ('of' | translate) : ''}} Summit
      Tokens {{ 'ico.invest.raised' | translate}} {{ 'on' | translate}}
      {{ targetAmountSMT | NumberPipe }} {{ 'sell' | translate}}
      <!--      <ng-container *ngIf="!!lockedAmount">-->
      <!--        (dont {{ (lockedAmount) | customNumber: '0': '0' :'€' }} en attente de confirmation)-->
      <!--      </ng-container>-->

    </div>
    <ng-container *ngIf="buying">
      <app-ico-btn *ngIf="item?.current && !item?.isFilled && !item?.isPrivate" [disabled]="!canBuy()"
                   [routerLink]="!canBuy() ?'./' : './../invest'"
                   [text]="canBuyText()"></app-ico-btn>
      <app-ico-btn *ngIf="item?.isPrivate && user?.privateSale && item?.current && !item?.isFilled"
                   routerLink="./../invest" text="ico.invest.privateSale"></app-ico-btn>
      <app-ico-btn *ngIf="item?.current && item?.isFilled" [disabled]="true" text="ico.invest.full">
      </app-ico-btn>
      <app-ico-btn *ngIf="!item?.current && !item?.isFilled" [disabled]="true"
                   text="ico.invest.comingSoon"></app-ico-btn>
      <app-ico-btn *ngIf="!item?.current && item?.isFilled" [disabled]="true" text="ico.invest.done"></app-ico-btn>
    </ng-container>
    <!--          <app-ico-btn [disabled]="!canBuy" [text]="buyText"></app-ico-btn>-->


  </div>
</div>
