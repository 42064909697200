<div class="errorDisplay">
  <!--close modal button -->
  <button
    class="close"
    icon="pi pi-times"
    pButton
    (click)="close()"
  ></button>
  <div class="modal-header">
    <div [innerHTML]="'mining.modal.title' | translate" class="title text-center">

    </div>

  </div>
  <div class="modal-body text-center flex flex-column aligns-items-center justify-content-center">
    <p class="description">
      {{ 'cgu.refuse.content' | translate}}
      <a class="link" (click)="read()">{{ 'readCGU'| translate}}</a>
    </p>
    <div class="actions">
      <app-ico-btn class="" (click)="accept()" [text]="'cguAccept' | translate"></app-ico-btn>
      <app-ico-btn class="" [outline]="true" (click)="refuse()" [text]="'cgu.refuse.close' | translate"></app-ico-btn>
    </div>
    <span class="simple-link" (click)="close()">{{ 'seeLater'| translate}}</span>
  </div>
</div>
