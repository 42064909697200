import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
// import {CountryISO, PhoneNumberFormat, SearchCountryField} from 'ngx-intl-tel-input';
import {MiscHelpers} from "@shared/helpers/misc.helpers";
import {ReactiveLoaderService, ToastService, UserService} from "@shared/services";
import {ETwilioStatus, IPhoneNumber} from "@models/dist/models/auth";
import {EAccountType, EToastSeverity} from "@models/dist";
import {TranslateService} from "@ngx-translate/core";
import {CountryISO, PhoneNumberFormat, SearchCountryField} from "ngx-intl-tel-input-gg";
import {reactiveButtonState} from "@shared/services/reactive-loader.service";
import {parsePhoneNumber} from "libphonenumber-js";

@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss']
})
export class PhoneNumberComponent implements OnInit, OnDestroy {
  @Input()
  email = '';

  @Input()
  displayTitle = true;


  @Input()
  accountType?: EAccountType | null;

  @Output()
  formChangeEvent = new EventEmitter<IPhoneNumber>();

  isFirstSend = true;

  timeLeft = '';
  remainTime = 60000;
  countdownInterval: any;
  codeSent = false;

  otpCode = '';
  codeLength = 6;

  currentPhone: any;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.France, CountryISO.Switzerland];

  @ViewChild('tel') tel?: ElementRef;
  timeOut?: any;

  @Input() noFocus = false;

  @HostListener('document:keyup', ['$event'])
  enter(event) {
    if (event.code === 'Enter' && this.isValidNumber()) {
      this.resendCode();
    }
  }


  constructor(
    private toastService: ToastService,
    private translateService: TranslateService,
    private userService: UserService,
    private reactiveLoaderService: ReactiveLoaderService) {
  }

  ngOnInit(): void {
    console.log(this.accountType);
    // if (!this.noFocus) {
      this.timeOut = setTimeout(() => {
        document.getElementById('phone')?.focus()
      }, 100)
    // }
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeOut)
  }

  async resendCode() {
    if (!this.isValidNumber()) {
      return;
    }
    if (!this.currentPhone) {
      this.toastService.addToast(
        EToastSeverity.error,
        'phone.toast.error.info',
        'phone.toast.error.noTel'
      )
      return;
    }
    this.codeSent = false;
    await this.getPhoneOtp(this.currentPhone.e164Number);
    await this.setCountDown();
  }

  isValidNumber(): boolean {
    if (this.currentPhone) {
      const phoneNumber = parsePhoneNumber(this.currentPhone?.e164Number, this.currentPhone?.countryCode)
      if (phoneNumber) {
        return phoneNumber.isValid()
      }
    }
    return false;
  }

  telChange(event: any) {
    if (!this.currentPhone) {
      this.isFirstSend = true;
      this.resetCheck();
    }
  }

  onOtpChanged(event: any) {
    this.otpCode = event;
  }

  getLabel(): string {
    return this.isFirstSend ? 'phone.receiveCode' : 'phone.codeNotReceived';
  }

  async onCodeComplete() {
    const phoneNumber = this.currentPhone.e164Number;
    if (!phoneNumber || !this.otpCode) {
      return;
    }
    // this.toastService.presentSpinner();
    try {
      const payload: IPhoneNumber & {accountType: EAccountType | null | undefined} = {
        phoneNumber: phoneNumber,
        phoneOtp: this.otpCode,
        email: this.email,
        accountType: this.accountType,
      }
      const res = await this.userService.verifyPhoneOtp(payload) as any;

      if (res?.valid && res?.status === ETwilioStatus.approved) {
        const values: IPhoneNumber = {
          phoneNumber: res?.to,
          phoneOtp: this.otpCode
        }
        this.toastService.addToast(EToastSeverity.success,
          "phone.toast.otpSuccessTitle", "phone.toast.otpSuccessBody");
        this.formChangeEvent.emit(values);
      }
    } catch (e: any) {
      console.error(e);
      let errorMessage = this.translateService.instant("phone.toast.otpErrorOTPInvalidMessage",
        {code: this.otpCode});
      if (e?.error?.message?.includes("is already used")) {
        errorMessage = this.translateService.instant("phone.toast.otpErrorPhoneAlreadyUsedMessage",
          {number: phoneNumber});
      }
      this.otpCode = '';
      this.toastService.addToast(EToastSeverity.error,
        "phone.toast.otpErrorTitle", errorMessage);
      document.getElementById('phone')?.focus()
    }
    // this.toastService.dismissSpinner();
  }

  private resetCheck() {
    // this.codeSent = false;
    this.timeLeft = '';
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
  }

  private async getPhoneOtp(phone: string) {
    if (!phone || this.codeSent) {
      return;
    }
    // this.toastService.presentSpinner();

    this.reactiveLoaderService.setReactiveButtonState('code-btn', reactiveButtonState.loading)

    if(phone === '+33100000000'){
      const values: IPhoneNumber = {
        phoneNumber: phone,
        phoneOtp: "000000",
      }
      this.formChangeEvent.emit(values);
    }

    try {
      const lang = this.translateService.currentLang;
      const res = await this.userService.getPhoneOtp(phone, lang);
      const message = this.translateService.instant("phone.toast.codeSentSuccessBody",
        {phoneNumber: phone})
      this.toastService.addToast(EToastSeverity.success,
        "phone.toast.codeSentSuccessTitle", message);
      this.codeSent = true;
      this.reactiveLoaderService.setReactiveButtonState('code-btn', reactiveButtonState.success)
      //FOCUS FIRST LETTER
    } catch (e) {
      console.error(e);
      this.toastService.addToast(EToastSeverity.error,
        "phone.toast.codeSentErrorTitle", e);
      this.resetCheck();
      this.reactiveLoaderService.setReactiveButtonState('code-btn', reactiveButtonState.error)

    }

    // this.toastService.dismissSpinner();
  }

  private setCountDown(): boolean {
    if (this.timeLeft) {
      return true;
    }
    let timestampDiff = MiscHelpers.clone(this.remainTime);
    const oneHourMs = 60 * 60 * 1000;
    this.timeLeft = `01:00`;
    this.countdownInterval = setInterval(() => {

      let minutes = Math.floor((timestampDiff % oneHourMs) / (1000 * 60)).toString();
      let seconds = Math.floor((timestampDiff % (1000 * 60)) / 1000).toString();

      minutes = minutes.length === 1 ? '0' + minutes : minutes;
      seconds = seconds.length === 1 ? '0' + seconds : seconds;

      this.timeLeft = `${minutes}:${seconds}`;
      // this.cdr.detectChanges();
      timestampDiff = timestampDiff - 1000;
      // If the count down is over, recheck the period
      if (timestampDiff <= 0) {
        this.resetCheck();
      }
    }, 1000);

    return false;
  }


}

