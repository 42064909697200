import {Component, OnDestroy, OnInit} from '@angular/core';
import {Select, Store} from "@ngxs/store";
import {GetCoinsList, InitResearch} from "@research/stores/research.actions";
import {distinctUntilChanged, filter, Observable, Subject, takeUntil} from "rxjs";
import {ResearchState} from "@research/stores/research.state";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {ResearchService} from "@research/shared/services/research.service";
import {WelcomeModalComponent} from "@research/shared/components/welcome-modal/welcome-modal.component";
import {IUser} from "@models/dist";

@Component({
  selector: 'app-research',
  templateUrl: './research.component.html',
  styleUrls: ['./research.component.scss']
})
export class ResearchComponent implements OnInit, OnDestroy {

  @Select(ResearchState.getResearchUser) user$?: Observable<any>;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  public user?: any;
  public ref?: DynamicDialogRef | null;

  constructor(
    private store: Store,
    private dialogService: DialogService,
    public researchService: ResearchService
  ) {

  }

  ngOnInit() {
    this.user$?.pipe(filter((user) => !!user), takeUntil(this.destroy$)).subscribe((user) => this.setUser(user))
    this.store.dispatch(new InitResearch());
    // this.store.dispatch(new GetCoinsList());
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();

  }

  private setUser(user) {
    if (user.isFirstAccess && user.user.onboarding) {
      if (!this.ref) {
        this.ref = this.dialogService.open(WelcomeModalComponent, {
          showHeader: false,
          dismissableMask: true,
          data: {},
          styleClass: 'p-0',
          contentStyle: {
            'padding': '0px !important',
            'width': '100%',
            'min-width': '850px',
            'max-width': '850px',
            'border-radius': '20px'
          },
          baseZIndex: 10000,
        });

        this.ref.onClose.subscribe(async (data) => {
          this.ref = null;
        })
      }
    }
    this.user = user;
  }
}
