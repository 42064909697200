<div class="ico-wallet-modal">
    <!--close modal button -->
    <button class="close" icon="pi pi-times" pButton (click)="close()"></button>
    <div class="modal-header">
        <div [innerHTML]="title | translate" class="title text-center">

        </div>

    </div>
    <div class="modal-body flex flex-column aligns-items-start justify-content-center">
        <div [innerHTML]="'ico.dashboard.wallet.emptySubtitle' | translate" class="mb-3"></div>
        <!-- <div class="msg mb-3">
            <span [innerHTML]="'ico.dashboard.wallet.emptySubtitle2_1' | translate"></span>
            <span>
                <a routerLink="ico/dashboard" (click)="close()">{{ 'ico.dashboard.wallet.emptySubtitle2_2' | translate }}</a>
            </span>
            <span [innerHTML]="'ico.dashboard.wallet.emptySubtitle2_3' | translate"></span>
        </div> -->
        <div [innerHTML]="'ico.dashboard.wallet.emptySubtitle3' | translate"></div>
    </div>
    <div class="flex flex-row align-items-center justify-content-between gap-5 mb-3">
        <app-ico-btn [outline]="true" (click)="close()" class="w-full mt-5"
            [text]="'ico.dashboard.wallet.fillLater' | translate"></app-ico-btn>
        <app-ico-btn [outline]="false" routerLink="ico/dashboard" (click)="close()" class="w-full mt-5"
            [text]="'ico.dashboard.wallet.fill' | translate"></app-ico-btn>
    </div>
</div>