import {
  EYieldType,
  IBtcHistory, IInvestedData, IMiningChartGainsHistory,
  IMiningFile,
  IMiningGainsHistory,
  IMiningParc, IMiningParcComposition, IMiningParcMachine,
  IMiningUser, IMiningWallet, IMonthlyPaiement,
  IOverview, IYield
} from "@mining/store/mining.state";
import {Observable} from "rxjs";
import {ICryptoNetwork, ITransaction} from "@models/dist";
import {ICountry} from "@shared/pipes/country.pipe";
import {EMiningChartFilter, IMiningGainTotal, IMiningResponseGainTotal} from "@mining/store/gains/gains.state";

export interface IMiningParcResponse {
  miners: {
    on: number,
    off: number
  },
  power: {
    on: number,
    off: number
  },
  compositions: IMiningParcComposition[],
  machines: IMiningParcMachine[],
  country: string[]
}


export abstract class MiningGateway {
  abstract getMiningUser(): Observable<IMiningUser>;

  abstract getOverview(): Observable<IOverview>;

  abstract getMiningParc(): Observable<IMiningParcResponse>;

  abstract getMiningGainHistory(month:number, year:number): Observable<IMiningGainsHistory[]>;

  abstract getMiningChartGainHistory(filter:EMiningChartFilter): Observable<IMiningChartGainsHistory[]>;

  abstract getMiningTotalGainHistory(month:number, year:number): Observable<IMiningResponseGainTotal>;

  abstract getBtcHistory(): Observable<IBtcHistory>;

  abstract getMiningTransactions(year:number): Observable<ITransaction[]>;

  abstract getMiningFile(): Observable<IMiningFile[]>;

  abstract getMiningCurrency(): Observable<ICryptoNetwork[]>;

  abstract getMiningWallet(): Observable<IMiningWallet[]>;

  abstract getMiningInvestedData(year?:number): Observable<IInvestedData>;

  abstract getMiningInvested(): Observable<number>;

  abstract getMonthlyPayment(): Observable<IMonthlyPaiement>;

  abstract getMiningCryptocurrency(): Observable<ICryptoNetwork[]>;

  abstract getPerformance(type?: EYieldType): Observable<IYield>
}
