import {Component, OnDestroy, OnInit} from '@angular/core';
import {ToastService, TwoFaSignInRedirect} from "@shared/services/toast.service";
import {TwoFaGenericModalComponent} from "@profile/components";
import {ETwoFaType} from "@models/dist";
import {LoadUserAfterTwoFa} from "@shared/stores/auth/auth.actions";
import {DialogService} from "primeng/dynamicdialog";
import {map, Subscription} from "rxjs";
import {Store} from "@ngxs/store";

@Component({
  selector: 'app-twofa-trigger',
  templateUrl: './twofa-trigger.component.html',
  styleUrls: ['./twofa-trigger.component.scss']
})
export class TwofaTriggerComponent implements OnInit, OnDestroy {
  requestTwoFaSignIn: Subscription;
  twoFaModal: any;

  constructor(
    private toastService: ToastService,
    private dialogService: DialogService,
    private store: Store,
  ) {
    this.requestTwoFaSignIn = this.toastService.twoFaSignInRequest$.pipe(map(data => data)).subscribe((redirect: TwoFaSignInRedirect) => {
      if (!this.twoFaModal) {

        this.twoFaModal = this.dialogService.open(TwoFaGenericModalComponent, {
          showHeader: false,
          contentStyle: {'max-width': '550px', 'border-radius': '20px'},
          baseZIndex: 10000,
          data: {type: ETwoFaType.SIGNIN}
        });

        this.twoFaModal.onClose.subscribe((res: any) => {
            if (!res) {
              return;
            }
            if (redirect === TwoFaSignInRedirect.login) {
              this.store.dispatch(new LoadUserAfterTwoFa());
            } else return;
          }
        );
      }
    });
  }

  ngOnInit(): void {
    this.toastService.requestTwoFaSignIn(TwoFaSignInRedirect.login);
  }

  ngOnDestroy(): void {
    this.requestTwoFaSignIn.unsubscribe();
  }

}
