import {Component} from '@angular/core';
import {ELang} from "@models/dist";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-migration',
  templateUrl: './migration.component.html',
  styleUrls: ['./migration.component.scss']
})
export class MigrationComponent {

  protected readonly ELang = ELang;
  public langSelected = ELang.FR;
  public
  modalLang: boolean = false;


  constructor(
    private transalte: TranslateService,
  ) {
  }
  toggleLanguageModal() {
    this.langSelected = this.transalte.currentLang as ELang;
    this.modalLang = !this.modalLang
  }
}
