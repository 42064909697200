import {Component} from '@angular/core';
import {LayoutComponent} from "@shared/components";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  constructor(public appMain: LayoutComponent) {
  }

}
