import {EIcoRoutes} from "@models/dist";

interface IIcoDirectory {
  label: string
  items: any[]
}

const directorySummitConst: IIcoDirectory[] = [
  {
    label: 'menu.summit.home',
    items: [
      {
        label: 'menu.summit.repport',
        icon: 'document-text-outline',
        routerLink: ['/summit/reports'],
      },
    ]
  }
]

export default directorySummitConst;
