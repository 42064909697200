<div class="kyc-light">
  <app-ico-top [title]="'KYC.page.title' | translate : {title : 'KYC'}"></app-ico-top>

  <ng-container *ngIf="!loading else Loader">
    <div class="ico-card mt-3 relative" *ngIf="isKYCInReview()">
      In review
    </div>
    <div class="ico-card mt-3 relative" *ngIf="isKYCApproved()">
      Approved
    </div>
    <div class="ico-card mt-3 relative" *ngIf="!isKYCApproved() && !isKYCInReview() && !!form && (user$ | async) as user" [formGroup]="form"
      [class.noInit]="currentStep > 0">
      <div class="enhanced-loader" *ngIf="enhanceKycLoading && !isToReview">
        <img class="loader-img" src="/assets/loader/spincolored.svg" />
        <span>{{ 'KYC.light.fourSlide.loadingText' | translate }}</span>
      </div>
      <!--            <div class="return" (click)="back()" *ngIf="currentStep > 3 && !needEnhancedKyc">-->
      <!--                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="18" viewBox="0 0 11 18" fill="none">-->
      <!--                    <path d="M8.75 2.25L2 9L8.75 15.75" stroke="#718096" stroke-width="2" stroke-miterlimit="10"-->
      <!--                          stroke-linecap="square"/>-->
      <!--                </svg>-->
      <!--            </div>-->
      <ng-container *ngIf="isToReview">
        <div class="steps">
          <div class="step" *ngFor="let step of stepsToReview">
            <div class="step-progress" [style.width.%]="currentStep > step.step ? 100 : 0"></div>
          </div>
        </div>
      </ng-container>




      <!-- <ng-container *ngIf="!isToReview">
      <p-steps *ngIf="currentStep >0" [(activeIndex)]="currentStep" [model]="withdrawalSteps" [readonly]="true" class="kyc-w-steps">
        <ng-template let-item let-index="index" pTemplate="item">
          <div class="kyc-w-step-item" [ngClass]="{ 
                   'completed': index < currentStep, 
                   'active': index === currentStep,
                   'upcoming': index > currentStep 
                 }">
            <span *ngIf="index < currentStep" class="pi pi-check"></span>
            <span *ngIf="index === currentStep">{{ index + 1 }}</span>
            <span *ngIf="index > currentStep">{{ index + 1 }}</span>
          </div>
          <span class="kyc-w-step-label">{{ item.label }}</span>
        </ng-template>
      </p-steps>
      </ng-container> -->
      <ng-container *ngIf="!isToReview">
        <div class="steps" *ngIf="currentStep >0">
          <div class="step">
            <div class="step-progress" [style.width.%]="currentStep > 0 ? 100 : 0"></div>
          </div>
          <div class="step">
            <div class="step-progress" [style.width.%]="currentStep > 1 ? 100 : 0"></div>
          </div>
          <ng-container *ngIf="kycTypeForm === EKycTypeForm.FULL">
            <div class="step">
              <div class="step-progress" [style.width.%]="currentStep > 2 ? 100 : 0"></div>
            </div>
            <div class="step" *ngIf="needEnhancedKyc">
              <div class="step-progress" [style.width.%]="currentStep > 3 ? 100 : 0"></div>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <div class="ctn" *ngIf="currentStep === 0">

        <div class="side" *ngIf="kycTypeForm === EKycTypeForm.LIGHT">
          <!--          <span class="gradient-title">{{ 'KYC.light.firstSlide.head' | translate}}</span>-->
          <span class="title" *ngIf="!isToReview" [innerHTML]="'KYC.light-withdrawal.firstSlide.subtitle' | translate"></span>

          <p class="desc" [innerHTML]="'KYC.light-withdrawal.firstSlide.ctn' | translate">

          </p>

          <app-ico-btn rId="next" (click)="next()" [text]="'KYC.light-withdrawal.firstSlide.action' | translate"></app-ico-btn>
        </div>
        <div class="side" *ngIf="kycTypeForm === EKycTypeForm.FULL">
          <!--          <span class="gradient-title">{{ 'KYC.light.firstSlide.head' | translate}}</span>-->
          <span class="title" *ngIf="!isToReview" [innerHTML]="'KYC.light-withdrawal.firstSlide.subtitle' | translate">
          </span>

          <p class="desc" [innerHTML]="'KYC.light-withdrawal.firstSlide.ctn' | translate">

          </p>

          <app-ico-btn rId="next" (click)="next()" [text]="'KYC.light-withdrawal.firstSlide.action' | translate"></app-ico-btn>
        </div>
        <div class="side">
          <img alt="" src="assets/forms/kyc/confirmed.png" />
        </div>
      </div>

      <ng-container *ngIf="currentStep > 0">
        <div class="userSettings" *ngIf="currentStep === 1">
          <div class="form">
            <span class="gradient-title" *ngIf="!isToReview">{{ 'KYC.light.secondSlide.head'| translate }}</span>
            <span class="title">{{ 'KYC.light.secondSlide.subtitle'| translate }}</span>
            <div class="form-group">
              <div class="group">
                <label class="label">{{ 'gender' | translate }}</label>
                <p-dropdown formControlName="gender" styleClass="gender-dropdown" [options]="genders" optionLabel="name"
                  [emptyFilterMessage]="'Common.notFound' | translate" [placeholder]="'selectGender' | translate">
                  <ng-template pTemplate="selectedItem">
                    <span *ngIf="!!form?.controls?.gender?.value">
                      {{ form.controls.gender.value.name | TranslateObject }}
                    </span>
                  </ng-template>
                  <ng-template let-item pTemplate="item">
                    {{ item.name | TranslateObject }}
                  </ng-template>
                </p-dropdown>
              </div>
              <div class="group empty"></div>
            </div>
            <div class="form-group">
              <div class="group">
                <label class="label">{{ 'firstName' | translate }}</label>
                <input class="input" placeholder="{{ 'firstName' | translate }}" type="text"
                  formControlName="firstName">
              </div>
              <div class="group">
                <label class="label">{{ 'lastName' | translate }}</label>
                <input class="input " placeholder="{{ 'lastName' | translate }}" type="text" formControlName="lastName">
              </div>
            </div>

            <div class="form-group" [class.phone-group]="!form.controls.phoneNumber.value">
              <div class="group">
                <label class="label">{{ 'email' | translate }}</label>
                <input class="input disabled" placeholder="{{ 'email' | translate }}" type="text"
                  formControlName="email">
              </div>
              <div class="group ">
                <label class="label">{{ 'Common.phonenumber' | translate }}</label>
                <input class="input tel" (click)="openPhoneModal()" placeholder="{{ 'Common.phonenumber' | translate }}" type="text"
                  formControlName="phoneNumber" [readonly]="true" >
                <ng-container *ngIf="!form.controls.phoneNumber.value">
                  <a (click)="openPhoneModal()" class="link">
                    {{ 'Common.setPhoneNumber' | translate }}
                  </a>
                </ng-container>
              </div>
            </div>

            <div class="form-group">
              <div class="group">
                <label class="label">{{ 'birthdaytxt' | translate }}</label>
                <!--                <ng-container *ngIf="birthdayMask$ | async as birthdayMask">-->
                <!--                </ng-container>-->
                <p-calendar dateMask [minDate]="minDate" [maxDate]="maxDate" [dataType]="'string'"
                  placeholder="{{ 'birthdaytxt' | translate }}" styleClass="birthday-input"
                  formControlName="birthday"></p-calendar>
              </div>
              <div class="group postalGroup">
                <label class="label">{{ 'addressPostalTitle' | translate }}</label>
                <ng-container *ngIf="!manualEnterAddress else manualEnterAddressCtn">
                  <input class="input pka-input " placeholder="{{ 'addressPostalTitle' | translate }}" type="text"
                    formControlName="postalAddress" id="placekit">
                </ng-container>

                <ng-template #manualEnterAddressCtn>
                  <div class="flex flex-column gap-2">
                    <input formControlName="postalAddress" placeholder="{{ 'addressPostal' | translate }}" type="text"
                      class="input">
                    <input formControlName="postalAddressLigne2" placeholder="{{ 'addressPostal2' | translate }}"
                      type="text" class="input">
                    <div class=" postalSubGroup">
                      <input formControlName="postalCode" placeholder="{{ 'codePostal' | translate }}" type="text"
                        class="input">
                      <input formControlName="city" placeholder="{{ 'cityPostal' | translate }}" type="text"
                        class="input">
                    </div>
                  </div>

                </ng-template>
                <div class=" ico-link ligne-text " *ngIf="!manualEnterAddress">
                  <span class="xmall-txt">{{ 'noPostalAddres' | translate }}</span>
                  <span (click)="mannualyEnterAddr()" class="xmall-txt font-bold cursor">{{ 'manualyEnterPostalAddres' |
                    translate }}</span>
                </div>
                <div class=" ico-link ligne-text " *ngIf="manualEnterAddress">
                  <!--                                    <span class="xmall-txt">{{ 'findPostalAddres' | translate}}</span>-->
                  <span (click)="mannualyEnterAddr()" class="xmall-txt font-bold cursor">{{ 'autoEnterPostalAddres' |
                    translate }}</span>
                </div>
              </div>
            </div>

            <div class="form-group reserve-mobile">

              <div class="group">
                <label class="label">{{ 'KYC.light.thirdSlide.nationality' | translate }}</label>


                <div class="select-ctn" *ngIf="(countries$ | async ) as countries">
                  <p-dropdown formControlName="nationality" [options]="countries" optionLabel="name" [filter]="true"
                    filterBy="name" [emptyFilterMessage]="'Common.notFound' | translate" [showClear]="true"
                    (onClear)="clearNationalityValue($event)" [placeholder]="'selectCountry' | translate">
                    <ng-template pTemplate="filter" let-options="options">
                      <div class="search-dropdown">
                        <div class="input-ctn" (click)="$event.stopPropagation()">
                          <span class=""><i class="pi pi-search"></i></span>
                          <input class="w-full" type="text" pInputText [placeholder]="'searchCountry' | translate"
                            formControlName="searchNationality" (keyup)="options.filter($event)">
                          <span (click)="clear(options)" *ngIf="form.controls.searchNationality.value"><img
                              src="/assets/icon/close.svg" alt=""></span>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="selectedItem">
                      <div class="country-item country-item-value" *ngIf="form.value?.nationality">
                        {{ form.value?.nationality.flag }}<span class="pl-2">{{ form.value?.nationality.name }}</span>
                      </div>
                    </ng-template>
                    <ng-template let-country pTemplate="item">
                      <div class="country-item">
                        {{ country.flag }} <span class="pl-2">{{ country.name }}</span>
                      </div>
                    </ng-template>
                  </p-dropdown>
                </div>
              </div>

              <div class="group">
                <label class="label">{{ 'KYC.light.thirdSlide.country' | translate }}</label>
                <div class="select-ctn">
                  <div class="p-dropdown cursor-normal" *ngIf="!!user.country else noCountrySet">
                    <div class="country-item country-item-value" *ngIf="form.value?.country">
                      {{ form.value?.country.flag }}<span class="pl-2">{{ form.value?.country.name }}</span>
                    </div>
                  </div>
                  <ng-template #noCountrySet>
                    <ng-container *ngIf="(countries$ | async ) as countries">


                      <p-dropdown formControlName="country" [options]="countries" optionLabel="name" [filter]="true"
                        [emptyFilterMessage]="'Common.notFound' | translate" filterBy="name"
                        (onClear)="clearCountryValue($event)" [showClear]="true"
                        [placeholder]="'selectCountry' | translate">
                        <ng-template pTemplate="filter" let-options="options">
                          <div class="search-dropdown">
                            <div class="input-ctn" (click)="$event.stopPropagation()">
                              <span class=""><i class="pi pi-search"></i></span>
                              <input class="w-full" type="text" pInputText [placeholder]="'searchCountry' | translate"
                                formControlName="searchCountry" (keyup)="options.filter($event)">
                              <span (click)="clearNationality(options)"
                                *ngIf="form.controls.searchNationality.value"><img src="/assets/icon/close.svg"
                                  alt=""></span>
                            </div>
                          </div>
                        </ng-template>
                        <ng-template pTemplate="selectedItem">
                          <div class="country-item country-item-value" *ngIf="form.value?.country">
                            {{ form.value?.country.flag }}<span class="pl-2">{{ form.value?.country.name }}</span>
                          </div>
                        </ng-template>
                        <ng-template let-country pTemplate="item">
                          <div class="country-item">
                            {{ country.flag }}<span class="pl-2">{{ country.name }}</span>
                          </div>
                        </ng-template>
                      </p-dropdown>
                    </ng-container>
                  </ng-template>
                </div>
                <div class=" ico-link ligne-text" *ngIf="!!user.country">
                  <span class="xmall-txt">{{ 'notCountryResidence' | translate }}</span>
                  <span (click)="contactModal()" class="xmall-txt font-bold cursor">{{ 'contactTeam' | translate
                    }}</span>
                </div>
              </div>


            </div>
            <div class="form-group">
              <div class="group">
                <label class="label">{{ 'KYC.light.thirdSlide.otherNationality' | translate }}</label>


                <div class="select-ctn" *ngIf="(countries$ | async ) as countries">
                  <p-dropdown formControlName="otherNationality" [options]="countries" optionLabel="name"
                    [filter]="true" filterBy="name" [emptyFilterMessage]="'Common.notFound' | translate"
                    [showClear]="true" (onClear)="clearOtherNationalityValue($event)"
                    [placeholder]="'KYC.light.thirdSlide.otherNationality' | translate">
                    <ng-template pTemplate="filter" let-options="options">
                      <div class="search-dropdown">
                        <div class="input-ctn" (click)="$event.stopPropagation()">
                          <span class=""><i class="pi pi-search"></i></span>
                          <input class="w-full" type="text" pInputText [placeholder]="'searchCountry' | translate"
                            formControlName="searchOtherNationality" (keyup)="options.filter($event)">
                          <span (click)="clearOtherNationality(options)"
                            *ngIf="form.controls.searchOtherNationality.value"><img src="/assets/icon/close.svg"
                              alt=""></span>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="selectedItem">
                      <div class="country-item country-item-value" *ngIf="form.value?.otherNationality">
                        {{ form.value?.otherNationality.flag }}<span class="pl-2">{{ form.value?.otherNationality.name
                          }}</span>
                      </div>
                    </ng-template>
                    <ng-template let-country pTemplate="item">
                      <div class="country-item">
                        {{ country.flag }} <span class="pl-2">{{ country.name }}</span>
                      </div>
                    </ng-template>
                  </p-dropdown>
                </div>
              </div>
              <div class="group empty"></div>
            </div>

            <!--            <div class="form-group">-->
            <!--              <div class="group">-->
            <!--                <label class="label">Fichier</label>-->
            <!--                <p-fileUpload mode="basic" chooseLabel="Choose" accept="image/*" (onSelect)="onUpload($event)"></p-fileUpload>-->
            <!--                {{ files | json}}-->
            <!--              </div>-->
            <!--            </div>-->

          </div>
        </div>

        <div class="userSettings" *ngIf="currentStep === 2">

          <div class="form">
            <span *ngIf="!isToReview" class="gradient-title">{{ kycTypeForm === EKycTypeForm.LIGHT ?
              ('KYC.light.onfidoSlide.headLight'| translate) : ('KYC.light.onfidoSlide.headFull'| translate) }}</span>
            <span class="title">{{ 'KYC.light.onfidoSlide.subtitle' | translate }}</span>

            <div class="mb-4">
              <p-skeleton *ngIf="loadingOnfido" class="w-full" height="30rem"></p-skeleton>

              <!--              <div id="onfido-mount"></div>-->

              <div id="sumsub-websdk-container"></div>
            </div>
          </div>
        </div>

        <div class="userSettings" *ngIf="currentStep === 3">
          <div class="form">
            <span class="gradient-title" *ngIf="!isToReview">{{ 'KYC.light.fourSlide.head'| translate }}</span>
            <span class="title">{{ 'KYC.light.fourSlide.subtitle'| translate }}</span>


            <div class="form-group">
              <div class="group">
                <label class="label">{{ 'KYC.light.fourSlide.activityLabel' | translate }}</label>
                <p-dropdown formControlName="jobsType" [options]="jobsTypeOptions" optionLabel="name" [filter]="true"
                  filterBy="name" [emptyFilterMessage]="'Common.notFound' | translate" [showClear]="true"
                  (onClear)="clearJobTypeValue($event)"
                  [placeholder]="'KYC.light.fourSlide.selectActivityLabel' | translate">
                  <ng-template pTemplate="filter" let-options="options">
                    <div class="search-dropdown">
                      <div class="input-ctn" (click)="$event.stopPropagation()">
                        <span class=""><i class="pi pi-search"></i></span>
                        <input class="w-full" type="text" pInputText
                          [placeholder]="'KYC.light.fourSlide.searchActivityLabel' | translate"
                          formControlName="searchJobsType" (keyup)="options.filter($event)">
                        <span (click)="clear(options)" *ngIf="form.controls.searchJobsType.value"><img
                            src="/assets/icon/close.svg" alt=""></span>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="selectedItem">
                    <div class="country-item country-item-value" *ngIf="form.value?.jobsType">
                      <span class="pl-2">{{ form.value?.jobsType.name }}</span>
                    </div>
                  </ng-template>
                  <ng-template let-country pTemplate="item">
                    <div class="country-item"><span class="pl-2">{{ country.name }}</span>
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>
              <div class="group">
                <label *ngIf="requiresJobDescription" class="label">{{ 'KYC.light.fourSlide.activityLabelSector' | translate }}</label>
                <p-dropdown *ngIf="requiresJobDescription" formControlName="jobsSector" [options]="jobsSectorOptions" optionLabel="name"
                  [filter]="true" filterBy="name" [emptyFilterMessage]="'Common.notFound' | translate"
                  [showClear]="true" (onClear)="clearJobSectorValue($event)"
                  [placeholder]="'KYC.light.fourSlide.selectActivityLabelSector' | translate">
                  <ng-template pTemplate="filter" let-options="options">
                    <div class="search-dropdown">
                      <div class="input-ctn" (click)="$event.stopPropagation()">
                        <span class=""><i class="pi pi-search"></i></span>
                        <input class="w-full" type="text" pInputText
                          [placeholder]="'KYC.light.fourSlide.searchActivityLabelSector' | translate"
                          formControlName="searchJobsSector" (keyup)="options.filter($event)">
                        <span (click)="clear(options)" *ngIf="form.controls.searchJobsSector.value"><img
                            src="/assets/icon/close.svg" alt=""></span>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="selectedItem">
                    <div class="country-item country-item-value" *ngIf="form.value?.jobsSector">
                      <span class="pl-2">{{ form.value?.jobsSector.name }}</span>
                    </div>
                  </ng-template>
                  <ng-template let-country pTemplate="item">
                    <div class="country-item"><span class="pl-2">{{ country.name }}</span>
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>

            <div class="form-group" *ngIf="requiresJobDescription">
              <!--              <div class="group">-->
              <!--                <label class="label">{{ 'KYC.light.fourSlide.professionLabel' | translate}}</label>-->
              <!--                <p-dropdown formControlName="jobs" [options]="jobsOptions" optionLabel="name"-->
              <!--                            [filter]="true"-->
              <!--                            filterBy="name"-->
              <!--                            [emptyFilterMessage]="'Common.notFound' | translate"-->
              <!--                            [showClear]="true"-->
              <!--                            [placeholder]="'KYC.light.fourSlide.selectProfessionLabel' | translate">-->
              <!--                  <ng-template pTemplate="filter" let-options="options">-->
              <!--                    <div class="search-dropdown">-->
              <!--                      <div class="input-ctn" (click)="$event.stopPropagation()">-->
              <!--                        <span class=""><i class="pi pi-search"></i></span>-->
              <!--                        <input class="w-full" type="text" pInputText-->
              <!--                               [placeholder]="'KYC.light.fourSlide.searchProfessionLabel' | translate"-->
              <!--                               formControlName="searchJobs" (keyup)="options.filter($event)">-->
              <!--                        <span (click)="clear(options)" *ngIf="form.controls.searchJobs.value"><img-->
              <!--                          src="/assets/icon/close.svg" alt=""></span>-->
              <!--                      </div>-->
              <!--                    </div>-->
              <!--                  </ng-template>-->
              <!--                  <ng-template pTemplate="selectedItem">-->
              <!--                    <div class="country-item country-item-value" *ngIf="form.value?.jobs">-->
              <!--                      <span class="pl-2">{{form.value?.jobs.name }}</span>-->
              <!--                    </div>-->
              <!--                  </ng-template>-->
              <!--                  <ng-template let-country pTemplate="item">-->
              <!--                    <div class="country-item"><span class="pl-2">{{country.name}}</span>-->
              <!--                    </div>-->
              <!--                  </ng-template>-->
              <!--                </p-dropdown>-->
              <!--              </div>-->
              <div class="group">
                <label class="label">{{ 'KYC.light.fourSlide.professionLabelContent' | translate }}</label>
                <input class="input " type="text"
                  [placeholder]="'KYC.light.fourSlide.professionLabelContent' | translate" formControlName="jobsLabel">
              </div>
              <div class="group">
              </div>
            </div>

            <div class="divider"></div>
            <span class="title">{{ 'KYC.light.fourSlide.fundsOriginLabel'| translate }}</span>


            <div class="form-group" #multi>
              <div class="group">
                <label class="label">{{ 'KYC.light.fourSlide.fundsOriginLabel' | translate }}</label>

                <!--                               (onChange)="selectOriginFunds($event)"-->
                <p-multiSelect [maxSelectedLabels]="10" [emptyFilterMessage]="'Common.notFound' | translate"
                  [placeholder]="'KYC.light.fourSlide.selectFundsOriginLabel' | translate"
                  [options]="originsFundsOptions" formControlName="fundsOriginType" [style.--max-width]="width"
                  optionLabel="name">
                  <ng-template let-value pTemplate="selectedItems">

                    <div class="inline-flex align-items-center gap-2" *ngFor="let option of value; let index = index">
                      <div>

                        {{ option.name }}
                        <ng-container *ngIf="option.id === 'other' && !!form?.value?.fundsOriginLabel ">
                          ({{ form?.value.fundsOriginLabel }})
                        </ng-container>
                        {{ (value.length !== originsFundsOptions.length && index !== originsFundsOptions.length - 1) ?
                        ',' : '' }}
                      </div>
                    </div>
                    <div *ngIf="!value || value.length === 0">{{ 'KYC.light.fourSlide.selectFundsOriginLabel' |
                      translate }}
                    </div>
                  </ng-template>
                  <ng-template pTemplate="filter" let-options="options">

                  </ng-template>

                </p-multiSelect>

              </div>
              <div class="group" [class.empty]="!isOtherFundsOrigin()">
                <ng-container *ngIf="isOtherFundsOrigin()">
                  <label class="label">{{ 'KYC.light.fourSlide.fundsOriginLabelOther' | translate }}</label>
                  <input class="input" type="text"
                    [placeholder]="'KYC.light.fourSlide.fundsOriginLabelOther' | translate"
                    formControlName="fundsOriginLabel">
                </ng-container>
              </div>
            </div>

            <div class="form-group">

              <div class="group">
                <label class="label">{{ 'KYC.light.fourSlide.countryFoundOrigin' | translate }}</label>
                <div class="select-ctn" *ngIf="(countries$ | async ) as countries">
                  <p-dropdown formControlName="countryFundsOriginType" [options]="countries" optionLabel="name"
                    [filter]="true" filterBy="name" [emptyFilterMessage]="'Common.notFound' | translate"
                    [showClear]="true" (onClear)="clearCountryFundsOriginTypeValue($event)"
                    [placeholder]="'KYC.light.fourSlide.selectCountryFoundOrigin' | translate">
                    <ng-template pTemplate="filter" let-options="options">
                      <div class="search-dropdown">
                        <div class="input-ctn" (click)="$event.stopPropagation()">
                          <span class=""><i class="pi pi-search"></i></span>
                          <input class="w-full" type="text" pInputText [placeholder]="'searchCountry' | translate"
                            formControlName="searchCountryFundsOriginType" (keyup)="options.filter($event)">
                          <span (click)="clear(options)" *ngIf="form.controls.searchCountryFundsOriginType.value"><img
                              src="/assets/icon/close.svg" alt=""></span>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="selectedItem">
                      <div class="country-item country-item-value" *ngIf="form.value?.countryFundsOriginType">
                        {{ form.value?.countryFundsOriginType.flag }}<span class="pl-2">{{
                          form.value?.countryFundsOriginType.name }}</span>
                      </div>
                    </ng-template>
                    <ng-template let-country pTemplate="item">
                      <div class="country-item">
                        {{ country.flag }} <span class="pl-2">{{ country.name }}</span>
                      </div>
                    </ng-template>
                  </p-dropdown>
                </div>
              </div>
              <div class="group">
                <label class="label">{{ 'KYC.light.fourSlide.countryFoundResidence' | translate }}</label>
                <div class="select-ctn" *ngIf="(countries$ | async ) as countries">
                  <p-dropdown formControlName="countryFiscalFunds" [options]="countries" optionLabel="name"
                    [filter]="true" filterBy="name" [emptyFilterMessage]="'Common.notFound' | translate"
                    [showClear]="true" (onClear)="clearFiscalFundsValue($event)"
                    [placeholder]="'KYC.light.fourSlide.selectCountryFoundResidence' | translate">
                    <ng-template pTemplate="filter" let-options="options">
                      <div class="search-dropdown">
                        <div class="input-ctn" (click)="$event.stopPropagation()">
                          <span class=""><i class="pi pi-search"></i></span>
                          <input class="w-full" type="text" pInputText [placeholder]="'searchCountry' | translate"
                            formControlName="searchCountryFiscalFunds" (keyup)="options.filter($event)">
                          <span (click)="clear(options)" *ngIf="form.controls.searchCountryFiscalFunds.value"><img
                              src="/assets/icon/close.svg" alt=""></span>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="selectedItem">
                      <div class="country-item country-item-value" *ngIf="form.value?.countryFiscalFunds">
                        {{ form.value?.countryFiscalFunds.flag }}<span class="pl-2">{{
                          form.value?.countryFiscalFunds.name }}</span>
                      </div>
                    </ng-template>
                    <ng-template let-country pTemplate="item">
                      <div class="country-item">
                        {{ country.flag }} <span class="pl-2">{{ country.name }}</span>
                      </div>
                    </ng-template>
                  </p-dropdown>
                </div>
              </div>
            </div>


            <!-- <div class="form-group">
              <div class="group">
                <label class="label">{{ 'KYC.light.fourSlide.firstInvestLabel' | translate }}</label>
                <p-dropdown formControlName="firstAttemptInvest" [options]="firstInvestAttemptOptions"
                  (onChange)="handleFirstAttemptInvestChange($event)" optionLabel="name">
                  <ng-template let-item pTemplate="selectedItem" *ngIf="!!form?.controls?.firstAttemptInvest?.value">
                    {{ item.name | TranslateStatic }}
                  </ng-template>
                  <ng-template let-item pTemplate="item">
                    {{ item.name | TranslateStatic }}
                  </ng-template>
                </p-dropdown>
              </div>

              <div class="group empty"></div>

            </div> -->

            <div class="form-group file-upload"
              *ngIf="form?.value?.firstAttemptInvest === firstInvestAttemptOptions[2]">
              <div class="group">
                <span class="label">{{ 'KYC.light.fiveSlide.fileProofTaxResidenceLabel'| translate }}</span>
                <p class="file-desc">
                  {{ 'KYC.light.fiveSlide.fileProofTaxResidenceList'| translate }}
                </p>
              </div>
              <div class="group">
                <div class="file-zone">
                  <div class="drop-zone" [class.drag]="taxDrag">
                    <!--                    <p-fileUpload-->
                    <!--                      (onSelect)="addFileTax($event)"-->
                    <!--                      [multiple]="true"-->
                    <!--                      #fileTaxInput-->
                    <!--                      [customUpload]="true"-->
                    <!--                      (dragenter)="onDragEnterTax($event)"-->
                    <!--                      (dragover)="onDragEnterTax($event)"-->
                    <!--                      (dragleave)="onDragLeaveTax($event)"-->
                    <!--                      styleClass="file-input" mode="basic" chooseLabel="Choose"-->
                    <!--                      [maxFileSize]="10000000"></p-fileUpload>-->

                    <input #fileTaxInput type="file" class="fileinput" mode="basic" chooseLabel="Choose"
                      [multiple]="true" (change)="addFileTax($event)" (dragenter)="onDragEnterTax($event)"
                      (dragover)="onDragEnterTax($event)" (dragleave)="onDragLeaveTax($event)">

                    <svg xmlns="http://www.w3.org/2000/svg" width="74" height="60" viewBox="0 0 74 60" fill="none">
                      <path
                        d="M46.6 46.6H58C66.25 46.6 73 42.2185 73 34.06C73 25.9015 65.05 21.8395 58.6 21.52C57.2665 8.761 47.95 1 37 1C26.65 1 19.984 7.8685 17.8 14.68C8.8 15.535 1 21.262 1 30.64C1 40.018 9.1 46.6 19 46.6H27.4"
                        stroke="url(#paint0_linear_5328_567)" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path d="M46.6004 29.8L37.0004 20.2L27.4004 29.8M37.0004 58.663V22.6"
                        stroke="url(#paint1_linear_5328_567)" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <defs>
                        <linearGradient id="paint0_linear_5328_567" x1="0.999999" y1="46.6" x2="74.4296" y2="1.86946"
                          gradientUnits="userSpaceOnUse">
                          <stop stop-color="#D60580" />
                          <stop offset="1" stop-color="#00FFFF" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_5328_567" x1="27.4004" y1="58.6629" x2="53.2877" y2="53.6774"
                          gradientUnits="userSpaceOnUse">
                          <stop stop-color="#D60580" />
                          <stop offset="1" stop-color="#00FFFF" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <span class="label" [innerHTML]="('KYC.light.fiveSlide.dropFile' | translate)"></span>
                  </div>
                  <div class="lists-files" [class.not-empty]="fileProofTaxResidence.length > 0">
                    <div class="files" *ngFor="let file of fileProofTaxResidence;let index = index;"
                      [class.full]="!!file?.id">
                      <span>{{ file?.name }}</span>
                      <svg (click)="removeFile(index, 'tax')" class="cursor" xmlns="http://www.w3.org/2000/svg"
                        width="17" height="16" viewBox="0 0 17 16" fill="none">
                        <path
                          d="M8.4541 16C6.33243 16 4.29742 15.1571 2.79726 13.6568C1.297 12.1565 0.454102 10.1217 0.454102 8C0.454102 5.87833 1.297 3.84332 2.79726 2.34315C4.29763 0.842902 6.33243 0 8.4541 0C10.5758 0 12.6108 0.842902 14.1109 2.34315C15.6112 3.84353 16.4541 5.87833 16.4541 8C16.4515 10.121 15.6079 12.1545 14.1081 13.654C12.6084 15.1538 10.5748 15.9974 8.4541 16ZM11.5392 5.94242V5.9423C11.6795 5.80678 11.7595 5.62063 11.7612 5.42558C11.7629 5.23053 11.6862 5.04292 11.5482 4.90496C11.4103 4.76701 11.2227 4.69031 11.0276 4.69202C10.8325 4.69372 10.6464 4.77371 10.5109 4.91397L8.4541 6.97149L6.39733 4.91397C6.21263 4.7356 5.94756 4.66779 5.6998 4.73572C5.45215 4.80366 5.2587 4.99713 5.19075 5.24477C5.12281 5.49254 5.19063 5.7576 5.369 5.9423L7.42577 7.99983L5.369 10.0573C5.22874 10.1929 5.14875 10.379 5.14704 10.5741C5.14534 10.7691 5.22204 10.9567 5.35999 11.0947C5.49794 11.2326 5.68555 11.3093 5.8806 11.3076C6.07565 11.3059 6.2618 11.2259 6.39732 11.0857L8.45409 9.02815L10.5109 11.0857C10.6464 11.2259 10.8325 11.3059 11.0276 11.3076C11.2226 11.3093 11.4102 11.2326 11.5482 11.0947C11.6862 10.9567 11.7629 10.7691 11.7611 10.5741C11.7594 10.379 11.6795 10.1929 11.5392 10.0573L9.48242 7.99983L11.5392 5.94242Z"
                          fill="#E6E6E6" />
                      </svg>
                      <div class="progress-ctn">
                        <div class="progress" [ngStyle]="{width: file?.percent + '%'}"></div>
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div class="divider"></div>
            <span class="title">{{ 'KYC.light.fourSlide.cryptoXpLabelTitle'| translate }}</span>


            <div class="form-group">
              <div class="group flex-row justify-content-between">
                <label class="label">{{ 'KYC.light.fourSlide.cryptoXpLabel' | translate }}</label>
                <p-inputSwitch styleClass="offerSwitch" formControlName="cryptoXP"></p-inputSwitch>

              </div>
            </div>

            <div class="form-group" *ngIf="form?.value?.cryptoXP">
              <div class="group">
                <label class="label">{{ 'KYC.light.fourSlide.cryptoXpLabelYear' | translate }}</label>
                <input class="input" type="text" formControlName="cryptoXPYear">
              </div>

            </div>



          </div>
        </div>

        <div class="userSettings" *ngIf="currentStep === 4">
          <div class="form">
            <span class="gradient-title" *ngIf="!isToReview">{{ 'KYC.light.fiveSlide.head'| translate }}</span>
            <span class="title">{{ 'KYC.light.fiveSlide.subtitle'| translate }}</span>
            <div class="form-group">
              <div class="group">
                <label class="label">{{ 'KYC.light.fiveSlide.netMonthlyIncomeLabel' | translate }}</label>
                <ng-container *ngIf="currencyInputMask$ | async as currencyInputMask">
                  <input class="input currencyInputMask" [inputMask]="currencyInputMask"
                    [placeholder]="'KYC.light.fiveSlide.netMonthlyIncomeLabel' | translate"
                    formControlName="netMonthlyIncome">
                </ng-container>
                <!--                  <p-inputMask [mask]="lang === ELang.FR ? '999 999 999€' : '€999,999,999'" styleClass="input"-->
                <!--                               class="input"-->
                <!--                               placeholder="{{'KYC.light.fiveSlide.netMonthlyIncomeLabel' | translate}}"-->
                <!--                               formControlName="netMonthlyIncome"></p-inputMask>-->
                <!--                </ng-container>-->

              </div>
              <div class="group">
                <label class="label">{{ 'KYC.light.fiveSlide.grossValueAssetLabel' | translate }}</label>
                <ng-container *ngIf="currencyInputMask$ | async as currencyInputMask">
                  <input class="input currencyInputMask" [inputMask]="currencyInputMask"
                    [placeholder]="'KYC.light.fiveSlide.grossValueAssetLabel' | translate"
                    formControlName="grossValueAsset">
                </ng-container>
              </div>
            </div>

            <div class="form-group">
              <div class="group">
                <label class="label">{{ 'KYC.light.fiveSlide.numberOfDependantLabel' | translate }}</label>
                <input class="input" type="number" min="0"
                  [placeholder]="'KYC.light.fiveSlide.numberOfDependantLabel' | translate"
                  formControlName="numberOfDependant">

              </div>
              <div class="group">&nbsp;</div>
              <!-- <div class="group">
                <label class="label">{{ 'KYC.light.fiveSlide.plannedInvestForNext12MonthLabel' | translate }}</label>
                <p-dropdown formControlName="plannedInvestForNext12Month" [options]="plannedInvestForNext12MonthOptions"
                  optionLabel="name">

                  <ng-template let-item pTemplate="selectedItem"
                    *ngIf="!!form?.controls?.plannedInvestForNext12Month?.value">
                    {{ item.name | TranslateStatic }}
                  </ng-template>
                  <ng-template let-item pTemplate="item">
                    {{ item.name | TranslateStatic }}
                  </ng-template>
                </p-dropdown>
              </div> -->
            </div>

            <div class="divider"></div>
            <span class="title">{{ 'KYC.light.fiveSlide.moreDocumentLabel'| translate }}</span>


            <!-- <div class="form-group file-upload">
              <div class="group">
                <span class="label">{{ 'KYC.light.fiveSlide.proofOfIncomeLabel'| translate }}</span>
                <p class="file-desc">
                  {{ 'KYC.light.fiveSlide.proofOfIncomeList'| translate }}
                </p>
              </div>
              <div class="group">


                <div class="file-zone">
                  <div class="drop-zone" [class.drag]="incomeDrag">
                    <input #fileIncomeInput type="file" class="fileinput" mode="basic" chooseLabel="Choose"
                      [multiple]="true" (change)="addFileIncome($event)" (dragenter)="onDragEnterIncome($event)"
                      (dragover)="onDragEnterIncome($event)" (dragleave)="onDragLeaveIncome($event)">

                    <svg xmlns="http://www.w3.org/2000/svg" width="74" height="60" viewBox="0 0 74 60" fill="none">
                      <path
                        d="M46.6 46.6H58C66.25 46.6 73 42.2185 73 34.06C73 25.9015 65.05 21.8395 58.6 21.52C57.2665 8.761 47.95 1 37 1C26.65 1 19.984 7.8685 17.8 14.68C8.8 15.535 1 21.262 1 30.64C1 40.018 9.1 46.6 19 46.6H27.4"
                        stroke="url(#paint0_linear_5328_567)" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path d="M46.6004 29.8L37.0004 20.2L27.4004 29.8M37.0004 58.663V22.6"
                        stroke="url(#paint1_linear_5328_567)" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <defs>
                        <linearGradient id="paint0_linear_5328_567" x1="0.999999" y1="46.6" x2="74.4296" y2="1.86946"
                          gradientUnits="userSpaceOnUse">
                          <stop stop-color="#D60580" />
                          <stop offset="1" stop-color="#00FFFF" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_5328_567" x1="27.4004" y1="58.6629" x2="53.2877" y2="53.6774"
                          gradientUnits="userSpaceOnUse">
                          <stop stop-color="#D60580" />
                          <stop offset="1" stop-color="#00FFFF" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <span class="label" [innerHTML]="('KYC.light.fiveSlide.dropFile' | translate)">
                    </span>
                  </div>
                  <div class="lists-files" [class.not-empty]="fileProofIncome.length > 0">
                    <div class="files" *ngFor="let file of fileProofIncome;let index=index;" [class.full]="!!file?.id">
                      <span>{{ file?.name }}</span>
                      <svg *ngIf="file?.id" (click)="removeFile(index, 'income')" class="cursor"
                        xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                        <path
                          d="M8.4541 16C6.33243 16 4.29742 15.1571 2.79726 13.6568C1.297 12.1565 0.454102 10.1217 0.454102 8C0.454102 5.87833 1.297 3.84332 2.79726 2.34315C4.29763 0.842902 6.33243 0 8.4541 0C10.5758 0 12.6108 0.842902 14.1109 2.34315C15.6112 3.84353 16.4541 5.87833 16.4541 8C16.4515 10.121 15.6079 12.1545 14.1081 13.654C12.6084 15.1538 10.5748 15.9974 8.4541 16ZM11.5392 5.94242V5.9423C11.6795 5.80678 11.7595 5.62063 11.7612 5.42558C11.7629 5.23053 11.6862 5.04292 11.5482 4.90496C11.4103 4.76701 11.2227 4.69031 11.0276 4.69202C10.8325 4.69372 10.6464 4.77371 10.5109 4.91397L8.4541 6.97149L6.39733 4.91397C6.21263 4.7356 5.94756 4.66779 5.6998 4.73572C5.45215 4.80366 5.2587 4.99713 5.19075 5.24477C5.12281 5.49254 5.19063 5.7576 5.369 5.9423L7.42577 7.99983L5.369 10.0573C5.22874 10.1929 5.14875 10.379 5.14704 10.5741C5.14534 10.7691 5.22204 10.9567 5.35999 11.0947C5.49794 11.2326 5.68555 11.3093 5.8806 11.3076C6.07565 11.3059 6.2618 11.2259 6.39732 11.0857L8.45409 9.02815L10.5109 11.0857C10.6464 11.2259 10.8325 11.3059 11.0276 11.3076C11.2226 11.3093 11.4102 11.2326 11.5482 11.0947C11.6862 10.9567 11.7629 10.7691 11.7611 10.5741C11.7594 10.379 11.6795 10.1929 11.5392 10.0573L9.48242 7.99983L11.5392 5.94242Z"
                          fill="#E6E6E6" />
                      </svg>
                      <div class="progress-ctn">
                        <div class="progress" [style.width.%]="file?.percent"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->

            <div class="form-group file-upload">
              <div class="group">
                <span class="label">{{ 'KYC.light.fiveSlide.fileProofTaxResidenceLabel'| translate }}</span>
                <p class="file-desc">
                  {{ 'KYC.light.fiveSlide.fileProofTaxResidenceList'| translate }}
                </p>
              </div>
              <div class="group">
                <div class="file-zone">
                  <div class="drop-zone" [class.drag]="taxDrag">

                    <input #fileTaxInput type="file" class="fileinput" mode="basic" chooseLabel="Choose"
                      [multiple]="true" (change)="addFileTax($event)" (dragenter)="onDragEnterTax($event)"
                      (dragover)="onDragEnterTax($event)" (dragleave)="onDragLeaveTax($event)">

                    <svg xmlns="http://www.w3.org/2000/svg" width="74" height="60" viewBox="0 0 74 60" fill="none">
                      <path
                        d="M46.6 46.6H58C66.25 46.6 73 42.2185 73 34.06C73 25.9015 65.05 21.8395 58.6 21.52C57.2665 8.761 47.95 1 37 1C26.65 1 19.984 7.8685 17.8 14.68C8.8 15.535 1 21.262 1 30.64C1 40.018 9.1 46.6 19 46.6H27.4"
                        stroke="url(#paint0_linear_5328_567)" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path d="M46.6004 29.8L37.0004 20.2L27.4004 29.8M37.0004 58.663V22.6"
                        stroke="url(#paint1_linear_5328_567)" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <defs>
                        <linearGradient id="paint0_linear_5328_567" x1="0.999999" y1="46.6" x2="74.4296" y2="1.86946"
                          gradientUnits="userSpaceOnUse">
                          <stop stop-color="#D60580" />
                          <stop offset="1" stop-color="#00FFFF" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_5328_567" x1="27.4004" y1="58.6629" x2="53.2877" y2="53.6774"
                          gradientUnits="userSpaceOnUse">
                          <stop stop-color="#D60580" />
                          <stop offset="1" stop-color="#00FFFF" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <span class="label" [innerHTML]="('KYC.light.fiveSlide.dropFile' | translate)"></span>
                  </div>
                  <div class="lists-files" [class.not-empty]="fileProofTaxResidence.length > 0">
                    <div class="files" *ngFor="let file of fileProofTaxResidence;let index = index;"
                      [class.full]="!!file?.id">
                      <span>{{ file?.name }}</span>
                      <svg (click)="removeFile(index, 'tax')" class="cursor" xmlns="http://www.w3.org/2000/svg"
                        width="17" height="16" viewBox="0 0 17 16" fill="none">
                        <path
                          d="M8.4541 16C6.33243 16 4.29742 15.1571 2.79726 13.6568C1.297 12.1565 0.454102 10.1217 0.454102 8C0.454102 5.87833 1.297 3.84332 2.79726 2.34315C4.29763 0.842902 6.33243 0 8.4541 0C10.5758 0 12.6108 0.842902 14.1109 2.34315C15.6112 3.84353 16.4541 5.87833 16.4541 8C16.4515 10.121 15.6079 12.1545 14.1081 13.654C12.6084 15.1538 10.5748 15.9974 8.4541 16ZM11.5392 5.94242V5.9423C11.6795 5.80678 11.7595 5.62063 11.7612 5.42558C11.7629 5.23053 11.6862 5.04292 11.5482 4.90496C11.4103 4.76701 11.2227 4.69031 11.0276 4.69202C10.8325 4.69372 10.6464 4.77371 10.5109 4.91397L8.4541 6.97149L6.39733 4.91397C6.21263 4.7356 5.94756 4.66779 5.6998 4.73572C5.45215 4.80366 5.2587 4.99713 5.19075 5.24477C5.12281 5.49254 5.19063 5.7576 5.369 5.9423L7.42577 7.99983L5.369 10.0573C5.22874 10.1929 5.14875 10.379 5.14704 10.5741C5.14534 10.7691 5.22204 10.9567 5.35999 11.0947C5.49794 11.2326 5.68555 11.3093 5.8806 11.3076C6.07565 11.3059 6.2618 11.2259 6.39732 11.0857L8.45409 9.02815L10.5109 11.0857C10.6464 11.2259 10.8325 11.3059 11.0276 11.3076C11.2226 11.3093 11.4102 11.2326 11.5482 11.0947C11.6862 10.9567 11.7629 10.7691 11.7611 10.5741C11.7594 10.379 11.6795 10.1929 11.5392 10.0573L9.48242 7.99983L11.5392 5.94242Z"
                          fill="#E6E6E6" />
                      </svg>
                      <div class="progress-ctn">
                        <div class="progress" [ngStyle]="{width: file?.percent + '%'}"></div>
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="form-group file-upload">
              <div class="group">
                <span class="label flex align-items-center gap-1">
                  {{ 'KYC.light.fiveSlide.fileProofOriginFundsLabel'| translate }}
                  <app-ico-info [text]="'KYC.light.fiveSlide.fileProofOriginFundsInfo' | translate"></app-ico-info>
                </span>
                <p class="file-desc">
                  {{ 'KYC.light.fiveSlide.fileProofOriginFundsList'| translate }}
                </p>

              </div>
              <div class="group">
                <div class="file-zone">
                  <div class="drop-zone" [class.drag]="fundsDrag">
                    <input #fileFundsInput type="file" class="fileinput" mode="basic" chooseLabel="Choose"
                      [multiple]="true" (change)="addFileFunds($event)" (dragenter)="onDragEnterFunds($event)"
                      (dragover)="onDragEnterFunds($event)" (dragleave)="onDragLeaveFunds($event)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="74" height="60" viewBox="0 0 74 60" fill="none">
                      <path
                        d="M46.6 46.6H58C66.25 46.6 73 42.2185 73 34.06C73 25.9015 65.05 21.8395 58.6 21.52C57.2665 8.761 47.95 1 37 1C26.65 1 19.984 7.8685 17.8 14.68C8.8 15.535 1 21.262 1 30.64C1 40.018 9.1 46.6 19 46.6H27.4"
                        stroke="url(#paint0_linear_5328_567)" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path d="M46.6004 29.8L37.0004 20.2L27.4004 29.8M37.0004 58.663V22.6"
                        stroke="url(#paint1_linear_5328_567)" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <defs>
                        <linearGradient id="paint0_linear_5328_567" x1="0.999999" y1="46.6" x2="74.4296" y2="1.86946"
                          gradientUnits="userSpaceOnUse">
                          <stop stop-color="#D60580" />
                          <stop offset="1" stop-color="#00FFFF" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_5328_567" x1="27.4004" y1="58.6629" x2="53.2877" y2="53.6774"
                          gradientUnits="userSpaceOnUse">
                          <stop stop-color="#D60580" />
                          <stop offset="1" stop-color="#00FFFF" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <span class="label" [innerHTML]="('KYC.light.fiveSlide.dropFile' | translate)"></span>
                  </div>
                  <div class="lists-files" [class.not-empty]="fileProofOriginFunds.length > 0">

                    <div class="files" *ngFor="let file of fileProofOriginFunds;let index=index;"
                      [class.full]="!!file?.id">
                      <span>{{ file?.name }}</span>
                      <svg *ngIf="file?.id" (click)="removeFile(index, 'origin')" class="cursor"
                        xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                        <path
                          d="M8.4541 16C6.33243 16 4.29742 15.1571 2.79726 13.6568C1.297 12.1565 0.454102 10.1217 0.454102 8C0.454102 5.87833 1.297 3.84332 2.79726 2.34315C4.29763 0.842902 6.33243 0 8.4541 0C10.5758 0 12.6108 0.842902 14.1109 2.34315C15.6112 3.84353 16.4541 5.87833 16.4541 8C16.4515 10.121 15.6079 12.1545 14.1081 13.654C12.6084 15.1538 10.5748 15.9974 8.4541 16ZM11.5392 5.94242V5.9423C11.6795 5.80678 11.7595 5.62063 11.7612 5.42558C11.7629 5.23053 11.6862 5.04292 11.5482 4.90496C11.4103 4.76701 11.2227 4.69031 11.0276 4.69202C10.8325 4.69372 10.6464 4.77371 10.5109 4.91397L8.4541 6.97149L6.39733 4.91397C6.21263 4.7356 5.94756 4.66779 5.6998 4.73572C5.45215 4.80366 5.2587 4.99713 5.19075 5.24477C5.12281 5.49254 5.19063 5.7576 5.369 5.9423L7.42577 7.99983L5.369 10.0573C5.22874 10.1929 5.14875 10.379 5.14704 10.5741C5.14534 10.7691 5.22204 10.9567 5.35999 11.0947C5.49794 11.2326 5.68555 11.3093 5.8806 11.3076C6.07565 11.3059 6.2618 11.2259 6.39732 11.0857L8.45409 9.02815L10.5109 11.0857C10.6464 11.2259 10.8325 11.3059 11.0276 11.3076C11.2226 11.3093 11.4102 11.2326 11.5482 11.0947C11.6862 10.9567 11.7629 10.7691 11.7611 10.5741C11.7594 10.379 11.6795 10.1929 11.5392 10.0573L9.48242 7.99983L11.5392 5.94242Z"
                          fill="#E6E6E6" />
                      </svg>
                      <div class="progress-ctn">
                        <div class="progress" [style.width.%]="file?.percent"></div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div> -->

            <!-- <div class="form-group file-upload" *ngIf="user.nationality === 'FR'">
              <div class="group">
                <span class="label flex align-items-center gap-1">
                  {{ 'KYC.light.fiveSlide.fileRibLabel'| translate }}
                </span>
                <p class="file-desc">
                  {{ 'KYC.light.fiveSlide.fileRibList'| translate }}
                </p>

              </div>
              <div class="group">
                <div class="file-zone">
                  <div class="drop-zone" [class.drag]="ribDrag">

                    <input #fileRIBInput type="file" class="fileinput" mode="basic" chooseLabel="Choose"
                      [multiple]="true" (change)="addFileRIB($event)" (dragenter)="onDragEnterRIB($event)"
                      (dragover)="onDragEnterRIB($event)" (dragleave)="onDragLeaveRIB($event)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="74" height="60" viewBox="0 0 74 60" fill="none">
                      <path
                        d="M46.6 46.6H58C66.25 46.6 73 42.2185 73 34.06C73 25.9015 65.05 21.8395 58.6 21.52C57.2665 8.761 47.95 1 37 1C26.65 1 19.984 7.8685 17.8 14.68C8.8 15.535 1 21.262 1 30.64C1 40.018 9.1 46.6 19 46.6H27.4"
                        stroke="url(#paint0_linear_5328_567)" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path d="M46.6004 29.8L37.0004 20.2L27.4004 29.8M37.0004 58.663V22.6"
                        stroke="url(#paint1_linear_5328_567)" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <defs>
                        <linearGradient id="paint0_linear_5328_567" x1="0.999999" y1="46.6" x2="74.4296" y2="1.86946"
                          gradientUnits="userSpaceOnUse">
                          <stop stop-color="#D60580" />
                          <stop offset="1" stop-color="#00FFFF" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_5328_567" x1="27.4004" y1="58.6629" x2="53.2877" y2="53.6774"
                          gradientUnits="userSpaceOnUse">
                          <stop stop-color="#D60580" />
                          <stop offset="1" stop-color="#00FFFF" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <span class="label" [innerHTML]="('KYC.light.fiveSlide.dropFile' | translate)"></span>
                  </div>
                  <div class="lists-files" [class.not-empty]="fileProofRIB.length > 0">

                    <div class="files" *ngFor="let file of fileProofRIB;let index=index;" [class.full]="!!file?.id">
                      <span>{{ file?.name }}</span>
                      <svg *ngIf="file?.id" (click)="removeFile(index, 'rib')" class="cursor"
                        xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                        <path
                          d="M8.4541 16C6.33243 16 4.29742 15.1571 2.79726 13.6568C1.297 12.1565 0.454102 10.1217 0.454102 8C0.454102 5.87833 1.297 3.84332 2.79726 2.34315C4.29763 0.842902 6.33243 0 8.4541 0C10.5758 0 12.6108 0.842902 14.1109 2.34315C15.6112 3.84353 16.4541 5.87833 16.4541 8C16.4515 10.121 15.6079 12.1545 14.1081 13.654C12.6084 15.1538 10.5748 15.9974 8.4541 16ZM11.5392 5.94242V5.9423C11.6795 5.80678 11.7595 5.62063 11.7612 5.42558C11.7629 5.23053 11.6862 5.04292 11.5482 4.90496C11.4103 4.76701 11.2227 4.69031 11.0276 4.69202C10.8325 4.69372 10.6464 4.77371 10.5109 4.91397L8.4541 6.97149L6.39733 4.91397C6.21263 4.7356 5.94756 4.66779 5.6998 4.73572C5.45215 4.80366 5.2587 4.99713 5.19075 5.24477C5.12281 5.49254 5.19063 5.7576 5.369 5.9423L7.42577 7.99983L5.369 10.0573C5.22874 10.1929 5.14875 10.379 5.14704 10.5741C5.14534 10.7691 5.22204 10.9567 5.35999 11.0947C5.49794 11.2326 5.68555 11.3093 5.8806 11.3076C6.07565 11.3059 6.2618 11.2259 6.39732 11.0857L8.45409 9.02815L10.5109 11.0857C10.6464 11.2259 10.8325 11.3059 11.0276 11.3076C11.2226 11.3093 11.4102 11.2326 11.5482 11.0947C11.6862 10.9567 11.7629 10.7691 11.7611 10.5741C11.7594 10.379 11.6795 10.1929 11.5392 10.0573L9.48242 7.99983L11.5392 5.94242Z"
                          fill="#E6E6E6" />
                      </svg>
                      <div class="progress-ctn">
                        <div class="progress" [style.width.%]="file?.percent"></div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div> -->

            <!-- <div class="form-group file-upload">
              <div class="group">
                <span class="label flex align-items-center gap-1">
                  {{ 'KYC.light.fiveSlide.fileBancaireLabel'| translate }}
                </span>
                <p class="file-desc">
                  {{ 'KYC.light.fiveSlide.fileBancaireList'| translate }}
                </p>

              </div>
              <div class="group">
                <div class="file-zone">
                  <div class="drop-zone" [class.drag]="bancaireDrag">
                    <input #fileBancaireInput type="file" class="fileinput" mode="basic" chooseLabel="Choose"
                      [multiple]="true" (change)="addFileBancaire($event)" (dragenter)="onDragEnterBancaire($event)"
                      (dragover)="onDragEnterBancaire($event)" (dragleave)="onDragLeaveBancaire($event)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="74" height="60" viewBox="0 0 74 60" fill="none">
                      <path
                        d="M46.6 46.6H58C66.25 46.6 73 42.2185 73 34.06C73 25.9015 65.05 21.8395 58.6 21.52C57.2665 8.761 47.95 1 37 1C26.65 1 19.984 7.8685 17.8 14.68C8.8 15.535 1 21.262 1 30.64C1 40.018 9.1 46.6 19 46.6H27.4"
                        stroke="url(#paint0_linear_5328_567)" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path d="M46.6004 29.8L37.0004 20.2L27.4004 29.8M37.0004 58.663V22.6"
                        stroke="url(#paint1_linear_5328_567)" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <defs>
                        <linearGradient id="paint0_linear_5328_567" x1="0.999999" y1="46.6" x2="74.4296" y2="1.86946"
                          gradientUnits="userSpaceOnUse">
                          <stop stop-color="#D60580" />
                          <stop offset="1" stop-color="#00FFFF" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_5328_567" x1="27.4004" y1="58.6629" x2="53.2877" y2="53.6774"
                          gradientUnits="userSpaceOnUse">
                          <stop stop-color="#D60580" />
                          <stop offset="1" stop-color="#00FFFF" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <span class="label" [innerHTML]="('KYC.light.fiveSlide.dropFile' | translate)"></span>
                  </div>
                  <div class="lists-files" [class.not-empty]="fileProofBancaire.length > 0">

                    <div class="files" *ngFor="let file of fileProofBancaire;let index=index;"
                      [class.full]="!!file?.id">
                      <span>{{ file?.name }}</span>
                      <svg *ngIf="file?.id" (click)="removeFile(index, 'bancaire')" class="cursor"
                        xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                        <path
                          d="M8.4541 16C6.33243 16 4.29742 15.1571 2.79726 13.6568C1.297 12.1565 0.454102 10.1217 0.454102 8C0.454102 5.87833 1.297 3.84332 2.79726 2.34315C4.29763 0.842902 6.33243 0 8.4541 0C10.5758 0 12.6108 0.842902 14.1109 2.34315C15.6112 3.84353 16.4541 5.87833 16.4541 8C16.4515 10.121 15.6079 12.1545 14.1081 13.654C12.6084 15.1538 10.5748 15.9974 8.4541 16ZM11.5392 5.94242V5.9423C11.6795 5.80678 11.7595 5.62063 11.7612 5.42558C11.7629 5.23053 11.6862 5.04292 11.5482 4.90496C11.4103 4.76701 11.2227 4.69031 11.0276 4.69202C10.8325 4.69372 10.6464 4.77371 10.5109 4.91397L8.4541 6.97149L6.39733 4.91397C6.21263 4.7356 5.94756 4.66779 5.6998 4.73572C5.45215 4.80366 5.2587 4.99713 5.19075 5.24477C5.12281 5.49254 5.19063 5.7576 5.369 5.9423L7.42577 7.99983L5.369 10.0573C5.22874 10.1929 5.14875 10.379 5.14704 10.5741C5.14534 10.7691 5.22204 10.9567 5.35999 11.0947C5.49794 11.2326 5.68555 11.3093 5.8806 11.3076C6.07565 11.3059 6.2618 11.2259 6.39732 11.0857L8.45409 9.02815L10.5109 11.0857C10.6464 11.2259 10.8325 11.3059 11.0276 11.3076C11.2226 11.3093 11.4102 11.2326 11.5482 11.0947C11.6862 10.9567 11.7629 10.7691 11.7611 10.5741C11.7594 10.379 11.6795 10.1929 11.5392 10.0573L9.48242 7.99983L11.5392 5.94242Z"
                          fill="#E6E6E6" />
                      </svg>
                      <div class="progress-ctn">
                        <div class="progress" [style.width.%]="file?.percent"></div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>


        <div class="actions" *ngIf="isToReview">

          <app-ico-btn (click)="next()" [disabled]="form.invalid" rId="next"
            [text]="currentStep >= stepsToReview.length ? ('save' | translate) : ('next' | translate)"></app-ico-btn>
        </div>
        <div class="actions" *ngIf="!isToReview">
          <app-ico-btn (click)="back()" [outline]="true" *ngIf="currentStep > 1"
            [text]="'back' | translate"></app-ico-btn>

          <!--          {{ form?.controls | json }}-->
          <app-ico-btn (click)="next()" [disabled]="form.invalid" rId="next"
            [text]="(currentStep === 2 && kycTypeForm === EKycTypeForm.LIGHT) || (currentStep === 3 && kycTypeForm === EKycTypeForm.FULL && !needEnhancedKyc) || (currentStep === 4 && kycTypeForm === EKycTypeForm.FULL && needEnhancedKyc) ? ('save' | translate) : ('next' | translate)"></app-ico-btn>
        </div>
        <!--                {{ form.value | json}}-->
      </ng-container>
    </div>

  </ng-container>

  <ng-template #Loader>
    <div class="ico-card mt-3 relative">
      <p-skeleton class="w-full h-full" height="80vh"></p-skeleton>
    </div>
  </ng-template>


</div>