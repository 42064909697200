<div class="">
  <app-ico-top  [title]="'ChangePassword.pageChangePassword.header' | translate"></app-ico-top>

  <p-card class="mt-3">
    <div class="userSettings">
      <app-user-details></app-user-details>
      <div class="title mt-6">{{'ChangePassword.pageChangePassword.title' | translate}}</div>
      <div class="flex pl-0 changeUserPasswordBox">
        <div class="col-md-7 pr-4 pt-0 ">
          <form (ngSubmit)="changePassword()" [formGroup]="passwordForm" autocomplete="off">
            <div class="form-group relative">
              <div (click)="displayPassword = !displayPassword" class="showPassword">
                <ion-icon name="{{ !displayPassword ? 'eye-off-outline' : 'eye-outline' }}"></ion-icon>
              </div>
              <input [placeholder]="'currentPassword' | translate" [type]="!displayPassword ? 'password': 'text'"
                     class="input" formControlName="oldPassword">
            </div>
            <app-set-password
              (formChangeEvent)="formChange($event)"
              class="w-full"
            ></app-set-password>
            <app-ico-btn [disabled]="passwordForm.invalid"
                         [rId]="'summit-change-password'"
                         [text]="'save' | translate"></app-ico-btn>
          </form>
        </div>
        <div class="col-md-5 xl:px-4 pt-0">
          <h6 class="title">{{'ChangePassword.pageChangePassword.informations' | translate}}</h6>
          <div [innerHtml]="'ChangePassword.pageChangePassword.help' | translate" class="medium-txt"></div>
          <div (click)="navigateTo('/profile/2fa')"
               class="link mb-5">
            {{ "ChangePassword.pageChangePassword.clickHereToActivate" | translate }}
          </div>
        </div>
      </div>
    </div>
  </p-card>
</div>
