import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-research-btn',
  templateUrl: './research-btn.component.html',
  styleUrls: ['./research-btn.component.scss']
})
export class ResearchBtnComponent {
  @Input() label = '';
  @Input() disabled = false;
  @Input() outline = false;
  @Input() rId = '';
  @Input() icon = '';
  @Input() children = false;
  @Input() tooltip = "";
  @Input() wAuto = false;

  constructor() {
    if (!this.rId) {
      // @ts-ignore
      this.rId = `${Math.floor(Math.random() * Date.now()).toString(36)}`
    }
  }
}
