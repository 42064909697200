  <p-table #dt2 [value]="data" dataKey="id" (onSort)="onSort($event)" [paginator]="paginator"
           [rows]="rows" (onPage)="paginate($event)">
    <ng-template pTemplate="header">
      <tr [class]="'header-table ' + class">
        <ng-container *ngFor="let head of header; let index =index">

          <ng-container *ngIf="head.sortField">
            <th [pSortableColumn]="head.sortField">
              {{ head.name | translate}}
              <p-sortIcon [field]="head.sortField"></p-sortIcon>
            </th>
          </ng-container>
          <ng-container *ngIf="!head.sortField">
            <th>
              {{ head.name | translate}}
            </th>
          </ng-container>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template let-columns="columns" let-rowData pTemplate="body">
      <ng-content></ng-content>
    </ng-template>
  </p-table>
