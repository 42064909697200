import {Action, NgxsOnInit, Selector, State, StateContext} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {GetProfile, ReadAllNotificaiton, ResetICOWallet, UpdateICOWallet} from "@profile/stores/profile/profile.actions";
import {ProfileService} from "@profile/services/profile.service";
import {EToastSeverity, IProfile, IProfileStateModel, IStep} from "@models/dist";
import {GetAirdrop} from "@shared/stores/airdrop/airdrop.actions";
import { ToastService } from "@app/shared/services";

@State<IProfileStateModel>({
  name: 'ProfilState',
  defaults: {
    profile: null,
    referrals: [],
    amount: 0,
    step: null,
    targetStep: null,
    unreadNotifications: 0,
    conversations: 0,
    icoWallet: null,
    icoWalletLockedDate: null
  },
})

@Injectable()
export class ProfileState implements NgxsOnInit {

  constructor(private profileService: ProfileService, private toastService: ToastService) {
  }

  @Selector()
  static hasUnread(state: IProfileStateModel): number | null {
    return state.unreadNotifications;
  }

  @Selector()
  static hasConversations(state: IProfileStateModel): number | null {
    return state.conversations;
  }

  @Selector()
  static getProfile(state: IProfileStateModel): IProfile | null {
    return state.profile;
  }

  @Selector()
  static getICOWallet(state: IProfileStateModel): string | null {
    return state.icoWallet;
  }

  @Selector()
  static getICOWalletLockedDate(state: IProfileStateModel): Date | null {
    return state.icoWalletLockedDate;
  }

  @Selector()
  static getReferral(state: IProfileStateModel): {
    step: IStep | null,
    targetStep: IStep | null,
    referrals: { pending: number, validate: number, }[],
    amount: number
  } | null {
    return {referrals: state.referrals, amount: state.amount, step: state.step, targetStep: state.targetStep};
  }

  ngxsOnInit(ctx?: StateContext<any>): any {
  }

  @Action(GetProfile)
  async getProfile(ctx: StateContext<IProfileStateModel>) {
    try {
      const profile = await this.profileService.getProfile() as IProfile;
      const referral = await this.profileService.getReferral() as {
        step: { current: IStep, targetStep: IStep },
        referrals: { pending: number, validate: number, }[],
        amount: number
      };
      ctx.dispatch(new GetAirdrop());
      ctx.patchState({
        profile: profile,
        referrals: referral.referrals,
        amount: referral.amount,
        step: referral.step.current,
        targetStep: referral.step.targetStep,
        unreadNotifications: profile.unread,
        conversations: profile.conversations,
        icoWallet: profile.user.icoWallet,
        icoWalletLockedDate: profile.user.icoWalletLockedDate,
      })
    } catch (e) {
      console.log(e);
    }
  }

  @Action(ReadAllNotificaiton)
  async readAllNotification(ctx: StateContext<IProfileStateModel>) {
    console.log('triger');
    const profile = await this.profileService.getProfile() as IProfile;

    ctx.patchState({
      unreadNotifications: profile.unread,
    })
  }

  @Action(ResetICOWallet)
  async resetICOWallet(ctx: StateContext<IProfileStateModel>) {
    ctx.patchState({icoWallet: null, icoWalletLockedDate: null});
  }

  @Action(UpdateICOWallet)
  async updateICOWallet(ctx: StateContext<IProfileStateModel>, { walletAddress }: UpdateICOWallet){
    this.toastService.addToast(EToastSeverity.info, "ico.dashboard.wallet.toast.title", "ico.dashboard.wallet.toast.processing");
    try {
      await this.profileService.updateICOWallet(walletAddress);
      this.toastService.addToast(EToastSeverity.success, "ico.dashboard.wallet.toast.title", "ico.dashboard.wallet.toast.success");
      ctx.patchState({icoWallet: walletAddress});
    } catch(error: any){
      if (error.status === 409){
        this.toastService.addToast(EToastSeverity.error, "ico.dashboard.wallet.toast.title", "ico.dashboard.wallet.toast.errorDuplicate");
      } else {
        this.toastService.addToast(EToastSeverity.error, "ico.dashboard.wallet.toast.title", "ico.dashboard.wallet.toast.error");
      }
    }
  }

}
