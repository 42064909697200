<div class="modal-header">
  <div [innerHTML]="'compliance.modal.youMustCompleteKyc' | translate" class="title text-center">

  </div>
</div>


<div class="modal-body">
  <img alt="" height="130px" src="../../../../assets/img/kycModalImg.svg"
       width="130px">

  <div [innerHTML]="'compliance.modal.youDidntPassKyc' | translate" class="subtitle">

  </div>

  <div [innerHTML]="'compliance.modal.kycDescription' | translate" class="description">

  </div>
</div>

<div class="modal-footer">
  <div class="button">
    <app-ico-btn (click)="laterAction()" [outline]="true"
                 [text]="'compliance.modal.later' | translate">
    </app-ico-btn>
  </div>
  <div class="button">
    <app-ico-btn (click)="passKyc()" [rId]="'create-kyc'"
                 [text]="'compliance.modal.passKYC' | translate"></app-ico-btn>
  </div>
</div>




