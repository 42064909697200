import {Injectable} from "@angular/core";
import {Action, State} from "@ngxs/store";
import {
  ClaimAirdrop,
  GetAirdrop,
  SetAirdrop,
  SetAirdropSuccess,
  SuccessClaimAirdrop
} from "@shared/stores/airdrop/airdrop.actions";
import {AirdropGateway} from "@shared/stores/airdrop/airdrop.gateway";
import {tap} from "rxjs";

export class AirdropStateModel {
  airdrop: any
}

const defaults = {
  airdrop: null
};

@State<AirdropStateModel>({
  name: 'airdrop',
  defaults
})
@Injectable()
export class AirdropState {

  constructor(
    private airdropGateway: AirdropGateway
  ) {
  }

  @Action(GetAirdrop)
  async getAirdrop(ctx: any, action: GetAirdrop) {
    return this.airdropGateway.getAirdrop().pipe(
      tap(
        (data) => ctx.dispatch(new SetAirdrop(data))
      )
    )
  }

  @Action(SetAirdrop)
  async setAirdrop(ctx: any, {airdrop}: SetAirdrop) {
    ctx.patchState({
      airdrop
    });


    // ctx.dispatch(new SetAirdropSuccess(airdrop))
  }

  @Action(ClaimAirdrop)
  async claimAirdrop(ctx: any, {id}: ClaimAirdrop) {
    return this.airdropGateway.claimAirdrop(id).pipe(
      tap(
        (data) => ctx.dispatch(new SuccessClaimAirdrop())
      )
    )
  }


}
