import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";
import {differenceInYears} from 'date-fns'
import {Nullable} from "primeng/ts-helpers";

export function isThirdStepValidator(currentStep: number): ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {
    if (currentStep === 3 && !control.value) {
      return {required: true}
    }
    return null;
  }
}

export function isNotNullValidator(currentStep: number): ValidatorFn {

    return (control: AbstractControl): ValidationErrors | null => {
      if (currentStep === 4 && control.value === null) {
        return {required: true}
      }
      if(currentStep === 4 && control.value !== null ){
        return null;
      }
      return null;
    }
}

export function isFourthStepValidator(currentStep: number): ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {
    if (currentStep === 4 && !control.value) {
      return {required: true}
    }

    return null;
  }
}

export function isFourthStepFilesValidator(currentStep: number): ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {

    if (currentStep === 4 && !control.value.length) {
      return {required: true}
    }

    return null;
  }
}

export function isThirdstepValidatorIfAttempt(id:string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (id === 'between_5_001_10_000' && !control.value.length) {
      return {required: true}
    }
    return null;
  }
}

export function isRequiredIfLookingJob(value: any): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (value && !control.value.length) {
      return null;
    }
    return null;
  }
}
