import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {firstValueFrom, map} from "rxjs";
import {environment} from "../../../environments/environment";
import {ETransactionStatus, IRound} from "@models/dist";

const BASE = `${environment.nestApiUrl}/api/sgp/transactions`;

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  constructor(private http: HttpClient) {
  }

  /**************************************
   * TRANSACTION
   ***************************************/

  async getTransactions() {
    return firstValueFrom(this.http.get(`${BASE}`));
  }

  getHistory() {
    return firstValueFrom(this.http.get(`${BASE}/history`));
  }

  async getConfirmationFund(id: any) {
    return firstValueFrom(this.http.get(`${BASE}/confirmation/${id}`));
  }

  async createTransaction(amountSummitToken: number, assetId: string) {
    return firstValueFrom(this.http.post(`${BASE}/create-transaction`, {amountSummitToken, assetId}));

  }

  getLastInvest() {
    return this.http.get(`${BASE}/last-invests`).pipe(map((res: any) => res.data));
  }

  cancelTransactions(id: string) {
    return firstValueFrom(this.http.post(`${BASE}/cancel-transaction`, {id}));
  }

  registerToWaitingList(status: string) {
    return firstValueFrom(this.http.post(`${BASE}/waiting-list`, {status}));
  }

  getWaitingList() {
    return firstValueFrom(this.http.get(`${BASE}/waiting-list`, {}));
  }

  async getReferralTransactions() {
    return firstValueFrom(this.http.get(`${BASE}/referral`, {}));
  }

  async mapRoundToPhase(rounds: IRound[]) {
    return  rounds.map((round) => {
      const amountSMT = round.transactions.filter((e) => e.status === ETransactionStatus.COMPLETED).map((e) => Number(e.amount)).reduce((a, b) => a + b, 0);
      const amountUSD = amountSMT * Number(round.price);
      return {
        ...round,
        amountSMT,
        amountUSD
      }
    })

  }
}

