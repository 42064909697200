import {Component} from '@angular/core';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';

@Component({
  template: `
    <ul [innerHtml]="'KycTutorial.acceptedSepaCountry' | translate"></ul>
    <div class="flex justify-content-end mt-3">
        <p-button icon="pi pi-check" [label]="'Common.ok' | translate" (click)="close()"></p-button>
    </div>
  `,
  styles: [
    `
      ::ng-deep li {
        margin-right: 30px;
        margin-top: 10px;
      }
      ul {
        border-radius: 10px;
        background-color: #fff;
        padding: 2rem;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
      }
    `,
  ],
})
export class AcceptedSepaCountryModal {
  constructor(
    public dialogService: DialogService,
    public ref: DynamicDialogRef
  ) {
  }

  close() {
    this.ref.close();
  }
}
