import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {ERoutes} from "@models/dist";
import {EntryComponent, LayoutComponent, OnboardingLayoutComponent} from "@shared/components";
import {AuthGuard} from "@shared/guards/auth.guard";
import {TwofaGuard} from "@shared/guards/twofa.guard";
import {OnboardingGuard} from "@shared/guards/onboarding.guard";
import {ComplianceComponent} from "@app/compliance/compliance.component";
import {DiscordComponent, LoginLayoutComponent} from "@auth/components";

const routes: Routes = [
  // {
  //   path: "migration",
  //   component: MigrationComponent
  // },
  {
    path: "integrate/compliance",
    component: ComplianceComponent,
  },
  {
    path: "",
    component: EntryComponent,
    children: [
      {
        path: ERoutes.AUTH,
        loadChildren: () => import('@auth/auth.module').then(m => m.AuthModule),
        data: {preload: true}
      },
      {
        path: ERoutes.SUMMIT,
        component: LayoutComponent,
        canActivate: [AuthGuard, TwofaGuard],
        loadChildren: () => import('@summit/summit.module').then(m => m.SummitModule),
        data: {preload: true}
      },
      {
        path: 'medias',
        loadChildren: () => import('@medias/medias.module').then((m) => m.MediasModule),
        component: LayoutComponent,
        canActivate: [AuthGuard, TwofaGuard],
        data: {preload: true},
      },
      {
        path: ERoutes.ICO,
        loadChildren: () => import('@ico/ico.module').then((m) => m.IcoModule),
        component: LayoutComponent,
        canActivate: [AuthGuard, TwofaGuard],
        data: {preload: true},
      },
      {
        path: ERoutes.RESEARCH,
        loadChildren: () => import('@research/research.module').then((m) => m.ResearchModule),
        component: LayoutComponent,
        canActivate: [AuthGuard, TwofaGuard],
        data: {preload: true},
      },
      {
        path: 'onboarding',
        loadChildren: () => import('@onboarding/onboarding.module').then((m) => m.OnboardingModule),
        component: OnboardingLayoutComponent,
        canActivate: [AuthGuard, TwofaGuard, OnboardingGuard],
        data: {preload: true},
      },
      {
        path: 'mining',
        loadChildren: () => import('@mining/mining.module').then((m) => m.MiningModule),
        component: LayoutComponent,
        canActivate: [AuthGuard, TwofaGuard],
        data: {preload: true},
      },
      {
        path: 'gravity',
        loadChildren: () => import('@gravity/gravity.module').then((m) => m.GravityModule),
        component: LayoutComponent,
        canActivate: [AuthGuard, TwofaGuard],
        data: {preload: true},
      },
      {
        path: ERoutes.PROFILE,
        loadChildren: () => import('@profile/profile.module').then((m) => m.ProfileModule),
        component: LayoutComponent,
        canActivate: [AuthGuard, TwofaGuard],
        data: {preload: true},
      },
      {
        path: ERoutes.HELP,
        loadChildren: () => import('@shared/modules/help/help.module').then((m) => m.HelpModule),
        component: LayoutComponent,
        canActivate: [AuthGuard, TwofaGuard],
        data: {preload: true},
      },
      {
        path: "**",
        redirectTo: `${ERoutes.AUTH}`,
        pathMatch: "full"
      }
    ]
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      preloadingStrategy: PreloadAllModules,
      bindToComponentInputs: true,
    })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
