import {ELang} from "@models/dist";

export function getIcoPhaseName(name: string | undefined, lang: ELang) {
  if (!name) {
    return '';
  }
  const json = JSON.parse(name);
  return json[lang];
}

export function isICOEnabled() {
  const deadline = new Date(Date.UTC(2025, 1, 9, 23, 59, 59));
  const now = new Date();
  return now < deadline;
}