import {Component, Input, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {Observable} from "rxjs";
import {IUser} from "@models/dist";

@Component({
  selector: 'app-summit-logo',
  templateUrl: './summit-logo.component.html',
  styleUrls: ['./summit-logo.component.scss']
})
export class SummitLogoComponent {
  @Input() service: 'ico' | 'research' | 'mining' | 'gravity' | 'medias' | 'profile' = 'ico';
  @Input() user?: IUser;

  constructor(
    private router: Router
  ) {

  }

  goTo() {
    if (this.service === 'ico') {
      this.router.navigateByUrl('/ico/dashboard')
      return;
    }

    if (this.service === 'research') {
      this.router.navigateByUrl('/research/dashboard')
      return;
    }

    if (this.service === 'medias') {
      this.router.navigateByUrl('/medias/dashboard')
      return;
    }

    if (this.service === 'profile') {
      this.router.navigateByUrl('/profile')
      return;
    }


  }
}
