
<div class="errorDisplay">
  <!--close modal button -->
  <button
    class="close"
    icon="pi pi-times"
    pButton
    (click)="close()"
  ></button>
  <div class="modal-header">
    <div class="text-center">
      <span class="title">{{'ico.referrals.modal.title' | translate}}</span>
      <span class="title gradient">{{'ico.referrals.modal.title2' | translate}}</span>
      <span class="title">🎉</span>
    </div>
  </div>
  <div class="modal-body flex flex-column aligns-items-start justify-content-start">
    <span [innerHTML]="'ico.referrals.modal.titleDesc' | translate" class="sub-title mb-4"></span>
    <span [innerHTML]="'ico.referrals.modal.' + step + '.title' | translate" class="step-title text-left font-bold mb-2"></span>
    <span [innerHTML]="'ico.referrals.modal.' + step + '.desc' | translate" class="margin description text-left"></span>

    <span *ngIf="step === 3" [innerHTML]="'Profile.referral.referralLink' | translate" class="description text-left"></span>
    <div *ngIf="step === 3" class="margin tel-ctn flex justify-content-between align-items-center">
      <a
        [innerHTML]="referralLink"
        class="cursor-pointer"></a>
    <app-copy [text]="referralLink"></app-copy>
  </div>
  <div class="modal-footer">
    <app-ico-btn class="custom-button-size" [rId]="'gain'" (click)="goToNextStep()" [text]="'ico.referrals.modal.' + step + '.btn'|translate"></app-ico-btn>
    <p class="sub-title pointer" (click)="skipIntro()" *ngIf="!claim">{{'ico.referrals.modal.close' | translate}} </p>

  </div>
</div>
