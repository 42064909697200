import {
  EProducts,
  ETransactionCurrency,
  ETransactionStatus,
  ETransactionType,
  IOrder,
  ITransaction
} from "@models/dist";

export function getInfos(transaction: ITransaction) {
  return transaction.method;
}

export function getDate(transaction: ITransaction) {
  return transaction.created;
}

export function getUid(transaction: ITransaction): string {
  return transaction.uid;
}

export function getAmount(transaction: ITransaction): number {
  return transaction.amount;
}

interface IStatus {
  value: ETransactionStatus;
  text: string;
  color: string;
  textcolor: string;
}

export const researchTransactionStatus: any[] = [
  {
    value: ETransactionStatus.COMPLETED,
    text: 'Transactions.status.completed',
    color: 'rgba(133,210,80,0.08)',
    textcolor: 'rgba(133,210,80,0.7)'
  },
  {
    value: 'REQUIRED_ACTION',
    text: 'Transactions.status.requiredAction',
    color: 'rgba(128, 6, 35, 0.08)',
    textcolor: 'rgba(128, 6, 35, .7)'
  },
  {
    value: 'GIFT',
    text: 'Transactions.status.completed',
    color: 'rgba(133,210,80,0.08)',
    textcolor: 'rgba(133,210,80,0.7)'
  },
  {
    value: 'CANCEL_USER',
    text: 'Transactions.status.cancelled_user',
    color: 'rgba(128, 6, 35, 0.08)',
    textcolor: 'rgba(128, 6, 35, .7)'
  },
  {
    value: 'INIT',
    text: 'Transactions.status.init',
    color: 'rgba(9, 117, 224, 0.08)',
    textcolor: 'rgba(9, 117, 224, .7)'
  },

  {
    value: 'REFUND',
    text: 'Transactions.status.refunded',
    color: 'rgba(255,101,80,0.08)',
    textcolor: 'rgba(255,101,80,.7)'
  },
  {
    value: 'ERROR',
    text: 'Transactions.status.error',
    color: 'rgba(128, 6, 35, 0.08)',
    textcolor: 'rgba(128, 6, 35, .7)'
  },
  {
    value: 'REJECTED',
    text: 'Transactions.status.rejected',
    color: 'rgba(128, 6, 35, 0.08)',
    textcolor: 'rgba(128, 6, 35, .7)'
  },
]
export const researchTransactionState: any[] = [

  {
    value: true,
    text: 'Transactions.status.actif',
    color: 'rgba(133,210,80,0.08)',
    textcolor: 'rgba(133,210,80,0.7)'
  },
  {
    value: false,
    text: 'Transactions.status.noactif',
    color: 'rgba(128, 6, 35, 0.08)',
    textcolor: 'rgba(128, 6, 35, .7)'
  },

]

export const transactionStatus: IStatus[] = [
  {
    value: ETransactionStatus.CREATED,
    text: 'Transactions.status.created',
    color: 'rgba(9, 117, 224, 0.08)',
    textcolor: 'rgba(9, 117, 224, .7)'
  },
  {
    value: ETransactionStatus.PENDING_PAYMENT,
    text: 'Transactions.status.pending_payment',
    color: 'rgba(224, 224, 13, 0.08)',
    textcolor: 'rgba(224, 224, 13, .7)'
  },
  {
    value: ETransactionStatus.PENDING,
    text: 'Transactions.status.pending',
    color: 'rgba(224, 224, 13, 0.08)',
    textcolor: 'rgba(224, 224, 13, .7)'
  },
  {
    value: ETransactionStatus.PENDING_UNLOCK,
    text: 'Transactions.status.pending_unlock',
    color: 'rgba(224, 224, 13, 0.08)',
    textcolor: 'rgba(224, 224, 13, .7)'
  },
  {
    value: ETransactionStatus.TRANSACTION_CHECKING,
    text: 'Transactions.status.checking',
    color: 'rgba(224, 102, 9, 0.08)',
    textcolor: 'rgba(224, 102, 9, .7)'
  },
  {
    value: ETransactionStatus.TRANSACTION_CHECKED,
    text: 'Transactions.status.checked',
    color: 'rgba(92, 224, 9, 0.08)',
    textcolor: 'rgba(92, 224, 9, .7)'
  },
  {
    value: ETransactionStatus.TRANSACTION_REJECTED,
    text: 'Transactions.status.rejected',
    color: 'rgba(255,101,80,0.08)',
    textcolor: 'rgba(255,101,80,.7)'
  },
  {
    value: ETransactionStatus.CANCELLED_USER,
    text: 'Transactions.status.cancelled_user',
    color: 'rgba(128, 6, 35, 0.08)',
    textcolor: 'rgba(128, 6, 35, .7)'
  },
  {
    value: ETransactionStatus.CANCELLED_SUMMIT,
    text: 'Transactions.status.cancelled_summit',
    color: 'rgba(128, 6, 35, 0.08)',
    textcolor: 'rgba(128, 6, 35, .7)'
  },
  {
    value: ETransactionStatus.CANCELLED_DEPEG,
    text: 'Transactions.status.cancelled_depeg',
    color: 'rgba(128, 6, 35, 0.08)',
    textcolor: 'rgba(128, 6, 35, .7)'
  },
  {
    value: ETransactionStatus.COMPLETED,
    text: 'Transactions.status.completed',
    color: 'rgba(133,210,80,0.08)',
    textcolor: 'rgba(133,210,80,0.7)'
  }
];

interface IProduct {
  value: EProducts;
  name: string;
  img: string;
}

export const transactionProduct: IProduct[] = [
  {value: EProducts.SUMMIT_TOKEN, name: "Transactions.product.smt", img: 'assets/tokens/summit-token.png'},
  {value: EProducts.QUANTUM, name: "Transactions.product.quantum", img: 'assets/tokens/quantum.png'},
  {value: EProducts.IMPULSE, name: "Transactions.product.impulse", img: 'assets/tokens/impulse.png'},
  {value: EProducts.MPT, name: "Transactions.product.mpt", img: 'assets/tokens/mpt.png'},
  {value: EProducts.CREDITS, name: "Transactions.product.credits", img: 'assets/tokens/credits.png'}
]

export function getStatus(transaction: ITransaction): IStatus {
  return transactionStatus.find(x => x.value === transaction.status)!;
}

export function getResearchStatus(transaction: ITransaction): IStatus {
  return researchTransactionStatus.find(x => x.value === transaction.status)!;
}

export function getResearchState(transaction: IOrder): IStatus {
  return researchTransactionState.find(x => x.value === transaction.active)!;
}

export function getDirectStatus(status: string): IStatus {
  return transactionStatus.find(x => x.value === status)!;
}

export function getTransactionProduct(transaction: ITransaction): IProduct {
  let product = {value: EProducts.CREDITS, name: "Credits", img: 'assets/tokens/credits.png'}
  return transactionProduct.find(x => x.value === transaction.category) ?? product;
}


export function getCurrencyImg(currency: ETransactionCurrency | string | undefined): string {
  switch (currency) {
    case ETransactionCurrency.BITCOIN:
      return 'assets/currencies/bitcoin.png';
    case ETransactionCurrency.BUSD:
      return 'assets/currencies/busd.png';
    case ETransactionCurrency.DAI:
      return 'assets/currencies/dai.png';
    case ETransactionCurrency.DASH:
      return 'assets/currencies/dash.png';
    case ETransactionCurrency.ETHEREUM:
      return 'assets/currencies/ethereum.png';
    case ETransactionCurrency.EURO:
      return 'assets/currencies/euro.png';
    case ETransactionCurrency.LTC:
      return 'assets/currencies/ltc.png';
    case ETransactionCurrency.MPT:
      return 'assets/currencies/mpt.png';
    case ETransactionCurrency.TUSD:
      return 'assets/currencies/tusd.png';
    case ETransactionCurrency.USDC:
      return 'assets/currencies/usdc.png';
    case ETransactionCurrency.USDT:
      return 'assets/currencies/usdt.png';
    case ETransactionCurrency.ZCASH:
      return 'assets/currencies/zcash.png';
    case ETransactionCurrency.SQN:
      return 'assets/currencies/sqn.png';
    case ETransactionCurrency.SIN:
      return 'assets/currencies/sin.png';
    case ETransactionCurrency.US_DOLLAR:
      return 'assets/currencies/us_dollar.png';
    case ETransactionCurrency.SUMMIT_TOKEN:
      return 'assets/currencies/summit_token.png';
    case ETransactionCurrency.BNB:
      return 'assets/currencies/bnb.png';
    default:
      return 'assets/currencies/summit_token.png';
  }
}

export function getCurrencySymbol(currency: ETransactionCurrency): string {
  switch (currency) {
    case ETransactionCurrency.BITCOIN:
      return 'btc';
    case ETransactionCurrency.BUSD:
      return 'busd';
    case ETransactionCurrency.BNB:
      return 'BNB';
    case ETransactionCurrency.DAI:
      return 'dai';
    case ETransactionCurrency.DASH:
      return 'dash';
    case ETransactionCurrency.ETHEREUM:
      return 'eth';
    case ETransactionCurrency.EURO:
      return '€';
    case ETransactionCurrency.LITECOIN:
      return 'ltc';
    case ETransactionCurrency.MPT:
      return 'mpt';
    case ETransactionCurrency.TUSD:
      return 'tusd';
    case ETransactionCurrency.USDC:
      return 'usdc';
    case ETransactionCurrency.USDT:
      return 'usdt';
    case ETransactionCurrency.ZCASH:
      return 'zcash';
    case ETransactionCurrency.SQN:
      return 'sqn';
    case ETransactionCurrency.SIN:
      return 'sin';
    case ETransactionCurrency.US_DOLLAR:
      return '$';
    case ETransactionCurrency.SUMMIT_TOKEN:
      return 'smt';
    default:
      return 'smt';
  }
}

export function getCurrencyNetwork(currency: ETransactionCurrency): string {
  switch (currency) {
    case ETransactionCurrency.BITCOIN:
      return "BTC";
    case ETransactionCurrency.BUSD:
      return "BNB";
    case ETransactionCurrency.DAI:
      return "ETH";
    case ETransactionCurrency.LTC:
      return "ETH";
    default:
      return ""
  }
}

export function getTransactionDirection(transaction: ITransaction): string {
  switch (transaction.type) {
    case ETransactionType.DEPOSIT:
      return 'Transactions.direction.depot';
    case ETransactionType.REFERRAL:
      return 'Transactions.direction.referral';
    case ETransactionType.INVESTMENT:
      return 'Transactions.direction.depot';
    case ETransactionType.BONUS:
      return 'Transactions.direction.bonus';
    case ETransactionType.MARKETING_BAG:
      return 'Transactions.direction.marketingbag';
    case ETransactionType.RESERVE_IGNITION:
      return 'Transactions.direction.reserve';
    case ETransactionType.COMPENSATION:
      return 'Transactions.direction.compensation';
    case ETransactionType.MARKETING_BAG_NOEL_23:
      return 'Transactions.direction.depot';
    case ETransactionType.MINING_INSTANT_AIDROP:
      return 'Transactions.direction.miningAirdrop';
    case ETransactionType.MINING_100_100:
      return 'Transactions.direction.mining100_100';
    case ETransactionType["MARKETING_BAG_CAMPAGNE_11/23"]:
      return 'Transactions.direction.depot';
    default:
      return 'Transactions.direction.depot';
  }
}
