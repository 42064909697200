import {KycTutorialComponent} from "./kyc-tutorial/kyc-tutorial.component";
import {ProfileComponent} from "../profile.component";
import {AcceptedCountryModal} from "./kyc-tutorial/kyc-tutorial-modal/accepted-country.component";
import {AcceptedSepaCountryModal} from "./kyc-tutorial/kyc-tutorial-modal/accepted-sepa-country.component";
import {KycComponent} from "./kyc/kyc.component";
import {KycFormComponent} from "./kyc-form/kyc-form.component";
import {TwoFaComponent} from "./twofa/twofa.component";
import {ChangePasswordComponent} from "./change-password/change-password.component";
import {ChangeAvatarModalComponent} from "./profile-modal-avatar/change-avatar.modal.component";
import {ChangeNameModalComponent} from "./profile-modal-name/change-name.modal.component";
import {HistoryComponent} from "@profile/components/history/history.component";
import {TwoFaGenericModalComponent} from "@profile/components/twofa/twofa-modal-generic/twofa-generic.modal.component";
import {KybTutorialComponent} from "./kyb-tutorial/kyb-tutorial.component";
import { MessagingComponent } from "./messaging/messaging.component";
import { WalletsComponent } from "./wallets/wallets.component";
import { ProfileLayoutComponent } from "./layout/layout.component";
import { KycFormWithdrawalComponent } from "./kyc-form-withdrawal/kyc-form-withdrawal.component";

export {
  KycComponent,
  ProfileComponent,
  KycTutorialComponent,
  KybTutorialComponent,
  AcceptedCountryModal,
  AcceptedSepaCountryModal,
  ChangePasswordComponent,
  TwoFaComponent,
  KycFormComponent,
  KycFormWithdrawalComponent,
  TwoFaGenericModalComponent,
  ChangeAvatarModalComponent,
  ChangeNameModalComponent,
  HistoryComponent,
  // KycModalComponent,
  WalletsComponent,
  MessagingComponent,
  ProfileLayoutComponent
};

export const COMPONENTS = [
  KycComponent,
  ProfileComponent,
  KycTutorialComponent,
  KybTutorialComponent,
  AcceptedCountryModal,
  AcceptedSepaCountryModal,
  ChangePasswordComponent,
  TwoFaComponent,
  KycFormComponent,
  KycFormWithdrawalComponent,
  TwoFaGenericModalComponent,
  ChangeAvatarModalComponent,
  ChangeNameModalComponent,
  HistoryComponent,
  // KycModalComponent,
  WalletsComponent,
  MessagingComponent,
  ProfileLayoutComponent
];
