import {AfterViewInit, Component, OnInit} from '@angular/core';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {ProfileService} from "@profile/services/profile.service";
import {IPhoneNumber} from "@models/dist/models/auth";
import {EAccountType} from "@models/dist";

@Component({
  selector: 'app-phone-number-modal',
  templateUrl: './phone-number-modal.component.html',
  styleUrls: ['./phone-number-modal.component.scss']
})
export class PhoneNumberModalComponent implements OnInit, AfterViewInit {

  public email;
  public accountType?: EAccountType;

  constructor(
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public profileService: ProfileService
  ) {
  }


  async ngOnInit() {
    this.email = this.config.data.email;
    this.accountType = this.config.data.accountType;
  }

  ngAfterViewInit() {

  }

  close() {
    this.ref.close();
  }

  phoneValidated(event: IPhoneNumber) {
    console.log(event);
    this.ref.close(event);
  }
}
