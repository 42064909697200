import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {firstValueFrom} from "rxjs";
import {environment} from "@environments/environment";

const BASE = `${environment.nestApiUrl}/api/sgp/ico`;

@Injectable({
  providedIn: 'root'
})
export class IcoService {


  constructor(private http: HttpClient) {
  }

  async getIco() {
    return firstValueFrom(this.http.get(`${BASE}`));
  }

  async getPrivateSale() {
    return firstValueFrom(this.http.get(`${BASE}/private-sales`));
  }

  getRoadmap() {
    return this.http.get(`${BASE}/roadmap`);
  }

  getStackings(): Promise<Object> {
    return firstValueFrom(this.http.get(`${BASE}/stackings`));
  }

  getAsset() {
    return firstValueFrom(this.http.get(`${BASE}/assets`));
  }

  getOrderBooks() {
    return firstValueFrom(this.http.get(`${BASE}/otc/orderbooks`))
  }

  addBuyOrder(value: any) {
    return firstValueFrom(this.http.post(`${BASE}/otc/orderbooks/buy`, {...value}))
  }

  addSellOrder(value: any) {
    return firstValueFrom(this.http.post(`${BASE}/otc/orderbooks/sell`, {...value}))

  }

  async getOtcData() {
    return firstValueFrom(this.http.get(`${BASE}/otc/user`))
  }

  updateCGU(b: boolean) {
    return this.http.post(`${environment.nestApiUrl}/api/sgp/users/cgu`, {cgu: b})

  }
}
