import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "@environments/environment";
import {firstValueFrom} from "rxjs";
import {EAssetsIdsToFireblocks, EVaultService} from "@models/dist";

const BASE = `${environment.nestApiUrl}/api/sgp/custody`;


@Injectable({
  providedIn: 'root'
})
export class CustodyService {


  constructor(private http: HttpClient) {
  }


  async getDepositAddress(assetId: EAssetsIdsToFireblocks, service: EVaultService): Promise<any> {
    return firstValueFrom(this.http.get(`${BASE}/${assetId}/deposit-wallet/${service}`));
  }
}
