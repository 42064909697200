import {Injectable} from '@angular/core';
import { STORAGE_LAST_REFRESH } from '@app/app.declaration';
import {Action} from '@ngxs/store';
import {setLastRefresh} from './utils.actions';

@Injectable()
export class UtilsState {

  constructor() {
  }

  @Action(setLastRefresh)
  async setLastRefresh() {
    try {
        const now = Date.now();
        localStorage.setItem(STORAGE_LAST_REFRESH, now.toString());
    } catch (err) {
      console.error(err);
    }
    return;
  }

}
