import {Component, OnDestroy, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {Select} from "@ngxs/store";
import {AuthState} from "@shared/stores/auth/auth.state";
import {filter, Observable, Subject, takeUntil} from "rxjs";
import {ERoutes, IUser} from "@models/dist";

// import {ERoutes} from "@models/dist";

@Component({
  selector: "app-entry",
  styleUrls: ['entry.component.scss'],
  templateUrl: "entry.component.html",
})
export class EntryComponent implements OnInit, OnDestroy {
  @Select(AuthState.getUser) user$: Observable<IUser> | undefined;
  loading = true;

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router
  ) {
    this.user$?.pipe(filter((user) => !!user), takeUntil(this.destroy$)).subscribe(({onboarding, service}) => {

      if (this.loading) {
        if(this.router.url.includes('discord')) {
          this.loading = false;
          return;
        }

        if (!onboarding) {
          this.router.navigateByUrl('/onboarding')
        } else if (this.router.url === '/') {
          this.router.navigateByUrl(`/${service}`)
        } else {
          this.router.navigateByUrl(router.url)
        }
        this.loading = false;
      }
    })

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async ngOnInit() {
    const auth = getAuth()
    onAuthStateChanged(auth, (user: any) => {
      if (!user && this.router.url === '/') {
        this.loading = false;
        this.router.navigate([ERoutes.AUTH]);
      } else if (!user) {
        this.router.navigateByUrl(this.router.url);
        this.loading = false;
      }
    });
  }

}
