export class Login {
  static readonly type = '[Auth] Login';

  constructor(public payload: { email: string; password: string, welcome: boolean, customToken?:string, showError?:boolean, extraHeaders?:any}) {
  }
}

export class Logout {
  static readonly type = '[Auth] Logout';

  constructor(public noRedirection?: boolean, public customToken?: string, public extraHeaders?: any) {
  }
}

export class VirtualDashboard {
  static readonly type = '[Auth] VirtualDashboard';

  constructor(public customToken?: string, public extraHeaders?: any) {
  }
}

export class loadMyUser {
  static readonly type = '[Auth] Load My User';
}

export class LoadUserAfterTwoFa {
  static readonly type = '[Auth] Load User After TwoFa';
}

export class confirmingPasswordReset {
  static readonly type = '[Auth] confirmingPasswordReset';

  constructor(public payload: { oobCode: string; password: string }) {
  }
}

export class VerifyPasswordResetCode {
  static readonly type = '[Auth] VerifyPasswordResetCode';

  constructor(public payload: { oobCode: string }) {
  }
}

export class ResetPassword {
  static readonly type = '[Auth] ResetPassword';

  constructor(public payload: { email: string }) {
  }
}

export class SendVerificationEmail {
  static readonly type = '[Auth] SendVerificationEmail';

  constructor(public payload: { email: string, toast: boolean }) {
  }
}


export class updateMfa {
  static readonly type = '[Auth] updateMfa';

  constructor(public payload: { mfa: boolean }) {
  }
}
