import {
  EYieldType,
  IBtcHistory,
  IMiningGainsHistory,
  IMiningParc,
  IMiningUser,
  IOverview
} from "@mining/store/mining.state";
import {EMiningChartFilter} from "@mining/store/gains/gains.state";

export class GetMiningUser {
  static readonly type = '[Mining] Get Mining User';
}

export class SuccessRetrieveMiningUser {
  static readonly type = '[Mining] Success Retrieve Mining User';

  constructor(public payload: IMiningUser) {
  }
}

export class DefaultMiningError {
  static readonly type = '[Mining] Default Mining Error';

  constructor(public payload: any) {
  }
}

export class GetOverview {
  static readonly type = '[Mining] Get Overview';
}

export class SuccessRetrieveOverview {
  static readonly type = '[Mining] Success Retrieve Overview';

  constructor(public payload: IOverview) {
  }
}

export class GetMiningParc {
  static readonly type = '[Mining] Get Mining Parc';
}

export class SuccessRetrieveMiningParc {
  static readonly type = '[Mining] Success Retrieve Mining Parc';

  constructor(public payload: IMiningParc) {
  }
}

export class GetMiningGainsHistory {
  static readonly type = '[Mining] Get Mining Gains History';
}

export class SuccessRetrieveMiningGainsHistory {
  static readonly type = '[Mining] Success Retrieve Mining Gains History';

  constructor(public payload: IMiningGainsHistory[]) {
  }
}

export class GetBtcHistory {
  static readonly type = '[Mining] Get Btc History';
}

export class SuccessRetrieveBtcHistory {
  static readonly type = '[Mining] Success Retrieve Btc History';

  constructor(public payload: IBtcHistory) {
  }
}

export class GetMiningTransactions {
  static readonly type = '[Mining] Get Mining Transactions';

  constructor(public year?: number) {

  }

}

export class GetMiningFile {
  static readonly type = '[Mining] Get Mining File';
}

export class GetMiningCurrency {
  static readonly type = '[Mining] Get Mining Currency';
}

export class GetMiningWallet {
  static readonly type = '[Mining] Get Mining Wallet';
}

export class GetAmountInvested {
  static readonly type = '[Mining] Get Mining Invested';
}

export class GetInvestedData {
  static readonly type = '[Mining] Get Mining Transactions Invested Data';

  constructor(
    public year?: number
  ) {
  }
}

export class GetMonthlyPayment {
  static readonly type = '[Mining] Get Mining Monthly Payment';
}

export class GetMiningCryptocurrency {
  static readonly type = '[Mining] Get Mining Cryptocurrency';
}

export class SetBtcChartFilter {
  static readonly type = '[Mining] Set Btc Chart Filter';

  constructor(public payload: EMiningChartFilter) {
  }
}

export class GetPerformance {
  static readonly type = '[Mining] Get Mining Performance';

  constructor(
    public type?: EYieldType
  ) {
  }

}
