import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxsModule} from '@ngxs/store';
import {AppRoutingModule} from './app-routing.module';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFirestoreModule, USE_EMULATOR as FIRESTORE_EMULATOR,} from '@angular/fire/compat/firestore';
import {environment} from '@environments/environment';
import {ToastModule} from 'primeng/toast';
import {MessageService} from 'primeng/api';
import {AngularFireAuthModule, USE_EMULATOR as AUTH_EMULATOR,} from '@angular/fire/compat/auth';
import {initializeApp} from 'firebase/app';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule,} from '@angular/common/http';
import {USE_EMULATOR as FUNCTIONS_EMULATOR} from '@angular/fire/compat/functions';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {ReactiveLoaderService} from "@app/shared/services";
import {AuthState} from "@shared/stores/auth/auth.state";
import {TransactionState} from "@shared/stores/transaction/transaction.state";
import {HelpState} from "@shared/stores/help/help.state";
import {globalHttpInterceptor} from "@shared/helpers/http.interceptor";
import {AppComponent} from "@app/app.component";
import {SharedModule} from "@shared/shared.module";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {SocketIoConfig, SocketIoModule} from "ngx-socket-io";
import {IcoState} from "@ico/stores/ico/ico.state";
import {ProfileState} from "@profile/stores/profile/profile-state.service";
import {NgxHotjarModule, NgxHotjarRouterModule} from "ngx-hotjar";
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from "ngx-google-analytics";
import {UtilsState} from './shared/stores/utils/utils.state';
import {ResearchComponent} from './research/research.component';
import {NgxStripeModule} from "ngx-stripe";
import {ResearchState} from "@research/stores/research.state";
import { MigrationComponent } from './migration/migration.component';
import { ComplianceComponent } from './compliance/compliance.component';
import {AirdropState} from "@shared/stores/airdrop/airdrop.state";
import {PixelModule} from "ngx-multi-pixel";
import {MiningState} from "@mining/store/mining.state";
import {MiningGateway} from "@mining/ports/mining.gateway";
import {HttpMiningGateway} from "@mining/adapters/http-mining.gateway";
import {MiningGainsState} from "@mining/store/gains/gains.state";


export const firebaseApp = initializeApp(environment.firebase);

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json?v=' + Date.now());
}


const config: SocketIoConfig = {url: environment.websocketApi, options: {}};

@NgModule({
  declarations: [AppComponent, ResearchComponent, MigrationComponent, ComplianceComponent],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    ProgressSpinnerModule,
    AppRoutingModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    SharedModule,
    ConfirmDialogModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgxsModule.forRoot([
      AuthState,
      TransactionState,
      ResearchState,
      HelpState, IcoState, ProfileState, UtilsState, AirdropState, MiningState, MiningGainsState]),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    ToastModule,
    SocketIoModule.forRoot(config),
    NgxGoogleAnalyticsModule.forRoot(environment.GA),
    NgxGoogleAnalyticsRouterModule,
    NgxStripeModule.forRoot(environment.stripe_key),
    PixelModule.forRoot({ enabled: true, pixelId: ['494785852996686'] })
  ],
  providers: [
    MessageService,
    {
      provide: AUTH_EMULATOR,
      useValue: !environment.emulator
        ? undefined
        : ['http://localhost:9099', 9099],
    },
    {
      provide: FIRESTORE_EMULATOR,
      useValue: !environment.emulator
        ? undefined
        : ['http://localhost:8080', 8080],
    },
    {
      provide: FUNCTIONS_EMULATOR,
      useValue: !environment.emulator
        ? undefined
        : ['http://localhost:5001', 5001],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: globalHttpInterceptor,
      multi: true,
    },
    ReactiveLoaderService,
    {provide: Window, useValue: window},
    {
      provide: MiningGateway,
      useFactory: () => new HttpMiningGateway()
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
}
