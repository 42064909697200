import {Component} from '@angular/core';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {ProfileService} from "@profile/services/profile.service";
import {IPhoneNumber} from "@models/dist/models/auth";
import {AllCountries, ELang, EToastSeverity} from "@models/dist";
import {BehaviorSubject, map} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ToastService} from "@shared/services";
import {Store} from "@ngxs/store";
import {GetProfile} from "@profile/stores/profile/profile.actions";
import {loadMyUser} from "@shared/stores/auth/auth.actions";

@Component({
  selector: 'app-country-set-modal',
  templateUrl: './country-set-modal.component.html',
  styleUrls: ['./country-set-modal.component.scss']
})
export class CountrySetModalComponent {
  public email;

  france = AllCountries.find(({code}) => code === 'FR')!;
  lang$ = new BehaviorSubject<ELang>(this.translate.currentLang as ELang);
  countries$ = this.lang$.pipe(
    map((lang) => {
      return AllCountries.map((data) => {
        return {...data, name: data.name[lang]}
      }).sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
    }),
  );

  form?: FormGroup;

  constructor(
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public profileService: ProfileService,
    public translate: TranslateService,
    private fb: FormBuilder,
    private toatsService: ToastService,
    private store: Store,
  ) {
  }


  async ngOnInit() {
    this.email = this.config.data.email;
    this.translate.onLangChange.subscribe((lang) => {
      const langValue = lang.lang as ELang
      this.lang$.next(langValue)
    })

    this.form = this.fb.group({
      searchCountry: this.fb.control(''),
      country: this.fb.control('', [Validators.required])
    });
  }

  clear(options) {
    options.reset();
    this.form?.patchValue({
      searchCountry: ''
    })
  }

  ngAfterViewInit() {

  }

  close() {
    this.ref.close();
  }

  phoneValidated(event: IPhoneNumber) {
    console.log(event);
    this.ref.close(event);
  }

  async validate() {
    await this.profileService.updateCountry(this.form?.value.country.code);
    this.toatsService.addToast(
      EToastSeverity.success,
      'Information','Common.successCountrSet'
    )
    this.store.dispatch(new GetProfile());
    this.store.dispatch(new loadMyUser());
    this.close();
  }
}
