import { Component } from '@angular/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-ico-wallet-modal',
  templateUrl: './ico-wallet-modal.component.html',
  styleUrls: ['./ico-wallet-modal.component.scss']
})
export class IcoWalletModalComponent {
  title = 'ico.dashboard.wallet.empty';


  constructor(
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
  ) {
  }

  async ngOnInit() {
    this.title = this.config.data.title ?? 'ico.dashboard.wallet.empty'
  }

  cancel() {
    this.ref.close(false);
  }

  close() {
    this.ref.close();
  }

}
