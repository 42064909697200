import {Component, ElementRef, ViewChild} from '@angular/core';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {
  getAmount,
  getCurrencyImg,
  getCurrencyNetwork,
  getDate,
  getInfos,
  getStatus,
  getTransactionDirection,
  getTransactionProduct,
  getUid
} from '@shared/helpers/transactions.utils';
import {ETransactionCurrency, ETransactionStatus, ETransactionType, ITransaction} from "@models/dist";
import {ReactiveLoaderService, TransactionService} from "@shared/services";
import {map} from "rxjs";
import {WebSocketService} from "@shared/services/webSocket.service";
import {reactiveButtonState} from "@shared/services/reactive-loader.service";
import {setLastRefresh} from '@app/shared/stores/utils/utils.actions';
import {Store} from '@ngxs/store';

@Component({
  selector: 'app-transaction-details',
  templateUrl: './transaction-details.modal.component.html',
  styleUrls: ['../scss/transactions.scss']
})
export class TransactionDetailsModal {

  transaction: ITransaction | undefined;
  getInfos = getInfos;
  getDate = getDate;
  getUid = getUid;
  getAmount = getAmount;
  getStatus = getStatus;
  getTransactionProduct = getTransactionProduct;
  getCurrencyImg = getCurrencyImg;
  getTransactionDirection = getTransactionDirection;
  ETransactionStatusCompleted = ETransactionStatus.COMPLETED;
  ETransactionTypeReferral = ETransactionType.REFERRAL;
  public hasBeenUpdated: boolean = false;
  public encode: string = '';
  public name = '';

  @ViewChild('donwload') donwload = new ElementRef('donwload');

  constructor(
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private transactionService: TransactionService,
    public config: DynamicDialogConfig,
    private websocketService: WebSocketService,
    private reactiveLoaderService: ReactiveLoaderService,
    private store: Store,
  ) {

  }

  async ngOnInit() {
    this.name = this.config.data.name;
    this.transaction = this.config.data.transaction;
    this.initWebSocket();
  }

  close() {
    this.ref.close();
  }

  getId(id: number | undefined) {
    return `ST${id}`.toUpperCase().substring(0, 9);
  }

  getCurrencyNetwork(fromCurrency: ETransactionCurrency | undefined) {
    if (fromCurrency) {
      return getCurrencyNetwork(fromCurrency);
    }
    return '';
  }

  async download(): Promise<any> {
    try {
      this.reactiveLoaderService.setReactiveButtonState(
        'download-btn',
        reactiveButtonState.loading
      );
      const {data, name} = await this.transactionService.getConfirmationFund(this.transaction?.id) as { data: string, name:string }
      const byteArray = new Uint8Array(atob(data).split('').map(char => char.charCodeAt(0)));
      const blob = new Blob([byteArray], {type: 'application/pdf'});
      console.log(blob);

      const encode = window.URL.createObjectURL(blob);
      console.log(encode);
      this.reactiveLoaderService.setReactiveButtonState(
        'download-btn',
        reactiveButtonState.success
      );
      this.encode = encode;
      this.name = name;
      setTimeout(() => {
        const el: any = this.donwload.nativeElement;
        el.click();
      }, 10)
      // window.open(encode, '_blank');
    } catch (e) {
      console.log(e);
    }
  }

  private initWebSocket() {
    this.websocketService.getUserUpdateTransaction(this.config.data.userId).pipe(map((data: any) => data)).subscribe((transaction: ITransaction) => {
      if (transaction.id === this.transaction?.id) {
        this.hasBeenUpdated = true;
        this.transaction = {...transaction, created: transaction.created_at, updated: transaction.updated_at};
        this.store.dispatch(new setLastRefresh());
        setTimeout(() => {
          this.hasBeenUpdated = false
        }, 3000)
      }
    })
  }
}
