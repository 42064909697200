import {Component, OnInit} from '@angular/core';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-gravity-modal',
  templateUrl: './gravity-modal.component.html',
  styleUrls: ['./gravity-modal.component.scss']
})
export class GravityModalComponent implements OnInit {


  constructor(
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
  ) {
  }

  async ngOnInit() {
  }

  cancel() {
    this.ref.close(false);
  }

  close() {
    this.ref.close();
  }

  goToCalendly() {
  }

  goToGravity() {
    window.open('https://gravity.summit.io/')
  }
}
