<div class="errorDisplay">
  <!--close modal button -->
  <button
    (click)="close()"
    class="close"
    icon="pi pi-times"
    pButton
  ></button>
  <div class="modal-header">
    <div class="title text-center">
      {{ 'Common.updatePhoneNumber' | translate}}
    </div>
    <span class="description mb-3 text-center display-block" [innerHTML]="'phone.verificationTelDescription' | translate">
    </span>
  </div>

  <div class="modal-body">
    <app-phone-number
      (formChangeEvent)="phoneValidated($event)"
      [displayTitle]="false"
      [noFocus]="true"
      [email]="email"
      [accountType]="accountType"
    ></app-phone-number>
  </div>
  <div class="modal-footer mt-4">
    <app-ico-btn (click)="close()" [outline]="true" [text]="'Common.close' | translate"></app-ico-btn>
  </div>
</div>

