<div class="transactionDisplay">
  <!--close modal button -->
  <button
    (click)="close()"
    class="close"
    icon="pi pi-times"
    pButton
  ></button>

  <div class="modal-header">
    <div class="flex justify-content-center flex-column">
      <span [innerHtml]="'Transactions.details-modal.title'  | translate: { id: getId(transaction?.id)}"
            class="title"></span>
      <span
        [innerHtml]="'Transactions.details-modal.textTransaction' | translate"
        class="subtitle text-center"
      ></span>
    </div>
  </div>

  <!--  // DISPLAY NORMAL TRANSACTION DETAILS-->
  <div class="modal-body p-4" *ngIf="transaction && transaction.type !== ETransactionTypeReferral">
    <div class="lineValue w-full justify-content-center" *ngIf="hasBeenUpdated">
      <div class="txLabel green tempory">Mise à jour des données..</div>
    </div>
    <div class="lineValue">
      <div class="txLabel">{{ 'Transactions.details-modal.statut' | translate }}</div>
      <div [style]="{color: getStatus(transaction!)!.textcolor}"
           class="txValue">{{getStatus(transaction!)!.text | translate}}</div>
    </div>
    <div class="lineValue">
      <div class="txLabel">{{ 'Transactions.details-modal.date' | translate }}</div>
      <div class="txValue">{{transaction.created_at | localizedDate:'medium'}}</div>
    </div>
    <div class="lineValue" *ngIf="!!transaction.round">
      <div class="txLabel">{{ 'Transactions.details-modal.phaseico' | translate }}</div>
      <div
        class="txValue">{{ 'Transactions.details-modal.round' | translate:{round: transaction.round.name | PhaseName} }}</div>
    </div>
    <div class="lineValue">
      <div class="txLabel">{{ 'Transactions.details-modal.amount' | translate }}</div>
      <div class="flex align-items-center min-w-5rem justify-content-between">
        <div class="txValue ">{{ (transaction.amountEUR > 0) ? (transaction.amountEUR | customNumber:'3':'0':'€') : (transaction.fromAmount | customNumber: '': '': '€') }}</div>
      </div>
    </div>

    <div class="lineValue">
      <div class="txLabel">{{ 'Transactions.details-modal.currencyAmount' | translate }}</div>
      <div class="flex align-items-center min-w-5rem justify-content-between">
        <div class="txValue ">{{ transaction.amount | customNumber:'3':'0':transaction.currency }}</div>
      </div>
    </div>

    <div class="lineValue">
      <div class="txLabel">{{ 'Transactions.details-modal.rate' | translate }}</div>
      <div class="flex align-items-center min-w-5rem justify-content-between">
        <div class="txValue ">{{ transaction.round ? (transaction.round.price | customNumber : '7': '5': '€') : '-'}}</div>
      </div>
    </div>

    <div class="lineValue" *ngIf="transaction.custody !== null">
      <div class="txLabel">{{ 'Transactions.details-modal.network' | translate }}</div>
      <div class="txValue">{{ transaction.custody.currency.network}}</div>
    </div>

    <div class="lineValue" *ngIf="transaction.custody !== null">
      <div class="txLabel">{{ 'Transactions.details-modal.address' | translate }}</div>
      <div class="flex align-items-center">
        <div class="txValue">{{ transaction.custody.address | Address}}</div>
        <app-copy [mini]="true" [text]="transaction.custody.address!"></app-copy>
      </div>
    </div>

    <div class="lineValue" *ngIf="!!transaction.txHash">
      <div class="txLabel">{{ 'Transactions.details-modal.txid' | translate }}</div>
      <div class="flex align-items-center">
        <div class="txValue">{{transaction.txHash! | Address}}</div>
        <app-copy [mini]="true" [text]="transaction.txHash!"></app-copy>
      </div>
    </div>


    <div class="modal-footer mt-6">
      <div class="flex actions-buttons align-items-center justify-content-between w-full">
        <div class="col p-0" [class.mr-5]="transaction.status === ETransactionStatusCompleted">
          <app-ico-btn [outline]="true" [text]="'Common.close' | translate" (click)="close()"></app-ico-btn>
        </div>
        <div class="col p-0" *ngIf="transaction.status === ETransactionStatusCompleted">
          <app-ico-btn rId="download-btn" [outline]="false" [text]="'Common.download' | translate"
                       (click)="download()"></app-ico-btn>
        </div>

        <a [href]="encode" target="_blank" rel="noopener" [download]="name" #donwload class="p-hidden"></a>
      </div>
    </div>
  </div>
  <!--// DISPLAY REFERRAL DETAILS-->
  <div class="modal-body p-4" *ngIf="transaction && transaction.type === ETransactionTypeReferral">
    <div class="lineValue">
      <div class="txLabel">{{ 'Transactions.details-modal.statut' | translate }}</div>
      <div [style]="{color: getStatus(transaction!)!.textcolor}"
           class="txValue">{{getStatus(transaction!)!.text | translate}}</div>
    </div>

    <div class="lineValue">
      <div class="txLabel">{{ 'Transactions.details-modal.date' | translate }}</div>
      <div class="txValue">{{transaction.created_at | localizedDate:'medium'}}</div>
    </div>
    <div class="lineValue">
      <div class="txLabel">{{ 'Transactions.details-modal.amount-child' | translate }}</div>
      <div class="flex align-items-center min-w-5rem justify-content-between">
        <div class="txValue ">{{ transaction.fromAmount | customNumber:'3':'0':'€' }}</div>
      </div>
    </div>

    <div class="lineValue">
      <div class="txLabel">{{ 'Transactions.details-modal.percent' | translate }}</div>
      <div class="flex align-items-center min-w-5rem justify-content-between">
        <div class="txValue ">2%</div>
      </div>
    </div>

    <div class="lineValue">
      <div class="txLabel">{{ 'Transactions.details-modal.gain' | translate }}</div>
      <div class="flex align-items-center min-w-5rem justify-content-between">
        <div class="txValue ">{{ transaction.amount | customNumber:'3':'0':transaction.currency }}</div>
      </div>
    </div>

    <div class="lineValue" *ngIf="!!transaction.linkedTransaction">
      <div class="txLabel">{{ 'Transactions.details-modal.child' | translate }}</div>
      <div class="flex align-items-center min-w-5rem justify-content-between">
        <div class="txValue ">{{ transaction.linkedTransaction.user.firstName }} {{ transaction.linkedTransaction.user.lastName }}</div>
      </div>
    </div>


  </div>
</div>
