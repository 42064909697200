import {Component, OnDestroy, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ToastService} from "@shared/services";
import {AuthState} from '@shared/stores/auth/auth.state';
import {IUser} from "@models/dist";
import {Observable, Subject, takeUntil} from 'rxjs';

@Component({
  selector: 'app-change-name',
  templateUrl: './change-name.modal.component.html',
  styleUrls: ['./change-name.modal.component.scss']
})
export class ChangeNameModalComponent implements OnInit, OnDestroy {

  subscription: any;
  firstName: string | undefined;
  lastName: string | undefined;
  @Select(AuthState.getUser) user$: Observable<IUser> | undefined;

  private destroy$ = new Subject<void>();


  constructor(
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private store: Store,
    private toastService: ToastService
  ) {
  }

  async ngOnInit() {
    this.subscription = this.user$!.pipe(takeUntil(this.destroy$)).subscribe(user => {
      this.firstName = user.firstName;
      this.lastName = user.lastName;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  cancel() {
    this.ref.close(false);
  }

  close() {
    this.ref.close();
  }
}
