<div class="errorDisplay">
  <!--close modal button -->
  <button
    class="close"
    icon="pi pi-times"
    pButton
  ></button>
  <div class="modal-header">
    <div [innerHTML]="'errorMigrated.title' | translate" class="title text-center">

    </div>

  </div>
  <div class="modal-body">
    <span [innerHTML]="'errorMigrated.description' | translate" class="description"></span>
  </div>
  <div class="modal-footer">
    <app-ico-btn (click)="logout()" [text]="'errorMigrated.logout'|translate"></app-ico-btn>
  </div>
</div>
