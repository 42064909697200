import {Component, OnInit} from '@angular/core';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {ToastService, TransactionService} from "@shared/services";
import {EToastSeverity} from "@models/dist";

@Component({
  selector: 'app-cancel-modal-transaction',
  templateUrl: './cancel-modal-transaction.component.html',
  styleUrls: ['./cancel-modal-transaction.component.scss']
})
export class CancelModalTransactionComponent implements OnInit {
  public id: string = '';

  constructor(
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private transactionService: TransactionService,
    private toastService: ToastService
  ) {
  }

  ngOnInit(): void {
    this.id = this.config.data.id;
  }

  close() {
    this.ref.close();
  }

  async cancel() {
    try {
      await this.transactionService.cancelTransactions(this.id);
      this.ref.close();
    } catch (e) {
      this.toastService.addToast(EToastSeverity.error, 'Error', e);
    }
  }
}
