<div class="errorDisplay" *ngIf="!!form">
  <!--close modal button -->
  <button
    (click)="close()"
    class="close"
    icon="pi pi-times"
    pButton
  ></button>
  <div class="modal-header">
    <div class="title text-center">
      {{ 'Common.updateCountry' | translate}}
    </div>
    <span class="description mb-3 text-center display-block"
          [innerHTML]="'Common.updateCountryDescription' | translate">
    </span>
  </div>

  <div class="modal-body" *ngIf="(countries$ | async ) as countries" [formGroup]="form">
    <p-dropdown formControlName="country" [options]="countries" optionLabel="name" [filter]="true"
                filterBy="name"
                [emptyFilterMessage]="'Common.notFound' | translate"
                [showClear]="true" [placeholder]="'country' | translate">
      <ng-template pTemplate="filter" let-options="options">
        <div class="search-dropdown">
          <div class="input-ctn" (click)="$event.stopPropagation()">
            <span class=""><i class="pi pi-search"></i></span>
            <input class="w-full" type="text" pInputText [placeholder]="'searchCountry' | translate"
                   formControlName="searchCountry" (keyup)="options.filter($event)">
            <span (click)="clear(options)">
                      <img src="/assets/icon/close.svg" alt="">
                    </span>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="selectedItem">
        <div class="country-item country-item-value" *ngIf="form.value?.country">
          {{ form.value?.country.flag }}
          <span class="pl-2">{{form.value?.country.name}}</span>
        </div>
      </ng-template>
      <ng-template let-country pTemplate="item">
        <div class="country-item">
          {{ country.flag }}
          <span class="pl-2">{{country.name}}</span>
        </div>
      </ng-template>
    </p-dropdown>
  </div>
  <div class="modal-footer mt-4">
    <app-ico-btn (click)="validate()" [text]="'Common.validate' | translate"></app-ico-btn>
  </div>
</div>

