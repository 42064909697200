<div class="">
  <app-ico-top [title]="'ico.top.wallets' | translate"></app-ico-top>


  <p-card class="mt-3">
    <ng-container *ngIf="user$ | async">
      <div *ngIf="!user?.isTotpActivated" class="bannerMsg error mb-6">
        <div class="img">
          <img src="/assets/icon/twofa.svg" />
        </div>
        <div class="msg">
          <div class="title">
            {{ "2FA.page2fa.notProtected" | translate }}
          </div>
          <div [innerHtml]="'2FA.page2fa.notProtectedTextWallet' | translate" class="msg"></div>
          <div (click)="openGoogle2FaModal()" *ngIf="!user?.isTotpActivated" class="link">
            {{ "2FA.page2fa.notProtectedLink" | translate }}
          </div>
        </div>
      </div>
    </ng-container>
    <div class="flex justify-content-between items-center mb-5 top-ctn">
      <h2 [innerHTML]="'wallets.title' | translate" class="ico-subtitle"></h2>
      <app-research-btn-list [disabled]="!user?.isTotpActivated" [isIco]='true' class="btn-list mr-3" (clickEvent)="createOrEditWallet()" [label]="'wallets.addWallet'| translate">
        <span *ngIf="!hidden" (click)="getHiddenWallets()" [innerHTML]="'wallets.showHidenWallet' | translate"></span>
        <span *ngIf="hidden" (click)="getWallets()" [innerHTML]="'wallets.hideHidenWallet' | translate"></span>

      </app-research-btn-list>
    </div>
    <p-table *ngIf="wallets.length > 0" #dt2 [value]="wallets" dataKey="id" [rowsPerPageOptions]="[5,10,25,50]" [paginator]="true" [alwaysShowPaginator]="wallets.length > 5" [rows]="5">
      <ng-template pTemplate="header">
        <tr [class]="'mt-3 mb-3 header-table research-header-table'">
          <th pSortableColumn="name">
            {{'wallets.row.name' | translate }}
            <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th pSortableColumn="address">
            {{'wallets.row.walletAddress' | translate }}
            <p-sortIcon field="address"></p-sortIcon>
          </th>
          <th pSortableColumn="status">
            {{'wallets.row.status' | translate }}
            <p-sortIcon field="status"></p-sortIcon>
          </th>
          <th style="width: 10em">
            {{'wallets.row.actions' | translate }}
          </th>
        </tr>
      </ng-template>
      <ng-template let-columns="columns" let-row pTemplate="body">
        <tr class="wallets-row">
          <td class="td wallet-row">
            <div class="name-token-container">
              <img [src]="'/assets/currencies/' + row.currency.slug.toLowerCase() + '.png'" alt="" height="40px" width="40px">
              <div class="name-token">
                <span class="name">
                  {{ row.name }}
                </span>

                {{getCurrencyName(row)}}
              </div>
            </div>
          </td>
          <td class="td" style="width: 30em;">
            <span class="name">
              {{ row.address }}
            </span>
          </td>
          <td class="td status">
            <div [style]="{ background: getWalletStatus(row.status)!.color || '#777'}" class="badge-status">
              <span [style]="{ color: getWalletStatus(row.status)!.textcolor || '#777'}" [innerHTML]="getWalletStatus(row.status)!.text  | translate">
              </span>
            </div>
          </td>
          <td class="">
            <div class="flex justify-content-center">
              <app-research-btn-list [disabled]="!user?.isTotpActivated" [isIco]='true' (clickEvent)="createOrEditWallet(row)" [label]="'wallets.edit'| translate">
                <span (click)="hideWallet(row.id)" [innerHTML]="(row.hidden ? 'wallets.showWallet' : 'wallets.hideWallet') | translate"></span>
              </app-research-btn-list>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </p-card>
</div>
