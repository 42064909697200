export class GetIco {
  static readonly type = '[ico] get ico data';
}

export class TooglePastPhase {
  static readonly type = '[ico] tootlge past phase';
}

export class GetPrivateSale {
  static readonly type = '[ico private sale] get ico private sale data';
}


