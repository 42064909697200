import {AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Select, Store} from "@ngxs/store";
import {AuthState} from "@shared/stores/auth/auth.state";
import {BehaviorSubject, delayWhen, filter, interval, map, Observable, of, startWith, Subscription, timer} from "rxjs";
import {
  AllCountries,
  BannedCountry,
  EKycStatus,
  EKycStepStatus,
  EKycType,
  ELang,
  EPassfortProductState,
  EToastSeverity,
  firstInvestAttemptOptions,
  genders as gendersOptions,
  IKyc,
  IStepKyc,
  IUser,
  jobsSectorOptions,
  jobsTypeOptions,
  originsFundsOptions,
  plannedInvestForNext12MonthOptions,
  RiskCountry
} from "@models/dist";
import {OnfidoService} from "@profile/services/onfido.service";
import {TranslateService} from "@ngx-translate/core";
import {ReactiveLoaderService, ToastService} from "@shared/services";
import {ActivatedRoute, Router} from "@angular/router";
import {ProfileService} from "@profile/services/profile.service";
import {DialogService} from "primeng/dynamicdialog";
import {PrimeNGConfig} from "primeng/api";
import {reactiveButtonState} from "@shared/services/reactive-loader.service";
import placekit, {PKAClient} from "@placekit/autocomplete-js";
import {init, SdkResponse, SupportedLanguages} from "onfido-sdk-ui";
import {LocalFr} from "@profile/components/kyc-light/local.fr";
import {LocalEn} from "@profile/components/kyc-light/local.en";
import {loadMyUser} from "@shared/stores/auth/auth.actions";
import {ContactModalComponent, PhoneNumberModalComponent} from "@shared/components";
import {IPhoneNumber} from "@models/dist/models/auth";
import {IKYCLightStep} from "@profile/components/kyc-light/kyc-light.component";
import {FileSelectEvent} from "primeng/fileupload";
import {FileService} from "@shared/services/file.service";
import {first, switchMap} from "rxjs/operators";
import {
  isFourthStepFilesValidator,
  isFourthStepValidator, isNotNullValidator,
  isRequiredIfLookingJob,
  isThirdStepValidator, isThirdstepValidatorIfAttempt
} from "@profile/components/kyc-form/validator";
import {HttpEvent, HttpEventType} from "@angular/common/http";
import {createMask} from "@ngneat/input-mask";
import snsWebSdk from '@sumsub/websdk';


export interface IOnfidoStatus {
  pepStatus: { result: string, status: string },
  onfidoCheckStatus: { result: string, status: string }
}


export enum EKycForm {
  LIGHT = 'LIGHT',
  FULL = 'FULL'
}

@Component({
  selector: 'app-kyc-form',
  templateUrl: './kyc-form.component.html',
  styleUrls: ['./kyc-form.component.scss', './../scss/profile.scss']
})
export class KycFormComponent implements OnInit, OnDestroy, AfterViewInit {


  form?: FormGroup;
  @Select(AuthState.getUser) user$: Observable<IUser> | undefined;
  private user?: IUser;
  steps = IKYCLightStep;
  stepsToReview: IStepKyc[] = []
  currentStep = 0;

  ref: any;

  lang$ = new BehaviorSubject<ELang>(this.translate.currentLang as ELang);
  countries$ = this.lang$.pipe(
    map((lang) => {
      return AllCountries.map((data) => {
        return {...data, name: data.name[lang]}
      }).sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
    })
  );

  jobsTypeOptions = jobsTypeOptions.map((item) => ({...item, name: item.name[this.lang$.getValue()]}));

  jobsSectorOptions = jobsSectorOptions.map((item) => ({...item, name: item.name[this.lang$.getValue()]}));

  firstInvestAttemptOptions = firstInvestAttemptOptions

  plannedInvestForNext12MonthOptions = plannedInvestForNext12MonthOptions;

  originsFundsOptions = originsFundsOptions.map((item) => ({...item, name: item.name[this.lang$.getValue()]}))

  countryFr = AllCountries.find(({code}) => code === 'FR')!;
  france = this.countryFr
  //{...this.countryFr, name: this.countryFr.name[this.lang$.getValue()]}

  onfido: any;
  public loadingOnfido = true;
  minDate: Date = new Date();
  maxDate: Date = new Date();
  middleDate: Date = new Date();
  loading = true;


  localStorageKey = "kyc_light_step"
  public manualEnterAddress: boolean = false;
  files: any[] = [];


  genders = gendersOptions;


  kycTypeForm: EKycForm = EKycForm.LIGHT;
  EKycTypeForm = EKycForm;
  public pepCheck: boolean = false;


  public fileProofIncome: any[] = [];
  public fileProofTaxResidence: any[] = [];
  public fileProofOriginFunds: any[] = [];
  public fileProofRIB: any[] = [];
  public fileProofBancaire: any[] = [];

  @ViewChild('fileFundsInput') fileFundsInput: any;
  @ViewChild('fileTaxInput') fileTaxInput: any;
  @ViewChild('fileIncomeInput') fileIncomeInput: any;
  @ViewChild('fileRIBInput') fileRIBInput: any;
  @ViewChild('fileBancaireInput') fileBancaireInput: any;


  public enhanceKycLoading: boolean = false
  private timeInterval?: Subscription;
  public needEnhancedKyc: boolean = false;


  currencyInputMask$: Observable<any> = this.lang$.pipe(
    map((lang) => {
      if (lang === ELang.FR) {
        return createMask({
          alias: 'numeric',
          groupSeparator: ' ',
          allowMinus: false,
          numericInput: false,
          rightAlign: false,
          digits: 2,
          digitsOptional: true,
          prefix: '',
          placeholder: '',
          suffix: ' €',
        })
      } else {
        return createMask({
          alias: 'numeric',
          numericInput: false,
          rightAlign: false,
          groupSeparator: ',',
          digits: 2,
          digitsOptional: true,
          prefix: '€',
          placeholder: '',
          suffix: ''
        })
      }
    })
  )
  public incomeDrag: boolean = false;
  public taxDrag = false
  public fundsDrag = false
  public ribDrag = false
  public bancaireDrag = false
  public isToReview: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setMaxWidthForInput()
  }

  constructor(
    private fileService: FileService,
    public taostService: ToastService,
    private fb: FormBuilder,
    private onfidoService: OnfidoService,
    private translate: TranslateService,
    private reactiveLoader: ReactiveLoaderService,
    private router: Router,
    private route: ActivatedRoute,
    private profileService: ProfileService,
    private dialogService: DialogService,
    private store: Store,
    private toastService: ToastService,
    private config: PrimeNGConfig
  ) {
  }

  ngAfterViewInit(): void {
    this.setUpPlaceKit();
  }


  ngOnInit() {
    this.store.dispatch(new loadMyUser());
    this.route.queryParams.subscribe(({light, full}) => {
      this.kycTypeForm = !!light ? EKycForm.LIGHT : (!!full ? EKycForm.FULL : EKycForm.LIGHT);
    })

    this.translate.onLangChange.subscribe((lang) => {
      const langValue = lang.lang as ELang

      this.lang$.next(langValue);
      this.translate.get('primeng').subscribe(res => this.config.setTranslation(res));
      this.setUpPlaceKit();
    })
    this.translate.get('primeng').subscribe(res => this.config.setTranslation(res));

    this.user$?.pipe(filter(data => !!data), first()).subscribe(async (user) => {
      this.user = user;


      const banned = BannedCountry.find(({code}) => code === user.country)
      const risk = RiskCountry.find(({code}) => code === user.country);
      if (banned) {
        this.router.navigateByUrl('/profile/kyc')
        return;
      }
      if (risk && this.kycTypeForm === EKycForm.LIGHT) {
        this.router.navigateByUrl('/profile/kyc')
        return;
      }
      if (
        this.kycTypeForm === EKycForm.FULL
      ) {
        if (this.user?.complianceStatus === EPassfortProductState.APPROVED || this.user?.complianceStatus === EPassfortProductState.REJECTED || this.user?.complianceStatus === EPassfortProductState.IN_REVIEW) {
          this.router.navigateByUrl('/profile/kyc')

          return;
        }
      } else {
        if (this.user?.kycLightStatus === EPassfortProductState.APPROVED || this.user?.kycLightStatus === EPassfortProductState.REJECTED || this.user?.kycLightStatus === EPassfortProductState.IN_REVIEW) {
          this.router.navigateByUrl('/profile/kyc')

          return;
        }
      }

      await this.getCurrentKyc(this.kycTypeForm);

    })
  }

  async handleFirstAttemptInvestChange(event: any) {
    const id = event.value.id
    console.log(id === 'between_5_001_10_000');
    if (id === 'between_5_001_10_000') {
      this.form?.get('fileProofTaxResidence')?.setValidators([isThirdstepValidatorIfAttempt(event.value.id)])
      this.form?.get('fileProofTaxResidence')?.updateValueAndValidity();
    }else{
      this.form?.get('fileProofTaxResidence')?.clearValidators();
      this.form?.get('fileProofTaxResidence')?.setValidators([isFourthStepValidator(this.currentStep)])
      this.form?.get('fileProofTaxResidence')?.updateValueAndValidity();
    }
  }

  async initForm(user: IUser) {
    const lang = this.lang$.getValue();
    const now = new Date();
    this.minDate = new Date(new Date().setFullYear(now.getFullYear() - 100));
    // this.middleDate = new Date(new Date().setFullYear(now.getFullYear() - 35));
    this.maxDate = new Date(new Date().setFullYear(now.getFullYear() - 18));
    const country = !!user.country ? AllCountries.find(({code}) => code === user.country) : this.france;
    const nationality = !!user.nationality ? AllCountries.find(({code}) => code === user.nationality) : this.france;
    const otherNationality = !!user.otherNationality ? AllCountries.find(({code}) => code === user.otherNationality) : null;


    const nationalityFormValue = {...nationality, name: nationality?.name[lang]};
    const otherNationalityFormValue = otherNationality ? {
      ...otherNationality,
      name: otherNationality?.name[lang]
    } : null;

    const countryFormValue = {...country, name: country?.name[lang]};
    console.log(user.birthday);
    this.form = this.fb.group({
      isAvailable: this.fb.control(true, [Validators.required, Validators.requiredTrue]),
      step: this.fb.control(0),
      firstName: this.fb.control(user.firstName, [Validators.required]),
      lastName: this.fb.control(user.lastName, [Validators.required]),
      phoneNumber: this.fb.control({
        value: !!user.phoneNumber && user.phoneNumber.length > 0 ? user.phoneNumber : null,
        disabled: !!user.phoneNumber && user.phoneNumber.length > 0 ? true : false,
      }, [Validators.required]),
      type: this.fb.control(this.kycTypeForm, [Validators.required]),
      birthday: this.fb.control(!!user.birthday ? new Date(user.birthday).toLocaleDateString() : null, [Validators.required]),
      email: this.fb.control({value: user.email, disabled: true}, [Validators.required]),
      nationality: this.fb.control(nationalityFormValue, [Validators.required]),
      otherNationality: this.fb.control(otherNationalityFormValue, []),
      searchCountry: this.fb.control(''),
      searchFundsOriginType: this.fb.control(''),
      searchNationality: this.fb.control(''),
      searchOtherNationality: this.fb.control(''),
      country: this.fb.control(countryFormValue, [Validators.required]),
      onfido: this.fb.control(true, [Validators.required, Validators.requiredTrue]),
      postalAddress: this.fb.control(user.postalAddress, [Validators.required]),
      postalAddressLigne2: this.fb.control(null, []),
      postalCode: this.fb.control(user.postalCode, [Validators.required]),
      city: this.fb.control(user.city, [Validators.required]),
      gender: this.fb.control(!!user.gender ? this.genders.find((option) => option.id === user.gender) : null, [Validators.required]),
      searchJobsType: this.fb.control(''),
      searchJobsSector: this.fb.control(''),
      searchJobs: this.fb.control(''),
      searchCountryFiscalFunds: this.fb.control(''),
      searchCountryFundsOriginType: this.fb.control(''),
      jobsType: this.fb.control(null, [isThirdStepValidator(this.currentStep)]),
      jobsSector: this.fb.control(null, [isThirdStepValidator(this.currentStep)]),
      jobsLabel: this.fb.control('', [isThirdStepValidator(this.currentStep)]),
      cryptoXP: this.fb.control(null,),
      cryptoXPYear: this.fb.control(0,),
      fundsOriginType: this.fb.control([], [isThirdStepValidator(this.currentStep)]),
      countryFundsOriginType: this.fb.control(countryFormValue, [isThirdStepValidator(this.currentStep)]),
      fundsOriginLabel: this.fb.control('', [isThirdStepValidator(this.currentStep)]),
      countryFiscalFunds: this.fb.control(countryFormValue, [isThirdStepValidator(this.currentStep)]),
      firstAttemptInvest: this.fb.control(this.firstInvestAttemptOptions[0], [isThirdStepValidator(this.currentStep)]),
      netMonthlyIncome: this.fb.control(0, [isFourthStepValidator(this.currentStep), Validators.min(0)]),
      grossValueAsset: this.fb.control(0, [isFourthStepValidator(this.currentStep), Validators.min(0)]),
      numberOfDependant: this.fb.control(0, [isNotNullValidator(this.currentStep)]),
      plannedInvestForNext12Month: this.fb.control('', [isFourthStepValidator(this.currentStep)]),
      fileProofTaxResidence: this.fb.control([], [isFourthStepValidator(this.currentStep)]),
      fileProofOriginFunds: this.fb.control([], [isFourthStepFilesValidator(this.currentStep)]),
      fileProofIncome: this.fb.control([], [isFourthStepFilesValidator(this.currentStep), isRequiredIfLookingJob(this.form?.get('jobsType')?.value)]),
      fileProofRIB: this.fb.control([], user.nationality === 'FR' || this.form?.get('nationality')?.value.code === 'fr' ? [isFourthStepFilesValidator(this.currentStep)] : []),
      fileProofBancaire: this.fb.control([], [isFourthStepFilesValidator(this.currentStep)]),
    }, {});
  }

  // private isThirdStepValidator() {
  //   return (group: AbstractControl): Nullable<ValidationErrors> => {
  //     console.log(group);
  //     const step = this.currentStep;
  //     console.log(step);
  //     return step >= 3 ? {isRequired: true} : null;
  //   }
  // }

  async provideFormWithFullInformation(kyc: IKyc) {
    const filesData = await this.profileService.getKycFiles(kyc.id) as any[]
    const files = filesData.map((e: any) => {
      return {
        type: e.type,
        name: e.files.name,
        id: e.files.id
      }
    });
    const fileProofOriginFunds = files.filter(({type}) => type === "ORIGIN_FUNDS");
    const fileProofTaxResidence = files.filter(({type}) => type === "TAX");
    const fileProofIncome = files.filter(({type}) => type === "INCOME");
    const fileProofRIB = files.filter(({type}) => type === "RIB");
    const fileProofBancaire = files.filter(({type}) => type === "BANK");
    this.fileProofOriginFunds = fileProofOriginFunds;
    this.fileProofTaxResidence = fileProofTaxResidence;
    this.fileProofIncome = fileProofIncome;
    this.fileProofRIB = fileProofRIB;
    this.fileProofBancaire = fileProofBancaire;
    const countryFiscalFunds = this.getCountry(kyc.fiscal_country_residence);
    const countryFundsOriginType = this.getCountry(kyc.country_of_funds);
    const jobsType = this.jobsTypeOptions.find(({id}) => id === kyc.activity) ?? null;
    const jobsSector = this.jobsSectorOptions.find(({id}) => id === kyc.activity_sector) ?? null;
    const fundsOriginType = !!kyc.origin_of_funds ? this.originsFundsOptions.filter(({id}) => kyc.origin_of_funds.includes(id)) : null;
    const firstAttemptInvest = this.firstInvestAttemptOptions.find(({id}) => id === kyc.intented_invest_amount) ?? this.firstInvestAttemptOptions[0];
    const plannedInvestForNext12Month = this.plannedInvestForNext12MonthOptions.find(({id}) => id === kyc.intented_invest_on_12_month) ?? this.plannedInvestForNext12MonthOptions[0];
    this.form?.patchValue({
      countryFiscalFunds,
      jobsType,
      jobsSector,
      jobsLabel: kyc.job_name ?? '',
      cryptoXP: kyc.xp_crypto ? Number(kyc.xp_crypto) > 0 : null,
      cryptoXPYear: kyc.xp_crypto ?? 0,
      fundsOriginType,
      countryFundsOriginType,
      fundsOriginLabel: kyc.origin_of_funds_other,
      firstAttemptInvest,
      netMonthlyIncome: kyc.net_monthly_income,
      grossValueAsset: kyc.gross_asset_value,
      numberOfDependant: kyc.number_of_dependants,
      plannedInvestForNext12Month,
      fileProofOriginFunds,
      fileProofTaxResidence,
      fileProofIncome,
      fileProofRIB,
      fileProofBancaire,
    });

    if(this.form?.value.firstAttemptInvest.id === 'between_5_001_10_000'){
      this.form?.get('fileProofTaxResidence')?.setValidators([isThirdstepValidatorIfAttempt(this.form?.value.firstAttemptInvest.id)])
      this.form?.get('fileProofTaxResidence')?.updateValueAndValidity();
    }
  }

  isOtherFundsOrigin(): boolean | null {
    return !!this.form?.value.fundsOriginType ? this.form?.value.fundsOriginType.find(({id}) => id === 'other') : null
  }

  getCountry(codeInput: string) {

    let country = AllCountries.find(({code}) => code === codeInput)
    if (!country) {
      country = this.france;
    }
    return {
      ...country,
      name: country?.name[this.lang$.getValue()]
    }
  }

  async next(enhanced: boolean = false) {


    let step = Number(this.currentStep) + 1;
    console.log('Step : ', step);
    console.log('Form : ', this.form);
    if (this.form?.invalid && Number(this.currentStep) > 0) {
      return;
    }

    this.form?.patchValue({
      isAvailable: false
    })

    this.reactiveLoader.setReactiveButtonState('next', reactiveButtonState.loading)


    if (!enhanced) {
      await this.saveStep(step);
    }

    if (this.isToReview) {

      await this.getNextResetStep()

      this.reactiveLoader.setReactiveButtonState('next', reactiveButtonState.success)
      return;
    }

    if (step === 4) {
      // IS ON THE FOURTH FORMULAIRE (KYC FULL ENHANCED)
      this.form?.patchValue({
        isAvailable: true
      })

      if (!enhanced) {

        await this.checkEnhancedKyc();
        return;
      }
      await this.requiredFormForFourthStep(step);
    }

    step = await this.checkNextStep(step, enhanced);

    this.currentStep = step;

    this.reactiveLoader.setReactiveButtonState('next', reactiveButtonState.success)
    this.form?.patchValue({
      isAvailable: true
    })
  }


  async saveStep(step: number) {
    await this.profileService.saveStepKycLight(this.form?.value, step, this.kycTypeForm, [
      {data: this.fileProofIncome.map(({id}) => (id)), type: "INCOME"},
      {data: this.fileProofTaxResidence.map(({id}) => (id)), type: "TAX"},
      {data: this.fileProofOriginFunds.map(({id}) => (id)), type: "ORIGIN_FUNDS"},
      {data: this.fileProofRIB.map(({id}) => (id)), type: "RIB"},
      {data: this.fileProofBancaire.map(({id}) => (id)), type: "BANK"},
    ]);
  }

  async getNextResetStep() {
    const kyc = await this.profileService.getKyc(this.kycTypeForm) as IKyc;
    const steps = kyc.steps.filter((e) => e.status === EKycStepStatus.RESET).sort((a, b) => Number(a.step) - Number(b.step));
    console.log(steps);
    if (!steps.length) {
      kyc.type === EKycType.FULL ? await this.finisKycFull(false) : (await this.profileService.enKycLight(this.currentStep, false), await this.finish());
      return;
    }
    if (steps[0].step === 4 && !this.needEnhancedKyc) {
      this.needEnhancedKyc = true;
    }
    const step = steps[0].step;
    this.form?.patchValue({
      isAvailable: true
    })
    this.currentStep = await this.checkNextStep(step, true);
  }


  async checkNextStep(step, enhanced) {
    if (step === 1) {
      // IS ON THE FIRST FORMULAIRE (PERSONAL DATA)
      this.setUpPlaceKit();
    }

    if (step === 2) {
      // IS ON THE SECOND FORMULAIRE (ONFIDO)
      this.form?.patchValue({
        onfido: false
      })

      const hasToGoOnSumsubStep = await this.hasToGoOnSumsubStep();
      console.log(hasToGoOnSumsubStep);
      if ((this.kycTypeForm === EKycForm.LIGHT) && !hasToGoOnSumsubStep) {
        await this.profileService.enKycLight(this.currentStep)
        await this.finish();
        return;
      } else if (!hasToGoOnSumsubStep) {
        step++;
        await this.saveStep(step);
      } else {
        this.form?.patchValue({
          onfido: false
        })
        await this.initIdentityProvider();
      }

    }

    if (step === 3) {
      // IS ON THE THIRD FORMULAIRE (KYC FULL INFO)

      this.setMaxWidthForInput()

      await this.requiredFormForThirdStep(step);
    }

    if (step === 4) {
      // IS ON THE THIRD FORMULAIRE (KYC FULL INFO)
      console.log('requiredFormForFourthStep');
      await this.requiredFormForFourthStep(step);
    }

    if (step === 5) {
      // IS VALID 4 STEP
      await this.finisKycFull()
    }

    return step;

  }

  setUpPlaceKit() {
    setTimeout(() => {
      let pka: PKAClient | null = null;
      if (!!this.user?.country) {
        pka = placekit('pk_+4GHLYY/lnc2UrGg1G486Nlcc2bRF2mFRzHtP861L+E=', {
          target: '#placekit',
          language: this.translate.currentLang as ELang,
          types: ['street'],
          countries: [`${this.user?.country}`],
        });
      } else {
        pka = placekit('pk_+4GHLYY/lnc2UrGg1G486Nlcc2bRF2mFRzHtP861L+E=', {
          target: '#placekit',
          types: ['street'],
          language: this.translate.currentLang as ELang,
        });
      }
      pka.on('empty', (value) => {
        if (value) {
          this.form?.patchValue({
            postalAddress: null,
            postalCode: null,
            city: null
          })
        }
      })
      pka.on('pick', (value, item, index) => {
        this.form?.patchValue({
          postalAddress: `${value}, ${item.zipcode[0]}, ${item.city}`,
          postalCode: item.zipcode[0],
          city: item.city
        })
      })
    }, 300)

  }

  async back() {

    this.setUpPlaceKit()

    this.currentStep--;

    // await this.requiredFormForThirdStep()
    await this.requiredFormForFourthStep(this.currentStep)
    this.form?.patchValue({
      onfido: true
    })
  }

  public async getOnfidoSatus() {
    const {data: {pepStatus, onfidoCheckStatus}} = await this.profileService.getOnfidoStatus(this.kycTypeForm) as {
      data: IOnfidoStatus
    }
    this.pepCheck = pepStatus.result === 'clear' && pepStatus.status === 'complete';
    return onfidoCheckStatus;
  }

  public async getSumsubStatus() {
    const {data: {pepStatus, onfidoCheckStatus}} = await this.profileService.getSumSubStatus(this.kycTypeForm) as {
      data: IOnfidoStatus
    }
    console.log(onfidoCheckStatus);
    this.pepCheck = pepStatus.result === 'clear' && pepStatus.status === 'complete';
    return onfidoCheckStatus;
  }

  private async hasToGoOnOnfidoStep() {
    const onfidoCheckStatus = await this.getOnfidoSatus();
    if (onfidoCheckStatus.result === 'clear' && onfidoCheckStatus.status === 'complete') {
      this.form?.patchValue({
        onfido: true
      })

      return false;
    }

    return true
  }

  private async hasToGoOnSumsubStep() {
    const SumsubStatus = await this.getSumsubStatus();
    console.log(SumsubStatus, 'SumsubStatus');
    if (SumsubStatus.result === 'clear' && SumsubStatus.status === 'complete') {
      this.form?.patchValue({
        onfido: true
      })

      return false;
    }

    return true
  }

  private async initIdentityProvider() {


    this.onfidoService.getSdkTokenSubSum(this.kycTypeForm).subscribe((data: any) => {
      const {token} = data;
      // const locale: SupportedLanguages = (this.translate.currentLang as ELang === ELang.FR) ? 'fr_FR' : 'en_US';
      let snsWebSdkInstance = snsWebSdk.init(token, () => this.getNewAccessToken()).withConf({
        lang: this.lang$.getValue(), //language of WebSDK texts and comments (ISO 639-1 format)
        email: this.user?.email,
        theme: 'light',
      })
        .withOptions({addViewportTag: false, adaptIframeHeight: true})
        // see below what kind of messages WebSDK generates
        .on('idCheck.stepCompleted', (payload) => {
          console.log('onStepCompleted', payload)
        })
        .on('idCheck.onApplicantStatusChanged', async (payload: any) => {
          console.log('onApplicantStatusChanged', payload);
          if (payload.reviewStatus === 'completed') {

            console.log(!payload.reviewResult, '!payload.reviewResult');
            console.log(!!payload.reviewResult, '!!payload.reviewResult');
            console.log(!!payload.reviewResult.reviewAnswer, '!!payload.reviewResult.reviewAnswer');


            // si reviewResult n'est pas définis on complete

            if (!payload.reviewResult) await this.onCompleteSumSub()

            // si reviewResult est définis on complete si reviewAnswer n'est pas 'RETRY'
            if (!!payload.reviewResult) {
              if (!!payload.reviewResult.reviewRejectType) {
                if (payload.reviewResult.reviewRejectType !== 'RETRY') await this.onCompleteSumSub()
              } else {
                await this.onCompleteSumSub()
              }
            } else {
              await this.onCompleteSumSub()
            }
          }
        })
        .build();
      snsWebSdkInstance.launch('#sumsub-websdk-container');
      this.loadingOnfido = false;
    });

    //   this.onfido = init({
    //     token: token,
    //     useMemoryHistory: true,
    //     // useModal: true,
    //     isWebView: true,
    //     containerId: 'onfido-mount',
    //     language: {
    //       locale,
    //       direction: 'ltr',
    //       phrases: locale === 'fr_FR' ? LocalFr : LocalEn
    //     },
    //     onComplete: async (data) => {
    //       await this.onCompleteOnFido(data);
    //       return;
    //     },
    //     steps: [
    //       {
    //         type: 'welcome',
    //         options: {
    //           title: this.translate.instant('KYC.onfido.title'),
    //           nextButton: this.translate.instant('KYC.onfido.nextButton'),
    //           descriptions: [
    //             this.translate.instant('KYC.onfido.description1'),
    //             this.translate.instant('KYC.onfido.description2'),
    //             this.translate.instant('KYC.onfido.description3'),
    //             this.translate.instant('KYC.onfido.description4'),
    //             this.translate.instant('KYC.onfido.description5'),
    //           ]
    //         }
    //       },
    //       {
    //         type: 'poa',
    //         options: {
    //
    //           hideCountrySelection: true,
    //           documentTypes: {
    //             utility_bill: true,
    //             bank_building_society_statement: false,
    //             council_tax: false,
    //             benefit_letters: false,
    //             address_certificate: false,
    //             mortgage_statement: false,
    //             identity_document_with_address: false,
    //             mobile_phone_bill: false,
    //             general_letter: false,
    //             insurance_statement: false,
    //             pension_property_statement_letter: false,
    //             exchange_house_statement: false,
    //           }
    //         }
    //       },
    //       {
    //         type: 'document',
    //         options: {
    //           hideCountrySelection: true,
    //           documentTypes: {
    //             passport: true,
    //             national_identity_card: true,
    //             residence_permit: true
    //           }
    //         }
    //       },
    //       {type: 'face'},
    //       {type: 'complete'}],
    //   });
    // })
  }

  getNewAccessToken() {
    return Promise.resolve('')// get a new token from your backend
  }

  async onCompleteSumSub() {
    this.reactiveLoader.setReactiveButtonState(
      'next',
      reactiveButtonState.loading
    );

    await this.onfidoService.endSumSub(this.kycTypeForm);

    this.reactiveLoader.setReactiveButtonState(
      'next',
      reactiveButtonState.success
    );

    if (this.kycTypeForm === EKycForm.LIGHT) {
      // await this.profileService.enKycLight(this.currentStep)
      await this.finish();
      return;
    }

    this.reactiveLoader.setReactiveButtonState(
      'next',
      reactiveButtonState.normal
    );
    this.form?.patchValue({
      onfido: true,
    });
    await this.next();
  }

  async onCompleteOnFido(data?: SdkResponse) {
    try {


      this.reactiveLoader.setReactiveButtonState(
        'next',
        reactiveButtonState.loading
      );
      await this.onfidoService.storeData({data, ...this.form?.value});

      this.reactiveLoader.setReactiveButtonState(
        'next',
        reactiveButtonState.success
      );


      if (this.kycTypeForm === EKycForm.LIGHT) {
        await this.profileService.enKycLight(this.currentStep)
        await this.finish();
        return;
      }

      this.reactiveLoader.setReactiveButtonState(
        'next',
        reactiveButtonState.normal
      );
      this.form?.patchValue({
        onfido: true,
      });
      await this.next();

      return;
    } catch (e) {
      this.reactiveLoader.setReactiveButtonState(
        'next',
        reactiveButtonState.error
      );
    }
  }

  async finish() {
    this.enhanceKycLoading = false;
    await this.router.navigate(['/profile/kyc']);
    await this.store.dispatch(new loadMyUser());
    this.toastService.addToast(
      EToastSeverity.success,
      'KYC.page.info',
      'KYC.page.submited'
    )

    this.ngOnDestroy();
    // localStorage.removeItem(this.localStorageKey);
  }

  ngOnDestroy() {
    if (!!this.onfido) this.onfido.safeTearDown();
    this.currentStep = 0;
  }

  async openPhoneModal() {
    const ref = this.dialogService.open(PhoneNumberModalComponent, {
      showHeader: false,
      dismissableMask: true,
      data: {
        email: this.user?.email,
        accountType: this.user?.accountType
      },
      styleClass: 'p-0 phoneModal',
      contentStyle: {
        'padding': '0px !important',
        'width': '100%',
        'min-width': '680px',
        'max-width': '550px',
        'border-radius': '20px'
      },
      baseZIndex: 10000,
    });

    ref.onClose.subscribe(async (data: IPhoneNumber) => {
      if (data?.phoneNumber) {
        try {
          const {phoneNumber} = await this.profileService.updatePhoneNumber() as { phoneNumber: string }
          console.log(phoneNumber);
          this.form?.patchValue({
            phoneNumber
          })

        } catch (e) {
        }
      }
    })
  }

  clearFundsOriginType(options: any) {
    options.reset();
    this.form?.patchValue({
      searchFundsOriginType: ''
    })
  }

  clear(options: any) {
    options.reset();
    this.form?.patchValue({
      searchCountry: ''
    })
  }

  clearNationality(options: any) {
    options.reset();
    this.form?.patchValue({
      searchNationality: ''
    })
  }

  clearNationalityValue(options: any) {
    this.form?.patchValue({
      searchNationality: '',
      nationality: null
    })
  }

  clearJobTypeValue(event: any) {
    this.form?.patchValue({
      searchJobsType: '',
      jobsType: null
    })
  }

  clearJobSectorValue(event: any) {
    this.form?.patchValue({
      searchJobsSector: '',
      jobsSector: null
    })
  }

  clearCountryFundsOriginTypeValue(event: any) {
    this.form?.patchValue({
      searchCountryFundsOriginType: '',
      countryFundsOriginType: null
    })
  }

  clearFiscalFundsValue(event: any) {
    this.form?.patchValue({
      searchCountryFiscalFunds: '',
      countryFiscalFunds: null
    })
  }

  clearOtherNationalityValue(event: any) {
    this.form?.patchValue({
      searchOtherNationality: '',
      otherNationality: null
    })
  }

  clearCountryValue(event: any) {
    this.form?.patchValue({
      searchCountry: '',
      country: null
    })
  }

  clearOtherNationality(options: any) {
    options.reset();
    this.form?.patchValue({
      searchOtherNationality: ''
    })
  }

  contactModal() {
    this.ref = this.dialogService.open(ContactModalComponent, {
      showHeader: false,
      dismissableMask: true,
      data: {
        title: 'contactUs'
      },
      contentStyle: {'max-width': '860px', 'border-radius': '20px'},
      baseZIndex: 10000,
    })

  }

  async mannualyEnterAddr() {
    this.manualEnterAddress = !this.manualEnterAddress;
    if (!this.manualEnterAddress) {
      await this.setUpPlaceKit();
      this.form?.patchValue({
        postalAddress: null,
        postalCode: null,
        city: null
      })
    }

  }

  onUpload(data: any) {
    const file = data.currentFiles[0];
    this.files = [...this.files, file];
  }


  private async checkEnhancedKyc() {
    this.enhanceKycLoading = true

    this.timeInterval = interval(5_000).pipe(
      startWith(0),
      delayWhen(value => value === 0 ? timer(1000) : of(value)),
      switchMap(async () => {
        const {enhanced} = await this.profileService.checkEnhancedKyc() as { enhanced: boolean | null }
        return enhanced
      }),
    ).subscribe(async (needEnhancedKyc) => {
      if (needEnhancedKyc) {
        this.timeInterval?.unsubscribe();
        this.needEnhancedKyc = true
        await this.requiredFormForFourthStep(this.currentStep);
        await this.next(true);
        this.enhanceKycLoading = false;
        return;
      }
      if (needEnhancedKyc !== null) {
        this.timeInterval?.unsubscribe();
        if (!needEnhancedKyc) {
          await this.finisKycFull();
          return;
        }
      }

    })
  }

  uploadFileValidation(size: number): boolean {
    if (size > 10000000) {
      this.taostService.addToast(
        EToastSeverity.error,
        'information',
        'fileToBigger'
      )
      return false;
    }
    return true;
  }

  async addFileFunds(event: any) {
    this.fundsDrag = false;
    // const validation = this.uploadFileValidation(event)
    // if (!validation) {
    //   (this.fileIncomeInput as any).clear()
    //   return
    // }
    const inputFiles = event.target.files;
    this.incomeDrag = false;

    const files: any[] = [];
    for (let file of inputFiles) {
      const validation = this.uploadFileValidation(file.size)
      if (!validation) {
        // (this.fileIncomeInput as any).nativeElement.clear()
        event.target.value = null
        return
      }
      files.push(file)
    }
    event.target.value = null
    this.fileProofOriginFunds = [...new Array(files.length).fill({name: '', id: '', percent: 0}).map((e, index) => {
      return {
        ...e,
        name: files[index].name
      }
    }), ...this.fileProofOriginFunds];

    this.fileService.saveFileObservable(files)
      .pipe(
        map((event: HttpEvent<any>) => {
          if (event.type === HttpEventType.UploadProgress) {
            const percentDone = event.total ? Math.round(100 * event.loaded / event.total) : 0;
            this.fileProofOriginFunds = this.fileProofOriginFunds.map((e) => ({
              ...e,
              percent: !e.id ? (percentDone > 90 ? 90 : percentDone) : 100,
            }));
          }
          if (event.type === HttpEventType.Response) {
            return event.body;
          }
        }),
        filter((data) => !!data),
      )
      .subscribe((data) => {
        this.fileProofOriginFunds = [...data.map((e) => ({
          ...e,
          percent: 100
        })), ...this.fileProofOriginFunds.filter((e) => !!e.id)];
        this.form?.patchValue({
          fileProofOriginFunds: [...this.form?.value.fileProofOriginFunds, ...this.fileProofOriginFunds]
        })
      })


  }

  async addFileTax(event: any) {
    this.taxDrag = false;
    // const validation = this.uploadFileValidation(event)
    // if (!validation) {
    //   (this.fileTaxInput as any).clear()
    //   return
    // }
    const inputFiles = event.target.files;
    this.incomeDrag = false;

    const files: any[] = [];
    for (let file of inputFiles) {
      const validation = this.uploadFileValidation(file.size)
      if (!validation) {
        // (this.fileIncomeInput as any).nativeElement.clear()
        event.target.value = null
        return
      }
      files.push(file)
    }
    event.target.value = null
    this.fileProofTaxResidence = [...new Array(files.length).fill({name: '', id: '', percent: 0}).map((e, index) => {
      return {
        ...e,
        name: files[index].name
      }
    }), ...this.fileProofTaxResidence];
    // const data = await this.fileService.saveFile(files) as { name: string; id: string; }[]

    this.fileService.saveFileObservable(files)
      .pipe(
        map((event: HttpEvent<any>) => {
          if (event.type === HttpEventType.UploadProgress) {
            const percentDone = event.total ? Math.round(100 * event.loaded / event.total) : 0;
            this.fileProofTaxResidence = this.fileProofTaxResidence.map((e) => ({
              ...e,
              percent: !e.id ? (percentDone > 90 ? 90 : percentDone) : 100,
            }));
          }
          if (event.type === HttpEventType.Response) {
            return event.body;
          }
        }),
        filter((data) => !!data),
      )
      .subscribe((data) => {
        this.fileProofTaxResidence = [...data.map((e) => ({
          ...e,
          percent: 100
        })), ...this.fileProofTaxResidence.filter((e) => !!e.id)];
        this.form?.patchValue({
          fileProofTaxResidence: [...this.form?.value.fileProofTaxResidence, ...this.fileProofTaxResidence]
        })
      })


  }

  public temp = '';

  async addFileIncome(event: any) {
    const inputFiles = event.target.files
    this.incomeDrag = false;

    const files: any[] = [];
    for (let file of inputFiles) {
      const validation = this.uploadFileValidation(file.size)
      if (!validation) {
        // (this.fileIncomeInput as any).nativeElement.clear()
        event.target.value = null
        return
      }
      files.push(file)
    }
    event.target.value = null
    this.fileProofIncome = [...new Array(files.length).fill({name: '', id: '', percent: 0}).map((e, index) => {
      return {
        ...e,
        name: files[index].name
      }
    }), ...this.fileProofIncome];
    // const data = await this.fileService.saveFile(files) as { name: string; id: string; }[]

    this.fileService.saveFileObservable(files)
      .pipe(
        map((event: HttpEvent<any>) => {
          if (event.type === HttpEventType.UploadProgress) {
            const percentDone = event.total ? Math.round(100 * event.loaded / event.total) : 0;
            this.fileProofIncome = this.fileProofIncome.map((e) => ({
              ...e,
              percent: !e.id ? (percentDone > 90 ? 90 : percentDone) : 100,
            }));
          }
          if (event.type === HttpEventType.Response) {
            return event.body;
          }
        }),
        filter((data) => !!data),
      )
      .subscribe((data) => {
        this.fileProofIncome = [...data.map((e) => ({
          ...e,
          percent: 100
        })), ...this.fileProofIncome.filter((e) => !!e.id)];
        this.form?.patchValue({
          fileProofIncome: [...this.form?.value.fileProofIncome, ...this.fileProofIncome]
        })
      })
  }

  async addFileBancaire(event: any) {
    this.bancaireDrag = false;
    const inputFiles = event.target.files;
    this.incomeDrag = false;

    const files: any[] = [];
    for (let file of inputFiles) {
      const validation = this.uploadFileValidation(file.size)
      if (!validation) {
        // (this.fileIncomeInput as any).nativeElement.clear()
        event.target.value = null
        return
      }
      files.push(file)
    }
    event.target.value = null
    // (this.fileBancaireInput as any).clear()
    this.fileProofBancaire = [...new Array(files.length).fill({name: '', id: '', percent: 0}).map((e, index) => {
      return {
        ...e,
        name: files[index].name
      }
    }), ...this.fileProofBancaire];
    // const data = await this.fileService.saveFile(files) as { name: string; id: string; }[]

    this.fileService.saveFileObservable(files)
      .pipe(
        map((event: HttpEvent<any>) => {
          if (event.type === HttpEventType.UploadProgress) {
            const percentDone = event.total ? Math.round(100 * event.loaded / event.total) : 0;
            this.fileProofBancaire = this.fileProofBancaire.map((e) => ({
              ...e,
              percent: !e.id ? (percentDone > 90 ? 90 : percentDone) : 100,
            }));
          }
          if (event.type === HttpEventType.Response) {
            return event.body;
          }
        }),
        filter((data) => !!data),
      )
      .subscribe((data) => {
        this.fileProofBancaire = [...data.map((e) => ({
          ...e,
          percent: 100
        })), ...this.fileProofBancaire.filter((e) => !!e.id)];
        this.form?.patchValue({
          fileProofBancaire: [...this.form?.value.fileProofBancaire, ...this.fileProofBancaire]
        })
      })
  }

  async addFileRIB(event: any) {
    this.ribDrag = false;
    // const validation = this.uploadFileValidation(event)
    // if (!validation) {
    //   (this.fileRIBInput as any).clear()
    //   return
    // }

    const inputFiles = event.target.files;
    this.incomeDrag = false;

    const files: any[] = [];
    for (let file of inputFiles) {
      const validation = this.uploadFileValidation(file.size)
      if (!validation) {
        // (this.fileIncomeInput as any).nativeElement.clear()
        event.target.value = null
        return
      }
      files.push(file)
    }
    event.target.value = null
    this.fileProofRIB = [...new Array(files.length).fill({name: '', id: '', percent: 0}).map((e, index) => {
      return {
        ...e,
        name: files[index].name
      }
    }), ...this.fileProofRIB];
    // const data = await this.fileService.saveFile(files) as { name: string; id: string; }[]

    this.fileService.saveFileObservable(files)
      .pipe(
        map((event: HttpEvent<any>) => {
          if (event.type === HttpEventType.UploadProgress) {
            const percentDone = event.total ? Math.round(100 * event.loaded / event.total) : 0;
            this.fileProofRIB = this.fileProofRIB.map((e) => ({
              ...e,
              percent: !e.id ? (percentDone > 90 ? 90 : percentDone) : 100,
            }));
          }
          if (event.type === HttpEventType.Response) {
            return event.body;
          }
        }),
        filter((data) => !!data),
      )
      .subscribe((data) => {
        this.fileProofRIB = [...data.map((e) => ({
          ...e,
          percent: 100
        })), ...this.fileProofRIB.filter((e) => !!e.id)];
        this.form?.patchValue({
          fileProofRIB: [...this.form?.value.fileProofRIB, ...this.fileProofRIB]
        })
      })
  }

  removeFile(index: number, type: string) {
    if (type === 'origin') {
      this.fileProofOriginFunds.splice(index, 1)
      this.form?.patchValue({
        fileProofOriginFunds: this.fileProofOriginFunds
      })
    }

    if (type === 'tax') {
      this.fileProofTaxResidence.splice(index, 1);
      this.form?.patchValue({
        fileProofTaxResidence: this.fileProofTaxResidence
      })
    }

    if (type === 'income') {
      this.fileProofIncome.splice(index, 1)
      this.form?.patchValue({
        fileProofIncome: this.fileProofIncome
      })
    }

    if (type === 'rib') {
      this.fileProofRIB.splice(index, 1)
      this.form?.patchValue({
        fileProofRIB: this.fileProofRIB
      })
    }

    if (type === 'bancaire') {
      this.fileProofBancaire.splice(index, 1)
      this.form?.patchValue({
        fileProofBancaire: this.fileProofBancaire
      })
    }
  }

  private async finisKycFull(action = true) {
    await this.profileService.endKycFull(this.currentStep, action)
    await this.finish();
    console.log(`await this.finish();`);
    return;
  }

  private async requiredFormForThirdStep(step: number) {
    if (step === 3) {
      this.form?.get('jobsType')?.setValidators([isThirdStepValidator(step)])
      this.form?.get('jobsType')?.updateValueAndValidity();

      this.form?.get('jobsSector')?.setValidators([isThirdStepValidator(step)])
      this.form?.get('jobsSector')?.updateValueAndValidity()

      this.form?.get('jobsLabel')?.setValidators([isThirdStepValidator(step)])
      this.form?.get('jobsLabel')?.updateValueAndValidity()

      this.form?.get('fundsOriginType')?.setValidators([isThirdStepValidator(step)])
      this.form?.get('fundsOriginType')?.updateValueAndValidity()

      this.form?.get('countryFundsOriginType')?.setValidators([isThirdStepValidator(step)])
      this.form?.get('countryFundsOriginType')?.updateValueAndValidity()

      this.form?.get('countryFundsOriginType')?.setValidators([isThirdStepValidator(step)])
      this.form?.get('countryFundsOriginType')?.updateValueAndValidity()

      this.form?.get('countryFiscalFunds')?.setValidators([isThirdStepValidator(step)])
      this.form?.get('countryFiscalFunds')?.updateValueAndValidity()

      this.form?.get('firstAttemptInvest')?.setValidators([isThirdStepValidator(step)])
      this.form?.get('firstAttemptInvest')?.updateValueAndValidity()
    } else {
      this.form?.get('jobsType')?.clearValidators()
      this.form?.get('jobsType')?.updateValueAndValidity();
      this.form?.get('jobsSector')?.clearValidators()
      this.form?.get('jobsSector')?.updateValueAndValidity();
      this.form?.get('jobsLabel')?.clearValidators()
      this.form?.get('jobsLabel')?.updateValueAndValidity();
      this.form?.get('fundsOriginType')?.clearValidators()
      this.form?.get('fundsOriginType')?.updateValueAndValidity();
      this.form?.get('countryFundsOriginType')?.clearValidators()
      this.form?.get('countryFundsOriginType')?.updateValueAndValidity();
      this.form?.get('countryFundsOriginType')?.clearValidators()
      this.form?.get('countryFundsOriginType')?.updateValueAndValidity();
      this.form?.get('countryFiscalFunds')?.clearValidators()
      this.form?.get('countryFiscalFunds')?.updateValueAndValidity();
      this.form?.get('firstAttemptInvest')?.clearValidators()
      this.form?.get('firstAttemptInvest')?.updateValueAndValidity();
    }
  }

  private async requiredFormForFourthStep(step: number) {
    if (step === 4) {

      this.form?.get('netMonthlyIncome')?.setValidators([isFourthStepValidator(step)])
      this.form?.get('netMonthlyIncome')?.updateValueAndValidity();

      this.form?.get('grossValueAsset')?.setValidators([isFourthStepValidator(step)])
      this.form?.get('grossValueAsset')?.updateValueAndValidity();

      this.form?.get('numberOfDependant')?.setValidators([isNotNullValidator(step)])
      this.form?.get('numberOfDependant')?.updateValueAndValidity();

      this.form?.get('plannedInvestForNext12Month')?.setValidators([isFourthStepValidator(step)])
      this.form?.get('plannedInvestForNext12Month')?.updateValueAndValidity();

      this.form?.get('fileProofTaxResidence')?.setValidators([isFourthStepFilesValidator(step)])
      this.form?.get('fileProofTaxResidence')?.updateValueAndValidity();

      this.form?.get('fileProofOriginFunds')?.setValidators([isFourthStepFilesValidator(step)])
      this.form?.get('fileProofOriginFunds')?.updateValueAndValidity();

      const value = !(this.form?.get('jobsType')?.value?.id === 'noJob' || this.form?.get('jobsType')?.value?.id === 'student' || this.form?.get('jobsType')?.value?.id === 'stages')
      if (value) {
        this.form?.get('fileProofIncome')?.setValidators([isFourthStepFilesValidator(step)])
      }
      this.form?.get('fileProofIncome')?.updateValueAndValidity();


      this.form?.get('fileProofBancaire')?.setValidators([isFourthStepFilesValidator(step)])
      this.form?.get('fileProofBancaire')?.updateValueAndValidity();
      if (this.user?.nationality === 'fr' || this.form?.get('nationality')?.value.code === 'fr') {
        this.form?.get('fileProofRib')?.setValidators([isFourthStepFilesValidator(step)])
        this.form?.get('fileProofRib')?.updateValueAndValidity();
      }

    } else {
      this.form?.get('netMonthlyIncome')?.clearValidators();
      this.form?.get('netMonthlyIncome')?.updateValueAndValidity();
      this.form?.get('grossValueAsset')?.clearValidators();
      this.form?.get('grossValueAsset')?.updateValueAndValidity();
      this.form?.get('numberOfDependant')?.clearValidators();
      this.form?.get('numberOfDependant')?.updateValueAndValidity();
      this.form?.get('plannedInvestForNext12Month')?.clearValidators();
      this.form?.get('plannedInvestForNext12Month')?.updateValueAndValidity();
      this.form?.get('fileProofTaxResidence')?.clearValidators();
      this.form?.get('fileProofTaxResidence')?.updateValueAndValidity();
      this.form?.get('fileProofOriginFunds')?.clearValidators();
      this.form?.get('fileProofOriginFunds')?.updateValueAndValidity();
      this.form?.get('fileProofIncome')?.clearValidators();
      this.form?.get('fileProofIncome')?.updateValueAndValidity();
      this.form?.get('fileProofRib')?.clearValidators();
      this.form?.get('fileProofRib')?.updateValueAndValidity();
      this.form?.get('fileProofBancaire')?.clearValidators();
      this.form?.get('fileProofBancaire')?.updateValueAndValidity();

    }
  }

  protected readonly ELang = ELang;
  width: number | string = 'auto';
  @ViewChild('multi') multiInput?: ElementRef;

  private async getCurrentKyc(kycTypeForm: EKycForm) {
    let kyc: IKyc = await this.profileService.getKyc(kycTypeForm) as IKyc
    if (!kyc) {
      kyc = await this.profileService.initKyc(kycTypeForm) as IKyc;
    }

    if (kyc.status === EKycStatus.COMPLETED) {
      this.router.navigate(['..'], {relativeTo: this.route});
    }

    await this.initForm(this.user!);

    if (this.kycTypeForm === EKycForm.FULL) {
      await this.provideFormWithFullInformation(kyc);
    }


    if (kyc.status === EKycStatus.MAJ) {
      // THE KYC IS TO REVIEW
      this.isToReview = true;
      const steps = kyc.steps.filter((e) => e.status === EKycStepStatus.RESET).sort((a, b) => Number(a.step) - Number(b.step));
      this.stepsToReview = steps;
      const step = this.stepsToReview[0].step;
      this.currentStep = await this.checkNextStep(step, true);
      this.loading = false;
      return;
    }

    const step = Number(kyc.step);
    if (step > 3) {
      this.needEnhancedKyc = true;
    }

    this.currentStep = await this.checkNextStep(step, true);

    this.loading = false;
    return;
  }

  onDragEnterIncome(e: any) {
    this.clearDrap();
    console.log('onDragEnterIncome', this.incomeDrag);
    this.incomeDrag = true
  }

  onDragLeaveIncome(e: any) {
    this.clearDrap();
  }

  onDragEnterTax(e: any) {
    this.clearDrap();
    console.log('onDragEnterTax', this.taxDrag);
    this.taxDrag = true
  }

  onDragLeaveTax(e: any) {
    this.clearDrap();
  }

  onDragEnterFunds(e: any) {
    this.clearDrap();
    console.log('onDragEnterFunds', this.fundsDrag);
    this.fundsDrag = true
  }

  clearDrap() {
    this.incomeDrag = false
    this.fundsDrag = false
    this.taxDrag = false
    this.ribDrag = false
    this.bancaireDrag = false

  }

  onDragLeaveFunds(e: any) {
    this.clearDrap();
  }

  onDragEnterRIB(e: any) {
    this.clearDrap();
    console.log('onDragEnterRIB', this.ribDrag);
    this.ribDrag = true
  }

  onDragLeaveRIB(e: any) {
    this.clearDrap();
  }

  onDragEnterBancaire(e: any) {
    this.clearDrap();
    console.log('onDragEnterBancaire', this.bancaireDrag);
    this.bancaireDrag = true
  }

  onDragLeaveBancaire(e: any) {
    this.clearDrap();
  }

  selectOriginFunds(event: any) {
    this.form?.patchValue({
      fundsOriginType: [...event.value.map(({id}) => (id))]
    })
  }

  private setMaxWidthForInput() {
    setTimeout(() => {

      const child = this.multiInput?.nativeElement.querySelector('.p-element')
      if (child) {
        this.width = child.offsetWidth + 'px'
      }
    }, 500)
  }
}
