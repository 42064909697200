import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from '@environments/environment';
import {firstValueFrom} from 'rxjs';
import {ITwoFaConfig} from "@models/dist";

const BASE_SECURITY = `${environment.nestApiUrl}/api/sgp/security`;

@Injectable({
  providedIn: 'root'
})
export class TwoFaService {

  constructor(private http: HttpClient) {
  }

  async getTwoFaConfig() {
    return firstValueFrom(this.http.get(`${BASE_SECURITY}/mfa-totp`));
  }

  async createTwoFaCredential(twoFaConfig: ITwoFaConfig, totpToken: string, hotpToken: string) {
    return firstValueFrom(this.http.post(`${BASE_SECURITY}/mfa-totp`, {
      ...twoFaConfig,
      totpToken,
      hotpToken
    }));
  }

  async signInTwoFa(codeGoogle: string) {
    return firstValueFrom(this.http.post(`${BASE_SECURITY}/mfa-totp/signin`, {"totpToken": codeGoogle}));
  }

  async sendVerificationEmail() {
    return firstValueFrom(this.http.get(`${BASE_SECURITY}/mfa-totp/hotp-create`));
  }

  async sendRevokeEmail() {
    return firstValueFrom(this.http.get(`${BASE_SECURITY}/mfa-totp/hotp-revoke`));
  }

  async sendChangePasswordEmail() {
    return firstValueFrom(this.http.get(`${BASE_SECURITY}/mfa-totp/hotp-change-password`));
  }

  async sendChangeWalletEmail() {
    return firstValueFrom(this.http.get(`${BASE_SECURITY}/mfa-totp/hotp-change-wallet`));
  }

  async sendAddWalletEmail() {
    return firstValueFrom(this.http.get(`${BASE_SECURITY}/mfa-totp/hotp-add-wallet`));
  }

  async revokeTwoFa(totpToken: string, hotpToken: string) {
    return firstValueFrom(this.http.post(`${BASE_SECURITY}/mfa-totp/hotp-revoke`,
      {
        hotpToken,
        totpToken
      }));
  }

  async doubleAuthentication(totpToken: string, hotpToken: string) {
    return firstValueFrom(this.http.post(`${BASE_SECURITY}/mfa-totp/double-authentication`,
      {
        hotpToken,
        totpToken
      }));
  }

}
