import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {ERoutes} from "@models/dist";
import {AuthState} from "@shared/stores/auth/auth.state";
import {environment} from "@environments/environment";

@Injectable({
  providedIn: 'root'
})
export class OnboardingGuard implements CanActivate {

  constructor(
    private authState: AuthState,
    private afAuth: AngularFireAuth,
    private router: Router
  ) {
  }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return Promise.resolve(true)
  }

}
