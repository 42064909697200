import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {ELang} from "@models/dist";
import {Actions, ofActionDispatched, Store} from "@ngxs/store";
import {ClaimAirdrop, SuccessClaimAirdrop} from "@shared/stores/airdrop/airdrop.actions";
import {ReactiveLoaderService} from "@shared/services";
import {reactiveButtonState} from "@shared/services/reactive-loader.service";
import confetti from 'canvas-confetti';
import {FireworksService} from "@shared/services/fireworks.service";


@Component({
  selector: 'app-airdrop-modal',
  templateUrl: './airdrop-modal.component.html',
  styleUrls: ['./airdrop-modal.component.scss']
})
export class AirdropModalComponent implements OnInit {

  public currentImg = './assets/images/airdrop_fr.png';
  public imgEN = './assets/images/airdrop_en.png';
  public imgFR = './assets/images/airdrop_fr.png';

  public investImgEN = './assets/images/airdrop_invest_en.png';
  public investImgFr = './assets/images/airdrop_invest_fr.png';

  rightConfetti : any;
  leftConfetti : any;
  airdrop: any;

  //
  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private store: Store,
    public reactiveLoader: ReactiveLoaderService,
    private langService: TranslateService,
    private fireworkService: FireworksService,
  ) {


    // this.actions.pipe(
    //   ofActionDispatched(SuccessClaimAirdrop)
    // ).subscribe(() => {
    //   this.reactiveLoader.setReactiveButtonState('close-btn', reactiveButtonState.success);
    //   this.close();
    // })

  }

  //
  ngOnInit(): void {
    this.airdrop = this.config.data.airdrop;
    const lang = this.langService.currentLang as ELang;
    if (this.airdrop.scope === 'claimed') {
      this.currentImg = lang == ELang.FR ? this.imgFR : this.imgEN;
    }
    if (this.airdrop.scope === 'investment') {
      this.currentImg = lang == ELang.FR ? this.investImgFr : this.investImgEN;
    }
    this.fireworkService.initFireworks();
  }

  //
  claim() {
    this.reactiveLoader.setReactiveButtonState('close-btn', reactiveButtonState.loading);
    this.store.dispatch(new ClaimAirdrop(this.airdrop.id)).subscribe(() => {
      this.reactiveLoader.setReactiveButtonState('close-btn', reactiveButtonState.success);
      this.close();
    })
  }

  //
  close() {
    this.ref.close();
  }

}
