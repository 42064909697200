import {DatePipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ELang} from "@models/dist";

@Pipe({
  name: 'localizedDate',
  pure: false
})
export class LocalizedDatePipe implements PipeTransform {
  private lang: ELang = ELang.FR;

  constructor(private translateService: TranslateService) {
    this.lang = this.translateService.currentLang as ELang;
    this.translateService.onLangChange.subscribe(lang => {
      this.lang = lang.lang as ELang
    });
  }

  transform(value: any, pattern: string = 'mediumDate'): any {
    const datePipe: DatePipe = new DatePipe(this.lang);
    return datePipe.transform(value, pattern);
  }
}
