<div class="">
  <app-ico-top [title]="'ico.top.history' | translate"></app-ico-top>

  <p-card class="mt-3">
    <div class="userSettings">
      <div class="surface-section">
        <div class=" title mb-3">
          {{ 'SessionHistory.title' | translate }}
        </div>

        <ng-container>

          <p-table [value]="history" responsiveLayout="scroll">
            <ng-template pTemplate="header">
              <tr class="header-table">
                <th>{{ 'SessionHistory.device' | translate }}</th>
                <th>{{ 'SessionHistory.location' | translate }}</th>
                <th>{{ 'SessionHistory.date' | translate }}</th>
<!--                <th>{{ 'SessionHistory.trust.label' | translate }}</th>-->
                <!--                <th>Actions</th>-->
              </tr>
            </ng-template>
            <ng-template let-product pTemplate="body">
              <tr class="header-body">
                <td class="cursor w-20rem">
                  <span class="code" [title]="product.userAgent">{{ product.device}}</span>
                </td>
                <td class="cursor w-40rem">
                  <span class="code" [innerHTML]="product.country | CountryPipe : {location: product.location}"></span>
                </td>
                <td class="">
                  <span class="code">{{ product.created_at | date :'dd/MM/YYYY - HH:mm' }}</span>
                </td>
<!--                <td class="w-3rem">-->
<!--                  <div class="badge-status actif">{{ 'SessionHistory.trust.weak' | translate }}</div>-->
<!--                </td>-->
              </tr>
            </ng-template>
          </p-table>

        </ng-container>
      </div>
    </div>
  </p-card>
</div>
