import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class NavService {

  private nav = new Subject<any>();

  nav$ = this.nav.asObservable();

  addnav(route: any) {
    this.nav.next(route);
  }


}
