import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {firstValueFrom} from "rxjs";
import {environment} from "../../../environments/environment";
import {EPassfortProductAlias} from "@models/dist/models/passfort";

const BASE = `${environment.nestApiUrl}/api/sgp/compliance`;

@Injectable({
  providedIn: 'root'
})
export class ComplianceService {

  constructor(private http: HttpClient) {
  }

  /**************************************
   * COMPLIANCE
   ***************************************/


  initCompliance(product: EPassfortProductAlias) {
    return firstValueFrom(this.http.post(`${BASE}/init-compliance`, {product}));
  }

}

