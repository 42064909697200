<button
  *ngIf="!outline"
  [class.disabled]="disabled"
  [disabled]="disabled"
  [label]="text | translate"
  [rId]="rId"
  class="gradient-button w-full flex justify-content-center align-items-center text-center"
  (click)="handleButtonClick()"
  pButton
  pRipple
  [icon]="icon"
  rButton>
</button>

<button
  *ngIf="outline"
  [class.disabled]="disabled"
  [disabled]="disabled"
  [label]="text | translate"
  class="outline-button w-full purple flex justify-content-center align-items-center text-center"
  (click)="handleButtonClick()"
  pRipple
  pButton>
</button>
