import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {DEFAULT_QUEUE_TIME} from "@models/dist";
import {TransactionService} from "@shared/services";
import {WebSocketService} from "@shared/services/webSocket.service";
import {map} from "rxjs";
import {Store} from '@ngxs/store';
import {setLastRefresh} from '@app/shared/stores/utils/utils.actions';

@Component({
  selector: 'app-resume-modal',
  templateUrl: './resume-modal.component.html',
  styleUrls: ['./resume-modal.component.scss']
})
export class ResumeModalComponent implements OnInit {
  public data: any = {};
  public loading = true;
  canCancel: boolean = true;
  public timeLeft = '00:00:00';
  hasBeenUpdated: boolean = false;

  constructor(
    private transactionService: TransactionService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private websocketService: WebSocketService,
    private store: Store
  ) {
  }

  ngOnInit(): void {
    this.data = this.config.data;
    this.loading = false;
    this.initWebSocket();
  }


  closeFn() {
    this.ref.close({
      status: 'close',
    });
  }

  async cancelFn() {
    try {
      this.ref.close({
        status: 'openCancelModal',
        id: this.data.id,
      });
    } catch (e) {
      console.log(e);
    }
  }

  private initWebSocket() {
    this.websocketService.getUserUpdateTransaction(this.config.data.userId).pipe(map((data: any) => data)).subscribe((transaction: any) => {
      if (transaction.id === this.data.id) {
        this.hasBeenUpdated = true;
        this.data = {
          ...this.data,
          id: transaction.id,
          address: transaction.custody.address,
          selectedCrypto: transaction.custody.currency,
          time: transaction.created_at,
          queueTime: transaction.round ? transaction.round?.queueTime : DEFAULT_QUEUE_TIME,
          status: transaction.status,
          price: transaction.amount,
          credit: 0,
          rest: transaction.amount,
          convert: transaction.amountUSD,
          activeRound: transaction.round
        }
        this.store.dispatch(new setLastRefresh());
        setTimeout(() => {
          this.hasBeenUpdated = false
        }, 3000)
      }
    })

    this.websocketService.switchToTransaction(this.config.data.userId).pipe(map((data: any) => data)).subscribe((transaction: any) => {


      this.hasBeenUpdated = true;
      this.data = {
        ...this.data,
        id: transaction.id,
        address: transaction.custody.address,
        selectedCrypto: transaction.custody.currency,
        time: transaction.created_at,
        queueTime: transaction.round ? transaction.round?.queueTime : DEFAULT_QUEUE_TIME,
        status: transaction.status,
        price: transaction.amount,
        credit: 0,
        rest: transaction.amount,
        convert: transaction.amountUSD,
        activeRound: transaction.round
      }
      this.store.dispatch(new setLastRefresh());
      setTimeout(() => {
        this.hasBeenUpdated = false
      }, 3000)
    });
  }
}
