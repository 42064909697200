import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {ERoutes} from "@models/dist";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  redirectUrl: string = 'redirectUrl';

  constructor(private afAuth: AngularFireAuth,
              private router: Router) {
  }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const auth = getAuth()
    return new Promise(resolve => {
      const unsubscribe = onAuthStateChanged(auth, (user: any) => {
        if (user?.emailVerified) {
          resolve(true);
        } else {
          console.log(state.url);
          localStorage.setItem(this.redirectUrl, state.url);
          this.router.navigate([ERoutes.AUTH]);
          resolve(false);
        }
        unsubscribe();
      });
    });
  }

}
