import {Component, Input, OnInit} from '@angular/core';
import {IRound, IUser} from "@models/dist";

@Component({
  selector: 'app-ico-phase-ico-card',
  templateUrl: './phase-ico-card.component.html',
  styleUrls: ['./phase-ico-card.component.scss']
})
export class PhaseIcoCardComponent implements OnInit {
  @Input() buying = false;
  @Input() hasPrivate: boolean = false;
  @Input() user: IUser | null = null;
  @Input() item: IRound | undefined;
  @Input() index: number = 0;

  @Input() targetAmountSMT: number | string | undefined = 0;
  @Input() currentAmountSMT: number | string | undefined = 0;
  currentAmountSMTNumber: number = 0;
  // public currentLang: ELang;
  // public name: any = '';
  public lockedAmount: number = 0;

  constructor() {

  }

  ngOnInit(): void {
    this.currentAmountSMTNumber = Number(this.currentAmountSMT);
  }

  isNextRound() {
    if (this.item) {
      return (!this.item?.current) && (new Date().getTime() < new Date(this.item?.endAt).getTime());
    }
    return false;
  }

  isBeforeRound() {
    if (this.item) {
      return (!this.item?.current) && (new Date().getTime() > new Date(this.item?.endAt).getTime());
    }
    return false;
  }

  getPercent() {
    return Number(Number(this.currentAmountSMT) / (Number(this.item?.targetAmount)) * 100).toFixed(2)
  }

  canBuy() {
    if (this.user?.privateSale && this.hasPrivate && !this.item?.isPrivate) {
      return false
    } else {
      return !this.item?.isPrivate && this.item?.current && !this.item?.isFilled
    }

  }

  canBuyText() {
    if (this.user?.privateSale && this.hasPrivate && !this.item?.isPrivate) {
      return 'ico.invest.preferPrivateSale'
    }
    return 'ico.invest.buySummitTokens';
  }

  getPercentLocked() {
    return Number((Number(this.item?.currentAmount) + (Number(this.item?.lockedAmount))) / Number(this.item?.targetAmount) * 100).toFixed(2)

  }
}
