import {Component, ElementRef, OnDestroy, OnInit, signal, ViewChild} from '@angular/core';

import {AuthState} from '@shared/stores/auth/auth.state';
import {Select, Store} from '@ngxs/store';
import {filter, map, Observable, Subject, takeUntil} from 'rxjs';
import {EAccountType, EToastSeverity, IProfile, IUser} from "@models/dist";
import {ChangeAvatarModalComponent, ChangeNameModalComponent} from '@profile/components';
import {DialogService} from 'primeng/dynamicdialog';
import {GetProfile, ResetICOWallet} from "@profile/stores/profile/profile.actions";
import {FormBuilder, UntypedFormGroup} from "@angular/forms";
import {ProfileService} from "@profile/services/profile.service";
import {ToastService, UserService} from "@shared/services";
import {TranslateService} from "@ngx-translate/core";
import {loadMyUser} from "@shared/stores/auth/auth.actions";
import {IPhoneNumber} from "@models/dist/models/auth";
import {PhoneNumberModalComponent} from "@shared/components";
import {CountrySetModalComponent} from "@profile/components/country-set-modal/country-set-modal.component";
import {environment} from "@environments/environment";
import { ProfileState } from './stores/profile/profile-state.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./components/scss/profile.scss', './profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {

  @Select(ProfileState.getICOWallet) icoWallet$: Observable<string> | undefined;
  @Select(AuthState.getUser) user$: Observable<IUser> | undefined;
  @Select(ProfileState.getProfile) profile$: Observable<IProfile> | undefined;
  public user?: IUser;
  public form?: UntypedFormGroup;
  @ViewChild('pseudo') pseudo: ElementRef = new ElementRef<any>("pseudo");

  ref?: any;
  private destroy$ = new Subject<void>();
  private isCustomToken = false;
  public referral: any;
  public service = 'ico';
  public icoWallet;
  public icoWalletMessage;
  public summitTokens = 0;

  constructor(
    public toastService: ToastService,
    public userService: UserService,
    private translateService: TranslateService,
    public profileService: ProfileService, private dialogService: DialogService, private store: Store, public formBuilder: FormBuilder) {
  }

  openChangeAvatarModal() {
    this.dialogService.open(ChangeAvatarModalComponent, {
      showHeader: false,
      width: '360px',
      contentStyle: {
        'max-height': '500px',
        'overflow': 'hidden',
        'border-radius': '20px'
      }
    });
  }

  openChangeNameModal() {
    this.dialogService.open(ChangeNameModalComponent, {
      showHeader: false,
      width: '33%',
      contentStyle: {
        'max-height': '500px',
        'overflow': 'hidden',
        'border-radius': '20px'
      }
    });
  }

  ngOnInit(): void {
    console.log('profile');
    this.getCurrentUser();
    this.store.select(AuthState.isCustomToken).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      console.log(data, 'isCustomToken');
      this.isCustomToken = Boolean(data);
    })
    this.store.dispatch(new GetProfile()).pipe(filter(data => !!data), takeUntil(this.destroy$)).subscribe(async ({ProfilState}) => {
      const user = ProfilState.profile.user
      if ((!user.country || !user.country.length) && !this.isCustomToken) {
        this.displayCountryModal()
      }
      // await this.setForm()
    });

    this.icoWallet$?.pipe(takeUntil(this.destroy$), map(data => data)).subscribe((wallet: string) => {
      if (wallet){
        this.icoWallet = wallet;
        this.icoWalletMessage = this.translateService.instant('ico.dashboard.wallet.address', {icoWallet : wallet});
      }
    });

    this.profile$?.pipe(takeUntil(this.destroy$), map(data => data)).subscribe((profile: IProfile) => {
      if (profile) {
        this.summitTokens = profile.amount;
      }
    })
  }

  async setForm() {
    console.log(this.user?.signupReferral);
    this.form = this.formBuilder.group({
      pseudo: [this.user?.pseudo, null],
      referral: this.formBuilder.control({value: this.user?.signupReferral, disabled: !!this.user?.signupReferral})
    });
  }

  async setReferral() {
    try {
      const referralInDB = await this.userService.validateReferralCode(this.referral) as any;
      if (referralInDB?.code?.active) {
        await this.userService.setReferral(this.referral);
        this.toastService.addToast(EToastSeverity.success, 'info', 'ico.referrals.update.success')
        this.store.dispatch(new GetProfile());
      }
    } catch (e) {
      this.toastService.addToast(EToastSeverity.error, 'error', 'generalError')

    }
  }

  discordConnect() {
    const state = {
      id: this.user?.id,
      personal_scope: 'profile'
    }
    window.location.href = `https://discord.com/api/oauth2/authorize?client_id=1210588382074376242&response_type=code&redirect_uri=${environment.discord_uri}&scope=identify+email&state=${JSON.stringify(state)}`
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
    this.icoWallet = null;
    this.icoWalletMessage = null;
    this.summitTokens = 0;
    this.store.dispatch(new ResetICOWallet());
  }

  async update() {
    try {
      //check referal
      if (this.form?.controls.referral.value !== this.user?.signupReferral) {
        const referralInDB = await this.userService.validateReferralCode(this.form?.value.referral) as any;
        if (!referralInDB?.code?.active) {
          this.toastService.addToast(EToastSeverity.error, 'error', 'generalError')
        }
      }
      const update = await this.profileService.update({...this.form?.value, service: this.service});
      this.toastService.addToast(EToastSeverity.success, this.translateService.instant('info'), this.translateService.instant('updatePseudo'));
      this.store.dispatch(new loadMyUser());
      this.store.dispatch(new GetProfile());
    } catch (e) {

    }


  }

  openPhoneModal() {
    this.ref = this.dialogService.open(PhoneNumberModalComponent, {
      showHeader: false,
      dismissableMask: true,
      data: {
        email: this.user?.email,
        accountType: this.user?.accountType,
      },
      styleClass: 'p-0 phoneModal',
      contentStyle: {
        'padding': '0px !important',
        'width': '100%',
        'min-width': '680px',
        'max-width': '550px',
        'border-radius': '20px'
      },
      baseZIndex: 10000,
    });

    this.ref.onClose.pipe(takeUntil(this.destroy$)).subscribe(async (data: IPhoneNumber) => {
      if (data?.phoneNumber) {
        try {
          await this.profileService.updatePhoneNumber()
          // this.toastService.addToast(EToastSeverity.success,
          //   this.translateService.instant('info'),
          //   this.translateService.instant('updatePhoneNumberSuccess'));
          this.store.dispatch(new loadMyUser());
        } catch (e) {
          // this.toastService.addToast(EToastSeverity.error,
          //   this.translateService.instant('phone.toast.error.title'), e);
        }
      }
    })
  }

  private getCurrentUser() {
    this.user$?.pipe(takeUntil(this.destroy$), filter((user) => !!user)).subscribe(user => {
      this.user = user;
      this.service = user.service;

      this.setForm()
    })
  }

  private displayCountryModal() {
    console.log('displayCountryModal');
    this.ref = this.dialogService.open(CountrySetModalComponent, {
      showHeader: false,
      dismissableMask: true,
      data: {
        email: this.user?.email,
      },
      styleClass: 'p-0 phoneModal',
      contentStyle: {
        'padding': '0px !important',
        'width': '100%',
        'min-width': '680px',
        'max-width': '550px',
        'border-radius': '20px'
      },
      baseZIndex: 10000,
    });

    this.ref.onClose.pipe(takeUntil(this.destroy$)).subscribe(async (data: IPhoneNumber) => {
      if (data?.phoneNumber) {
        try {
          await this.profileService.updatePhoneNumber()
          // this.toastService.addToast(EToastSeverity.success,
          //   this.translateService.instant('info'),
          //   this.translateService.instant('updatePhoneNumberSuccess'));
          this.store.dispatch(new loadMyUser());
        } catch (e) {
          // this.toastService.addToast(EToastSeverity.error,
          //   this.translateService.instant('phone.toast.error.title'), e);
        }
      }
    })
  }

  goToICODashboard(){
    console.log('go to ico dashboard');
  }

  protected readonly EAccountType = EAccountType;
}
