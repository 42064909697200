import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {firstValueFrom, map, Observable, of} from "rxjs";
import {environment} from "@environments/environment";
import {SdkResponse} from 'onfido-sdk-ui';
import {EKycForm} from "@profile/components/kyc-form/kyc-form.component";





@Injectable({
  providedIn: 'root'
})
export class OnfidoService {
  BASE = `${environment.nestApiUrl}/api/sgp/compliance/onfido`;
  BASE_SUMSUB = `${environment.nestApiUrl}/api/sgp/compliance/sumsub`;


  token: string = "api_sandbox.H-FsXIDlsvv.XWk6Oun1SnYjX7oTtuXAetWj4a1wm9k2";

  constructor(private http: HttpClient) {
  }

  getToken() {
    return of(this.token);
  }

  getSdkToken(type: EKycForm) {
    return this.http.post(`${this.BASE}`, {type}).pipe(map((data) => data));
  }
  getSdkTokenSubSum(type: EKycForm){
    return this.http.post(`${this.BASE_SUMSUB}`, {type}).pipe(map((data) => data));

  }

  async storeData(data: SdkResponse) {
    return firstValueFrom(await this.http.post(`${this.BASE}/save`, {data}));
  }

  async endSumSub(type: EKycForm){
    return firstValueFrom(await this.http.post(`${this.BASE_SUMSUB}/save`, {type}));

  }
}
