<!--<div class="errorDisplay">-->
<!--  &lt;!&ndash;close modal button &ndash;&gt;-->
<!--  <button-->
<!--    class="close"-->
<!--    icon="pi pi-times"-->
<!--    pButton-->
<!--    (click)="close()"-->
<!--  ></button>-->
<!--  <div class="modal-header">-->
<!--    <div [innerHTML]="'ico.referrals.spin.title' | translate" class="title text-center"></div>-->
<!--  </div>-->
<!--  <div class="modal-body flex flex-column aligns-items-start justify-content-start">-->
<!--    <ngx-wheel-->
<!--      #wheel-->
<!--      [width]='400'-->
<!--      [height]='400'-->
<!--      [spinDuration]='8'-->
<!--      [items]='items'-->
<!--      [innerRadius]='20'-->
<!--      [spinAmount]='10'-->
<!--      [textOrientation]='textOrientation'-->
<!--      [textAlignment]='textAlignment'-->
<!--      pointerStrokeColor='black'-->
<!--      pointerFillColor='gold'-->
<!--      [idToLandOn]='idToLandOn'-->
<!--      (onSpinStart)='before()'-->
<!--      (onSpinComplete)='after()'-->
<!--    ></ngx-wheel>-->
<!--  </div>-->
<!--  <div class="modal-footer mt-5">-->
<!--    <app-ico-btn (click)="spin()" rId="spin" [disabled]="!available" [text]="'ico.referrals.spin.play'|translate: {spin:available}" ></app-ico-btn>-->
<!--    <app-ico-btn (click)="close()" [text]="'ico.referrals.spin.close'|translate" *ngIf="!available"></app-ico-btn>-->


<!--  </div>-->
<!--</div>-->


<div class="flex flex-column justify-content-center align-items-center">
  <div class="wheels">
    <img src="/assets/icon/pointer.svg" alt="" height="50px" width="50px">
    <ngx-wheel
      #wheel
      [width]='400'
      [height]='400'
      [spinDuration]='8'
      [items]='items'
      [innerRadius]='10'
      [spinAmount]='10'
      [textOrientation]='textOrientation'
      [textAlignment]='textAlignment'
      pointerStrokeColor='black'
      pointerFillColor='black'
      [idToLandOn]='idToLandOn'
      (onSpinStart)='before()'
      (onSpinComplete)='after()'
    ></ngx-wheel>
  </div>

  <app-ico-btn class="mt-2" (click)="spin()" rId="spin" [disabled]="!available"
               [text]="'ico.referrals.spin.play'|translate: {spin:available, multiple: available > 0 ? 's': ''}"></app-ico-btn>
</div>
