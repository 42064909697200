<div class="summitModal content-avatar-modal">
  <div>

  <a (click)="close()" class="close pink-hover"
          icon="pi pi-times" pButton></a>
  </div>
  <div class="modal-header">
    <div [innerHtml]="'Profile.profilePicture' | translate" class="title text-left"></div>
    <div
      [innerHtml]="'Profile.changeProfilePicture' | translate"
      class="subtitle text-left mb-4"
    ></div>
  </div>
  <ng-container>
    <div class="modal-body">
      <div class="avatar-container">
        <img
          [src]="previewAvatar ?? user?.avatar ?? 'assets/icon/exempleAvatar.jpg'"
          class="avatar w-5  border-round-xl"
        />
      </div>
      <!--      <div class="flex justify-content-center">-->
      <!--        <p-fileUpload (onSelect)="selectFile($event)"-->
      <!--                      (uploadHandler)="uploadAvatar($event)"-->
      <!--                      [auto]="false"-->
      <!--                      [chooseLabel]="'Profile.browser' | translate"-->
      <!--                      [customUpload]="true"-->
      <!--                      [maxFileSize]="1000000"-->
      <!--                      [mode]="'advanced'"-->
      <!--                      [multiple]="false"-->
      <!--                      [showCancelButton]="false" [showUploadButton]="!isLoading"-->
      <!--                      [uploadLabel]="'save' | translate"-->
      <!--                      [withCredentials]="false"-->
      <!--                      accept="image/*"-->
      <!--                      class="" name="avatar[]"-->
      <!--                      rButton rId="summit-upload-avatar" styleClass="mt-3">-->
      <!--        </p-fileUpload>-->
      <!--      </div>-->

      <div class="flex justify-content-center flex-column align-items-center">

        <button (click)="f.click()"
                [class]="fileToUpload ? 'pink-button' : 'gradient-button'"
                [label]="'Profile.browser' | translate"
                class="mt-4"
                icon="pi pi-plus"
                iconPos="left"
                pButton type="button">
        </button>

        <button
          (click)="uploadAvatar()"
          *ngIf="fileToUpload"
          [label]="'save' | translate"
          [loading]="false"
          class="gradient-button mt-2"
          icon="pi pi-check"
          iconPos="left"
          pButton
          rButton
          rId="summit-upload-avatar"
          type="button"></button>

        <input #f (change)="selectFile($event)"
               accept="image/*"
               class="file-input"
               id="file-input"
               type="file"/>
      </div>

    </div>
  </ng-container>
</div>
