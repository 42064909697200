import {Component, OnDestroy} from '@angular/core';
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {EToastSeverity} from "@models/dist";
import {loadMyUser} from "@shared/stores/auth/auth.actions";
import {GetProfile} from "@profile/stores/profile/profile.actions";
import {ToastService} from "@shared/services";
import {IcoService} from "@ico/services/ico.service";
import {Store} from "@ngxs/store";
import {RefuseModalComponent} from "@ico/modules/cgu/refuse-modal/refuse-modal.component";
import {Router} from "@angular/router";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-cgu-modal',
  templateUrl: './cgu-modal.component.html',
  styleUrls: ['./cgu-modal.component.scss']
})
export class CguModalComponent implements OnDestroy{
  private destroy$ = new Subject<void>();
  constructor(
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private toastService: ToastService,
    private icoService: IcoService,
    private store: Store,
    private router: Router,
  ) {
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  cancel() {
    this.ref.close(true)
  }

  close() {
    this.ref.close(false);
  }

  goToCalendly() {
  }

  accept() {
    this.icoService.updateCGU(true).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.toastService.addToast(
        EToastSeverity.info,
        'info',
        'cgu.toast.update'
      );
      this.store.dispatch(new loadMyUser());
      this.store.dispatch(new GetProfile());
      this.close();
    })
  }

  read() {
    this.close();
    this.router.navigateByUrl('/ico/cgu')
  }
}
