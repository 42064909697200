import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ReactiveLoaderService, ToastService, UserService} from "@shared/services";
import {Observable, Subject, takeUntil} from 'rxjs';
import {AuthState} from '@shared/stores/auth/auth.state';
import {DomSanitizer} from '@angular/platform-browser';
import {EToastSeverity, IUser} from "@models/dist";
import {reactiveButtonState} from '@shared/services/reactive-loader.service';
import {loadMyUser} from "@shared/stores/auth/auth.actions";

@Component({
  selector: 'app-change-avatar',
  templateUrl: './change-avatar.modal.component.html',
  styleUrls: ['./change-avatar.modal.component.scss']
})
export class ChangeAvatarModalComponent implements OnInit, OnDestroy {

  @Select(AuthState.getUser)
  user$: Observable<any> | undefined;


  user: IUser | null = null;

  previewAvatar: string | null = null;

  isLoading = false;

  fileToUpload: any | null = null;
  reader?: FileReader;

  private subscription = new Subject<void>();

  constructor(
    public dialogService: DialogService,
    private reactiveLoader: ReactiveLoaderService,
    public userService: UserService,
    public ref: DynamicDialogRef,
    private store: Store,
    private toastService: ToastService,
    private sanitizer: DomSanitizer
  ) {
  }

  async ngOnInit() {
    this.getUser();
    this.initReader();
  }

  ngOnDestroy() {
    this.subscription.next();
    this.subscription.complete();
  }

  selectFile(event: any) {
    const file = event;
    const files = event?.target?.files;
    if (files?.length) {
      this.fileToUpload = files.item(0);
      this.reader?.readAsDataURL(this.fileToUpload);
    }
  }

  async uploadAvatar() {
    if (this.isLoading || !this.fileToUpload) {
      return;
    }
    this.isLoading = true;
    try {
      this.reactiveLoader.setReactiveButtonState(
        'summit-upload-avatar',
        reactiveButtonState.loading
      );
      await this.userService.uploadAvatar(
        this.fileToUpload
      );
      this.toastService.addToast(
        EToastSeverity.success,
        'Common.confirmation',
        'Profile.avatarUpdated'
      );
      this.reactiveLoader.setReactiveButtonState(
        'summit-upload-avatar',
        reactiveButtonState.success
      );
      this.store.dispatch(new loadMyUser());
      this.close();
    } catch (error) {
      this.reactiveLoader.setReactiveButtonState(
        'summit-upload-avatar',
        reactiveButtonState.error
      );
      this.toastService.addToast(
        EToastSeverity.error,
        'Error.error',
        'Error.error_upload_avatar'
      );
    }
    this.isLoading = false;
  }

  close() {
    // this.closeEl?.nativeElement.focus();
    this.ref.close();
  }

  private initReader() {
    this.reader = new FileReader();
    this.reader.onload = async (e) => {
      this.previewAvatar = this.reader?.result as string;
    };
  }

  private getUser() {
    this.user$?.pipe(takeUntil(this.subscription)).subscribe(user => {
      this.user = user;
    })
  }
}
