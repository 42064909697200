import {AfterViewChecked, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild,} from '@angular/core';
import {ProfileService} from '@app/profile/services/profile.service';
import {BehaviorSubject, combineLatest, filter, map, Observable, Subject, takeUntil} from 'rxjs';
import {EDiscussionTitle, EMessageStatus, EToastSeverity, IUser} from 'summit-group-common';
import {WebSocketService} from '@app/shared/services/webSocket.service';
import {Select} from '@ngxs/store';
import {AuthState} from '@app/shared/stores/auth/auth.state';
import {TranslateService} from '@ngx-translate/core';
import {FileUpload} from 'primeng/fileupload';
import {ReactiveLoaderService, ToastService} from "@shared/services";
import {reactiveButtonState} from "@shared/services/reactive-loader.service";
import {DialogService} from "primeng/dynamicdialog";
import {CloseMessagingComponent} from "@profile/components/close-messaging/close-messaging.component";
import {ELang} from "@models/dist";

export interface IConversation {
  id: string;
  isEnded: boolean;
  title: string;
  messages: IMessage[];
  lastMessage?: IMessage;
  unread: number;
  initiator: IUser;
  recipient: IUser;
  teamTag: string;
  updated_at: Date;
}

export interface IMessage {
  id: string;
  content: string;
  created_at: Date;
  sender: IUser;
  status: 'sent';
  updated_at: Date;
  files: IFile[]
}

export interface IFile {
  name: string;
  data: string;
  file:string;
}

export interface IDisplayConversation {
  title: string;
  display: boolean;
  conversations: IConversation[];
  updated_at: Date | null;
}

@Component({
  selector: 'app-messaging',
  templateUrl: './messaging.component.html',
  styleUrls: ['./../scss/profile.scss', './messaging.component.scss', './quill.snow.scss'],
})
export class MessagingComponent implements OnInit, OnDestroy, AfterViewChecked {
  @Select(AuthState.getUser) user$: Observable<IUser> | undefined;
  @ViewChild('messagesContainer') private messagesContainer!: ElementRef;
  @ViewChild('fileInput') fileInput!: FileUpload;

  private destroy$ = new Subject<void>();

  // availableSendBtn$ = new Subject<boolean>().pipe(startWith(false));
  availableSendBtn = true;
  conversations: any[] = [];
  messages: any[] = [];
  selectedConversation: any = null;
  selectedUser: IUser | null = null;

  lang = this.translate.currentLang as ELang;
  newMessageContent: string = '';
  newConversation: boolean = false;
  EDiscussionTitle = EDiscussionTitle;
  type: EDiscussionTitle = EDiscussionTitle.OTHER;
  discussionOptions: any[] = [];
  groupedConversations: { [title: string]: any[] } = {};
  expandedTitles: { [title: string]: boolean } = {};
  files: any[] = [];
  acceptedFiles: string = '.pdf, .png, .doc, .docx, .xls, .xlsx, .csv, .jpg, .jpeg';
  isDropdownConversationVisible = false;
  newConversationLabel = null;
  placeholder = this.translate.instant('selectSubject');
  @ViewChild('editor') editor = new ElementRef('editor');
  @ViewChild('fileupload') fileupload = new ElementRef('fileupload');
  random = Math.floor(Math.random() * 10) ?? 1;
  messageLoader = new Array(!this.random ? 1 : this.random).fill({});
  groupedConversation$ = new BehaviorSubject<IDisplayConversation[]>([])

  conversations$ = new BehaviorSubject<IConversation[]>([])

  activeConversationId$ = new BehaviorSubject<string>('');

  messages$ = new BehaviorSubject<IMessage[]>([])

  activeConversation$: Observable<IConversation | null> = combineLatest(
    [this.conversations$, this.activeConversationId$]
  ).pipe(
    map(
      ([conversations, id]) => {
        if (!id) {
          return null;
        }
        this.page = 0;
        this.canLoadMore = false;
        return conversations.find((conversation) => conversation.id === id)!
      })
  )!;


  EDiscussionTitleKey = Object.keys(EDiscussionTitle);


  timeOut?;

  loading = true;
  private websocketServiceLoad: boolean = false;

  page: number = 0;
  public totalMessageConv: number = 0;
  public canLoadMore: boolean = false;
  public emptyTagRegex = /<p[^>]*>(&nbsp;|\s+|<br\s*\/?>)*<\/p>/g;
  public loadingMore: boolean = false;
  private quill?: any;

  constructor(
    private translate: TranslateService,
    private profileService: ProfileService,
    private websocketService: WebSocketService,
    private cdr: ChangeDetectorRef,
    private reactiveService: ReactiveLoaderService,
    private dialogService: DialogService,
    public taostService: ToastService,
  ) {
    // this.messages$.pipe(
    //   tap(() => {
    //     this.scrollDown();
    //   })
    // )
  }


  async ngOnInit() {

    this.translate.onLangChange.pipe(takeUntil(this.destroy$)).subscribe((lang) => {
      window.location.reload();
    })

    this.discussionOptions = this.EDiscussionTitleKey.map((key) => ({
      name: this.translate.instant(
        `messaging.titleSelect.${EDiscussionTitle[key]}`
      ),
      value: this.translate.instant(EDiscussionTitle[key]),
    }));


    await this.getConversationsForUser();
    this.user$?.pipe(filter(Boolean), takeUntil(this.destroy$)).subscribe(async (user: IUser) => {
      if (!this.websocketServiceLoad) {
        await this.initWebSocket(user.id);
      }
    })

  }


  quillInit(event) {
    event.editor.clipboard.options.matchVisual = true;
    // delete event.editor.keyboard.bindings[13];
  }

  async getConversationsForUser() {
    console.log(this.files);
    const data = (await this.profileService.getAllConversationsForUser() as IConversation[]);
    const conversations = data

    const closedConversations = data.filter((conversation) => conversation.isEnded).map(
      (conversation) => {
        return {
          ...conversation,
          lastMessage: conversation.messages[0]
        }
      }
    ).sort((a: any, b: any) => (new Date(b.updated_at) > new Date(a.updated_at) ? 1 : -1))

    this.conversations$.next(conversations);
    if (data.length > 0) {
      const grouppedConversation: IDisplayConversation[] = this.EDiscussionTitleKey.map((title) => {
        const conversationsValue = conversations
          .filter((c) => !c.isEnded)
          .map((c) => {
            return {...c, lastMessage: c.messages[0]}
          })
          .filter((conversation) => conversation.title === title)
          .sort((a: any, b: any) => (new Date(b.updated_at) > new Date(a.updated_at) ? 1 : -1))
        return {
          title,
          display: false,
          conversations: conversationsValue,
          updated_at: conversationsValue.length ? conversationsValue[0].updated_at : null,
        }
      }).filter((e) => e.conversations.length);


      const sortedGrouppedConversation = grouppedConversation
        .sort((a, b) => (new Date(b.updated_at ?? '') > new Date(a.updated_at ?? '') ? 1 : -1));

      if (closedConversations.length) {
        sortedGrouppedConversation.push({
          title: 'CLOSED',
          display: false,
          conversations: closedConversations,
          updated_at: closedConversations[0].updated_at,
        });
      }
      const id = sortedGrouppedConversation[0].conversations[0].id;
      this.groupedConversation$.next(sortedGrouppedConversation)
      this.activeConversationId$.next(id);
      await this.getMessages(id)
    } else {
      this.groupedConversation$.next([])
    }


    this.loading = false;

  }

  getUnreadMessage(c: IConversation, id: string): number {
    const number = c.messages.filter(
      (msg) =>
        msg.status === EMessageStatus.SENT &&
        msg.sender?.id !== id
    ).length;
    return number;
  }

  toggleDropdown() {
    this.isDropdownConversationVisible = !this.isDropdownConversationVisible;
  }

  contentChange(event) {
    console.log(event.textValue);

    if (!event.textValue.length) {
      this.newMessageContent = '';
      return;
    }

    this.newMessageContent = event.htmlValue;
  }

  initEditor(event) {
    console.log(event);
    this.quill = event.editor;
  }

  async enterKeyUp(event) {
    // event.preventDefault();


    if (event.code === 'Enter' && event.shiftKey && this.availableSendBtn) {
      this.newMessageContent = this.newMessageContent.replace(this.emptyTagRegex, '');
      await this.sendMessage();
      // this.availableSendBtn = false;
    }
  }

  async sendMessage() {

    const newMessageContent = this.newMessageContent.replace(this.emptyTagRegex, '')
    const hasFile = this.files.length
    if (!newMessageContent && !hasFile) {
      this.newMessageContent = '';
      this.availableSendBtn = false;
      return;
    }

    if (!this.availableSendBtn) return;

    const selectedConversationId = this.activeConversationId$.getValue();
    this.availableSendBtn = false;
    this.reactiveService.setReactiveButtonState('sendMsg', reactiveButtonState.loading);
    try {
      let conversationId = ''
      const formData = new FormData();
      const content: string = newMessageContent;


      formData.append('content', content);
      if (this.files && this.files.length > 0) {
        for (let file of this.files) {
          formData.append('files', file);
        }
      }
      this.files = [];
      if (this.newConversation) {
        formData.append('title', this.type);
        let newConversation = (await this.profileService.createNewConversation(
          formData
        )) as any;
        await this.getConversationsForUser();
      } else {
        formData.append('convId', selectedConversationId);
        const {data: messages} = await this.profileService.addNewMessageToConversation(formData) as { data: IMessage[] }
        this.messages$.next(messages);

        this.timeOut = setTimeout(async () => {
          this.messagesContainer.nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
          });
          await this.getConversationsForUser();
          clearTimeout(this.timeOut)
        }, 200)

      }
      (this.fileupload as any).clear();
      this.newMessageContent = '';
      this.availableSendBtn = true;
      this.newConversation = false;
      (this.quill as any).deleteText(0, this.quill.getLength())
      this.reactiveService.setReactiveButtonState('sendMsg', reactiveButtonState.success);
    } catch (err) {
      console.log(err);
    }
  }

  scrollDown() {
    console.log(`Scroll down trigger`, this.messagesContainer);
    if (!!this.messagesContainer) {
      // console.log(this.messagesContainer.nativeElement.clientHeight);
      this.messagesContainer.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
      // this.messagesContainer.nativeElement.scrollTo({
      //   bottom: this.messagesContainer.nativeElement.clientHeight,
      //   behavior: 'smooth'
      // })
      // this.messagesContainer.nativeElement.scrollDown(this.messagesContainer.nativeElement.scrollHeight);
    }
  }

  getSortedConversationTitles(): string[] {
    const titles = Object.keys(this.groupedConversations);
    return titles.sort((a, b) =>
      a === 'CLOSED' ? 1 : b === 'CLOSED' ? -1 : 0
    );

  }

  async endConversation(id: string) {
    this.isDropdownConversationVisible = false;
    const ref = this.dialogService.open(
      CloseMessagingComponent,
      {
        showHeader: false,
        contentStyle: {'max-width': '800px', 'border-radius': '20px'},
        baseZIndex: 10000,
        data: {},
        closable: true,
        dismissableMask: true,
      }
    )
    ref.onClose.pipe(takeUntil(this.destroy$)).subscribe(async (data) => {
      console.log(data);
      if (data.close) {
        await this.profileService.endConversation(id);
        await this.getConversationsForUser();
      }
    })
  }

  getLastMessage(convo): any {
    return convo?.messages[convo.messages.length - 1] || {};
  }

  addNewConversation() {
    this.newConversation = !this.newConversation;
  }

  selectType(event: any) {
    if (event.value) {
      this.newConversationLabel = event.value;
      this.type = event.value.value;
    }
  }

  getKeys(obj: any) {
    return Object.keys(obj);
  }

  toggleDisplay(title: string) {
    this.expandedTitles[title] = !this.expandedTitles[title];
  }

  isExpanded(title: string): boolean {
    return this.expandedTitles[title];
  }

  triggerFileInput() {
    this.fileInput.choose();
  }

  addFile(event: any) {
    if (event.files[0].size > 10000000) {
      this.taostService.addToast(
        EToastSeverity.error,
        'information',
        'fileToBigger'
      )
    }
    if (this.files.length > 10) {
      //TODO: alerte trop de fichier
      return;
    }

    if (!!event) {
      this.files = event?.currentFiles;
    }
  }

  deleteFile(index) {
    this.files.splice(index, 1);
  }

  downloadFile(file) {
    const byteCharacters = atob(file.data);
    const byteArrays: Uint8Array[] = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: file.type});

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = file.name;
    link.click();

    URL.revokeObjectURL(link.href);
  }

  // private async getUser() {
  //   this.user$?.pipe(takeUntil(this.destroy$), filter(Boolean)).subscribe(async (user) => {
  //     this.user = user;
  //
  //   });
  // }

  private initWebSocket(id: string) {
    this.websocketService
      .getIncomingMessage(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (data: any) => {
        await this.getConversationsForUser();
      });


    this.websocketService
      .getIncomingConversation(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(async () => {
        await this.getConversationsForUser();
      });

    this.websocketServiceLoad = true;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewChecked(): void {


  }

  async getMessages(id: string) {

    const {data: messages, total} = await this.profileService.getMessagesFromConversation(id, {page: this.page}) as {
      data: IMessage[],
      total: number
    };
    this.totalMessageConv = total;

    this.canLoadMore = messages.length < total;
    this.messages$.next(messages);
    this.timeOut = setTimeout(() => {
      this.messagesContainer.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
      clearTimeout(this.timeOut)
    }, 200)
  }


  async selecteCurrentConversation(conversation: IConversation) {
    this.activeConversationId$.next(conversation.id);
    this.messages$.next([]);

    await this.getMessages(conversation.id);

  }

  async loadMore() {
    this.loadingMore = true;
    this.page++;
    const id = this.activeConversationId$.getValue();


    const {data: messages, total} = await this.profileService.getMessagesFromConversation(id, {
      page: this.page
    }) as {
      data: IMessage[],
      total: number
    };
    const totalMessages = [...messages, ...this.messages$.getValue()];
    // console.log(total);
    // this.canLoadMore = totalMessages.length < total;
    this.messages$.next(totalMessages)
    this.loadingMore = false;

  }

  handleError(event: any) {
    console.log(event);
  }
}
