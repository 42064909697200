import {EIcoRoutes} from "@models/dist";

interface IIcoDirectory {
  label: string
  items: any[]
}

const directory: IIcoDirectory[] = [
  {
    label: 'menu.ico.home',
    items: [
      {
        label: 'menu.ico.dashboard',
        icon: 'home-outline',
        routerLink: ['/ico/' + EIcoRoutes.DASHBOARD],
      },
      // {
      //   label: 'menu.ico.investir',
      //   icon: 'wallet-outline',
      //   routerLink: ['/ico/' + EIcoRoutes.INVEST],
      // },
      {
        label: 'menu.ico.transactions',
        icon: 'receipt-outline',
        routerLink: ['/ico/' + EIcoRoutes.TRANSACTIONS],
      },
      // {
      //   label: 'menu.ico.stacking',
      //   icon: 'lock-closed-outline',
      //   routerLink: ['/ico/' + EIcoRoutes.STACKING],
      // },
      // {
      //   label: 'menu.ico.referral',
      //   icon: 'people-outline',
      //   routerLink: ['/ico/' + EIcoRoutes.SPONSORSHIP],
      // },
      // {
      //   label: 'menu.ico.litepaper',
      //   icon: 'document-text-outline',
      //   routerLink: ['/ico/' + EIcoRoutes.LITEPAPER],
      // },
      {
        label: 'menu.ico.ressources',
        icon: 'document-text-outline',
        routerLink: ['/ico/ressources'],
      },
      {
        label: 'menu.ico.roadmap',
        icon: 'map-outline',
        routerLink: ['/ico/' + EIcoRoutes.ROADMAP],
      },
      // {
      //   label: 'menu.ico.medias',
      //   icon: 'document-outline',
      //   routerLink: ['/ico/' + EIcoRoutes.MEDIAS],
      // },
      // {
      //   label: 'menu.ico.leaderboard',
      //   icon: 'game-controller-outline',
      //   routerLink: ['/ico/leaderboard'],
      // },
      // {
      //   label: 'menu.ico.help',
      //   icon: 'information-circle-outline',
      //   routerLink: ['/help/'],
      // },
      {
        label: 'menu.ico.cgu',
        icon: 'information-circle-outline',
        routerLink: ['/ico/cgu/'],
      },
      // {
      //   label: 'OTC',
      //   icon: 'pricetags-outline',
      //   routerLink: [EIcoRoutes.OTC],
      // },
    ],
  }
];


export default directory;
