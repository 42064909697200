import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {ContactModalComponent} from "@shared/components";
import {DialogService} from "primeng/dynamicdialog";

@Component({
  selector: 'app-ico-top',
  templateUrl: './ico-top.component.html',
  styleUrls: ['./ico-top.component.scss']
})
export class IcoTopComponent implements OnInit {

  @Input() back?: boolean = false;

  @Input()
  title = '';

  @Input() nohelp = false;
  ref: any;

  @Input() helpRoute = '';

  constructor(private router: Router,
              private dialogService: DialogService,) {
  }

  ngOnInit(): void {
  }

  goToHelp() {
    return this.router.navigate(["help"], {replaceUrl: true});
  }

  contactModal() {
    this.ref = this.dialogService.open(ContactModalComponent, {
      showHeader: false,
      dismissableMask: true,
      data: {},
      contentStyle: {'max-width': '860px', 'border-radius': '20px'},
      baseZIndex: 10000,
    })
  }
}
