import {Component} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {MenuItem} from 'primeng/api';
import {Observable} from 'rxjs';
import {AuthState} from '@shared/stores/auth/auth.state';
import {Logout} from '@shared/stores/auth/auth.actions';
import {LayoutComponent} from "@shared/components";
import {IUser} from "@models/dist";
import {NavigationEnd, NavigationStart, Router} from "@angular/router";
import {Location} from "@angular/common";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  userPanel = false;
  items: MenuItem[] = [];
  @Select(AuthState.getUser) user$: Observable<IUser> | undefined;
  public name = "";
  public service: any = '';

  constructor(
    private location: Location,
    public appMain: LayoutComponent, private store: Store, public router: Router) {
  }

  ngOnInit(): void {
    this.checkRouter(this.router.url);
    this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart || event instanceof NavigationEnd) {
          this.checkRouter(this.router.url);
        }
      }
    )
  }

  async checkRouter(url){
    if (url.includes('research')) {
      this.service = 'research';
    } else if (url.includes('mining')) {
      this.service = 'mining';
    } else if (url.includes('gravity')) {
      this.service = 'gravity';
    } else if (url.includes('medias')) {
      this.service = 'medias';
    } else if (url.includes('profile')) {
      this.service = 'profile';
    } else {
      this.service = 'ico';
    }
  }

  logout() {
    this.store.dispatch(new Logout());
  }

  displayUserPanel() {

    // const body = document.getElementsByTagName('body')[0];
    // if (this.userPanel) {
    //   body.classList.add('no-scroll');
    // } else {
    //   body.classList.remove('no-scroll');
    // }
  }

// getName() {
//
// }


  handleUserPanel() {
    console.log('handle');
    // this.userPanel = !this.userPanel
    this.router.navigateByUrl(`/ico/user-panel`)
    // if (this.userPanel) {
    //   const context = this.router.url.includes('ico') ? 'ico' : 'profile';
    //   console.log(context);
    //   if(context === 'ico'){
    //
    //   }else{
    //     this.router.navigateByUrl(`/profile`)
    //
    //   }
    // } else {
    //   this.location.back();
    // }
  }
}
