import {Component} from '@angular/core';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-kyc-modal-tutorial',
  templateUrl: './accepted-country.component.html',
  styleUrls: ['./accepted-country.component.scss'],
})
export class AcceptedCountryModal {

  public title = "";
  constructor(
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,

  ) {
    this.title = this.config.data.title;
  }

  close() {

    this.ref.close();
  }
}
