import {Component, OnDestroy, OnInit} from '@angular/core';
import {DialogService} from 'primeng/dynamicdialog';
import {AcceptedCountryModal} from './kyc-tutorial-modal/accepted-country.component';
import {Router} from "@angular/router";
import {BASE_URL_CORPORATE, EAccountType, ETutorialStep, IUser} from "@models/dist";
import {map, Observable, Subject, takeUntil} from "rxjs";
import {AuthState} from "@shared/stores/auth/auth.state";
import {Select} from "@ngxs/store";


@Component({
  selector: 'app-kyc-tutorial',
  templateUrl: './kyc-tutorial.component.html',
  styleUrls: ['./kyc-tutorial.component.scss'],
  providers: [DialogService],
})
export class KycTutorialComponent implements OnInit, OnDestroy {

  @Select(AuthState.getUser) user$: Observable<IUser> | undefined;
  user: IUser | null = null;

  ref: any;
  tutorialStep: ETutorialStep = ETutorialStep.START;
  euSelect = true;

  ETutorialStep = ETutorialStep;
  EAccountType = EAccountType;

  private subscription = new Subject<void>();

  constructor(public dialogService: DialogService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.getCurrentUser();
  }

  ngOnDestroy() {
    this.subscription.next();
    this.subscription.complete();
  }

  openAcceptedCountryModal() {
    this.ref = this.dialogService.open(AcceptedCountryModal, {
      showHeader: false,
      closable: true,
      data: {
        title: "Voir la liste des pays acceptés"
      },
      contentStyle: {'max-width': '980px', 'border-radius': '20px'},
      baseZIndex: 10000,
    });
  }

  openKyc() {

    if (this?.user?.accountType === EAccountType.COMPANY) {
      // @ts-ignore
      window.open(BASE_URL_CORPORATE, '_blank').focus();
      return;
    }
    this.router.navigate(['profile/kyc/form'], {replaceUrl: true});
  }

  openAcceptedSepaCountryModal() {
    this.ref = this.dialogService.open(AcceptedCountryModal, {
      showHeader: false,
      closable: true,
      data: {
        title: 'Liste des pays Sepa acceptés',
      },
      contentStyle: {'max-width': '980px', 'border-radius': '20px'},
      baseZIndex: 10000,
    });
  }

  nextStep() {
    window.scroll({top: 0, behavior: 'smooth'});
    if (this.tutorialStep === ETutorialStep.TWO && !this.euSelect) {
      this.tutorialStep = ETutorialStep.EIGHT;
      return;
    }
    this.tutorialStep++;
  }

  prevStep() {
    if (this.tutorialStep === ETutorialStep.EIGHT) {
      this.tutorialStep = ETutorialStep.TWO;
      return;
    }
    this.tutorialStep--;
  }

  navigateTo(route: string) {
    this.router.navigate([route], {replaceUrl: true});
  }

  private getCurrentUser() {
    this.user$?.pipe(takeUntil(this.subscription), map(data => data)).subscribe(user => {
      this.user = user;
    })
  }
}
