<div class="edit-wallet">
  <!--close modal button -->
  <button (click)="close()" class="close" icon="pi pi-times" pButton></button>

  <div class="modal-header">
    <div [innerHTML]="(id ? 'wallets.edit-modal.editWallet' : 'wallets.edit-modal.addWallet') | translate"
         class="title text-center"></div>


    <div *ngIf="step === 0"
         [innerHTML]="(id ? 'wallets.edit-modal.editWalletDesc' : 'wallets.edit-modal.addWalletDesc') | translate"
         class="subtitle text-center w-70 m-auto"></div>
    <div *ngIf="step === 1" [innerHTML]="'wallets.edit-modal.authenticationDesc' | translate"
         class="subtitle text-center w-70 m-auto"></div>

  </div>

  <div *ngIf="step === 0" class="modal-body">
    <input [(ngModel)]="name" class="w-full" id="name" pInputText
           placeholder="{{ 'wallets.edit-modal.name' | translate }}" style="padding: 1rem" type="text"/>

    <div *ngIf="nameError" class="error-message">
      {{ nameError }}
    </div>

    <div class="selector flex justify-content-between">
      <p-dropdown (ngModelChange)="validateInputs()" [(ngModel)]="selectedCrypto" [disabled]="!!id"
                  [options]="allCryptos" [placeholder]="'ico.invest.placeholder' | translate" [style]="{width: '100%'}"
                  appendTo="body" class="w-full" optionLabel="label">

        <ng-template pTemplate="selectedItem">
          <div *ngIf="selectedCrypto" class="currency-item">
            <img [src]="'/assets/currencies/' + selectedCrypto.slug.toLowerCase() + '.png'" alt="" height="24px"
                 width="24px">
            <div>{{ getCurrencyName(selectedCrypto) }}</div>
          </div>
        </ng-template>
        <ng-template let-currency pTemplate="item">
          <div class="flex align-items-center">
            <img [src]="'/assets/currencies/' + currency.slug.toLowerCase() + '.png'" alt="" height="24px"
                 style="margin-right: 5px;" width="24px">

            <div>{{ getCurrencyName(currency) }}</div>
          </div>
        </ng-template>

      </p-dropdown>
    </div>

    <input (input)="validateAddress($event)" [(ngModel)]="address" [disabled]="id != ''" class="w-full"
           id="address" pInputText placeholder="{{ 'wallets.edit-modal.address' | translate }}" style="padding: 1rem"
           type="text"/>

    <div *ngIf="addressError" [innerHTML]="addressError" class="error-message">
    </div>
    <span *ngIf="statusAddress !== null" [class]="'status '+ statusAddress">
      {{ 'wallets.edit-modal.status.' + statusAddress | translate }}
    </span>

    <div [innerHTML]="'wallets.edit-modal.info' | translate" class="info w-90 m-auto"></div>
  </div>

  <div *ngIf="step === 1" class="modal-body">
    <div class="modal-body w-full">
      <div class="mt-4">
        <h6 [innerHtml]="'2FA.summitCode' | translate" class="code-title"></h6>
        <div class="code-input-container">
          <code-input (codeChanged)="onHotpChanged($event)" (codeCompleted)="onCodeComplete()" [codeLength]="codeLength"
                      [code]="hotp" [initialFocusField]="0" [isCodeHidden]="false">
          </code-input>
        </div>
        <div class="mt-4 flex justify-content-center align-center">
          <div *ngIf="timeLeft" [innerHTML]="'resendCodeTimer' | translate:{timeLeft}" class="m-auto">
          </div>
          <button (click)="resendCode()" *ngIf="!isSending" [label]="'2FA.codeNotReceived' | translate"
                  class="p-button-text p-button-secondary text-center w-70" icon="pi pi-send" pButton>
          </button>
        </div>
      </div>
      <div *ngIf="!loading" class="code-body mt-3">
        <h6 [innerHtml]="'2FA.googleCode' | translate" class="code-title"></h6>
        <div *ngIf="!loading" class="code-input-container">
          <code-input #codeInput (codeChanged)="onTotpChanged($event)" (codeCompleted)="onCodeComplete()"
                      [codeLength]="codeLength" [code]="totp" [initialFocusField]="0" [isCodeHidden]="false">
          </code-input>
        </div>
      </div>
      <div [innerHTML]="'wallets.edit-modal.infoCode' | translate" class="info-message"></div>

    </div>

  </div>

  <div class="modal-footer">
    <div class="bottom-button mb-5 mt-5">
      <app-ico-btn (click)="back()" [outline]="true"
                   [text]="step === 0 ? 'ico.invest.modalInvest.cancel' : 'ico.invest.modalInvest.return' | translate"
                   style="width: 48%;"></app-ico-btn>
      <app-ico-btn (btnClick)="next()" [rId]="'next'" *ngIf="step === 0"
                   [disabled]="isDisabled()"
                   [text]="'next'" style="width: 48%;"></app-ico-btn>
      <app-ico-btn (btnClick)="submit()" *ngIf="step === 1"
                   [disabled]="(totp.length !== codeLength) || (hotp.length !== codeLength)" [text]="'save'"
                   style="width: 48%;"></app-ico-btn>
    </div>
  </div>
</div>
