<ng-container *ngIf="!resume">


  <div class="transactionDisplay">
    <!--close modal button -->
    <button (click)="back()" class="close" icon="pi pi-times" pButton></button>

    <div class="modal-header">
      <div [innerHTML]="'ico.invest.modalInvest.title' | translate" class="title text-center">

      </div>
      <div [innerHTML]="'ico.invest.modalInvest.subtitle' | translate" class="subtitle text-center w-70 m-auto">

      </div>
    </div>

    <div class="modal-body">
      <div class="selector flex justify-content-between" *ngIf="!!rest || isWaitingList">
        <p-dropdown (onChange)="selectedValue()" [(ngModel)]="selectedCrypto" [options]="allCryptos"
                    [placeholder]="'ico.invest.placeholder' | translate" [style]="{width: '100%'}" class="w-full"
                    optionLabel="label">

          <ng-template pTemplate="selectedItem">
            <div *ngIf="selectedCrypto" class="currency-item currency-item-value">
              <img [src]="'/assets/currencies/' + selectedCrypto.slug.toLowerCase() + '.png'" alt="" height="24px"
                   width="24px">
              <div>{{selectedCrypto.label}}</div>
            </div>
          </ng-template>
          <ng-template let-currency pTemplate="item">
            <div class="currency-item flex">
              <img [src]="'/assets/currencies/' + currency.slug.toLowerCase() + '.png'" alt="" height="24px"
                   width="24px">
              <div>{{currency.label}}</div>
            </div>
          </ng-template>

        </p-dropdown>
      </div>

      <div *ngIf="selectedCrypto" class="my-3">
        {{'ico.invest.network' | translate }} {{selectedCrypto.network}}
      </div>

      <ng-container *ngIf="loading">
        <div class="mb-4">
          <p-skeleton class="mb-5" height="4rem" width="100%"></p-skeleton>
        </div>
      </ng-container>
      <ng-container *ngIf="!loading && address">
        <span [innerHTML]="'Common.address' | translate" class="small-txt font-bold"></span>
        <div class="addr flex justify-content-between align-items-center">
          <span
            [innerHTML]="address.substring(0, address.length - 20) + '....' + address.substring(address.length - 6, address.length)"
            class="cursor-pointer inline-block break-word xl:hidden"></span>
          <span [innerHTML]="address" class="cursor-pointer hidden xl:inline-block"></span>
          <app-copy [text]="address"></app-copy>
        </div>
        <div class="flex qrcontent justify-content-center align-items-center w-full">
          <span [innerHTML]="'ico.invest.modalInvest.qrcode' | translate" class="small-txt text"> </span>
          <qrcode [qrdata]="address" cssClass="qrcode" [errorCorrectionLevel]="'M'"></qrcode>

        </div>
        <span *ngIf="!isWaitingList" class="small-txt mb-2 font-bold display-block text-center "
              [innerHTML]="'ico.invest.description' | translate : {target: (target | customNumber : '' : '': 'SMT'), convert: convert | customNumber, selectedCrypto: selectedCrypto!.label}"></span>
        <span *ngIf="isWaitingList" class="small-txt mb-2 font-bold display-block text-center "
              [innerHTML]="'ico.invest.descriptionWaitingList' | translate : {selectedCrypto: selectedCrypto!.label}"></span>


      </ng-container>
      <div *ngIf="!isWaitingList" class="details ">
        <div class="flex w-full align-items-center justify-content-between mb-3">
          <span
            [innerHTML]="'ico.invest.target' | translate:
            {target: target | customNumber:'0':'0',
            price: activeRound?.price | customNumber:'7': '5':'€',
            name: activeRound?.name | PhaseName}"
            class="small-txt">

          </span>
          <div class="value">
            {{ price | customNumber:'3':'0':'€' }}
          </div>
        </div>
        <div class="flex w-full align-items-center justify-content-between mb-1" *ngIf="!!credit">
          <span class="small-txt" [innerHTML]="'ico.invest.credit' | translate"></span>
          <div class="value">
            - {{ credit | FixedPipe | customNumber:'3':'0':'€'}}
          </div>
        </div>

        <div class="flex w-full align-items-center justify-content-between mb-1">
          <span [innerHTML]="'ico.invest.balance' | translate" class="small-txt"></span>
          <div class="value">
            {{ rest | customNumber:'3':'0':'€'}}
          </div>
        </div>

        <div *ngIf="!loading && address" class="flex w-full align-items-center justify-content-between mb-1 mt-3">
          <span [innerHTML]="'ico.invest.modalInvest.totalSend' | translate"
                class="small-txt">

          </span>
          <div [ngClass]="{'blink': priceChanged}" *ngIf="convert" class="value max-color font-bold">
            {{ convert | customNumber: '7' : '5' : selectedCrypto?.label }}
          </div>
        </div>
        <div *ngIf="loading" class="flex w-full align-items-center justify-content-between mb-1 mt-3">
          <span class="small-txt">
            <p-skeleton height="1rem" width="50px"></p-skeleton>
          </span>
          <div class="value">
            <p-skeleton height="1rem" width="50px"></p-skeleton>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="mb-5 mt-5" *ngIf="isWaitingList">
        <app-ico-btn (click)="back()" [outline]="true" [text]="'ico.invest.modalInvest.return' | translate">
        </app-ico-btn>
      </div>
      <div *ngIf="!isWaitingList" class="col 12 flex justify-content-between actions-buttons">
        <span *ngIf="!!error"
              class="warning text-center w-full flex justify-content-center align-items-center my-3 ">{{error }}</span>
        <ng-container *ngIf="!rest">
          <div class="col px-0 mr-2">
            <app-ico-btn (click)="back()" [outline]="true" [text]="'ico.invest.modalInvest.return' | translate">
            </app-ico-btn>
          </div>
          <div class="col px-0 ml-2">
            <app-ico-btn [rId]="'direct-buy'" (click)="buy()" [text]="'ico.invest.buy' | translate"></app-ico-btn>
          </div>
        </ng-container>
        <ng-container *ngIf="rest">
          <ng-container *ngIf="!address">
            <div class="col px-0 mr-2">
              <app-ico-btn (click)="back()" [outline]="true" [text]="'ico.invest.modalInvest.return' | translate">
              </app-ico-btn>
            </div>
            <div class="col px-0 ml-2">
              <app-ico-btn [disabled]="true" [text]="'ico.invest.modalInvest.chooseCrypto' | translate"></app-ico-btn>
            </div>
          </ng-container>
          <ng-container *ngIf="!!address">
            <div class="col px-0 mr-2">
              <app-ico-btn (click)="back()" [outline]="true" [text]="'ico.invest.modalInvest.return' | translate">
              </app-ico-btn>
            </div>
            <div class="col px-0 ml-2">
              <app-ico-btn (click)="goToResume()" [rId]="'create-tr'"
                           [text]="'ico.invest.modalInvest.resume' | translate"></app-ico-btn>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <div [innerHtml]="'ico.invest.modalInvest.rateInfo' | translate : {amount: (convert | customNumber: '5' : '5' : selectedCrypto?.slug)}" *ngIf="convert && !isWaitingList"
           class="warning mt-2"></div>


    </div>
  </div>

</ng-container>
<!--<ng-container *ngIf="resume">-->
<!--  <app-invest-modal-resume (backEvent)="resume = false" (closeEvent)="back()"-->
<!--                           [data]="{time, rate: activeRound?.price! ,ueueTime: activeRound!.queueTime ,selectedCrypto,address, status, message, price, credit, rest, convert, target, activeRound}"></app-invest-modal-resume>-->
<!--</ng-container>-->
