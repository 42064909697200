import {AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {AuthState} from '@shared/stores/auth/auth.state';
import {Select, Store} from '@ngxs/store';
import {DialogService} from 'primeng/dynamicdialog';
import {BehaviorSubject, combineLatest, exhaustMap, filter, map, Observable, Subject, takeUntil} from 'rxjs';
import {
  BASE_URL_CORPORATE,
  EAccountType,
  ELang,
  EPassfortFormState,
  EPassfortProductState,
  EToastSeverity,
  IUser
} from "@models/dist";
import {TranslateService} from '@ngx-translate/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {
  AllCountries,
  BannedCountry,
  ICountry,
  NiceCountry,
  RiskCountry,
  SpecialCountry,
} from "@models/dist";
import {ContactModalComponent} from "@shared/components";
import {ProfileService} from "@profile/services/profile.service";
import {ToastService} from "@shared/services";
import {loadMyUser} from "@shared/stores/auth/auth.actions";

@Component({
  selector: 'app-kyc',
  templateUrl: './kyc.component.html',
  styleUrls: ['./kyc.component.scss', '../scss/profile.scss', '../kyc-light/kyc-light.component.scss'],
  styles: [],
  providers: [DialogService]
})
export class KycComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input()
  is10kChecked: boolean = true;
  isUnlimited: boolean = false;

  @Select(AuthState.getUser) user$: Observable<IUser> | undefined;
  user: IUser | null = null;

  ref: any;

  public EPassfortProductState = EPassfortProductState;
  EAccountType = EAccountType;
  public isApproved: boolean = false;
  public isKyb: boolean = false;
  public isErrorKyc = false;
  private subscription = new Subject<void>();


  lang$ = new BehaviorSubject<ELang>(this.translateService.currentLang as ELang);
  countries$: Observable<ICountry[]> = this.lang$.pipe(
    map((lang) => {
      return AllCountries.map((data) => {
        return {...data, name: data.name[lang]}
      }).sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
    })
  );

  countries = AllCountries

  niceCountry = NiceCountry;

  bannedCountries = BannedCountry;

  riskCountries = RiskCountry;
  specialsCountries = SpecialCountry;
  // countryFr =  AllCountries.find(({code}) => code === 'FR')!;
  france = AllCountries.find(({code}) => code === 'FR')!;
  franceValue = {...this.france, name: this.france.name[this.lang$.getValue()]};
  selectedCountry$ = new BehaviorSubject<any>(this.france)!;

  kycLightStatus$ = new BehaviorSubject(EPassfortProductState.NONE);
  kycRenforced$ = new BehaviorSubject(EPassfortProductState.NONE);

  kycAvailable$ = this.selectedCountry$.pipe(
    map((country) => {
      const codeValue = country?.code;
      if (this.bannedCountries.find(({code}) => code === codeValue)) {
        return {
          noKyc: false,
          kycLight: false,
          kycComplete: false,
        }
      }
      if (this.specialsCountries.find(({code}) => code === codeValue)) {
        return {
          noKyc: false,
          kycLight: false,
          kycComplete: true,
        }
      }
      if (this.niceCountry.find(({code}) => code === codeValue)) {
        return {
          noKyc: true,
          kycLight: true,
          kycComplete: true,
        }
      }
      if (this.riskCountries.find(({code}) => code === codeValue)) {
        return {
          noKyc: true,
          kycLight: false,
          kycComplete: true,
        }
      }
      return {
        noKyc: false,
        kycLight: false,
        kycComplete: false,
      }
    })
  )

  isNiceCountry$ = this.selectedCountry$.pipe(
    map(
      (country) => {
        if (!country) {
          return false;
        }
        const {code: c} = country;
        return this.niceCountry.find(({code}) => code === c) ;
      }
    )
  );

  isRiskCountry$ = this.selectedCountry$.pipe(
    map(
      (country) => {
        if (!country) {
          return false;
        }
        const {code: c} = country;
        return this.riskCountries.find(({code}) => code === c) || this.specialsCountries.find(({code}) => code === c)
      }
    )
  );

  isBannedCountry$ = this.selectedCountry$.pipe(
    map(
      (country) => {
        if (!country) {
          return false;
        }
        const {code: c} = country;
        return this.bannedCountries.find(({code}) => code === c);
      }
    )
  )

  isSpecialCountry$ = this.selectedCountry$.pipe(
    map(
      (country) => {
        if (!country) {
          return false;
        }
        const {code: c} = country;
        return this.specialsCountries.find(({code}) => code === c);
      }
    )
  )

  canPassKyc$ = combineLatest([
    this.kycLightStatus$
  ]).pipe(
    map(
      ([kycLightStatus]) => {
        return kycLightStatus === EPassfortProductState.NONE
      }
    )
  )


  form?: FormGroup = this.fb.group({
    searchCountry: this.fb.control(''),
    country: this.fb.control(this.franceValue, [Validators.required]),
  });


  public isKycRenforcedNeed = false;
  public isKycLight = false;

  @ViewChild('tableau')
  tableau: ElementRef = new ElementRef<any>('tableau');

  width$ = new BehaviorSubject(0);


  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    if (event.target.innerWidth < 900) {
      this.width$.next(this.tableau.nativeElement.scrollWidth)
    } else {
      this.width$.next(this.tableau.nativeElement.offsetWidth)
    }
  }


  constructor(public dialogService: DialogService,
              private store: Store,
              private route: ActivatedRoute,
              private translateService: TranslateService,
              private router: Router,
              private fb: FormBuilder,
              private profileService: ProfileService,
              private toatsService: ToastService
  ) {
    this.form?.controls.country.valueChanges.pipe(takeUntil(this.subscription)).subscribe((value) => {
      this.selectedCountry$.next(value);
    })
  }

  ngAfterViewInit(): void {
    if (window.innerWidth < 900) {
      this.width$.next(this.tableau.nativeElement.scrollWidth)
    } else {
      this.width$.next(this.tableau.nativeElement.offsetWidth)
    }
  }

  ngOnInit(): void {
    this.translateService.onLangChange.pipe(takeUntil(this.subscription)).subscribe((lang) => {
      const langValue = lang.lang as ELang
      this.lang$.next(langValue)
    })
    this.getCurrentUser();
  }



  ngOnDestroy() {
    this.subscription.next();
    this.subscription.complete();
  }

  getKYCStatus(disabled = false) {
    const user = this.store.selectSnapshot(AuthState.getUser);
    if (disabled) return {
      text: this.translateService.instant('KYC.status.notAvailable'),
      color: 'rgba(128, 6, 35, 0.2)'
    };
    switch (user?.passfortFormRequest?.data?.status) {
      case EPassfortFormState.COMPLETED:
        return {text: this.translateService.instant('KYC.status.completed'), color: 'rgba(9, 117, 224, 0.2)'};
      case EPassfortFormState.SUBMITTED:
        return {text: this.translateService.instant('KYC.status.submitted'), color: 'rgba(224, 224, 13, 0.2)'};
      case EPassfortFormState.INSTANTIATED:
        return {text: this.translateService.instant('KYC.status.instantiated'), color: ' rgba(224, 102, 9, 0.2)'};
      case EPassfortFormState.SENT:
        return {text: this.translateService.instant('KYC.status.sent'), color: 'rgba(92, 224, 9, 0.2)'};
      case EPassfortFormState.ACCEPTED:
        return {text: this.translateService.instant('KYC.status.accepted'), color: 'rgba(255,101,80,0.2)'};
      case EPassfortFormState.EXPIRED:
        return {text: this.translateService.instant('KYC.status.expired'), color: 'rgba(128, 6, 35, 0.2)'};
      default:
        return {text: this.translateService.instant('KYC.status.notAvailable'), color: 'rgba(128, 6, 35, 0.2)'};
    }
  }

  openKycTutorial() {
    this.router.navigate(['profile/kyc/tutorial'], {replaceUrl: true});
  }

  openKyc() {
    if (this?.user?.accountType === EAccountType.INDIVIDUAL && !this.user?.passfortFormRequest?.data) {
      return;
    }

    if (this?.user?.accountType === EAccountType.COMPANY) {
      // @ts-ignore
      window.open(BASE_URL_CORPORATE, '_blank').focus();
      return;
    }
    this.router.navigate(['profile/kyc/form'], {replaceUrl: true});
  }

  // TODO A IMPLEMENTER
  displayPassfortAlertStatus(user: IUser, isKyb = false) {
    if (!user) {
      return;
    }

    const url = user?.passfortFormRequest?.data?.url;

    const kycProductStatus = user?.kycLightStatus;
    const formStatus = user?.passfortFormRequest?.data?.status;
    let message;
    if (kycProductStatus === EPassfortProductState.APPROVED) {
      message = user?.accountType === EAccountType.COMPANY ?
        'kybApproved' : 'kycApproved';
    } else if (kycProductStatus === EPassfortProductState.CANCELLED
      || kycProductStatus === EPassfortProductState.REJECTED) {
      message = user?.accountType === EAccountType.COMPANY ?
        'kybRejected' : 'kycRejected';
    } else if (url) {
      // Warning on peut avoir un status a IN_REVIEW, mais quand mm avoir une url a faire
      // donc ne pas afficher a l'user que son kyc est in review si il ya une url
      // @ts-ignore
      window.open(url, '_blank').focus();
      return;
    } else if (formStatus === EPassfortFormState.COMPLETED || kycProductStatus === EPassfortProductState.IN_REVIEW) {
      message = user?.accountType === EAccountType.COMPANY ?
        'kybInReview' : 'kycInReview';
    } else if (isKyb) {
      // TODO when we implement KYB we can remove
      message = 'kybWillBeAvailableSoon';
    } else {
      message = user?.accountType === EAccountType.COMPANY ?
        'kybNotAvailable' : 'kycNotAvailable';
    }

    if (message) {
      // this.loadingToastAlertProvider.presentAlert('compliance',
      //   message, false).then(() => {
      // });
    }
  }

  tutorialRoute() {
    if (this.isKyb) {
      return this.router.navigate(['./', 'kyb-tutorial'], {relativeTo: this.route});
    } else {
      return this.router.navigate(['./', 'kyc-tutorial'], {relativeTo: this.route});
    }
  }

  isExpired(): boolean {
    const kycLightStatus = this.user?.kycLightStatus;

    return this.user?.passfortFormRequest?.data?.status === EPassfortFormState.EXPIRED
      && kycLightStatus !== EPassfortProductState.APPROVED
      && kycLightStatus !== EPassfortProductState.CANCELLED
      && kycLightStatus !== EPassfortProductState.REJECTED;
  }

  isInReview(): boolean {

    return this.user?.kycLightStatus === EPassfortProductState.IN_REVIEW
      || (this.user?.passfortFormRequest?.data?.status === EPassfortFormState.COMPLETED
        && this.user?.kycLightStatus !== EPassfortProductState.APPROVED
        && this.user?.kycLightStatus !== EPassfortProductState.CANCELLED
        && this.user?.kycLightStatus !== EPassfortProductState.REJECTED);
  }

  displayStartButton(): boolean {
    if (this.isExpired()) {
      return false;
    }

    return this.user?.kycLightStatus === EPassfortProductState.NONE || this.user?.kycLightStatus === EPassfortProductState.NEEDED;
    // const apikey = this.user?.passfortFormRequest?.data?.apikey;
    // const request_id = this.user?.passfortFormRequest?.data?.id;
    //
    // return !!apikey && !!request_id;
  }

  displayStartButtonKycRenforced(): boolean {
    const apikey = this.user?.passfortFormRequest?.data?.apikey;
    const request_id = this.user?.passfortFormRequest?.data?.id;

    return !!apikey && !!request_id;
  }

  clickStartBtnKycRenforced() {
    if (!this.displayStartButtonKycRenforced()) {
      return;
    }

    if ((this.user?.synapsId && this.user?.passfortProfileId) && this.user?.kycLightStatus === EPassfortProductState.NONE) {
      return this.openKyc();

    }

    return this.router.navigate(['profile/kyc/form'], {replaceUrl: true});
  }

  clickStartBtn() {
    if (!this.displayStartButton()) {
      return;
    }

    return this.router.navigate(['light'], {relativeTo: this.route});

    // if ((this.user?.synapsId && this.user?.passfortProfileId) && this.user?.kycLightStatus === EPassfortProductState.NONE) {
    //   return this.openKyc();
    //
    // }
    //
    // return this.tutorialRoute();

  }

  private getCurrentUser() {
    this.user$?.pipe(filter((user) => !!user), takeUntil(this.subscription)).subscribe((user: IUser) => {
      console.log(user.country);
      this.isKycRenforcedNeed = user.isKycRenforcedNeed;
      this.isKycLight = user.isKycLightNeed;
      this.user = user;
      this.isKyb = this.user?.accountType === EAccountType.COMPANY;
      const userCountry = !!user.country ? this.countries.find(({code}) => code === user.country)! : this.france;
      console.log(userCountry);
      const selectedCountry = {
        ...userCountry,
        name: userCountry?.name[this.lang$.getValue()]
      } as ICountry;

      this.selectedCountry$.next(selectedCountry)
      this.kycLightStatus$.next(user.kycLightStatus!);
      this.kycRenforced$.next(user.complianceStatus!);
      this.form?.patchValue({
        country: selectedCountry
      })

    })
  }

  // switchCountry({value}:any) {
  //
  //   console.log(value);
  //   if (!!value) {
  //     this.form?.patchValue({
  //       country: value
  //     })
  //     this.selectedCountry$.next(value);
  //   }
  // }

  clear() {
    if (this.form?.controls.country.value) {
      const france = {
        ...this.france,
        name: this.france?.name[this.lang$.getValue()]
      } as ICountry;
      this.form?.patchValue({
        country: france
      })
      this.selectedCountry$.next(france);
    }
  }

  clearSearch(options: any) {
    console.log(options);
    options.reset();
    this.form?.patchValue({
      searchCountry: ''
    })
  }

  goToKycLight(can: boolean | undefined) {
    if (!can) {
      return;
    }
    if (this.user?.kycLightStatus === EPassfortProductState.NONE || this.user?.kycLightStatus === EPassfortProductState.MAJ) {
      this.store.dispatch(new loadMyUser())
      this.router.navigate(['form'], {queryParams: {light: true}, relativeTo: this.route});
    }
  }

  contactModal(can: boolean | undefined) {
    if (!can) {
      return;
    }
    this.ref = this.dialogService.open(ContactModalComponent, {
      showHeader: false,
      dismissableMask: true,
      data: {
        title: 'contactUs'
      },
      contentStyle: {'max-width': '860px', 'border-radius': '20px'},
      baseZIndex: 10000,
    })
  }

  async contest(event) {
    event.stopPropagation();
    await this.profileService.contestCountry()
    this.toatsService.addToast(
      EToastSeverity.success,
      'info',
      'KYC.light.contest'
    );
    this.store.dispatch(new loadMyUser());
  }

  goToKyb() {

    // @ts-ignore
    window.open(BASE_URL_CORPORATE, '_blank').focus();
  }

  goToKycFull(can: boolean | undefined) {
    if (!can) {
      return;
    }
    if (this.user?.complianceStatus === EPassfortProductState.NONE || this.user?.complianceStatus === EPassfortProductState.MAJ) {
      this.store.dispatch(new loadMyUser())
      this.router.navigate(['form'], {queryParams: {full: true}, relativeTo: this.route});
    }
  }

  goToEdd() {
    localStorage.setItem('kyc_light_step', `${5}`);
    this.store.dispatch(new loadMyUser())
    this.router.navigate(['form'], {queryParams: {full: true}, relativeTo: this.route});
  }

}
