<div class="errorDisplay">
  <!--close modal button -->
  <button
    class="close"
    icon="pi pi-times"
    pButton
    (click)="close(false)"
  ></button>
  <div class="modal-header">
    <div [innerHTML]="'ico.referrals.spin.boost.title' | translate" class="title text-center"></div>

  </div>
  <div class="modal-body text-center flex flex-column aligns-items-center justify-content-center">
    <span [innerHTML]="'ico.referrals.spin.boost.desc' | translate" class="description text-center mb-4"></span>

  </div>
  <div class="modal-footer">
    <app-ico-btn [outline]="true"  (click)="close(false)" [text]="'ico.referrals.spin.boost.close'|translate"></app-ico-btn>
    <app-ico-btn  (click)="close(true)" [text]="'ico.referrals.spin.boost.confirm'|translate"></app-ico-btn>

  </div>
</div>
