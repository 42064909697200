<div class="errorDisplay">
  <!--close modal button -->
  <button
    class="close"
    icon="pi pi-times"
    pButton
    (click)="close()"
  ></button>
  <div class="modal-header">
    <div [innerHTML]="'mining.modal.title' | translate" class="title text-center">

    </div>

  </div>
  <div class="modal-body text-center flex flex-column aligns-items-center justify-content-center">
    <span [innerHTML]="'mining.modal.content' | translate" class="description"></span>

    <app-ico-btn class="mt-4" (click)="close()" [text]="'mining.modal.cta' | translate"></app-ico-btn>
    <span (click)="goToMining()" class="link w-full mt-2 flex justify-content-center">{{ 'mining.modal.old' |translate}}</span>
  </div>
</div>
