export default [
  {
    label: 'menu.mining.home',
    items: [
      // {
      //   label: 'menu.mining.dashboard',
      //   icon: 'pi pi-fw pi-home',
      //   routerLink: ['/mining/integration/home/dashboard'],
      // },
    ],
  }
];