import {Component} from '@angular/core';
import {AllCountries, ELang, EToastSeverity} from "@models/dist";
import {BehaviorSubject, map} from "rxjs";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {ProfileService} from "@profile/services/profile.service";
import {TranslateService} from "@ngx-translate/core";
import {ToastService} from "@shared/services";
import {Store} from "@ngxs/store";
import {IPhoneNumber} from "@models/dist/models/auth";
import {GetProfile} from "@profile/stores/profile/profile.actions";
import {loadMyUser} from "@shared/stores/auth/auth.actions";

@Component({
  selector: 'app-close-messaging',
  templateUrl: './close-messaging.component.html',
  styleUrls: ['./close-messaging.component.scss']
})
export class CloseMessagingComponent {

  constructor(
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public profileService: ProfileService,
    public translate: TranslateService,
    private fb: FormBuilder,
    private toatsService: ToastService,
    private store: Store,
  ) {
  }


  async ngOnInit() {

  }

  ngAfterViewInit() {

  }

  close() {
    this.ref.close({close: false});
  }

  phoneValidated(event: IPhoneNumber) {
    console.log(event);
    this.ref.close(event);
  }

  async validate() {
    this.ref.close({close: true});
  }
}
