import {ESuggestionType, IHelp} from "@models/dist";

export interface HelpStateModel {
  help: IHelp[] | null;
  suggestions: { type: ESuggestionType; label: string; route: string[]; }[] | null;
}

export class GetHelp {
  static readonly type = '[Transaction] Get Help';
}
