import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "@environments/environment";
import {BehaviorSubject, debounceTime, filter, map, Observable} from "rxjs";
import {IInvestCard} from "@profile/components/kyc/list-invest-card/list-invest-card.component";
import {tap} from "rxjs/operators";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Injectable({
  providedIn: 'root'
})
export class InvestService {

  private url = `${environment.nestApiUrl}/api/sgp/users/invest`;
  private fundsUrl = `${environment.nestApiUrl}/api/sgp/users/funds`;
  constructor(
    private http: HttpClient
  ) { }

  retrieveInvest(){
    return this.http.get<IInvestCard[]>(this.url).pipe(map((data) => data));
  }

  retrieveFunds(){
    return this.http.get(this.fundsUrl).pipe(map((data) => data));
  }
}
