import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
//import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { filter, map, Observable, Subject, Subscription, take, takeUntil } from 'rxjs';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { NavService, ToastService, UserService, UtilsService } from "@app/shared/services";
import { Actions, ofActionCompleted, ofActionDispatched, Select, Selector, StateContext, Store } from '@ngxs/store';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { STORAGE_DARK_MODE, STORAGE_LANG } from "@app/app.declaration";
import { EAccountType, ELang, EToastSeverity, ETwoFaType, IProfile, IUser } from "@models/dist";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { firebaseApp } from "@app/app.module";
import LogRocket from 'logrocket';
import { environment } from "@environments/environment";
import { GetProfile, ReadAllNotificaiton, ResetICOWallet } from "@profile/stores/profile/profile.actions";
import { WebSocketService } from "@shared/services/webSocket.service";
import { AuthState } from "@shared/stores/auth/auth.state";
import { loadMyUser, Logout, VirtualDashboard } from "@shared/stores/auth/auth.actions";
import { setLastRefresh } from './shared/stores/utils/utils.actions';
import { ProfileService } from "@profile/services/profile.service";
import { IAuthStateModel, IPhoneNumber } from "@models/dist/models/auth";
import { ContactModalComponent, PhoneNumberModalComponent } from "@shared/components";
import { RefuseModalComponent } from "@ico/modules/cgu/refuse-modal/refuse-modal.component";
import { IcoService } from "@ico/services/ico.service";
import { CguModalComponent } from "@ico/modules/cgu/cgu-modal/cgu-modal.component";
import { AirdropModalComponent } from "@shared/components/airdrop-modal/airdrop-modal.component";
import { SetAirdropSuccess } from "@shared/stores/airdrop/airdrop.actions";
import { TwoFaGenericModalComponent } from "@profile/components";
import { ProfileState } from './profile/stores/profile/profile-state.service';
import { IcoWalletModalComponent } from './shared/components/ico-wallet-modal/ico-wallet-modal.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [DialogService]
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'summit';
  displaySpinner = false;
  navSubscriber: Subscription;
  spinnerDisplay: Subscription;
  firebaseUser: User | null = null;
  user: IUser | undefined;

  refPhoneModal: DynamicDialogRef | null = null;
  refCGU: DynamicDialogRef | null = null;
  refICOWallet: any;

  @Select(AuthState.getUser) user$: Observable<any> | undefined;
  private destroy$ = new Subject<void>();

  @Select(ProfileState.getProfile) profile$: Observable<IProfile> | undefined;
  private profileRetrieved$ = new Subject<void>();

  // requestTwoFaSignIn: Subscription;
  private init: boolean = true;
  private isCustomToken: boolean = false;
  constructor(private toastService: ToastService,
    private dialogService: DialogService,
    private zone: NgZone,
    private router: Router,
    private websocketService: WebSocketService,
    private navService: NavService,
    private profileService: ProfileService,
    private userService: UserService,
    private store: Store,
    private utilsService: UtilsService,
    private config: PrimeNGConfig,
    private icoService: IcoService,
    private route: ActivatedRoute,
    private actions: Actions,
    private translate: TranslateService) {
    if (environment.production) {
      LogRocket.init('img6xp/summit');
    }
    const favIcon: any = document.querySelector('#favicon');
    if (!!favIcon) {
      favIcon.href = `assets/favicon/360-${environment.status}.ico`;
    }

    // this.actions.pipe(ofActionDispatched(SetAirdropSuccess)).subscribe((action) => {
    //   if (!this.isCustomToken) this.handleAirdrop(action);
    // })


    this.spinnerDisplay = this.toastService.spinnerUpdate$.subscribe(status => {
      this.displaySpinner = status;
    });
    this.navSubscriber = this.navService.nav$.subscribe(route => this.zone.run(() => router.navigate(route)));
    // this.requestTwoFaSignIn = this.toastService.twoFaSignInRequest$.subscribe((redirect: TwoFaSignInRedirect) => {
    //   const twoFaModal = this.dialogService.open(TwoFaGenericModalComponent, {
    //     showHeader: false,
    //     contentStyle: {'max-width': '450px', 'border-radius': '20px'},
    //     baseZIndex: 10000,
    //     data: {type: ETwoFaType.SIGNIN}
    //   });
    //
    //   twoFaModal.onClose.subscribe((res: any) => {
    //       if (!res) {
    //         return;
    //       }
    //       if (redirect === TwoFaSignInRedirect.login) {
    //         this.store.dispatch(new LoadUserAfterTwoFa());
    //       } else return;
    //     }
    //   );
    // });

  }


  ngOnInit(): void {
    this.initializeVirtualDashboard();
    this.config.ripple = true;
    this.initializeFirebase();
    this.initTranslation();
    this.setDarkMode();
    this.getCurrentUser();
    this.setLastRefresh();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        document.getElementsByTagName('body')[0].classList.remove('research', 'ico', 'profile', 'mining', 'gravity')
        if (event.url.includes("research")) {
          document.getElementsByTagName('body')[0].classList.add('research')
        } else if (event.url.includes("ico")) {
          document.getElementsByTagName('body')[0].classList.add('ico')
        } else if (event.url.includes('profile')) {
          document.getElementsByTagName('body')[0].classList.add('profile')
        } else if (event.url.includes('mining')) {
          document.getElementsByTagName('body')[0].classList.add('mining')
        } else if (event.url.includes('gravity')) {
          document.getElementsByTagName('body')[0].classList.add('gravity')
        }
        const user = this.user;
        this.store.select(AuthState.isCustomToken).subscribe((data) => {
          this.isCustomToken = Boolean(data);
        })
        if (user && !user?.phoneNumber && user.onboarding && !this.router.url.includes('auth')) {
          this.openPhoneModal(user?.email, user?.accountType);
        } else {
          if (user && user.cgu === null && user.onboarding && !this.router.url.includes('auth') && this.router.url.includes('ico')) {
            this.openCGUModal(user)
          }
        }
      }
    });

    // 0x1437bEbAa45afA88Dc9C6CFeB6e0bAAeDCD6eCda
    this.profile$?.pipe(takeUntil(this.profileRetrieved$), map(data => data)).subscribe((profile: IProfile) => {
      if (profile && profile.user) {
        if (profile.user && profile.amount > 0 && !profile.user.icoWallet) {
          this.refICOWallet = this.dialogService.open(IcoWalletModalComponent, {
            showHeader: false,
            dismissableMask: true,
            data: {},
            contentStyle: { 'min-width': '560px', 'max-width': '860px', 'border-radius': '20px' },
            baseZIndex: 10000,
          })
        } else {
          console.log(`ICO WALLET => ${profile.user.icoWallet}`);
        }
        this.profileRetrieved$.next();
        this.profileRetrieved$.complete();
      }
    })
  }


  ngOnDestroy(): void {
    this.navSubscriber.unsubscribe();
    this.store.dispatch(new ResetICOWallet());
  }

  // handleAirdrop(action) {
  //   const airdrop = action.airdrop;
  //   if (!!airdrop) this.displayAirdropModal(airdrop);
  // }

  // displayAirdropModal(airdrop: any) {
  //   const airdropModal = this.dialogService.open(AirdropModalComponent, {
  //     showHeader: false,
  //     data: {
  //       airdrop
  //     },
  //     closeOnEscape: false,
  //     styleClass: 'p-0 airdropmodal',
  //     contentStyle: {
  //       'padding': '0',
  //       'width': '100%',
  //       'max-width': '550px',
  //       'border-radius': '20px'
  //     },
  //   });
  // }

  private setLastRefresh() {
    this.store.dispatch(new setLastRefresh())
  }

  private getCurrentUser() {
    this.user$?.pipe(filter((user) => !!user), takeUntil(this.destroy$)).subscribe((user: IUser) => {
      if (!this.user && user?.id) {
        this.initWebSocket(user.id);
      }
      this.user = user;
      if (!environment.production) {
        this.handleTotp(user);
      }
      if (!user?.isTotpActivated && user?.onboarding && !this.isCustomToken && environment.production) this.handleTotp(user);
    })
  }

  private openPhoneModal(email: string, accountType: EAccountType) {
    // uncomment after test
    if (this.isCustomToken) return;
    // if (environment.production) {
    if (!this.refPhoneModal) {
      this.refPhoneModal = this.dialogService.open(PhoneNumberModalComponent, {
        showHeader: false,
        data: {
          email,
          accountType
        },
        styleClass: 'p-0 phoneModal ',
        contentStyle: {
          'padding': '0px !important',
          'width': '100%',
          'min-width': '680px',
          'max-width': '550px',
          'border-radius': '20px'
        },
        baseZIndex: 10000,
      });

      this.refPhoneModal.onClose.subscribe(async (data: IPhoneNumber) => {
        if (data?.phoneNumber) {
          try {
            await this.profileService.updatePhoneNumber()
            // this.toastService.addToast(EToastSeverity.success,
            //   this.translate.instant('info'),
            //   this.translate.instant('updatePhoneNumberSuccess'));
            this.store.dispatch(new loadMyUser());
          } catch (e) {
            this.toastService.addToast(EToastSeverity.error,
              this.translate.instant('phone.toast.error.title'), e);
          }
        }
      })
    }
    // }
  }

  private initializeVirtualDashboard() {
    const ADMIN_URL = environment.adminUrl;

    if (window.opener) {
      window.opener.postMessage('ready', ADMIN_URL);
    }

    window.addEventListener("message", async (event: any) => {
      if (event.data.token && event.origin === ADMIN_URL) {
        const token = event.data.token
        const tokenDecrypted = await this.utilsService.decryptToken(token);
        console.log(`Event message`, event.data);
        this.store.dispatch(new VirtualDashboard(tokenDecrypted.decrypted, event.data.extraHeaders));
        this.store.dispatch(new Logout(false, tokenDecrypted.decrypted, event.data.extraHeaders));
        sessionStorage.setItem("isVirtualDashboard", 'true');
      }
    }, false);
  }

  private async initTranslation() {
    this.translate.setDefaultLang(ELang.EN);
    let language = this.translate.getBrowserLang()?.toLowerCase();
    const value = localStorage.getItem(STORAGE_LANG);
    if (value) {
      language = value.toLowerCase();
    }
    if (language) {
      this.translate.use(language as ELang)
    }
    if (language === ELang.FR) {
      registerLocaleData(localeFr, 'fr');
    }
    this.translate.get('primeng').subscribe(res => this.config.setTranslation(res));
    this.translate.onLangChange.subscribe(async (lang: { lang: string }) => {
      const selectedLang = lang?.lang || ELang.EN;
      localStorage.setItem(STORAGE_LANG, selectedLang);
      await this.updateLang(selectedLang as ELang);
      this.init = false;
    });

  }

  private setDarkMode() {
    const dark = localStorage.getItem(STORAGE_DARK_MODE);
    this.utilsService.toggleDark(!!dark);
  }

  private async updateLang(lang: ELang) {
    if (!this.firebaseUser?.uid) {
      return;
    }
    try {
      await this.userService.updateLang(lang);
      if (!this.init) {
        await this.store.dispatch(new loadMyUser());
      }
    } catch (e) {
      console.error(e);
    }
  }


  private initializeFirebase() {
    const auth = getAuth(firebaseApp);
    onAuthStateChanged(auth, async user => {
      this.firebaseUser = user;
    });
  }


  private initWebSocket(userId: string) {
    if (!userId) {
      return;
    }
    this.websocketService.getUserKycStatus(userId).subscribe((status) => {
      this.store.dispatch(new loadMyUser());
    })

    this.websocketService.getUserReferralUsed(userId).subscribe(() => {
      this.store.dispatch(new GetProfile());
    })

    this.websocketService.getUserUpdateUnread(userId).subscribe(() => {
      console.log('triger');
      this.store.dispatch(new ReadAllNotificaiton())
      this.store.dispatch(new GetProfile());
    })

    this.websocketService.getUserSummitToken(userId).subscribe((summitTokenAmount) => {
      this.store.dispatch(new loadMyUser());
      this.store.dispatch(new GetProfile());
    })

    this.websocketService.getUserToast(userId).pipe(takeUntil(this.destroy$), map((data: any) => data['data'])).subscribe(({
      type,
      title,
      message
    }: {
      type: EToastSeverity,
      title: string,
      message: string
    }
    ) => {
      console.info(`Trigger toast`);
      this.store.dispatch(new setLastRefresh());
      this.toastService.addToast(EToastSeverity[type], title, message);
    })
  }

  private openCGUModal(user: IUser) {
    if (this.isCustomToken) return;
    if (!this.refCGU) {

      this.refCGU = this.dialogService.open(CguModalComponent, {
        showHeader: false,
        dismissableMask: true,
        styleClass: 'p-0 cguModal',
        contentStyle: {
          'padding': '0px !important',
          'width': '100%',
          'min-width': '850px',
          'max-width': '850px',
          'border-radius': '20px'
        },
        baseZIndex: 10000,
      });
      this.refCGU.onClose.subscribe((data) => {
        if (!!data) {
          this.openRefuseCGUModal()
        } else {
          this.store.dispatch(new GetProfile());
        }
      })
    }
  }

  private openRefuseCGUModal() {
    const ref = this.dialogService.open(RefuseModalComponent, {
      showHeader: false,
      dismissableMask: true,
      styleClass: 'p-0',
      contentStyle: {
        'padding': '0px !important',
        'width': '100%',
        'min-width': '1050px',
        'max-width': '1050px',
        'border-radius': '20px'
      },
      baseZIndex: 10000,
    });

    ref.onClose.subscribe((data) => {
      if (!!data) {
        this.icoService.updateCGU(false).subscribe((data) => {
          this.toastService.addToast(
            EToastSeverity.info,
            'info',
            'cgu.toast.update'
          );
          this.store.dispatch(new loadMyUser())
          this.store.dispatch(new GetProfile())
        })
      }
    })
  }

  private async handleTotp(user: IUser | undefined) {
    // const {logins} = await this.userService.getHistory();
    // console.log(logins);
    // const cantClose = logins.filter((e) => {
    //   const createdAtDate = new Date(e.created_at);
    //   const comparisonDate = new Date('2024-03-28');
    //   return createdAtDate > comparisonDate;
    // }).length >= 3;

    // const ref = this.dialogService.open(TwoFaGenericModalComponent, {
    //   showHeader: false,
    //   closeOnEscape: true,
    //   contentStyle: {'max-width': '550px', 'border-radius': '20px'},
    //   baseZIndex: 10000,
    //   data: {type: ETwoFaType.ACTIVATE, cantClose: false, user}
    // });
  }
}
