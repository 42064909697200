import { EWalletStatus } from 'summit-group-common';

export const walletStatus: any[] = [
  {
    value: EWalletStatus.VERIFIED,
    text: 'wallets.status.verified',
    color: 'rgba(133,210,80,0.08)',
    textcolor: 'rgba(133,210,80,0.7)',
  },
  {
    value: EWalletStatus.CHECKING,
    text: 'wallets.status.checking',
    color: 'rgba(9, 117, 224, 0.08)',
    textcolor: 'rgba(9, 117, 224, .7)',
  },
  {
    value: EWalletStatus.DISABLED,
    text: 'wallets.status.disabled',
    color: 'rgba(128, 6, 35, 0.08)',
    textcolor: 'rgba(128, 6, 35, .7)',
  },
];


export function getWalletStatus(status) {
    return walletStatus.find(x => x.value === status)!;
  }
  