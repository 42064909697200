<div class="errorDisplay">
  <!--close modal button -->
  <button
    (click)="close()"
    class="close"
    icon="pi pi-times"
    pButton
  ></button>
  <div class="modal-header">
    <div class="title text-center">
      {{ 'messaging.close.title' | translate }}
    </div>
    <span class="description mb-3 text-center display-block">
      {{ 'messaging.close.ctn' | translate }}

    </span>
  </div>

  <div class="modal-body">

  </div>
  <div class="modal-footer mt-4">
    <div class="actions">
      <app-ico-btn (click)="close()" [outline]="true" [text]="'messaging.close.cancel' | translate"></app-ico-btn>
      <app-ico-btn (click)="validate()" [text]="'messaging.close.close' | translate"></app-ico-btn>
    </div>
  </div>
</div>

