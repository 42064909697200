import {Component, Input, OnInit} from '@angular/core';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ProfileService} from "@profile/services/profile.service";
import {reactiveButtonState, ReactiveLoaderService} from "@shared/services/reactive-loader.service";
import {FireworksService} from "@shared/services";

@Component({
  selector: 'app-info-referral-modal',
  templateUrl: './info-referral-modal.component.html',
  styleUrls: ['./info-referral-modal.component.scss']
})
export class InfoReferralModalComponent implements OnInit {

  @Input() claim?: boolean
  @Input() referralLink: string = "";
  fireworks: any = false;
  step: number = 0;

  constructor(
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private reactiveLoader: ReactiveLoaderService,
    private profilService: ProfileService,
    private fireworkService: FireworksService,

  ) {
  }

  async ngOnInit() {
    this.claim = this.config.data.claim;
    this.referralLink = this.config.data.referralLink;
    if(this.fireworks) this.fireworkService.initFireworks();
  }

  close() {
    this.ref.close(false);
  }

  skipIntro() {
    this.gain();
  }

  goToNextStep() {
    if (this.step === 3){
      this.gain();
    }
    this.step += 1;
  }

  async gain() {
    if (!this.claim) {
      this.fireworks = true;
      try {
        this.reactiveLoader.setReactiveButtonState(
          'gain',
          reactiveButtonState.loading
        );
        await this.profilService.validateOnboardReferral();
        this.reactiveLoader.setReactiveButtonState(
          'spin',
          reactiveButtonState.success
        );
        this.fireworks = false;
        this.ref.close(true);
      } catch (e) {}
    } else {
      this.ref.close(false);
    }
  }
}
