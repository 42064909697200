export class GetProfile {
  static readonly type = '[ico] [profil] get profile data';
}

export class ReadAllNotificaiton{
  static readonly type = '[user] [profil] read all notification';
}

export class UpdateICOWallet{
  static readonly type = '[user] [profil] update ico wallet';
  constructor(public walletAddress: string) {}
}

export class ResetICOWallet{
  static readonly type = '[user] [profil] reset ico wallet';
}
