import {Component, OnInit} from '@angular/core';
import {UserService} from "@app/shared/services";
import {IHistory} from "@models/dist";

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./../scss/profile.scss', './history.component.scss']
})
export class HistoryComponent implements OnInit {

  public history: IHistory[] = []

  constructor(private userService: UserService) {
  }

  async ngOnInit() {
    try {
      const {logins} = await this.userService.getHistory() as { logins: IHistory[] }
      this.history = logins;
    } catch (err) {
      console.log(err);
    }
  }

}
