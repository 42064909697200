<div class="twoFaActivate">
  <button
    *ngIf="!isProduction"
    (click)="close()"
    class="close"
    icon="pi pi-times"
    pButton
  ></button>
  <div class="modal-header">
    <div [innerHtml]="title | translate" class="title"></div>
    <p *ngIf="type === ETwoFaType.ACTIVATE && cantClose" [innerHTML]="'onboarding.2FA.title' | translate" class="subtitle"></p>
<!--    <p *ngIf="" [innerHTML]="'onboarding.2FA.desc' | translate" class="subtitle"></p>-->
    <div *ngIf="subtitle" [innerHtml]="subtitle | translate" class="subtitle"></div>
  </div>

  <ng-container *ngIf="!loading">
    <ng-container *ngIf="type === ETwoFaType.ACTIVATE">
      <ng-container *ngIf="step === 0">
        <div class="modal-body mt-3">
          <div class="logo">
            <img src="assets/forms/settings/gauth-logo.png"/>
          </div>
        </div>
        <div class="modal-body">
          <div class="col 12 flex mt-2 download-2fa-container">
            <div class="col">
              <a href="https://play.google.com/stores/apps/details?id=com.google.android.apps.authenticator2"
                 target="_blank">
                <img src="assets/forms/settings/gplay.png"/>
              </a>
            </div>
            <div class="col">
              <a href="https://apps.apple.com/fr/app/google-authenticator/id388497605"
                 target="_blank">
                <img src="assets/forms/settings/iosmarket.png"/>
              </a>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="step === 1">
        <div *ngIf="!twoFaConfig" class="modal-body mt-3">
          <p-skeleton height="4rem" styleClass="margin-auto" width="50%"></p-skeleton>
        </div>
        <div *ngIf="twoFaConfig" class="modal-body mt-3">
          <span class="small-txt error text-red-500 font-bold" [innerHTML]="'2faMessage' | translate"></span>
          <div class="qrcode">
            <qrcode [qrdata]="'otpauth://totp/S360 - app.summit.io: '+ user?.email  + '?secret=' + twoFaConfig.secret" [width]="163" [imageHeight]="166" [imageWidth]="163" cssClass="qrcode" [errorCorrectionLevel]="'L'"></qrcode>
          </div>
        </div>
        <div [innerHtml]="'2FA.orUseCode' | translate" class="useCode"></div>
        <div *ngIf="!twoFaConfig" class="modal-body mt-3">
          <p-skeleton height="2rem" styleClass="" width="100%"></p-skeleton>
        </div>
        <div *ngIf="twoFaConfig" class="modal-body w-full">

          <div class="p-inputgroup border-1 surface-border border-round">
            <input
              [value]="twoFaConfig.secret"
              class="inputReferral border-none"
              pInputText
              readonly
              type="text"
            />
            <button
              (click)="copyCodeToClipboard()"
              [pTooltip]="'Common.copied' | translate"
              [showDelay]="300"
              class=" bg-transparent border-none cursor flex align-items-center justify-content-center mr-3"
              tooltipEvent="focus" tooltipPosition="bottom"
            >
              <img alt="" src="/assets/icon/copy.svg"/>
            </button>
          </div>

        </div>
      </ng-container>

    </ng-container>

    <ng-container *ngIf="type !== ETwoFaType.ACTIVATE || step === 2">

      <div *ngIf="displayHotp" class=" mt-4">
        <h6 [innerHtml]="'2FA.summitCode' | translate" class="code-title"></h6>
        <div class="code-input-container">
          <code-input
            #codeHotpInput
            (codeChanged)="onHotpChanged($event)"
            (codeCompleted)="onCodeComplete()"
            [codeLength]="codeLength"
            [code]="hotp"
            [initialFocusField]="0"
            [isCodeHidden]="false">
          </code-input>
        </div>
        <div class="mt-4 flex justify-center align-center">
          <div
            *ngIf="timeLeft"
            [innerHTML]="'resendCodeTimer' | translate:{timeLeft}"
            class="m-auto">
          </div>
          <button
            (click)="resendCode()"
            *ngIf="!isSending"
            [label]="'2FA.codeNotReceived' | translate"
            class="p-button-text p-button-secondary text-center w-full"
            icon="pi pi-send"
            pButton>
          </button>
        </div>
      </div>
      <div *ngIf="displayTotp && !loading" class=" mt-3">
        <h6 [innerHtml]="'2FA.googleCode' | translate" class="code-title"></h6>
        <div class="code-input-container">
          <code-input
            #codeInput
            (codeChanged)="onTotpChanged($event)"
            (codeCompleted)="onCodeComplete()"
            *ngIf="!loading && ETwoFaType.ACTIVATE"
            [codeLength]="codeLength"
            [code]="totp"
            [isCodeHidden]="false">
          </code-input>

          <code-input
            #codeInput
            (codeChanged)="onTotpChanged($event)"
            (codeCompleted)="onCodeComplete()"
            *ngIf="!loading && !ETwoFaType.ACTIVATE"
            [codeLength]="codeLength"
            [code]="totp"
            [initialFocusField]="0"
            [isCodeHidden]="false">
          </code-input>
        </div>
      </div>
      <div
        [innerHtml]="'2FA.codeAvailability' | translate"
        class="mt-2 text-red-700"
      ></div>
    </ng-container>
    <div class="modal-body">
      <div class="col 12 flex actions-buttons">
        <div class="col" *ngIf="!cantClose || step !== 0">
          <button
            (click)="prevStep()"

            [label]="step === 0
              ? ('Common.cancel' | translate)
              : ('Common.back' | translate)"
            class="outline-button"
            pButton
            pRipple></button>

        </div>
        <div class="col">
          <button
            (click)="submit()"
            [disabled]="!enableSubmit()"
            [label]="step === 2 || type !== ETwoFaType.ACTIVATE
              ? (submitButton | translate)
              : ('Common.next' | translate)"
            bgColor="gradient-button"
            pButton
            pRipple
            rButton
            rId="mfa-totp-button"
          ></button>
        </div>
      </div>
    </div>
  </ng-container>
</div>
