<div class="research-modal">
  <!--close modal button -->
  <button
    class="close"
    icon="pi pi-times"
    (click)="close()"
    pButton
  ></button>
  <div class="modal-header">
    <div class="display-block">
      <div [innerHTML]="'research.welcome.title' | translate" class="title">
      </div>
    </div>


  </div>
  <img class="banner" [src]="currentImg" alt="">
  <div class="modal-body">
    <span [innerHTML]="'research.welcome.description' | translate" class="description"></span>
  </div>
  <div class="modal-footer">
    <app-research-btn rId="close-btn" (click)="close()" class="w-full" [label]="'research.welcome.cta'|translate"></app-research-btn>
  </div>
</div>
