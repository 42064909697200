<div class="transactionDisplay">

  <!--close modal button -->
  <button (click)="closeFn()" class="close" icon="pi pi-times" pButton></button>

  <div class="modal-header">
    <div class="title text-center" [innerHTML]="'ico.invest.modalRecap.title' | translate">

    </div>
    <div
      class="subtitle text-center w-full m-auto" [innerHTML]="'ico.invest.modalRecap.subtitle' | translate">

    </div>
  </div>

  <div class="modal-body">

    <div class="details" *ngIf="!!data">

      <div class="lineValue w-full justify-content-center mb-2" *ngIf="hasBeenUpdated">
        <div class="txLabel green tempory">{{ 'ico.invest.modalRecap.update' | translate}}</div>
      </div>
      <div class="flex w-full align-items-center justify-content-between mb-3"
           *ngIf="data.status === ETransactionStatusPending">
        <div class="flex align-items-center">
          <span class="mini-loader"></span>
          <span class="queueStatus font-bold ml-3">{{ 'ico.invest.modalRecap.waitingPaiement' | translate}}</span>
        </div>
        <p-skeleton height="1rem" width="50px" *ngIf="!timeLeft"></p-skeleton>
        <div class="queueStatus status" *ngIf="!!timeLeft">
          {{ timeLeft }}
        </div>
      </div>
      <div class="flex w-full align-items-center justify-content-between mb-3">
            <span [innerHTML]="'ico.invest.modalRecap.status' | translate"
                  class="small-txt">

            </span>
        <div class="value status">
          {{ getStatus(data.status)!.text  | translate }}
        </div>
      </div>
      <div class="flex w-full align-items-center justify-content-between mb-3">
            <span [innerHTML]="'ico.invest.modalRecap.blockchain' | translate"
                  class="small-txt">

            </span>
        <div class="value">
          {{ data.selectedCrypto?.label}}
        </div>
      </div>
      <div class="flex w-full align-items-center justify-content-between mb-3">
            <span [innerHTML]="'ico.invest.modalRecap.reseau' | translate"
                  class="small-txt">

            </span>
        <div class="value">
          {{ data.selectedCrypto?.network}}
        </div>
      </div>
      <div class="flex w-full align-items-center justify-content-between mb-1 mt-3">
            <span [innerHTML]="'ico.invest.modalInvest.rate' | translate"
                  class="small-txt">

            </span>
        <div class="value font-bold cursor"
        >
          {{ data.rate | customNumber : '7': '5': '€'}}
        </div>
      </div>

      <div class="flex w-full align-items-center justify-content-between mb-1 mt-3">
        <span [innerHTML]="'ico.invest.balance' | translate" class="small-txt"></span>
        <div class="value">
          {{ data.amountUSD | customNumber:'2':'0':'€'}}
        </div>
      </div>

      <div class="flex w-full align-items-center justify-content-between mb-1 mt-3">
        <span [innerHTML]="'ico.invest.smt' | translate" class="small-txt"></span>
        <div class="value">
          {{ summitTokenAmount | customNumber:'0':'0':'SMT'}}
        </div>
      </div>

      <div class="flex w-full align-items-center justify-content-between mb-1 mt-3">
            <span [innerHTML]="'ico.invest.modalInvest.totalSend' | translate:{name:data.selectedCrypto?.slug}"
                  class="small-txt">

            </span>
        <div class="value max-color font-bold cursor"
        >
          {{ data.convert | customNumber: '5': '5': ''}} {{ data.selectedCrypto?.slug}}
          <app-copy [text]="data.convert | toString"></app-copy>

        </div>
      </div>

<!--      <div class="flex w-full align-items-center justify-content-between mb-1 mt-3">-->
<!--            <span [innerHTML]="'ico.invest.modalRecap.address' | translate" class="small-txt">-->
<!--            </span>-->
<!--        <br>-->
<!--        <div class="value max-color font-bold cursor">-->
<!--          {{ data.address | Address }}-->
<!--          <app-copy [text]="data.address"></app-copy>-->
<!--        </div>-->
<!--      </div>-->

      <span [innerHTML]="'Common.address' | translate" class="small-txt font-bold"></span>
      <div class="addr flex justify-content-between align-items-center">
          <span
            [innerHTML]="data.address.substring(0, data.address.length - 20) + '....' + data.address.substring(data.address.length - 6, data.address.length)"
            class="cursor-pointer inline-block break-word xl:hidden"></span>
        <span [innerHTML]="data.address" class="cursor-pointer hidden xl:inline-block"></span>
        <app-copy [text]="data.address"></app-copy>
      </div>
      <div class="flex qrcontent justify-content-center align-items-center w-full">
        <span [innerHTML]="'ico.invest.modalInvest.qrcode' | translate" class="small-txt text"> </span>
        <qrcode [qrdata]="data.address" cssClass="qrcode" [errorCorrectionLevel]="'M'"></qrcode>

      </div>
    </div>


  </div>
  <div class="modal-footer">
    <div class="col 12 flex justify-content-between actions-buttons">
      <div class="col px-0 mr-2" *ngIf="data?.status === ETransactionStatusPending">
        <app-ico-btn (click)="cancel()" [outline]="true" [text]="'ico.invest.modalRecap.cancel' | translate">
        </app-ico-btn>
      </div>
      <div class="col px-0 ml-2"
           *ngIf="data?.status === ETransactionStatusPending">
        <app-ico-btn (click)="closeFn()"
                     [text]="'ico.invest.modalRecap.close' | translate"></app-ico-btn>
      </div>
      <div class="col px-0 ml-2"
           *ngIf="data?.status !== ETransactionStatusPending">

        <app-ico-btn (click)="closeFn()"
                     [text]="'ico.invest.modalRecap.close' | translate"></app-ico-btn>

      </div>
    </div>
<!--    <div class="w-full flex justify-content-center" *ngIf="data?.status === ETransactionStatusPending">-->
<!--      <span *ngIf="canCancel" (click)="cancel()" class="link cursor underline-hover small-txt">Annuler</span>-->
<!--    </div>-->
  </div>
</div>
