<div>
  <ng-container *ngIf="service !== 'medias' && service !== 'profile'">

    <div [class]="service + ' summit-token-logo'" (click)="goTo()">
      <img
        alt=""
        class="svg-icon"
        src="assets/icon/logo.svg"/>
    </div>
  </ng-container>
  <ng-container *ngIf="service === 'profile' && !!user">

    <div [class]="service + ' summit-token-logo'" (click)="goTo()">
      <img
        height="32px"
        width="32px"
        [src]="!!user.avatar ? user.avatar[0] : './assets/icon/user.svg'"
        alt=""
        class="border-round-full object-cover avatar"
      />
    </div>
  </ng-container>
  <ng-container *ngIf="service === 'medias'">
    <div [class]="service + ' summit-token-logo'" (click)="goTo()">
      <img
        alt=""
        class="svg-icon medias-icon"
        src="assets/icon/medias.svg"/>
    </div>
  </ng-container>
</div>
