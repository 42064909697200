import {Component, OnInit} from '@angular/core';
import {DynamicDialogRef} from "primeng/dynamicdialog";
import {Router} from "@angular/router";
import {STORAGE_WELCOME} from "@app/app.declaration";
import {TranslateService} from "@ngx-translate/core";
import {ELang} from "@models/dist";

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  public currentImg = './assets/images/Dashboard_anniversaire_FR.png';
  public imgEN = './assets/images/Dashboard_anniversaire_EN.png';
  public imgFR = './assets/images/Dashboard_anniversaire_FR.png';

  constructor(
    public ref: DynamicDialogRef,
    private router: Router, private langService: TranslateService) {
    const lang = this.langService.currentLang as ELang;
    if (lang == ELang.FR) {
      this.currentImg = this.imgFR
    } else if (lang === ELang.EN) {
      this.currentImg = this.imgEN
    }
  }

  ngOnInit(): void {
  }

  done() {
    localStorage.removeItem(STORAGE_WELCOME);
    this.router.navigate(['./', 'ico', 'dashboard'], {queryParams: {welcome: true}})
    this.ref.close();
  }

  close() {
    this.ref.close();
  }
}
