import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-copy',
  templateUrl: './copy.component.html',
  styleUrls: ['./copy.component.scss']
})
export class CopyComponent implements OnInit {

  @Input() text: string = '';
  @Input() mini: boolean = false;

  @Input() noMl = false;

  @Input() isIco = true;
  @Input() isMining: boolean;

  constructor() {
  }

  ngOnInit(): void {
  }

}
