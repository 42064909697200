import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'highlight' })
export class HighlightPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string, searchTerm: string): any {
    if (!searchTerm || !value) {
      return value;
    }

    value = value.toString().replace(/\s|\u2009/g, ' ');
    searchTerm = searchTerm.replace(/\s|\u2009/g, ' ');    

    const pattern = searchTerm.replace(
      /[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g,
      '\\$&'
    );
    const regex = new RegExp(pattern, 'gi');

    const highlighted = value.replace(regex, (match) => {
      return '<span style="background-color: #EFC5D4;">' + match + '</span>';
    });

    return this.sanitizer.bypassSecurityTrustHtml(highlighted);
  }
}
