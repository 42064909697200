import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {countries as CountriesData} from 'summit-group-common';
import {FormGroup} from "@angular/forms";


interface Country {
  name: string,
  file: string,
}

@Component({
  selector: 'app-country-dropdown',
  templateUrl: './country-dropdown.component.html',
  styleUrls: ['./country-dropdown.component.scss']
})
export class CountryDropdownComponent implements AfterViewInit {
  @Input() selectedCountry?: Country;

  @Input() formGroup!: FormGroup;
  @Input() placehodler!: string;
  @Input() formControlName: string = '';
  filterValue = '';

  countries = CountriesData;
  loading = true;

  constructor() {
  }

  ngAfterViewInit() {
    // this.loading = false;
  }

  myResetFunction() {
    this.filterValue = '';
  }
}
