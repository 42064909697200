import {AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject, combineLatest, map, Observable, Subject, takeUntil} from "rxjs";
import {
  AllCountries,
  BannedCountry,
  ELang,
  EPassfortProductState,
  ICountry,
  NiceCountry,
  RiskCountry,
  SpecialCountry
} from "@models/dist";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DialogService} from "primeng/dynamicdialog";
import {Store} from "@ngxs/store";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {ProfileService} from "@profile/services/profile.service";
import {ToastService} from "@shared/services";

@Component({
  selector: 'app-compliance',
  templateUrl: './compliance.component.html',
  styleUrls: ['./compliance.component.scss', './../profile/components/kyc/kyc.component.scss', './../profile/components/kyc/../scss/profile.scss', './../profile/components/kyc/../kyc-light/kyc-light.component.scss'],
})
export class ComplianceComponent implements OnInit,OnDestroy, AfterViewInit{


  lang$ = new BehaviorSubject<ELang>(this.translateService.currentLang as ELang);
  countries$: Observable<ICountry[]> = this.lang$.pipe(
    map((lang) => {
      return AllCountries.map((data) => {
        return {...data, name: data.name[lang]}
      }).sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
    })
  );

  private destroy$ = new Subject<void>();

  @ViewChild('tableau')
  tableau: ElementRef = new ElementRef<any>('tableau');

  width$ = new BehaviorSubject(0);

  countries = AllCountries

  niceCountry = NiceCountry;

  bannedCountries = BannedCountry;

  riskCountries = RiskCountry;
  specialsCountries = SpecialCountry;
  // countryFr =  AllCountries.find(({code}) => code === 'FR')!;
  france = AllCountries.find(({code}) => code === 'FR')!;
  franceValue = {...this.france, name: this.france.name[this.lang$.getValue()]};

  selectedCountry$ = new BehaviorSubject<any>(this.france)!;

  kycLightStatus$ = new BehaviorSubject(EPassfortProductState.NONE);
  kycRenforced$ = new BehaviorSubject(EPassfortProductState.NONE);

  kycAvailable$ = this.selectedCountry$.pipe(
    map((country) => {
      const codeValue = country?.code;
      if (this.bannedCountries.find(({code}) => code === codeValue)) {
        return {
          noKyc: false,
          kycLight: false,
          kycComplete: false,
        }
      }
      if (this.specialsCountries.find(({code}) => code === codeValue)) {
        return {
          noKyc: false,
          kycLight: false,
          kycComplete: true,
        }
      }
      if (this.niceCountry.find(({code}) => code === codeValue)) {
        return {
          noKyc: true,
          kycLight: true,
          kycComplete: true,
        }
      }
      if (this.riskCountries.find(({code}) => code === codeValue)) {
        return {
          noKyc: true,
          kycLight: false,
          kycComplete: true,
        }
      }
      return {
        noKyc: false,
        kycLight: false,
        kycComplete: false,
      }
    })
  )

  isRiskCountry$ = this.selectedCountry$.pipe(
    map(
      (country) => {
        if (!country) {
          return false;
        }
        const {code: c} = country;
        return this.riskCountries.find(({code}) => code === c) || this.specialsCountries.find(({code}) => code === c)
      }
    )
  );

  isBannedCountry$ = this.selectedCountry$.pipe(
    map(
      (country) => {
        if (!country) {
          return false;
        }
        const {code: c} = country;
        return this.bannedCountries.find(({code}) => code === c);
      }
    )
  )

  isSpecialCountry$ = this.selectedCountry$.pipe(
    map(
      (country) => {
        if (!country) {
          return false;
        }
        const {code: c} = country;
        return this.specialsCountries.find(({code}) => code === c);
      }
    )
  )

  canPassKyc$ = combineLatest([
    this.kycLightStatus$
  ]).pipe(
    map(
      ([kycLightStatus]) => {
        return kycLightStatus === EPassfortProductState.NONE
      }
    )
  )


  form?: FormGroup = this.fb.group({
    searchCountry: this.fb.control(''),
    country: this.fb.control(this.franceValue, [Validators.required]),
  });

  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    if (event.target.innerWidth < 900) {
      this.width$.next(this.tableau.nativeElement.scrollWidth)
    } else {
      this.width$.next(this.tableau.nativeElement.offsetWidth)
    }
  }


  constructor(public dialogService: DialogService,
              private store: Store,
              private route: ActivatedRoute,
              private translateService: TranslateService,
              private router: Router,
              private fb: FormBuilder,
              private profileService: ProfileService,
              private toatsService: ToastService
  ) {
    this.form?.controls.country.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.selectedCountry$.next(value);
    })
  }


  async ngAfterViewInit() {
    if (window.innerWidth < 900) {
      this.width$.next(this.tableau.nativeElement.scrollWidth)
    } else {
      this.width$.next(this.tableau.nativeElement.offsetWidth)
    }

    const queryParams = this.route.snapshot.queryParams
    if (!!queryParams.lang) {
      await this.translateService.use(queryParams.lang)
    }
  }

  ngOnInit(): void {
    const body = window.document.getElementsByTagName('body')[0];
    console.log(body);
    body.classList.add('integrate')

    this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe((lang) => {
      const langValue = lang.lang as ELang
      this.lang$.next(langValue)
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  switchCountry({value}: any) {
    if (!!value) {
      this.form?.patchValue({
        country: value
      })
      this.selectedCountry$.next(value);
    }
  }

  clear() {
    if (this.form?.controls.country.value) {
      const france = {
        ...this.france,
        name: this.france?.name[this.lang$.getValue()]
      } as ICountry;
      this.form?.patchValue({
        country: france
      })
      this.selectedCountry$.next(france);
    }
  }

  clearSearch(options: any) {
    console.log(options);
    options.reset();
    this.form?.patchValue({
      searchCountry: ''
    })
  }


}
