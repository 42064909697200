<button
  [cdkCopyToClipboard]="text" tooltipEvent="focus" tooltipPosition="bottom"
  [pTooltip]="'Common.copied' | translate"  [showDelay]="100"  [life]="3000"
  [class.mini]="mini"

  class=" bg-transparent border-none cursor flex align-items-center justify-content-center"
>
  <img alt="" *ngIf="isIco && !isMining" [class.ml-2]="!noMl" src="/assets/icon/copy.svg"/>
  <img alt="" *ngIf="isMining" [class.ml-2]="!noMl" src="/assets/icon/mining/copy.svg"/>

</button>
