import {Component} from '@angular/core';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ProfileService} from "@profile/services/profile.service";
import {ReactiveLoaderService} from "@shared/services";
import {reactiveButtonState} from "@shared/services/reactive-loader.service";

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent {
  public currentBoost: any;
  public gains: string[] = []
  public selected: number | null = null;


  constructor(
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public profilService: ProfileService,
    private reactiveLoader: ReactiveLoaderService,
  ) {

  }

  async ngOnInit() {
    this.currentBoost = this.config.data.currentBoost;
  }

  close(confirm: boolean) {
    this.ref.close({
      confirm
    });
  }
}
