<div [formGroup]="formGroup" *ngIf="!loading">
  <p-dropdown [formControlName]="formControlName" [options]="countries" optionLabel="name" [filter]="true"
              filterBy="name"
              [showClear]="true" [placeholder]="placehodler">
    <ng-template pTemplate="selectedItem">
      <div class="country-item country-item-value" *ngIf="selectedCountry">
        <img [src]="'/assets/all-flags/' + selectedCountry.file.toLowerCase() + '.svg'" height="15px" width="15px"/>
        <div>{{selectedCountry.name}}</div>
      </div>
    </ng-template>
    <ng-template let-country pTemplate="item">
      <div class="country-item">
        <img [src]="'/assets/all-flags/' + country.file.toLowerCase() + '.svg'" height="15px" width="15px"/>
        <div>{{country.name}}</div>
      </div>
    </ng-template>
  </p-dropdown>
</div>
