import {Pipe, PipeTransform} from "@angular/core";
import {MiscHelpers} from "@shared/helpers/misc.helpers";
import {TranslateService} from "@ngx-translate/core";
import {COUNTRY_EN, COUNTRY_FR, ELang} from "@models/dist";

export interface ICountry {
  name: string;
  code: string;
  flag: string;
}

@Pipe({
  name: 'CountryPipe',
  pure: false
})
export class CountryPipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  transform(value: any, {location}: any): any {
    const lang = this.translateService.currentLang as ELang;
    const concernCountry = lang === ELang.FR ? COUNTRY_FR : COUNTRY_EN
    let country = concernCountry.find((item) => item.code.toLowerCase() === value?.toLowerCase());

    if (!country) {
      country = concernCountry.find((item) => item.code.toLowerCase() === 'fr');
    }

    return `<div><span class="mr-1">${country?.flag}</span>${this.capitalize(country?.name)}, ${this.capitalize(location)}</div>`;
  }

  private capitalize(value: any): any {
    return MiscHelpers.capitalizeFirstLetter(value);
  }
}
