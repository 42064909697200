import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, Store,} from '@ngxs/store';
import {GetHelp, HelpStateModel} from './help.actions';
import {HelpService} from '@shared/services/help.service';
import {ESuggestionType, IHelp} from "@models/dist";

export class GetUserTransaction {
  static readonly type = '[Transaction] Get Transactions';
}

@State<HelpStateModel>({
  name: 'HelpState',
  defaults: {
    help: null,
    suggestions: null
  },
})
@Injectable()
export class HelpState {

  constructor(
    private helpService: HelpService,
    private store: Store
  ) {
  }

  @Selector()
  static getHelp(state: HelpStateModel) {
    return state.help;
  }

  @Selector()
  static getSuggestions(state: HelpStateModel) {
    return state.suggestions;
  }

  @Action(GetHelp)
  async getHelp(ctx: StateContext<HelpStateModel>) {
    try {
      const res = await this.helpService.getHelp();
      let suggestions: { type: ESuggestionType; label: string; route: string[]; }[] = [];
      (res as IHelp[]).forEach((help: any) => {
        suggestions.push({type: ESuggestionType.section, label: help.section, route: [help.route]});
        help.subSections.forEach((sub: any) => {
          suggestions.push({type: ESuggestionType.subsection, label: sub.title, route: [help.route, sub.route]});
        });
      });
      ctx.patchState({
        help: res as IHelp[],
        suggestions: suggestions
      });
    } catch (error) {
      console.log(error);
    }
  }

}
