import {environment} from "@environments/environment";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {firstValueFrom, map} from "rxjs";
import {IProject} from "summit-group-common";
import {IPortfolio, IPortfolioProject, OrderTypeEnum} from "@models/dist";

const BASE = `${environment.nestApiUrl}/api/sgp/research`;


@Injectable({
  providedIn: 'root',
})
export class ResearchService {
  constructor(
    private http: HttpClient,
  ) {
  }

  getAccessibleProjects() {
    return this.http.get<{ data: { id: string }[] }>(`${BASE}/projects/accessible`).pipe(
      map((data) => data.data.map(({id}) => id))
    )
  }

  getFavoritesProjects() {
    return this.http.get<{ data: { id: string }[] }>(`${BASE}/projects/favorites`).pipe(
      map((data) => data.data.map(({id}) => id))
    )
  }

  getSpotlightsProjects() {
    return this.http.get<IProject[]>(`${BASE}/projects/spotlights`);
  }

  getDashboardData(projectPage = false) {
    return firstValueFrom(this.http.get(`${BASE}/dashboard${projectPage ? '?page=project' : ''}`))
  }

  getAnalyses() {
    return firstValueFrom(this.http.get(`${BASE}/documents?type=analyses`))
  }

  async getGuides() {
    return firstValueFrom(this.http.get(`${BASE}/documents?type=guides`))
  }

  async getReports() {
    return firstValueFrom(this.http.get(`${BASE}/documents?type=reports`))
  }

  async getProjectsToVotes(isDashboard: boolean = false) {
    return firstValueFrom(this.http.get(`${BASE}/projects/votes?isDashboard=${isDashboard}`))
  }

  async getUserProjects() {
    return firstValueFrom(this.http.get(`${BASE}/projects/user`))
  }

  async getUserVotes(isDashboard: boolean = false) {
    return firstValueFrom(this.http.get(`${BASE}/projects/user-votes?isDashboard=${isDashboard}`))
  }

  async getProjects() {
    return firstValueFrom(this.http.get(`${BASE}/projects`))
  }

  getProjectsObservable() {
    return this.http.get<IProject[]>(`${BASE}/projects`).pipe(
      map(
        (data) => data.filter((project) => project.title !== 'Liquidités')
      )
    )
  }

  async getCause(id: any) {
    return firstValueFrom(this.http.get(`${BASE}/projects/cause/${id}`))
  }

  async getNotes(id: any) {
    return firstValueFrom(this.http.get(`${BASE}/projects/notes/${id}`))
  }

  getAccess(id: any) {
    return firstValueFrom(this.http.get(`${BASE}/documents/access/${id}`))
  }

  getCauseAccess(id: any) {
    return firstValueFrom(this.http.get(`${BASE}/projects/access/${id}`))
  }

  cancelOrder(body: { type: OrderTypeEnum, id: string, gift?: boolean }) {
    return firstValueFrom(this.http.post(`${BASE}/order/cancel`, body))
  }

  getCoinmarketcapData(id: string) {
    return firstValueFrom(this.http.get(`${BASE}/projects/detail/${id}`))
  }

  getPrice(id: string) {
    return this.http.get(`https://api.coingecko.com/api/v3/simple/price?ids=${id}&vs_currencies=usd`, {
      headers: {
        "x_cg_demo_api_key": "CG-gS5VviPVfgWorpPmMwjtoZ6u"
      }
    })
  }

  async getFile(id) {
    return firstValueFrom(this.http.get(`${BASE}/documents/file/${id}`))
  }

  getActiveSubscription() {
    return firstValueFrom(this.http.get(`${BASE}/order/subscription/active`))
  }

  submitProject(project: any) {
    return firstValueFrom(this.http.post(`${BASE}/projects/create`, project))
  }

  cancelProject(id: string) {
    return firstValueFrom(this.http.post(`${BASE}/projects/cancel/${id}`, {}))
  }

  voteProject(id: string) {
    return firstValueFrom(this.http.post(`${BASE}/projects/vote/${id}`, {}))
  }

  getCoinsList() {
    return firstValueFrom(this.http.get(`${BASE}/projects/coins-list`))
  }

  getProjectDetail(id: string) {
    return firstValueFrom(this.http.get(`${BASE}/projects/detail/${id}`))
  }

  getProjectsDetail(searchResult: IProject[]) {
    return this.http.post(`${BASE}/projects/detail`, searchResult).pipe(map((data: any) => data))
  }

  getProjectsSortedByDemand(project: IProject[]) {
    return this.http.post(`${BASE}/projects/sort-demand`, project).pipe(map((data: any) => data))
  }

  async initResearch() {
    return firstValueFrom(this.http.get(`${BASE}`))
  }

  setIfFirstAccessFalse() {
    return firstValueFrom(this.http.post(`${BASE}/firstAccess`, {}))
  }

  async getSubscriptions() {
    return firstValueFrom(this.http.get(`${BASE}/order/subscriptions`, {}))

  }

  async getActivePack() {
    return firstValueFrom(this.http.get(`${BASE}/order/pack-history/active`, {}))

  }

  handleCancelSubscription(id: string) {
    return firstValueFrom(this.http.post(`${BASE}/order/subscription/cancel`, {id}))

  }

  async useSubscription(id) {
    return firstValueFrom(this.http.post(`${BASE}/order/subscription/use`, {id}))

  }

  async handleDowngradeSubscription(id: string) {
    return firstValueFrom(this.http.post(`${BASE}/order/subscription/downgrade`, {id}))

  }

  async handleUpgradeSubscription(id: string) {
    return firstValueFrom(this.http.post(`${BASE}/order/subscription/upgrade`, {id}))
  }

  async toogleFav(id: string) {
    return firstValueFrom(this.http.post(`${BASE}/projects/toogleFav`, {id}))

  }

  toogleFavObservable(id: string) {
    return this.http.post(`${BASE}/projects/toogleFav`, {id})

  }

  getFavoriteProject() {
    return firstValueFrom(this.http.get(`${BASE}/projects/favorites`))
  }

  getPortfolio() {
    return this.http.get(`${BASE}/portfolio`).pipe(map((data: any) => data.data))
  }

  getPortfolioProject(id: string) {
    return this.http.get(`${BASE}/portfolio/${id}/projects`).pipe(map((data: any) => data.data));
  }

  getInvoice(id) {
    return firstValueFrom(this.http.get(`${BASE}/order/invoice/${id}`))

  }

  sendIdea(data: { idea: string; files: string[]; id: string | undefined }) {
    return this.http.post<any>(`${BASE}/projects/idea/${data.id}`, data)
  }

  getIdea(id: any) {
    return this.http.get<{ idea: boolean }>(`${BASE}/projects/idea/${id}`).pipe(
      map((data) => data.idea)
    )

  }

  getNarrative() {
    return this.http.get<{ name: string, id: string }[]>(`${BASE}/projects/narratives`).pipe(
      map((data) => data)
    )
  }
}
