<div class="top-section " [class.pl-0]="back">
  <ng-container *ngIf="back">
    <div class="flex w-full align-items-center">
      <app-ico-btn class="mr-3" [text]="'back' | translate" routerLink="./../referral"></app-ico-btn>
      <h1 class="ico-title mt-0">{{ title }}</h1>
    </div>
  </ng-container>

  <ng-container *ngIf="!back">
    <h1 class="ico-title mt-0">{{ title }}</h1>
  </ng-container>


  <!--  <button-->
  <!--    (click)="goToHelp()"-->
  <!--    *ngIf="!nohelp"-->
  <!--    [label]="'helpCenter.help' | translate"-->
  <!--    class="gradient-button"-->
  <!--    pButton-->
  <!--    type="button"></button>-->
  <div class="flex align-items-center ">
    <a href="https://help.summit.io" target="_blank">
      <app-ico-btn class="" *ngIf="!nohelp" [text]="'help' | translate"></app-ico-btn>
    </a>
    <!--    <app-ico-btn [outline]="true" (click)="goToHelp()" *ngIf="!nohelp" [text]="'helpCenter.help' | translate"></app-ico-btn>-->
  </div>
</div>
