import {Action, NgxsOnInit, Selector, State, StateContext} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {ResearchService} from "@research/shared/services/research.service";
import {
  GetAccessibleProjects,
  GetCoinsList, GetFavoritesProjects, GetProjects,
  GetSpotlightsProjects,
  HideCarousel,
  InitResearch, SetAccessibleProjects, SetActivePortfolioIndex, SetFilter, SetPortfolioProject, SetProjects,
  SetSpotlightsProjects, SetUpResearch,
  ToggleCarousel, ToggleFavorite
} from "@research/stores/research.actions";
import {IOrder, IProject, IRenderPortfolio} from "@models/dist";
import {map} from "rxjs";


export interface IResearchState {
  coinsList: any[],
  user: any,
  isCarouselVisible: boolean,
  subscription?: IOrder,
  canSeeProjects: boolean,
  favoritesProjectsIds: string[];
  accessibleProjectsIds: string[];
  projects: IProject[];
  activePortolioId: string | null;
  portfolioProject: IRenderPortfolio[] | [];
  spotlightsProjects: IProject[];
  search: string | null;
  narrativeFilter: string[] | null;
  currentFilter: 'recent' | 'note' | 'buyed' | null;
}


@State<IResearchState>({
  name: 'ResearchState',
  defaults: {
    coinsList: [],
    user: {},
    isCarouselVisible: true,
    canSeeProjects: false,
    favoritesProjectsIds: [],
    accessibleProjectsIds: [],
    projects: [],
    search: null,
    activePortolioId: null,
    portfolioProject: [],
    spotlightsProjects: [],
    currentFilter: null,
    narrativeFilter: null
  },
})

@Injectable()
export class ResearchState implements NgxsOnInit {

  constructor(private researchService: ResearchService) {
  }

  @Selector()
  static GetCoinsList(state: any): any | null {
    return state;
  }

  @Selector()
  static IsCarouselVisible(state: any): boolean {
    return state.isCarouselVisible;
  }

  @Selector()
  static getResearchUser(state: any): any {
    return state.user;
  }

  @Selector()
  static restProjects(state: IResearchState): number {
    if (!state.subscription) return 0;
    // const projectsRest = state.subscription!.projects.filter((project) => {
    //   return new Date(project.created_at).getTime() <= new Date(state.subscription!.created_at).getTime();
    // })
    // if (projectsRest.length > 20) {
    //   return 0;
    // }
    return 20 - state.subscription['projectsCounts'];
  }

  @Selector()
  static currentSubscription(state: IResearchState): IOrder | undefined {
    return state.subscription;
  }

  @Selector()
  static canSeeProjects(state: IResearchState): boolean {
    return state.canSeeProjects;
  }

  @Selector()
  static canUsedGift(state: any): boolean {
    return !state.user.gift;
  }

  ngxsOnInit(ctx?: StateContext<any>): any {
  }

  @Action(GetCoinsList)
  async GetCoinsList(ctx: StateContext<any>) {
    try {

      const res = await this.researchService.getCoinsList() as any;
      ctx.patchState({
        coinsList: res?.data || [],
      })
    } catch (e) {
      console.log(e);
    }
  }

  @Action(ToggleCarousel)
  ToggleCarousel(ctx: StateContext<any>, {payload}: ToggleCarousel) {
    const currentVisibility = ctx.getState().isCarouselVisible;
    localStorage.setItem('carrouselDisplay', `${!currentVisibility}`)
    localStorage.setItem('carrouselDisplayCount', `${payload.lenght}`)

    ctx.patchState({isCarouselVisible: !currentVisibility});
  }

  @Action(InitResearch)
  async InitResearch(ctx: StateContext<any>) {


    ctx.dispatch(new SetUpResearch());
    ctx.dispatch(new GetAccessibleProjects());
    ctx.dispatch(new GetFavoritesProjects());
    ctx.dispatch(new GetProjects());
    ctx.dispatch(new GetSpotlightsProjects());

    // const projectsRest = !!subscription ? subscription!.projects.filter((project) => {
    //   return new Date(project.created_at).getTime() <= new Date(subscription!.created_at).getTime();
    // }) : []

  }

  @Action(SetUpResearch)
  async setupResearch(ctx: StateContext<any>) {
    const {user, subscription} = await this.researchService.initResearch() as { user: any, subscription: IOrder }
    ctx.patchState({
      user,
      subscription,
      canSeeProjects: !subscription ? false : (subscription['projectsCounts'] < 20)
    })
  }

  @Action(HideCarousel)
  HideCarousel(ctx: StateContext<any>) {
    ctx.patchState({
      isCarouselVisible: false
    })
  }


  @Action(GetAccessibleProjects)
  async GetAccessibleProjects(ctx: StateContext<any>) {
    return this.researchService.getAccessibleProjects().pipe(
      map(
        (data) => {
          ctx.dispatch(new SetAccessibleProjects(data));
        }
      )
    )
  }

  @Action(SetAccessibleProjects)
  async SetAccessibleProjects(ctx: StateContext<any>, {payload}: SetAccessibleProjects) {
    ctx.patchState({
      accessibleProjectsIds: payload
    })
  }

  @Action(GetFavoritesProjects)
  async GetFavoritesProjects(ctx: StateContext<any>) {
    return this.researchService.getFavoritesProjects().pipe(
      map(
        (data) => {
          ctx.patchState({
            favoritesProjectsIds: data
          })
        }
      )
    )
  }

  @Action(GetProjects)
  async GetProjects(ctx: StateContext<any>) {
    return this.researchService.getProjectsObservable().pipe(
      map(
        (data) => {
          ctx.dispatch(new SetProjects(data))
        }
      )
    )
  }

  @Action(SetProjects)
  async SetProjects(ctx: StateContext<any>, {payload}: SetProjects) {
    ctx.patchState({
      projects: payload
    })
  }

  @Action(SetSpotlightsProjects)
  async SetSpotlightsProjects(ctx: StateContext<any>, {payload}: SetSpotlightsProjects) {
    ctx.patchState({
      spotlightsProjects: payload
    })
  }

  @Action(GetSpotlightsProjects)
  async GetSpotlightsProjects(ctx: StateContext<any>) {
    return this.researchService.getSpotlightsProjects().pipe(
      map(
        (data) => {
          ctx.dispatch(new SetSpotlightsProjects(data))
        }
      )
    )
  }

  @Action(SetFilter)
  async SetFilter(ctx: StateContext<any>, {payload, search, narrative}: SetFilter) {
    if (payload === 'search') {
      ctx.patchState({
        currentFilter: payload,
        search,
        narrativeFilter: null
      })
      return;
    }
    if (payload === 'narrative') {
      ctx.patchState({
        currentFilter: payload,
        narrativeFilter: narrative,
      })
      return;
    }
    ctx.patchState({
      currentFilter: payload,
      search: null,
      narrativeFilter: null
    })
  }

  @Action(ToggleFavorite)
  async ToggleFavorite(ctx: StateContext<IResearchState>, {id}: ToggleFavorite) {
    // const favorites = ctx.getState().favoritesProjectsIds;
    // if (favorites.includes(id)) {
    //   ctx.patchState({
    //     favoritesProjectsIds: favorites.filter((fav) => fav !== id)
    //   })
    // } else {
    //   ctx.patchState({
    //     favoritesProjectsIds: [...favorites, id]
    //   })
    // }
    return this.researchService.toogleFavObservable(id).pipe(
      map(
        () => ctx.dispatch(new GetFavoritesProjects())
      )
    )
  }

  @Action(SetActivePortfolioIndex)
  async SetActivePortfolioIndex(ctx: StateContext<IResearchState>, {id}: SetActivePortfolioIndex) {
    return this.researchService.getPortfolioProject(
      id
    ).pipe(
      map(
        (data) => {
          ctx.patchState({
            activePortolioId: id
          })
          ctx.dispatch(new SetPortfolioProject(data));
        }
      )
    )
  }

  @Action(SetPortfolioProject)
  async SetPortfolioProject(ctx: StateContext<IResearchState>, {payload}: SetPortfolioProject) {
    ctx.patchState({
      portfolioProject: payload
    })
  }

}
