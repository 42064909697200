export const environment = {
  version: "1.0.0",
  production: false,
  emulator: false,
  status: "qualif",
  discord_uri:"https://staging-api-9bc0001c.summit.io/api/sgp/oauth/discord",
  appUrl: "https://staging-front-9bc0001c.summit.io",
  /*apiUrl: "https://europe-west3-summit-group-qualif.cloudfunctions.net",*/
  apiUrl: "https://staging-api-9bc0001c.summit.io",
  adminUrl: "https://staging-admin-9bc0001c.summit.io",
  nestApiUrl: "https://staging-api-9bc0001c.summit.io",
  websocketApi: "https://staging-api-9bc0001c.summit.io",
  hotjar: '3359566',
  GA: 'G-JEHCE2CPTF',
  stripe_key: "pk_test_51Ml71iKMtKuLBnrE0j2HCBYKGKZisRSaSskfXeQDkKOcloov62VMxRvZjld3m9n4kPOi8dtwqbYI5BWiZChhkOrs00IRVuwfGZ",
  firebase: {
    apiKey: "AIzaSyAfNa8zAN4F8Q7JPrX-74ZngERjsFjEC78",
    authDomain: "summit-group-qualif.firebaseapp.com",
    databaseURL: "https://summit-group-qualif-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "summit-group-qualif",
    storageBucket: "summit-group-qualif.appspot.com",
    messagingSenderId: "686027887735",
    appId: "1:686027887735:web:dd99614ce4560dc51d6272",
    measurementId: "G-EP2PWVT36W"
  },
  mining: {
    apiKey: "AIzaSyANkOHQc4sBeSQicu5iyXwMUV1ff_av6bI",
    authDomain: "summit-mining-qualif.firebaseapp.com",
    databaseURL: "https://summit-mining-qualif-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "summit-mining-qualif",
    storageBucket: "summit-mining-qualif.appspot.com",
    messagingSenderId: "678149564957",
    appId: "1:678149564957:web:76c6ba0575766a985bb4f4",
    measurementId: "G-2258MQLLNH"
  },
  gravity: {
    apiKey: "AIzaSyAbulJzsgijxqi2K-PSE6lGV_7rinbKRhI",
    authDomain: "summit-gravity-qualif.firebaseapp.com",
    databaseURL: "https://summit-gravity-qualif-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "summit-gravity-qualif",
    storageBucket: "summit-gravity-qualif.appspot.com",
    messagingSenderId: "364016656429",
    appId: "1:364016656429:web:8fea84eafc38c7aacdd8a4",
    measurementId: "G-1KPWTTT21B"
  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
