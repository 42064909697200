<div class="errorDisplay" *ngIf="airdrop.scope === 'claimed'">
  <!--close modal button -->
  <div class="modal-header">
    <div class="display-block">
      <div [innerHTML]="'airdrop.claimed.title' | translate" class="title text-center">
      </div>
    </div>


  </div>
  <div class="modal-body flex flex-column aligns-items-start justify-content-start">
    <span [innerHTML]="'airdrop.claimed.subtitle' | translate" class="sub-title mb-4"></span>
    <span [innerHTML]="'airdrop.claimed.description' | translate" class="description text-center"></span>
    <img class="w-full object-container mt-4" [src]="currentImg" alt="">
  </div>
  <div class="modal-footer">
    <app-ico-btn rId="close-btn" *ngIf="!airdrop.processed" (click)="claim()"
                 [text]="'airdrop.claimed.cta'|translate : {amount: airdrop.amount | customNumber: '': ''}"></app-ico-btn>
  </div>
</div>

<div class="errorDisplay" *ngIf="airdrop.scope === 'investment'">
  <!--close modal button -->
  <div class="modal-header">
    <div class="display-block">
      <div [innerHTML]="'airdrop.invest.title' | translate" class="title text-center">
      </div>
    </div>


  </div>
  <div class="modal-body flex flex-column aligns-items-start justify-content-start">
    <span [innerHTML]="'airdrop.invest.subtitle' | translate" class="sub-title mb-4"></span>
    <span [innerHTML]="'airdrop.invest.description' | translate" class="description text-center"></span>
    <img class="w-full object-container mt-4" [src]="currentImg" alt="">
  </div>
  <div class="modal-footer">
    <app-ico-btn rId="close-btn" (click)="claim()"
                 [text]="'airdrop.invest.cta'|translate"></app-ico-btn>
  </div>
</div>
