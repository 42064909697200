<div class="transactionDisplay">
  <!--close modal button -->
  <button (click)="close()" class="close" icon="pi pi-times" pButton></button>

  <div class="modal-header">
    <div class="flex w-full justify-content-center">
      <span [innerHTML]="'ico.invest.modalInvest.cancelTransaction' | translate" class="title ">

    </span>
    </div>

  </div>
  <div class="modal-body">
      <span [innerHTML]="'ico.invest.modalInvest.subtitleCancelTransaction' | translate" class="content w-full flex justify-content-center">

      </span>
  </div>
  <div class="modal-footer">
    <div class="col 12 flex justify-content-between actions-buttons">
      <ng-container>
        <div class="col px-0 mr-2">
          <app-ico-btn (click)="close()" [outline]="true" [text]="'ico.invest.modalInvest.returnCancel' | translate">
          </app-ico-btn>
        </div>
        <div class="col px-0 ml-2">
          <app-ico-btn (click)="cancel()" [text]="'ico.invest.modalInvest.confirme' | translate"></app-ico-btn>
        </div>
      </ng-container>
    </div>

  </div>

</div>


