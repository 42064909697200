import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Select, Store} from "@ngxs/store";
import {AuthState} from "@shared/stores/auth/auth.state";
import {BehaviorSubject, filter, map, Observable} from "rxjs";
import {
  AllCountries,
  BannedCountry,
  ELang,
  EPassfortProductState,
  EToastSeverity,
  IUser,
  RiskCountry
} from "@models/dist";
import {OnfidoService} from "@profile/services/onfido.service";
import {init, SdkResponse, SupportedLanguages} from "onfido-sdk-ui";
import {TranslateService} from "@ngx-translate/core";
import {ReactiveLoaderService, ToastService} from "@shared/services";
import {reactiveButtonState} from "@shared/services/reactive-loader.service";
import {Router} from "@angular/router";
import {ContactModalComponent, PhoneNumberModalComponent} from "@shared/components";
import {IPhoneNumber} from "@models/dist/models/auth";
import {loadMyUser} from "@shared/stores/auth/auth.actions";
import {ProfileService} from "@profile/services/profile.service";
import {DialogService} from "primeng/dynamicdialog";
import placekit, {PKAClient} from '@placekit/autocomplete-js';
import {PrimeNGConfig} from "primeng/api";
import {LocalFr} from './local.fr'
import {LocalEn} from './local.en'

export const IKYCLightStep = ["INIT", "PROFIL", "FILE", "IMAGE"]

export interface Country {
  name: string,
}

@Component({
  selector: 'app-kyc-light',
  templateUrl: './kyc-light.component.html',
  styleUrls: ['./kyc-light.component.scss', './../scss/profile.scss']
})
export class KycLightComponent {
  // form?: FormGroup;
  // @Select(AuthState.getUser) user$: Observable<IUser> | undefined;
  // private user?: IUser;
  // steps = IKYCLightStep;
  // currentStep = 0;
  //
  // ref: any;
  //
  // lang$ = new BehaviorSubject<ELang>(this.translate.currentLang as ELang);
  // countries$ = this.lang$.pipe(
  //   map((lang) => {
  //     return AllCountries.map((data) => {
  //       return {...data, name: data.name[lang]}
  //     }).sort((a, b) => {
  //       if (a.name < b.name) {
  //         return -1;
  //       }
  //       if (a.name > b.name) {
  //         return 1;
  //       }
  //       return 0;
  //     })
  //   })
  // );
  //
  //
  // countryFr = AllCountries.find(({code}) => code === 'FR')!;
  // france = this.countryFr
  // //{...this.countryFr, name: this.countryFr.name[this.lang$.getValue()]}
  //
  // onfido: any;
  // public loadingOnfido = true;
  // minDate: Date = new Date();
  // maxDate: Date = new Date();
  // middleDate: Date = new Date();
  // loading = true;
  //
  //
  // localStorageKey = "kyc_light_step"
  // public manualEnterAddress: boolean = false;
  // files: any[] = [];
  //
  //
  // genders = [
  //   {name: 'Monsieur', id: 'mr'},
  //   {name: 'Madame', id: 'md'},
  //   {name: 'Autre', id: 'other'},
  // ]
  //
  // constructor(
  //   private fb: FormBuilder,
  //   private onfidoService: OnfidoService,
  //   private translate: TranslateService,
  //   private reactiveLoader: ReactiveLoaderService,
  //   private router: Router,
  //   private profileService: ProfileService,
  //   private dialogService: DialogService,
  //   private store: Store,
  //   private toastService: ToastService,
  //   private config: PrimeNGConfig
  // ) {
  //
  // }
  //
  // ngAfterViewInit(): void {
  //   this.setUpPlaceKit();
  // }
  //
  //
  // ngOnInit() {
  //
  //   this.translate.onLangChange.subscribe((lang) => {
  //     const langValue = lang.lang as ELang
  //     this.setUpPlaceKit();
  //     this.lang$.next(langValue);
  //     this.translate.get('primeng').subscribe(res => this.config.setTranslation(res));
  //
  //   })
  //   this.translate.get('primeng').subscribe(res => this.config.setTranslation(res));
  //
  //   this.user$?.pipe(filter(data => !!data)).subscribe(async (user) => {
  //     const lang = this.lang$.getValue();
  //     this.user = user;
  //     if (!!user['fk_clickup_kyc_id']) {
  //       this.loading = false;
  //     } else {
  //       await localStorage.removeItem(this.localStorageKey);
  //     }
  //     const banned = BannedCountry.find(({code}) => code === user.country)
  //     const risk = RiskCountry.find(({code}) => code === user.country);
  //     if ((this.user?.kycLightStatus !== EPassfortProductState.NONE && this.user?.kycLightStatus !== EPassfortProductState.REJECTED) && (!!risk || !!banned)) {
  //       this.router.navigateByUrl('/profile/kyc')
  //     }
  //
  //     const now = new Date();
  //     this.minDate = new Date(new Date().setFullYear(now.getFullYear() - 100));
  //     this.middleDate = new Date(new Date().setFullYear(now.getFullYear() - 35));
  //     this.maxDate = new Date(new Date().setFullYear(now.getFullYear() - 18));
  //     const country = !!user.country ? AllCountries.find(({code}) => code === user.country) : this.france;
  //     const nationality = !!user.nationality ? AllCountries.find(({code}) => code === user.nationality) : this.france;
  //
  //     const nationalityFormValue = {...nationality, name: nationality?.name[lang]};
  //     const countryFormValue = {...country, name: country?.name[lang]}
  //     this.form = this.fb.group({
  //       firstName: this.fb.control(user.firstName, [Validators.required]),
  //       lastName: this.fb.control(user.lastName, [Validators.required]),
  //       phoneNumber: this.fb.control({
  //         value: user.phoneNumber ?? null,
  //         disabled: true
  //       }, [Validators.required]),
  //       birthday: this.fb.control(!!user.birthday ? new Date(user.birthday) : null, [Validators.required]),
  //       email: this.fb.control({value: user.email, disabled: true}, [Validators.required]),
  //       nationality: this.fb.control(nationalityFormValue, [Validators.required]),
  //       otherNationality: this.fb.control(nationalityFormValue, [Validators.required]),
  //       searchCountry: this.fb.control(''),
  //       searchNationality: this.fb.control(''),
  //       country: this.fb.control(countryFormValue, [Validators.required]),
  //       onfido: this.fb.control(true, [Validators.required, Validators.requiredTrue]),
  //       postalAddress: this.fb.control(user.postalAddress, [Validators.required]),
  //       postalAddressLigne2: this.fb.control(null, []),
  //       postalCode: this.fb.control(user.postalCode, [Validators.required]),
  //       city: this.fb.control(user.city, [Validators.required]),
  //       gender: this.fb.control('', [Validators.required])
  //     });
  //
  //     this.setUpPlaceKit();
  //     await this.profileService.saveStepKycLight(this.form?.value, this.currentStep);
  //     const currentStep = localStorage.getItem(this.localStorageKey);
  //     if (!!currentStep) {
  //
  //       this.currentStep = Number(currentStep);
  //
  //       if (this.currentStep === 2) {
  //         await this.initOnfido();
  //       } else if (this.currentStep > 2) {
  //         this.currentStep = 0;
  //         localStorage.removeItem(this.localStorageKey)
  //       }
  //     }
  //     this.loading = false;
  //   })
  // }
  //
  // async next() {
  //
  //   let step = this.currentStep + 1
  //
  //   if (this.form?.invalid && this.currentStep > 0) {
  //     return;
  //   }
  //
  //   this.reactiveLoader.setReactiveButtonState('next', reactiveButtonState.loading)
  //
  //   await this.profileService.saveStepKycLight(this.form?.value, step, this.files);
  //
  //   localStorage.setItem(this.localStorageKey, `${step}`);
  //
  //   this.reactiveLoader.setReactiveButtonState('next', reactiveButtonState.success)
  //
  //   this.currentStep++;
  //
  //   this.setUpPlaceKit();
  //
  //   if (this.currentStep === 3) {
  //     await this.save();
  //     return;
  //   }
  //
  //
  //   if (this.currentStep === 2) {
  //     this.form?.patchValue({
  //       onfido: false
  //     })
  //     await this.initOnfido();
  //     return;
  //   }
  //
  //
  // }
  //
  // setUpPlaceKit() {
  //   setTimeout(() => {
  //     let pka: PKAClient | null = null;
  //     if (!!this.user?.country) {
  //       pka = placekit('pk_+4GHLYY/lnc2UrGg1G486Nlcc2bRF2mFRzHtP861L+E=', {
  //         target: '#placekit',
  //         language: this.translate.currentLang as ELang,
  //         types: ['street'],
  //         countries: [`${this.user?.country}`],
  //       });
  //     } else {
  //       pka = placekit('pk_+4GHLYY/lnc2UrGg1G486Nlcc2bRF2mFRzHtP861L+E=', {
  //         target: '#placekit',
  //         types: ['street'],
  //         language: this.translate.currentLang as ELang,
  //       });
  //     }
  //     pka.on('empty', (value) => {
  //       if (value) {
  //         this.form?.patchValue({
  //           postalAddress: null,
  //           postalCode: null,
  //           city: null
  //         })
  //       }
  //     })
  //     pka.on('pick', (value, item, index) => {
  //       this.form?.patchValue({
  //         postalAddress: `${value}, ${item.zipcode[0]}, ${item.city}`,
  //         postalCode: item.zipcode[0],
  //         city: item.city
  //       })
  //     })
  //   }, 300)
  //
  // }
  //
  // back() {
  //   this.setUpPlaceKit()
  //
  //   this.currentStep--;
  //   this.form?.patchValue({
  //     onfido: true
  //   })
  // }
  //
  // selectIDCardFile(event) {
  //   const file = event?.currentFiles[0];
  //   console.log(file);
  //   this.form?.patchValue({
  //     idCard: file,
  //   })
  // }
  //
  // selectProveFile(event) {
  //   const file = event?.currentFiles[0];
  //   this.form?.patchValue({
  //     proveAddress: file,
  //   })
  // }
  //
  // private async save() {
  //
  // }
  //
  // private initOnfido() {
  //   // this.onfidoService.getSdkToken().subscribe((data: any) => {
  //   //   this.loadingOnfido = false;
  //   //   const {token} = data;
  //   //   const locale: SupportedLanguages = (this.translate.currentLang as ELang === ELang.FR) ? 'fr_FR' : 'en_US';
  //   //   this.onfido = init({
  //   //     token: token,
  //   //     // useModal: true,
  //   //     isWebView: true,
  //   //     containerId: 'onfido-mount',
  //   //     language: {
  //   //       locale,
  //   //       direction: 'ltr',
  //   //       phrases: locale === 'fr_FR' ? LocalFr : LocalEn
  //   //     },
  //   //     onComplete: async (data) => {
  //   //       await this.onCompleteOnFido(data);
  //   //     },
  //   //     steps: [
  //   //       {
  //   //         type: 'welcome',
  //   //         options: {
  //   //           title: this.translate.instant('KYC.onfido.title'),
  //   //           nextButton: this.translate.instant('KYC.onfido.nextButton'),
  //   //           descriptions: [
  //   //             this.translate.instant('KYC.onfido.description1'),
  //   //             this.translate.instant('KYC.onfido.description2'),
  //   //             this.translate.instant('KYC.onfido.description3'),
  //   //             this.translate.instant('KYC.onfido.description4'),
  //   //             this.translate.instant('KYC.onfido.description5'),
  //   //           ]
  //   //         }
  //   //       },
  //   //       {
  //   //         type: 'poa',
  //   //         options: {
  //   //
  //   //           hideCountrySelection: true,
  //   //           documentTypes: {
  //   //             utility_bill: true,
  //   //             bank_building_society_statement: false,
  //   //             council_tax: false,
  //   //             benefit_letters: false,
  //   //             address_certificate: false,
  //   //             mortgage_statement: false,
  //   //             identity_document_with_address: false,
  //   //             mobile_phone_bill: false,
  //   //             general_letter: false,
  //   //             insurance_statement: false,
  //   //             pension_property_statement_letter: false,
  //   //             exchange_house_statement: false,
  //   //           }
  //   //         }
  //   //       },
  //   //       {
  //   //         type: 'document',
  //   //         options: {
  //   //           hideCountrySelection: true,
  //   //           documentTypes: {
  //   //             passport: true,
  //   //             national_identity_card: true,
  //   //             residence_permit: true
  //   //           }
  //   //         }
  //   //       },
  //   //       {type: 'face'},
  //   //       {type: 'complete'}],
  //   //   });
  //   // })
  // }
  //
  // async onCompleteOnFido(data: SdkResponse) {
  //   try {
  //
  //
  //     this.form?.patchValue({
  //       onfido: true,
  //     })
  //     this.reactiveLoader.setReactiveButtonState(
  //       'next',
  //       reactiveButtonState.loading
  //     );
  //     await this.onfidoService.storeData({data, ...this.form?.value});
  //     this.store.dispatch(new loadMyUser());
  //     this.toastService.addToast(
  //       EToastSeverity.success,
  //       'KYC.page.info',
  //       'KYC.page.submited'
  //     )
  //     this.reactiveLoader.setReactiveButtonState(
  //       'next',
  //       reactiveButtonState.success
  //     );
  //
  //     await this.router.navigate(['/profile/kyc']);
  //     this.ngOnDestroy();
  //     this.currentStep = 0;
  //     localStorage.removeItem(this.localStorageKey);
  //   } catch (e) {
  //     this.reactiveLoader.setReactiveButtonState(
  //       'next',
  //       reactiveButtonState.error
  //     );
  //   }
  // }
  //
  // ngOnDestroy() {
  //   if (!!this.onfido) this.onfido.safeTearDown();
  //   this.currentStep = 0;
  // }
  //
  // async openPhoneModal() {
  //   const ref = this.dialogService.open(PhoneNumberModalComponent, {
  //     showHeader: false,
  //     dismissableMask: true,
  //     data: {
  //       email: this.user?.email,
  //     },
  //     styleClass: 'p-0 phoneModal',
  //     contentStyle: {
  //       'padding': '0px !important',
  //       'width': '100%',
  //       'min-width': '680px',
  //       'max-width': '550px',
  //       'border-radius': '20px'
  //     },
  //     baseZIndex: 10000,
  //   });
  //
  //   ref.onClose.subscribe(async (data: IPhoneNumber) => {
  //     if (data?.phoneNumber) {
  //       try {
  //         await this.profileService.updatePhoneNumber()
  //         this.store.dispatch(new loadMyUser());
  //       } catch (e) {
  //       }
  //     }
  //   })
  // }
  //
  // clear(options: any) {
  //   options.reset();
  //   this.form?.patchValue({
  //     searchCountry: ''
  //   })
  // }
  //
  // clearNationality(options: any) {
  //   options.reset();
  //   this.form?.patchValue({
  //     searchNationality: ''
  //   })
  // }
  //
  // contactModal() {
  //   this.ref = this.dialogService.open(ContactModalComponent, {
  //     showHeader: false,
  //     dismissableMask: true,
  //     data: {
  //       title: 'contactUs'
  //     },
  //     contentStyle: {'max-width': '860px', 'border-radius': '20px'},
  //     baseZIndex: 10000,
  //   })
  //
  // }
  //
  // async mannualyEnterAddr() {
  //   this.manualEnterAddress = !this.manualEnterAddress;
  //   if (!this.manualEnterAddress) {
  //     console.log('auto');
  //     await this.setUpPlaceKit();
  //     this.form?.patchValue({
  //       postalAddress: null,
  //       postalCode: null,
  //       city: null
  //     })
  //   }
  //
  // }
  //
  // onUpload(data: any) {
  //   console.log(data);
  //   const file = data.currentFiles[0];
  //   console.log(file);
  //   this.files = [...this.files, file];
  // }
}
