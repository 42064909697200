import {Component, Input} from '@angular/core';
import {AuthState} from '@shared/stores/auth/auth.state';
import {Select} from '@ngxs/store';
import {DialogService} from 'primeng/dynamicdialog';
import {Observable} from 'rxjs';
import {IUser} from "@models/dist";
import {ChangeAvatarModalComponent} from "@profile/components";

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['../../modules/referral/scss/referral.scss'],
  styles: []
})
export class UserDetailsComponent {
  @Select(AuthState.getUser) user$: Observable<IUser> | undefined;
  selectedCountry: any;
  countries: any[];
  ref: any;
  @Input() noAvatar = false;
  @Input() edit = false;

  constructor(public dialogService: DialogService) {
    this.selectedCountry = {name: 'France', code: 'FR'}
    this.countries = [
      {name: 'Australia', code: 'AU'},
      {name: 'Brazil', code: 'BR'},
      {name: 'China', code: 'CN'},
      {name: 'Egypt', code: 'EG'},
      {name: 'France', code: 'FR'},
      {name: 'Germany', code: 'DE'},
      {name: 'India', code: 'IN'},
      {name: 'Japan', code: 'JP'},
      {name: 'Spain', code: 'ES'},
      {name: 'United States', code: 'US'},
    ];
  }

  handleChangeAvatar() {
    if (!this.edit) {
      return;
    }
    this.dialogService.open(ChangeAvatarModalComponent, {
      showHeader: false,
      width: '360px',
      styleClass: 'avatarModal',
      dismissableMask: true,
      contentStyle: {
        'max-height': '500px',
        'overflow': 'hidden',
        'border-radius': '20px'
      }
    });
  }
}
