import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-ico-btn',
  templateUrl: './ico-btn.component.html',
  styleUrls: ['./ico-btn.component.scss']
})
export class IcoBtnComponent implements OnInit {

  @Output() btnClick = new EventEmitter<void>();

  @Input()
  text = '';

  @Input()
  rId = 'cannotBeEmpty';

  @Input()
  icon = '';

  @Input()
  disabled: boolean | null = false;


  @Input() outline = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  handleButtonClick(): void {
    if (!this.disabled) {
      this.btnClick.emit();
    }
  }
}
